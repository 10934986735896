import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import IntlMessage from "../../component/const/IntlMessage";
import {UniButton} from "../../Elements";

interface RowConfig {
    type: string;
    dataProperty: string;
    buttonVariant: any;
    targetWindow: string;
    messageId: string;
    size: any;
}
function buttonChangeable(
    data: { [key: string]: any },
    Store: any,
    Rows: string,
    tableBodyPositionElement: any
): JSX.Element {
    const rowConfig: RowConfig = {
        type: Rows.split('|')[0],
        dataProperty: Rows.split('|')[1],
        buttonVariant: Rows.split('|')[2],
        targetWindow: Rows.split('|')[3],
        messageId: Rows.split('|')[4],
        size: Rows.split('|')[5],
    };

    const { type, dataProperty, buttonVariant, targetWindow, messageId, size } = rowConfig;


    const buttonData = data[dataProperty];

    return (
        <>
            <TableCell
                style={{ textAlign: tableBodyPositionElement }}
                className={type}
            >
                <UniButton
                    type="button" // Explicitly set button type for clarity
                    href={buttonData}
                    variant={buttonVariant}
                    target={targetWindow}
                    size={size}
                    class="table-button-single"
                >
                    <IntlMessage Store={Store} messageId={messageId} />
                </UniButton>
            </TableCell>
        </>
    );
}

/**
 * Exports the default buttonChangeable function.
 */
export default buttonChangeable;