import React from 'react'
import AppLocale from '../../../templates/default/localization'
import PreperatorReplace from '../../../templates/default/localization/preperator/replace'
import OwnerName from '../../../templates/default/localization/preperator/owner_name'

interface IntlProps {
    messageId: string
    locale?: string
    preperator?: string
    param?: string
    replace?: string | Element | number
    htmlReplace?: boolean
    Store: any
}

const IntlMessage: React.FC<IntlProps> = (props) => {
    const Store = props.Store

    /**
     * locale wird aus der localStorage geholt
     */
    let locales =
        Store !== undefined &&
        Store !== null &&
        Store.locale !== undefined &&
        Store.locale !== null &&
        Store.locale.locale !== '' &&
        Store.locale.locale !== undefined &&
        Store.locale.locale !== null
            ? Store.locale.locale
            : localStorage.getItem('locale')

    let locale =
        locales !== undefined && locales !== null ? locales.toLowerCase() : 'de'

    let languageFile
    if (AppLocale[locale] !== undefined) {
        languageFile = AppLocale[locale]?.messages

        /**
         *  Falls vorhanden wird der Language string entnommen.
         */

        let messageValue = languageFile[props.messageId]

        if (messageValue === undefined) {
            messageValue = props.messageId
        }

        /**
         * Preperatoren werden durchlaufen falls einer angegeben wurde.
         *
         */
        switch (props.preperator) {
            case 'replace':
                let searchAndReplace = new PreperatorReplace(
                    messageValue,
                    props.param,
                    props.replace
                )
                messageValue = searchAndReplace.Init()
                break
            case 'owner':
                let preperatorOwnerName = new OwnerName(messageValue)
                messageValue = preperatorOwnerName.Init()
                break
        }

        if (props.htmlReplace !== undefined && props.htmlReplace === true) {
            messageValue = replaceHTMLTag(messageValue)
        }

        return messageValue
    } else {
        return <></>
    }
}

export default IntlMessage

export const IntlMessageValue = (
    locale: any,
    messageId: any,
    Store: any,
    preperator: string = '',
    param: string = '',
    replace: string | number | Element = '',
    htmlReplace?: boolean
) => {
    /**
     * locale wird aus der localStorage geholt
     */
    let locales =
        Store !== undefined &&
        Store !== null &&
        Store.locale !== undefined &&
        Store.locale !== null &&
        Store.locale.locale !== '' &&
        Store.locale.locale !== undefined &&
        Store.locale.locale !== null
            ? Store.locale.locale
            : localStorage.getItem('locale')

    let localeValue =
        locales !== undefined && locales !== null && locales !== 'undefined' ? locales.toLowerCase() : 'de'

    let languageFile

    if (AppLocale[localeValue] !== undefined) {
        languageFile = AppLocale[localeValue]?.messages

        /**
         *  Falls vorhanden wird der Language string entnommen.
         */

        let messageValue = languageFile[messageId]

        if (messageValue === undefined) {
            messageValue = messageId
        }

        /**
         * Preperatoren werden durchlaufen falls einer angegeben wurde.
         *
         */
        switch (preperator) {
            case 'replace':
                let searchAndReplace = new PreperatorReplace(
                    messageValue,
                    param,
                    replace
                )
                messageValue = searchAndReplace.Init()
                break
            case 'owner':
                let preperatorOwnerName = new OwnerName(messageValue)
                messageValue = preperatorOwnerName.Init()
                break
        }

        if (htmlReplace !== undefined && htmlReplace === true) {
            messageValue = replaceHTMLTag(messageValue)
        }

        return messageValue
    } else {
        return <></>
    }
}

const replaceHTMLTag = (data: any) => {
    var htmlReplace = data
    for (let x = 0; x < mapSearchAndReplace().length; x++) {
        htmlReplace = htmlReplace.replaceAll(
            mapSearchAndReplace()[x].search,
            mapSearchAndReplace()[x].replace
        )
    }

    return htmlReplace
        .replaceAll(/&lt;/g, '<')
        .replaceAll(/&gt;/g, '>')
        .replaceAll(/&amp;/g, '&')
}

const mapSearchAndReplace = () => {
    const map = [
        { search: '[bold]', replace: '<strong>' },
        { search: '[/bold]', replace: '</strong>' }
    ]
    return map
}
