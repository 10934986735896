import React from "react";
import Reports from "./Pages/reports";
import WorkTimeTrackingOverview from "./Pages/overview";
import WorkTimeTrackingCalender from "./Pages/worktimetrackingcalender";
import TimeSheet from "./Pages/timeSheet";
import Planer from "./Pages/planer";
import {CryptDecrypt} from "../../@WUM/core/Elements/crypt/Crypt";

export const WorkTimeTrackingConfig = (value:any) => {
    if(localStorage.getItem('userRights') !== null && localStorage.getItem('userRights') !== undefined && JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string).worktimetracking === 0) {
        return []
    } else {
        return [
            {
                path: '/worktime/tracking/overview/:page',
                elements: <WorkTimeTrackingOverview value={value}/>,
                roles: ['superadmin', 'admin', 'worktime']
            },
            {
                path: '/calender/worktime/tracking/software/:page',
                elements: <WorkTimeTrackingCalender value={value}/>,
                roles: ['superadmin', 'admin', 'worktime']
            },
            {
                path: '/time/sheet/work/time/:page',
                elements: <TimeSheet value={value}/>,
                roles: ['superadmin', 'admin', 'worktime']
            },
            {
                path: '/planer/work/time/:page',
                elements: <Planer value={value}/>,
                roles: ['superadmin', 'admin', 'worktime']
            },
            {
                path: '/software/report/support/:page',
                elements: <Reports value={value}/>,
                roles: ['superadmin', 'admin', 'worktime']
            }
        ]
    }
}

export const WorkTimeTrackingMenuConfig = (value:any) => {
    if(localStorage.getItem('userRights') !== null && localStorage.getItem('userRights') !== undefined && JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string).worktimetracking === 0) {
        return []
    } else {
        return [
            {
                header: 'Arbeitszeiterfassung',
                icon: 'hourglass_empty',
                roles: ['superadmin', 'worktime'],
                menuitem: [
                    {
                        name: 'Dashboard',
                        route: '/worktime/tracking/overview/1',
                        icon: 'dashboard',
                        roles: ['superadmin', 'admin', 'worktime']
                    },
                    {
                        name: 'Time Entries',
                        route: '/calender/worktime/tracking/software/1',
                        icon: 'alarm_on',
                        roles: ['superadmin', 'admin', 'worktime']
                    },
                    {
                        name: 'Time Sheets',
                        route: '/time/sheet/work/time/1',
                        icon: 'alarm',
                        roles: ['superadmin', 'admin', 'worktime']
                    },
                    {
                        name: 'Planer',
                        route: '/planer/work/time/1',
                        icon: 'calendar_today',
                        roles: ['superadmin', 'admin', 'worktime']
                    }

                    ,/*
            {
                name: 'Berichte',
                route: '/software/report/support/1',
                icon: 'query_stats',
                roles: ['superadmin', 'admin', 'worktime']
            }*/
                ]
            }
        ]
    }
}

export const WorkTimeTracking = () => [

    {
        title: 'Dashboard',
        href: '/worktime/tracking/overview/1',
        undertitle: 'Arbeitszeiterfassung',
        icon: 'dashboard',
        text: 'Arbeitszeiterfassung',
        roles: ['superadmin', 'admin', 'worktime']
    },
    {
        title: 'Time Entries',
        href: '/calender/worktime/tracking/software/1',
        undertitle: 'Arbeitszeiterfassung',
        icon: 'alarm_on',
        text: 'Arbeitszeiterfassung',
        roles: ['superadmin', 'admin', 'worktime']
    },
    {
        title: 'Time Sheets',
        href: '/time/sheet/work/time/1',
        undertitle: 'Arbeitszeiterfassung',
        icon: 'calendar_today',
        text: 'Arbeitszeiterfassung',
        roles: ['superadmin', 'admin', 'worktime']
    },
    {
        title: 'Planer',
        href: '/planer/work/time/1',
        undertitle: 'Arbeitszeiterfassung',
        icon: 'alarm',
        text: 'Arbeitszeiterfassung',
        roles: ['superadmin', 'admin', 'worktime']
    }
]
