import React, {useState, useEffect, useRef, CSSProperties} from 'react';
import './draganddrop.css';
import Card from "react-bootstrap/Card";

interface DraggableDivProps {
    content: React.ReactNode;
    className:string;
    /** Set inline style for Element */
    style?:CSSProperties | undefined
    stylebox?:CSSProperties | undefined
}

const DraggableDiv: React.FC<DraggableDivProps> = ({content,className,style,stylebox}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const boxRef = useRef<HTMLDivElement>(null);

    const isClicked = useRef<boolean>(false);

    const coords = useRef<{
        startX: number,
        startY: number,
        lastX: number,
        lastY: number
    }>({
        startX: 0,
        startY: 0,
        lastX: 0,
        lastY: 0
    })

    useEffect(() => {
        if (!boxRef.current || !containerRef.current) return;

        const boxs = boxRef.current;
        const box = boxs.querySelectorAll('.draggable-header')[0] as HTMLDivElement;
        const container = containerRef.current;

        const onMouseDown = (e: MouseEvent) => {
            isClicked.current = true;
            coords.current.startX = e.clientX;
            coords.current.startY = e.clientY;
        }

        const onMouseUp = (e: MouseEvent) => {
            isClicked.current = false;
            coords.current.lastX = boxs.offsetLeft;
            coords.current.lastY = boxs.offsetTop;
        }

        const onMouseMove = (e: MouseEvent) => {
            if (!isClicked.current) return;

            const nextX = e.clientX - coords.current.startX + coords.current.lastX;
            const nextY = e.clientY - coords.current.startY + coords.current.lastY;

            boxs.style.top = `${nextY}px`;
            boxs.style.left = `${nextX}px`;
        }

        box.addEventListener('mousedown', onMouseDown);
        box.addEventListener('mouseup', onMouseUp);
        container.addEventListener('mousemove', onMouseMove);
        container.addEventListener('mouseleave', onMouseUp);

        const cleanup = () => {
            box.removeEventListener('mousedown', onMouseDown);
            box.removeEventListener('mouseup', onMouseUp);
            container.removeEventListener('mousemove', onMouseMove);
            container.removeEventListener('mouseleave', onMouseUp);
        }

        return cleanup;
    }, [])

    return (
        <>
            <Card ref={containerRef} style={style} className={className}>
                <div ref={boxRef} className="boxDrag border rounded">
                    <Card.Body style={stylebox}>
                        <div className={'draggable-header'} style={{marginTop:'-10px'}}>
                            <span className="material-icons-outlined">
                                open_with
                            </span>
                        </div>
                        {content}
                    </Card.Body>
                </div>
            </Card>
        </>
    );
};

export default DraggableDiv;
