import {useEffect, useState} from "react";
import {chatInterface} from "../request/chat.interface";
import {chat} from "../request/chat";
import {checkLogin} from "../../../../templates/default/Auth/service/AuthService";
import {error} from "../../../component/const/error.services";
import {UserInterfaceApiType} from "../request/user.interface";
import {groupInterface} from "../request/group.interface";

export const ResponseAllChat = (id: any,fetchData:any) => {
    const [posts, setPosts] = useState<chatInterface[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await chat
                .getPostsAllChat(id)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id,fetchData])

    return isError ? isError : posts
}

export const ResponseAllGroup = (fetchData:any) => {
    const [posts, setPosts] = useState<groupInterface[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await chat
                .getPostGroup()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [fetchData])

    return isError ? isError : posts
}

export const ResponseAllUser = () => {
    const [posts, setPosts] = useState<UserInterfaceApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await chat
                .getPostUser()
                .then((data) => {
                    setPosts(data)
/*
                    localStorage.setItem('AllUser', JSON.stringify(data))*/
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [])

    return isError ? isError : posts
}
