export const collapse = (e:any,id:any,arrowShow:any=true,noneShow:any = [],withLabel:any=false,setFetchData?:any) => {

    if(arrowShow === true) {
        let arrow = document.getElementById(id + '_arrow') as HTMLSpanElement

        let Collapse = document.getElementById(id) as HTMLDivElement;
        if (Collapse.classList.contains("d-none") === true) {
            Collapse.classList.remove("d-none");
            Collapse.classList.add("d-block");
            arrow.innerHTML = 'expand_less'
        } else {
            Collapse.classList.add("d-none");
            Collapse.classList.remove("d-block");
            arrow.innerHTML = 'expand_more'
        }
    } else {
        let Collapse = document.getElementById(id) as HTMLDivElement;
        if (Collapse.classList.contains("d-none") === true) {
            Collapse.classList.remove("d-none");
            Collapse.classList.add("d-block");
            if(withLabel === true) {
                let CollapseLabel = document.getElementById(id+'_label') as HTMLDivElement;
                CollapseLabel.classList.add("bg-primary");
                CollapseLabel.classList.remove("bg-primary-subtle");
                setFetchData(Date.now())
            }
            for(let ns=0;ns<noneShow.length;ns++) {
                let CollapseClose = document.getElementById(noneShow[ns]) as HTMLDivElement;
                CollapseClose.classList.add("d-none");
                CollapseClose.classList.remove("d-block");
                if(withLabel === true) {
                    let CollapseLabel = document.getElementById(noneShow[ns]+'_label') as HTMLDivElement;
                    CollapseLabel.classList.add("bg-primary-subtle");
                    CollapseLabel.classList.remove("bg-primary");
                    setFetchData(Date.now())
                }
            }
        }
    }

}
