import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import IntlMessage from "../../component/const/IntlMessage";
import { UniButton } from "../../Elements";

/**
 * Interface defining the expected format for the 'Rows' string.
 *
 * @typedef {Object} RowConfig
 * @property {string} type - The type of button cell to render.
 * @property {string} dataProperty1 - The property in the 'data' object for button 1's data.
 * @property {string} dataProperty2 - The property in the 'data' object for button 2's data.
 * @property {any} buttonVariant - The variant of the buttons (e.g., primary, secondary).
 * @property {string} targetWindow - The target window for the buttons (e.g., _blank).
 * @property {string} messageId1 - The message ID for button 1's text.
 * @property {string} messageId2 - The message ID for button 2's text.
 * @property {any} size - The size of the buttons (e.g., small, medium, large).
 */

interface RowConfig {
    type: string;
    dataProperty1: string;
    dataProperty2: string;
    buttonVariant: any;
    targetWindow: string;
    messageId1: string;
    messageId2: string;
    size: any;
}

/**
 * Renders two clickable button cells in a Material-UI table.
 *
 * @param {object} data - The data object containing information for the buttons.
 * @param {any} Store - The IntlMessage store for localization.
 * @param {string} Rows - A string containing button configuration in the format:
 *                         "dataProperty1|dataProperty2|buttonClass|targetWindow|messageId1|messageId2|size"
 * @param {any} tableBodyPositionElement - The CSS alignment of the buttons within the cell
 *                                            (e.g., "left", "center", "right").
 * @returns {JSX.Element} A React component representing the two button cells.
 */
function doubleButtonChangeable(
    data: { [key: string]: any },
    Store: any,
    Rows: string,
    tableBodyPositionElement: any
): JSX.Element {
    /**
     * Converts the 'Rows' string to a RowConfig object.
     *
     * @returns {RowConfig} The RowConfig object.
     */
    const rowConfig: RowConfig = {
        type: Rows.split('|')[0],
        dataProperty1: Rows.split('|')[1],
        dataProperty2: Rows.split('|')[2],
        buttonVariant: Rows.split('|')[3],
        targetWindow: Rows.split('|')[4],
        messageId1: Rows.split('|')[5],
        messageId2: Rows.split('|')[6],
        size: Rows.split('|')[7],
    };

    const { type, dataProperty1, dataProperty2, buttonVariant, targetWindow, messageId1, messageId2, size } = rowConfig;

    // Retrieve button data from the 'data' object.
    const buttonData1 = data[dataProperty1];
    const buttonData2 = data[dataProperty2];

    return (
        <>
            <TableCell
                style={{ textAlign: tableBodyPositionElement }}
                className={type}
            >
                <UniButton
                    type="button"
                    href={buttonData1}
                    variant={buttonVariant}
                    target={targetWindow}
                    size={size}
                    class="me-1 table-button-first"
                >
                    <IntlMessage Store={Store} messageId={messageId1} />
                </UniButton>
                <UniButton
                    type="button"
                    href={buttonData2}
                    variant={buttonVariant}
                    target={targetWindow}
                    size={size}
                    class="table-button-second"
                >
                    <IntlMessage Store={Store} messageId={messageId2} />
                </UniButton>
            </TableCell>
        </>
    );
}

export default doubleButtonChangeable;
