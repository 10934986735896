import React from 'react'

import PluginOverview from "./Pages/overview";

export const PluginPagesConfig = (value: any) => {
    if(process.env.REACT_APP_PLUGIN_APP === 'True') {
        return [
            {
                path: '/plugins/overview/:page',
                elements: <PluginOverview value={value} />,
                roles: [
                    'superadmin',
                    'admin',
                ]
            }
        ]
    } else {
        return [ ]
    }

}

export const PluginMenuConfig = (value: any) => {
    if(process.env.REACT_APP_PLUGIN_APP === 'True') {
        return [
            {
                header: 'Plugins & App',
                icon: 'extension',
                roles: ['superadmin', 'admin'],
                menuitem: [
                    {
                        name: 'common.dashbaord',
                        route: '/plugins/overview/1',
                        icon: 'view_headline',
                        roles: ['superadmin', 'admin']
                    },/*
            {
                name: 'Berichte',
                route: '/software/report/support/1',
                icon: 'query_stats',
                roles: ['superadmin', 'admin', 'merchant']
            }*/
                ]
            }
        ]
    } else{
        return [ ]
    }
}
