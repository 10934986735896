
import {requests} from "../../../../../@WUM/core/RestFullApi/api";
import {_UserInterface, depatments, Position} from "./SupportSoftwareApi.interface";

export const Department = {
    getPosts: (id:any): Promise<_UserInterface> =>
        requests.get(`User/${id}/`),
    getPostsDepartment: (): Promise<depatments[]> =>
        requests.get(`yoptimize/fetch/departments/`),
    getPostsDepartmentDetail: (): Promise<depatments[]> =>
        requests.get(`yoptimize/departments/`),
    getPostsSingleDepartmentDetail: (did:any): Promise<Position[]> =>
        requests.get(`yoptimize/departments/${did}/positions/ `),
    updatePost: (post: any, id: any): Promise<_UserInterface> =>
        requests.put(`User/update/${id}/`, post),
}
