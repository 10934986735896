import React, {useEffect, useState} from "react";
import {error, IntlMessageValue, UniForm} from "../../../../../@WUM/core/Elements/index";
import {Department} from "../request/support_software";
import {checkLogin} from "../../../../../@WUM/templates/default/Auth/service/AuthService";
import {_UserInterface, depatments, Position} from "../request/SupportSoftwareApi.interface";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import {ApiRoot} from "../../../../../@WUM/core/RestFullApi/api";

export const ResponseDeparmtentDetail = (id:any,fetchData:any) => {
    const [posts, setPosts] = useState<depatments[]>()
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
            ;(async () => {
                    await Department
                        .getPostsDepartmentDetail()
                        .then((data) => {
                            checkLogin(data)
                            setPosts(data)
                            //console.log(data);
                        })
                        .catch((err) => {
                            error(err)
                            setIsError(true)
                        })
                })()
    }, [id,fetchData])

    return isError ? isError : posts
}

export const ResponseSingleDeparmtentDetail = (did:any,Store:any,setDepartmensJSX:any) => {

    const [fetchData, setFetchData] = useState(0);

    const [posts, setPosts] = useState<Position[]>()
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await Department
                .getPostsSingleDepartmentDetail(did)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    let positionsHolder = [];

                        for (let p = 0; p < data.length; p++) {
                            console.log('Status: '+data[p].status )
                            positionsHolder.push(
                                <>
                                    <Row>
                                        <Col>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="basic-addon1">Position</InputGroup.Text>
                                                <Form.Control
                                                    placeholder="Username"
                                                    aria-label="Username"
                                                    aria-describedby="basic-addon1"
                                                    name={'position'}
                                                    defaultValue={
                                                        IntlMessageValue('de', data[p].name, Store)
                                                    }
                                                />
                                            </InputGroup>
                                        </Col>
                                        <Col>

                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={'Status'}/>
                                                <ToggleButtonGroup type="checkbox" className={'mb-2'} id={'status'+data[p].id} defaultValue={(data[p].status == 1 ? data[p].status : 0)} style={{display: "block"}}
                                                                   >
                                                    <ToggleButton id={"tbg-btn-2"+data[p].id} value={1} onClick={(e)=>switchStatePosition(e,data[p].did,data[p].id,1,setFetchData)}>
                                                        Aktiv
                                                    </ToggleButton>
                                                    <ToggleButton id={"tbg-btn-3"+data[p].id} value={0} onClick={(e)=>switchStatePosition(e,data[p].did,data[p].id,-1,setFetchData)}>
                                                        Inaktiv
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </UniForm.InputGroup>
                                        </Col>
                                    </Row>

                                </>
                            )

                        setDepartmensJSX(positionsHolder)
                    }
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [did,fetchData])

    return isError ? isError : posts
}

export const ResponseDeparmtent = (id: any,fetchData?:any,setCount?:any) => {
    const [posts, setPosts] = useState<depatments[]>()
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await Department
                .getPostsDepartment()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    if(setCount !== undefined) {
                        setCount(data.length)
                    }

                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id,fetchData])

    return isError ? isError : posts
}


const switchStatePosition = (e:any,did:any,pid:any,state:any,setFetchData:any) => {
    e.preventDefault()

    var raw = JSON.stringify({
        did: did,
        pid:pid,
        state:state
    })

    var requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization:
                'Bearer ' +
                JSON.parse(localStorage.getItem('token') as string)
        },
        /*body: raw*/
    }

    fetch(ApiRoot()+'yoptimize/departments/'+did+'/'+pid+'/'+state+'/', requestOptions)
        .then((response) => response.text())
        .then((result) =>setFetchData(Date.now()))
        .catch((error) => console.log(error))
}