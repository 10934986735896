import ProgressBar from "react-bootstrap/ProgressBar";
import React from "react";

const MultiprogressBar = (props:{end:any,data:any,Mode?:any}) => {

    let ProgressHolder = [];

    if(props.data === undefined) {
        return(<></>)
    }

    let ColorMap =[
        {name:'success',color:'#28a745'},
        {name:'warning',color:'#ffc107'},
        {name:'danger',color:'#dc3545'},
        {name:'secondary',color:'#6c757d'}
    ]

    for(let p=0;p<props.end;p++){



        const Color = () => {
            let makeColor = ''
            for(let c=0;c<ColorMap.length;c++){
                if(ColorMap[c].name === props.data.variants[p]){
                    makeColor = ColorMap[c].color
                }
            }

            return makeColor
        }
        if(props.Mode !== undefined && props.Mode === 'horizontal') {
            if(p === (props.end - 1)) {
                ProgressHolder.push(<>

                        <p className={'float-start'}>
                            <p className={'m-0'}>
                                <p className="float-start mr-2 ml-2 mb-0" style={{
                                    marginTop: '5px',
                                    color: Color(),
                                    background: Color(),
                                    borderRadius: '50%',
                                    height: '20px',
                                    width: '20px'
                                }}/>
                                <span className={'mt-3 pl-2'}>
                                {props.data.title[p]}
                            </span>
                                <br style={{clear: 'both'}}/>
                            </p>


                        </p>

                    </>
                )
            } else {
                ProgressHolder.push(<>

                        <p className={''} style={{marginBottom:'7px'}}>
                            <p className={'m-0'}>
                                <p className="float-start mr-2 ml-2 mb-0" style={{
                                    marginTop: '5px',
                                    color: Color(),
                                    background: Color(),
                                    borderRadius: '50%',
                                    height: '20px',
                                    width: '20px'
                                }}/>
                                <br/>
                                <ProgressBar variant={props.data.variants[p]} now={100}
                                             style={{
                                                 width: '40px',
                                                 display: 'inline-flex',
                                                 height: '5px',
                                                 transform: 'rotate(90deg)',
                                                 marginTop: ' 19px',
                                                 marginLeft: '-38px'
                                             }}/><span className={'mt-1 pl-2'}>
                            {props.data.title[p]}
                        </span>


                            </p>

                        </p>

                    </>
                )
            }
        } else {

        if(p === (props.end - 1)) {
            ProgressHolder.push(<>
                    <p className={'float-start'}>
                        <p className={'m-0'}>
                            <p className="float-start mr-2 ml-2 mb-0" style={{marginTop:'5px',color:  Color(),background:Color(),borderRadius:'50%',height:'20px',width:'20px'}} />

                            <br style={{clear:'both'}}/>
                        </p>
                        <p className={'mt-1 pl-2'}>
                            {props.data.title[p]}
                        </p>
                    </p>

                </>
            )
        } else {
            ProgressHolder.push(<>
                    <p className={'float-start'}>
                        <p className={'m-0'}>
                            <p className="float-start mr-2 ml-2 mb-0" style={{marginTop:'5px',color:  Color(),background:Color(),borderRadius:'50%',height:'20px',width:'20px'}} />
                            <ProgressBar variant={props.data.variants[p]} now={100}  style={{width:'50px',display: 'inline-flex',height:'5px'}} />
                            <br style={{clear:'both'}}/>
                        </p>
                        <p className={'mt-1 pl-2'}>
                            {props.data.title[p]}
                        </p>
                    </p>

                </>
            )
        }


    }
    }
    return(
        <>
            {ProgressHolder}
        </>
    )





}

export default MultiprogressBar;
