import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useState} from "react";
import {MIcon} from "../index";
import {SingleTaskFetch} from "../CheckRoute/Fetch/SingleTaskFetch";
import {useNavigate} from "react-router-dom";
import React from 'react'
interface IndexProps{
    backFunc?:void
    orderFunc?:() => void
    chatFunc?:void
    text?:string
    header?:string
    backButtonLabel?:string
    orderButtonLabel?:string
    chatButtonLabel?:string
    onlyShow?:any
    onlyShowButtonLabel?:string
}

const Index = (props:IndexProps) => {

    const navigate = useNavigate();
    const [show, setShow] = useState(true);

    const indiFunc = (indi:any) => {
        setShow(false)
        props.onlyShow(false)
    }

    return (

            <Modal
                show={props.onlyShow !== undefined && props.onlyShowButtonLabel !== undefined ? show : true}
                size="lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title dangerouslySetInnerHTML={{__html: props.header as string}} />
                </Modal.Header>

                <Modal.Body>
                    <p dangerouslySetInnerHTML={{__html: props.text as string}} />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>navigate(-1)}>{props.backButtonLabel}</Button>
                    {props.onlyShow !== undefined && props.onlyShowButtonLabel !== undefined ? <Button variant="success" className={'float-end'} onClick={()=>indiFunc(props.onlyShow)}>{props.onlyShowButtonLabel}</Button>: <></>}
                    <Button variant="success" className={'float-end'} onClick={()=>navigate('/chat/message')}>{props.chatButtonLabel}</Button>
                    <Button variant="danger" className={'float-end'} onClick={()=>props.orderFunc!()}>{props.orderButtonLabel}</Button>
                </Modal.Footer>
            </Modal>

    )
}

export default Index;
