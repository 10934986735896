import React from "react";
import {Button} from "react-bootstrap";
import {JSXElementConstructor, ReactElement, ReactNode, ReactPortal} from "react";

export interface UniButtonProps {
    /** Allows it to have children and have them reflected. */
    children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined,
    /** Combine Element with onClick Function and Event */
    onClick?: (e: any) => void;
    /** One or more button variant combinations **/
    variant?:'primary'| 'secondary'| 'success'| 'danger'| 'warning'| 'info'| 'dark'| 'light'| 'link'|'outline-primary'| 'outline-secondary'| 'outline-success'| 'outline-danger'| 'outline-warning'| 'outline-info'| 'outline-dark'| 'outline-light'
    /** Defines HTML button type attribute. **/
    type?:"button" | "submit" | "reset" | undefined
    /** Disables the Button, preventing mouse events, even if the underlying component is an <a> element **/
    disabled?:boolean
    /** Providing a href will render an <a> element, styled as a button. **/
    href?:string
    /** Manually set the visual state of the button to :active **/
    active?:boolean
    /** Manually set value of the button  **/
    value?:string | number | readonly string[] | undefined
    /** Set custom Classes on Element */
    class?:string
    /** set id on Element in DOM */
    id?:string
    /** Sets the target attribute of the underlying button element. */
    target?: string | undefined;
    /** Sets the size of the button. Can be used to define different button sizes.*/
    size?: 'sm' | 'lg';
    /** An object defining inline styles for the button element. */
    style?: React.CSSProperties | undefined;
}

const UniButton = (props:UniButtonProps) => {

    return(
        <>
            <Button variant={props.variant} style={props.style} className={props.class} id={props.id} value={props.value} disabled={props.disabled} active={props.active} href={props.href} type={props.type} onClick={props.onClick} target={props.target} size={props.size}>
                {props.children}
            </Button>
        </>
    )
}

export default UniButton;
