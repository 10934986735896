import TableCell from "@material-ui/core/TableCell";
import React from "react";
import {colorCheck} from "../../component/const/ColorCheck";

export const TableBadgeGrundfos  = (
    text: string,
    id: any,
    index: number,
    VisitData: any,
    VisitDataDel: any,
    Store: any,
    tableBodyPositionElement: any,
    Rows: any,
) => {

    let ColorMap= [
        {name: '0 Erfasst', color: '#FF0000'},
        {name: '7 Reparatur erledigt', color: '#FFEB3B'},
        {name: '9 Geschlossen', color: '#8BC34A'},
    ]

    let Name = text;
    let Color = '';

    for(let c=0;c<ColorMap.length;c++) {
        if(Name === ColorMap[c].name) {
            Color = ColorMap[c].color
        }
    }

    return (
        <>
            <TableCell
                style={{textAlign: tableBodyPositionElement}}
                key={'td' + id + '_' + index}
                data-param={VisitData}
                data-param-del={VisitDataDel}
                data-id={id}
                id={'td' + id + '_' + index}
            >
                <span className="badge rounded-pill" style={{background:Color,color:(Color !== undefined && colorCheck(Color,false) === false ? 'white' : 'black')}}>{Name} </span>
            </TableCell>
        </>
    )
}

