import {useState} from "react";
import {JSXElementConstructor, ReactElement, ReactNode, ReactPortal} from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {UniModalHeader} from "./Elements/UniModalHeader";
import {UniModalBody} from "./Elements/UniModalBody";
import {UniModalFooter} from "./Elements/UniModalFooter";
import React from 'react'
interface UniModalProps {
    /** Allows it to have children and have them reflected. */
    children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined;
    /**
     * Optional CSS class name for the button.
     * @type {string}
     */
    className?: string;

    /**
     * Optional ID for the button.
     * @type {string}
     */
    id?: string;

    /**
     * Button configuration options.
     * @typedef {Object} Button
     * @property {string|number|boolean|ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined} Text - Content of the button. Can be a string, number, boolean, React element, iterable of React nodes, React portal, or null.
     * @property {function} [OnClick] - Function to be called when the button is clicked.
     * @property {string} [Variant] - Button variant (e.g., 'primary', 'secondary').
     * @property {string} [Class] - Optional CSS class name for the button.
     * @property {string} [ID] - Optional ID for the button within the Button component.
     * @property {"button" | "submit" | "reset" | undefined} [Type] - Button type ('button', 'submit', or 'reset'). Defaults to 'button'.
     */
    Button: Button;

    /**
     * Optional state value for visibility control.
     * @type {any}
     */
    show?: any;

    /**
     * Optional function to set the show state.
     * @type {function}
     */
    setShow?: any;

    /**
     * Optional function to handle show state changes.
     * @type {function}
     */
    handleShow?: any;

    /**
     * Optional function to handle hide state changes.
     * @type {function}
     */
    handleClose?: any;
}

interface Button {
    Text: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined
    OnClick?: any;
    Variant?:string
    Class?:string
    ID?:string
    Type?:"button" | "submit" | "reset" | undefined
}

const UniModal = (props:UniModalProps) => {

    const [show, setShow] = useState(false);

    const handleClose = () => (props.show !== undefined && props.setShow !== undefined ? props.setShow(false) : setShow(false));
    const handleShow = () => (props.show !== undefined && props.setShow !== undefined ? props.setShow(true) : setShow(true));

    const ButtonFunc = () => {
        (props.handleShow !== undefined ? props.handleShow() : handleShow());
        if(props.Button.OnClick !== undefined ){
            props.Button.OnClick()
        };
    }

    return(
        <>
            <Button type={props.Button.Type} variant={props.Button.Variant !== undefined ? props.Button.Variant : "primary"} id={props.Button.ID} className={props.Button.Class} onClick={ButtonFunc}>
                {props.Button.Text}
            </Button>
            <Modal className={props.className} id={props.id} show={(props.show !== undefined  ? props.show : show)} onHide={ (props.handleShow !== undefined ? props.handleClose : handleClose)}>
                {props.children}
            </Modal>
        </>
    )

}

UniModal.Header  = UniModalHeader
UniModal.Body = UniModalBody
UniModal.Footer = UniModalFooter

export default UniModal;
