import '../css/Software_Support.css'
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {refresh} from "../../ticketsystem/modules/new/Pages/SingleTask";
import Button from 'react-bootstrap/Button';
import {Link, useNavigate} from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import {colorSwitcher, savearchiv, savedelete} from "../../ticketsystem/modules/new/Pages/MyTask";
import Form from "react-bootstrap/Form";
import SunEditor from "suneditor-react";
import SunEditorConfig from "../../ticketsystem/modules/components/SuneditorConfig";
import InputGroup from "react-bootstrap/InputGroup";
import {init2} from "../../../@WUM/core/form-serilizer/seriliaze/Formserialize";
import {getToken, setNavi} from "../../../@WUM/core/Function/index";
import {ResponseAllSupportSoftware} from "../API/response/support_software";
import {support_softwareApiType} from "../API/request/SupportSoftwareApi.interface";
import {SpinnerJSX} from "./components/Spinner";
import {dateFormat, IntlMessage, IntlMessageValue, DynamikOffCanvas, ApiURL} from "../../../@WUM/core/Elements/index";
import Badge from "react-bootstrap/Badge";
import PaginationYoptimize from "../../ticketsystem2.0/Pages/components/PaginationYoptimize";
import {FilterResponse} from "../../ticketsystem/modules/new/Pages/MyProjects";
const Software_SupportOverview = (props:{value:any}) =>{
    setNavi({
        route:'/support/software/overview/'
    })
    const navigate = useNavigate();
    const [fetchData, setFetchData] = useState(0);
    const [load,setLoad] = useState(false);
    const [fetchSortData, setFetchSortData] = useState([]);
    const [filter, setfilter] = useState([]);
    const [clearFilter,setClearFilter] = useState(true);
    const response =  ResponseAllSupportSoftware({instanz:window.location.hostname},fetchData,load,setLoad)
    const Store = useSelector((state: any) => state)
    const [value, setValue] = useState("");
    const [pageDelimiter, setpageDelimiter] = useState(10)
    const [endpage, setEndPage] = useState(pageDelimiter)
    const [startpage, setstartPage] = useState(0)
    const [active, setactive] = useState(1)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handlesubmit = (e:any) => {
        const form = e.currentTarget
        e.preventDefault()
        let Data = init2(form)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+ getToken());

        var raw = JSON.stringify(
            {
                "regard": Data.regard,
                "status_id": 1,
                "member": Data.member,
                "description": Data.description,
                "plugin": Data.plugin,
                "due_on": Data.due_on,
                "instanz": window.location.hostname,
                "support_type_id":Data.support_type,
                "priority": Data.priority,
                "responsibility": localStorage.getItem('username'),
                "creator": localStorage.getItem('username'),
                "lastupdatefrom": localStorage.getItem('username'),
                "created_at": new Date().toISOString().replace('T',' ').split('.')[0]
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        setValue('');
        fetch(ApiURL() +"yoptimize/new/ticket/support/", requestOptions)
            .then(response => response.text())
            .then(result => {successForm(result)})
            .catch(error => errorForm(error));
    };

    const CloseDynmaikModal = () => {
        handleClose();
    }

    const successForm = (result:any) => {
        setFetchData(Date.now())
        setLoad(false)
        setValue('')
        handleClose()
    }

    const Reloadsuccess = () => {
        setFetchData(Date.now())
        setLoad(false)
    }

    const errorForm = (error:any)=> {
        handleClose()
    }

    const setEditorContent = (value:any,textarea?:any) =>{
        setValue(value)
        if(textarea !== undefined) {
            (document.getElementById('descriptionTextarea') as HTMLTextAreaElement).value = value
        }
    }


    let TicketHolder = [];
    let colorset = [
        {color:'#2EA02E'},
        {color:'#bfbfc4'},
        {color:'#6f80d1'},
        {color:'#f2f299'},
        {color:'#eca453'},
        {color:'#e9a89b'}
    ]

    let colorsetState = [
        {color:'#2EA02E'},
        {color:'#fcfc40'},
        {color:'#ff8800'},
        {color:'#f82c00'},
        {color:'#14b814'},
        {color:'#bfbfc4'}
    ]
    if (typeof response !== "boolean" && response?.length > 0) {
        console.log(fetchSortData)
        let DataSort = (clearFilter === true ? response : fetchSortData);
        const reloadRow = (e:any) => {
            setpageDelimiter(parseInt(e.target.value));
            setstartPage(0)
            setEndPage(parseInt(e.target.value))
            setactive(1)
            setFetchData(Date.now())
        }

        let open = 0;
        let doing = 0;
        let answ = 0;
        let close = 0;
        for(let x=startpage;x<(endpage>DataSort.length ? DataSort.length : endpage);x++){

            let data = DataSort[x];
            if(data.status_id === 1) {
                open++;
            }
            if(data.status_id === 2) {
                doing++;
            }
            if(data.status_id === 3) {
                answ++;
            }
            if(data.status_id === 4) {
                doing++;
            }
            if(data.status_id === 5) {
                close++;
            }
            TicketHolder.push(
                <>
                    <tr onDoubleClick={()=>navigate('/support/ticket/software/'+data.id)}>
                        <td>{
                            dateFormat(
                                data.created_at,
                                localStorage.getItem('locale'),
                                'us',
                                true,
                                false,
                                Store
                            )
                        }</td>
                        <td>#{data.id}</td>
                        <td>{data.plugin}</td>
                        <td>{data.regard}</td>
                        <td>
                               <span className="badge " style={{
                                   background: (data.priority !== null ? colorset[data.priority].color : colorset[0].color),
                                   color: (colorSwitcher((data.priority !== null ? colorset[data.priority].color : colorset[0].color), false) ? 'black' : 'white')
                               }}>
                            {(data.priority !== null ? IntlMessageValue('de', 'inbox.priority.' + data.ticket_prioritaeten.name, Store) : 'Low')}
                        </span>
                        </td>
                        <td>{(data.support_type_id !== null ? <><IntlMessage messageId={"t2." + data.ticket_type.type}
                                                                             Store={Store}/></> : '')}</td>
                        <td>
                        <span className="badge rounded-pill" style={{
                            background: (data.status_id !== null ? colorsetState[data.status_id].color : colorsetState[0].color),
                            color: (colorSwitcher((data.status_id !== null ? colorsetState[data.status_id].color : colorsetState[0].color), false) ? 'black' : 'white')
                        }}>
                            {IntlMessageValue('de', 'inbox.status.' + data.status.state, Store)}
                        </span>
                        </td>
                        <td>
                            <NavDropdown
                                className={'text-right'}
                                title={
                                    <span>
                                       <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#999999"
                                            className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                      <path
                                          d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                    </svg>
                                 </span>
                                }
                                id='collasible-nav-dropdown_dashboard_projects'>
                                <NavDropdown.Item onClick={()=>navigate('/support/ticket/software/'+data.id)}>
                                    Open
                                </NavDropdown.Item>
                            </NavDropdown>
                        </td>
                    </tr>
                </>
            )

        }

        return (<>

            <div className={'ticketsystem'} style={{paddingLeft: '25px'}}>
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Row className={'p-3'}>
                                    <Col className={'col-4'}>
                                <span className="material-icons" style={{fontSize: '60px'}}>
                                    lock_open
                                </span>
                                    </Col>
                                    <Col className={'text-center col-8'}>
                                        {IntlMessageValue('de', 'supportTicket.open', Store)}<br/>({open})
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Row className={'p-3'}>
                                    <Col className={'col-4'}>
                                    <span className="material-icons" style={{fontSize: '60px'}}>
                                        schedule
                                    </span>
                                    </Col>
                                    <Col className={'text-center col-8'}>
                                        {IntlMessageValue('de', 'supportTicket.inProgress', Store)}<br/>({doing})
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Row className={'p-3'}>
                                    <Col className={'col-4'}>
                                    <span className="material-icons" style={{fontSize: '60px'}}>
                                        check_circle
                                    </span>
                                    </Col>
                                    <Col className={'text-center col-8'}>
                                        {IntlMessageValue('de', 'supportTicket.answer', Store)}<br/>({answ})
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Row className={'p-3'}>
                                    <Col className={'col-4'}>
                                    <span className="material-icons" style={{fontSize: '60px'}}>
                                        lock
                                    </span>
                                    </Col>
                                    <Col className={'text-center col-8'}>
                                        {IntlMessageValue('de', 'supportTicket.close', Store)}<br/>({close})
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Row className={'p-3'}>
                                    <Col className={'col-4'}>
                                    <span className="material-icons" style={{fontSize: '60px'}}>
                                        menu
                                    </span>
                                    </Col>
                                    <Col className={'text-center col-8'}>
                                        {IntlMessageValue('de', 'supportTicket.alltickets', Store)}<br/>({response.length})
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row style={{marginTop: '2em', marginLeft: '0px', marginRight: '0px'}}>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">{IntlMessageValue('de', 'inbox.subject', Store)}</InputGroup.Text>
                            <Form.Control aria-describedby="basic-addon1"
                                          onChange={(e) => FilterResponse(response, setFetchSortData, setFetchData, 'regard', e, filter, setfilter,false,setClearFilter)}/>
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">{IntlMessageValue('de', 'inbox.priority', Store)}</InputGroup.Text>
                            <Form.Select aria-label="Default select example"
                                         onChange={(e) => FilterResponse(response, setFetchSortData, setFetchData, 'priority', e, filter, setfilter, false, setClearFilter)}
                                         >
                                <option label={IntlMessageValue('de', 'inbox.priority.select', Store)}
                                        value=""></option>
                                <option value="1">{IntlMessageValue('de', 'inbox.priority.Lowest', Store)}</option>
                                <option value="2">{IntlMessageValue('de', 'inbox.priority.Low', Store)}</option>
                                <option value="3">{IntlMessageValue('de', 'inbox.priority.Medium', Store)}</option>
                                <option value="4">{IntlMessageValue('de', 'inbox.priority.High', Store)}</option>
                                <option value="5">{IntlMessageValue('de', 'inbox.priority.Urgent', Store)}</option>
                            </Form.Select>
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Text
                                id="basic-addon1">{IntlMessageValue('de', 'common.status', Store)}</InputGroup.Text>
                            <Form.Select aria-label="Default select example"
                                         onChange={(e) => FilterResponse(response, setFetchSortData, setFetchData, 'status_id', e, filter, setfilter, true, setClearFilter)}
                                         >
                                <option label=""
                                        value=""></option>
                                <option value="1">{IntlMessageValue('de', 'inbox.status.Open', Store)}</option>
                                <option value="2">{IntlMessageValue('de', 'inbox.status.In Progress', Store)}</option>
                                <option value="3">{IntlMessageValue('de', 'inbox.status.Pending', Store)}</option>
                                <option value="5">{IntlMessageValue('de', 'inbox.status.Resolve', Store)}</option>
                            </Form.Select>
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">Support-Type</InputGroup.Text>
                            <Form.Select aria-label="Default select example"
                                         onChange={(e) => FilterResponse(response, setFetchSortData, setFetchData, 'support_type_id', e, filter, setfilter, true, setClearFilter)}>
                               <option
                                    label={IntlMessageValue('de', 'inbox.Support-Typ.select', Store)}
                                    value=""></option>
                                <option value="2"><IntlMessage messageId={"t2.Bug"}
                                                               Store={Store}/></option>
                                <option value="4"><IntlMessage
                                    messageId={"t2.Fehlende Funktion"} Store={Store}/></option>
                                <option value="6"><IntlMessage messageId={"t2.Verbesserung"}
                                                               Store={Store}/></option>
                                <option value="7"><IntlMessage
                                    messageId={"t2.Individuelle Anpassung"} Store={Store}/>
                                </option>
                            </Form.Select>
                        </InputGroup>
                    </Col>
                </Row>

                <Row style={{marginTop: '2em', marginLeft: '0px', marginRight: '0px'}}>
                    <Card>
                        <Card.Body>
                            <Row className={'mb-3'}>
                                <Col>
                                    <h3>{IntlMessageValue('de', 'supportTicket.headline', Store)}</h3>
                                </Col>
                                <Col>
                                    <DynamikOffCanvas
                                        openButtonLabel={<><span className="material-icons"
                                                                 style={{verticalAlign: 'bottom'}}>
                    add
                    </span>
                                            {IntlMessageValue('de', 'common.add.new.ticket', Store)}</>}
                                        saveButtonLabel={IntlMessageValue('de', 'common.save', Store)}
                                        closeButtonLabel={IntlMessageValue('de', 'common.close', Store)}
                                        title={'Create new ticket'}
                                        ClassOpenButton={'float-end'}
                                        ClassSaveButton={'float-end'}
                                        body={<>

                                            <div className="modal-dialog" style={{marginRight: '-23px'}}>

                                            </div>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{IntlMessageValue('de', 'common.title', Store)}</InputGroup.Text>
                                                    <Form.Control name={'regard'} type="title"/>
                                                </InputGroup>
                                            </Form.Group>
                                            <Row>
                                                <Col>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text id="basic-addon1"><IntlMessage
                                                            messageId="inbox.application" Store={Store}/></InputGroup.Text>
                                                        <Form.Select aria-label="Default select example" name={'plugin'}
                                                                     required={true}>
                                                            <option label="" value=""></option>
                                                            <option value="Team Takt">Task Manager</option>
                                                            <option value="Task Manager">Task Manager</option>
                                                            <option value="Ticket System 2.0">Ticket System 2.0</option>
                                                            <option value="Leistungserfassung">Leistungserfassung</option>
                                                            <option value="Arbeitszeiterfassung">Arbeitszeiterfassung</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </Col>
                                                <Col>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text
                                                            id="basic-addon1">{IntlMessageValue('de', 'common.priority', Store)}</InputGroup.Text>
                                                        <Form.Select aria-label="Default select example" name={'priority'}
                                                                     required={true}>
                                                            <option
                                                                label={IntlMessageValue('de', 'inbox.priority.select', Store)}
                                                                value=""></option>
                                                            <option
                                                                value="1">{IntlMessageValue('de', 'inbox.priority.Lowest', Store)}</option>
                                                            <option
                                                                value="2">{IntlMessageValue('de', 'inbox.priority.Low', Store)}</option>
                                                            <option
                                                                value="3">{IntlMessageValue('de', 'inbox.priority.Medium', Store)}</option>
                                                            <option
                                                                value="4">{IntlMessageValue('de', 'inbox.priority.High', Store)}</option>
                                                            <option
                                                                value="5">{IntlMessageValue('de', 'inbox.priority.Urgent', Store)}</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col >
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text id="basic-addon1"><IntlMessage
                                                            messageId="common.ticket.dueOn"
                                                            Store={Store}/></InputGroup.Text>

                                                        <Form.Control type="date" name={'due_on'}/>

                                                    </InputGroup>
                                                </Col>
                                                <Col>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text
                                                            id="basic-addon1">{IntlMessageValue('de', 'inbox.Support-Typ', Store)}</InputGroup.Text>
                                                        <Form.Select aria-label="Default select example"
                                                                     name={'support_type'}
                                                                     required={true}>
                                                            <option
                                                                label={IntlMessageValue('de', 'inbox.Support-Typ.select', Store)}
                                                                value=""></option>
                                                            <option value="2"><IntlMessage messageId={"t2.Bug"}
                                                                                           Store={Store}/></option>
                                                            <option value="4"><IntlMessage
                                                                messageId={"t2.Fehlende Funktion"} Store={Store}/></option>
                                                            <option value="6"><IntlMessage messageId={"t2.Verbesserung"}
                                                                                           Store={Store}/></option>
                                                            <option value="7"><IntlMessage
                                                                messageId={"t2.Individuelle Anpassung"} Store={Store}/>
                                                            </option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                            <div id={'htmleditor'} style={{display: 'block'}}>
                                                <SunEditor
                                                    key={'test'}

                                                    name={'description'}
                                                    setContents={value}
                                                    setOptions={{
                                                        buttonList: SunEditorConfig(),
                                                        attributesWhitelist: {
                                                            div: 'id',
                                                            p: 'id',
                                                            h: 'id'
                                                        }
                                                    }}
                                                    height={'450px'}
                                                    onChange={(value) => setEditorContent(value)}
                                                />
                                            </div>
                                            <br/>



                                                {/*<Col className={'col-3'}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text id="basic-addon1">Status</InputGroup.Text>
                                                        <Form.Select aria-label="Default select example"
                                                                     name={'status_id'}>
                                                            <option value="1">Open</option>
                                                            <option value="2">In Progress</option>
                                                            <option value="3">Pending</option>
                                                            <option value="4">Overdue</option>
                                                            <option value="5">Resolve</option>
                                                        </Form.Select>
                                                    </InputGroup>

                                                </Col>*/}

                                            <Form.Control name={'creator'} type="hidden"
                                                          defaultValue={localStorage.getItem('username') as string}/>
                                        </>}
                                        handlesubmit={handlesubmit}
                                        handleShow={handleShow}
                                        show={show}
                                        id={'TaskNew'}
                                        setShow={setShow}
                                        handleClose={CloseDynmaikModal}
                                    />

                                    {refresh(setFetchData, IntlMessageValue('de', 'common.reloadPage', Store))}
                                </Col>
                                <br style={{clear: 'both'}}/>
                            </Row>
                            <Table striped hover>
                                <thead>
                                <tr>
                                    <th><IntlMessage
                                        messageId="inbox.date" Store={Store}/></th>
                                    <th>Ticket-ID</th>
                                    <th><IntlMessage
                                        messageId="inbox.application" Store={Store}/></th>
                                    <th><IntlMessage
                                        messageId="inbox.subject" Store={Store}/></th>
                                    <th><IntlMessage
                                        messageId="inbox.priority" Store={Store}/></th>
                                    <th>Support-Type</th>
                                    <th><IntlMessage
                                        messageId="inbox.status" Store={Store}/></th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {TicketHolder}
                                </tbody>
                            </Table>
                            <div className={'float-start'}>
                                <Form.Select  style={{width:'70px'}} className={'float-start'} onChange={(e)=>reloadRow(e)}>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={30}>30</option>
                                    <option value={40}>40</option>
                                    <option value={50}>50</option>
                                </Form.Select>
                                <span className={'float-start p-2'}><IntlMessage messageId="inbox.rowperpage" Store={Store} /></span>
                            </div>

                            <PaginationYoptimize lenght={(clearFilter === true ? response.length : fetchSortData.length)} setstartPage={setstartPage} setEndPage={setEndPage} active={active} setactive={setactive} pageDelimiter={pageDelimiter} setFetchData={setFetchData} />
                            <br style={{clear:'both'}}/>
                        </Card.Body>
                    </Card>
                </Row>
            </div>
        </>)

    } else {
            return(
                <>
                    <div className={'ticketsystem'} style={{paddingLeft: '25px'}}>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Row className={'p-3'}>
                                            <Col className={'col-4'}>
                                <span className="material-icons" style={{fontSize: '60px'}}>
                                    lock_open
                                </span>
                                            </Col>
                                            <Col className={'text-center col-8'}>
                                                {IntlMessageValue('de', 'supportTicket.open', Store)}<br/>(-)
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Row className={'p-3'}>
                                            <Col className={'col-4'}>
                                    <span className="material-icons" style={{fontSize: '60px'}}>
                                        schedule
                                    </span>
                                            </Col>
                                            <Col className={'text-center col-8'}>
                                                {IntlMessageValue('de', 'supportTicket.inProgress', Store)}<br/>(-)
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Row className={'p-3'}>
                                            <Col className={'col-4'}>
                                    <span className="material-icons" style={{fontSize: '60px'}}>
                                        check_circle
                                    </span>
                                            </Col>
                                            <Col className={'text-center col-8'}>
                                                {IntlMessageValue('de', 'supportTicket.answer', Store)}<br/>(-)
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Row className={'p-3'}>
                                            <Col className={'col-4'}>
                                    <span className="material-icons" style={{fontSize: '60px'}}>
                                        lock
                                    </span>
                                            </Col>
                                            <Col className={'text-center col-8'}>
                                                {IntlMessageValue('de', 'supportTicket.close', Store)}<br/>(-)
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Row className={'p-3'}>
                                            <Col className={'col-4'}>
                                    <span className="material-icons" style={{fontSize: '60px'}}>
                                        menu
                                    </span>
                                            </Col>
                                            <Col className={'text-center col-8'}>
                                                {IntlMessageValue('de', 'supportTicket.alltickets', Store)}<br/>(-)
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row style={{marginTop: '2em', marginLeft: '0px', marginRight: '0px'}}>
                            <Card>
                                <Card.Body>
                                    <Row className={'mb-3'}>
                                        <Col>
                                            <h3>{IntlMessageValue('de', 'supportTicket.headline', Store)}</h3>
                                        </Col>
                                        <Col>
                                            <DynamikOffCanvas
                                                openButtonLabel={<><span className="material-icons"
                                                                         style={{verticalAlign: 'bottom'}}>
                                                    add
                                                    </span>
                                                    {IntlMessageValue('de', 'common.add.new.ticket', Store)}</>}
                                                saveButtonLabel={IntlMessageValue('de', 'common.save', Store)}
                                                closeButtonLabel={IntlMessageValue('de', 'common.close', Store)}
                                                title={'Create new ticket'}
                                                ClassOpenButton={'float-end'}
                                                ClassSaveButton={'float-end'}
                                                body={<>

                                                    <div className="modal-dialog" style={{marginRight: '-23px'}}>

                                                    </div>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <InputGroup className="mb-3">
                                                            <InputGroup.Text id="basic-addon1">{IntlMessageValue('de', 'common.title', Store)}</InputGroup.Text>
                                                            <Form.Control name={'regard'} type="title"/>
                                                        </InputGroup>
                                                    </Form.Group>
                                                    <Row>
                                                        <Col>
                                                            <InputGroup className="mb-3">
                                                                <InputGroup.Text id="basic-addon1"><IntlMessage
                                                                    messageId="inbox.application" Store={Store}/></InputGroup.Text>
                                                                <Form.Select aria-label="Default select example" name={'plugin'}
                                                                             required={true}>
                                                                    <option label="" value=""></option>
                                                                    <option value="Team Takt">Task Manager</option>
                                                                    <option value="Task Manager">Task Manager</option>
                                                                    <option value="Ticket System 2.0">Ticket System 2.0</option>
                                                                    <option value="Leistungserfassung">Leistungserfassung</option>
                                                                    <option value="Arbeitszeiterfassung">Arbeitszeiterfassung</option>
                                                                </Form.Select>
                                                            </InputGroup>
                                                        </Col>
                                                        <Col>
                                                            <InputGroup className="mb-3">
                                                                <InputGroup.Text
                                                                    id="basic-addon1">{IntlMessageValue('de', 'common.priority', Store)}</InputGroup.Text>
                                                                <Form.Select aria-label="Default select example" name={'priority'}
                                                                             required={true}>
                                                                    <option
                                                                        label={IntlMessageValue('de', 'inbox.priority.select', Store)}
                                                                        value=""></option>
                                                                    <option
                                                                        value="1">{IntlMessageValue('de', 'inbox.priority.Lowest', Store)}</option>
                                                                    <option
                                                                        value="2">{IntlMessageValue('de', 'inbox.priority.Low', Store)}</option>
                                                                    <option
                                                                        value="3">{IntlMessageValue('de', 'inbox.priority.Medium', Store)}</option>
                                                                    <option
                                                                        value="4">{IntlMessageValue('de', 'inbox.priority.High', Store)}</option>
                                                                    <option
                                                                        value="5">{IntlMessageValue('de', 'inbox.priority.Urgent', Store)}</option>
                                                                </Form.Select>
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col >
                                                            <InputGroup className="mb-3">
                                                                <InputGroup.Text id="basic-addon1"><IntlMessage
                                                                    messageId="common.ticket.dueOn"
                                                                    Store={Store}/></InputGroup.Text>

                                                                <Form.Control type="date" name={'due_on'}/>

                                                            </InputGroup>
                                                        </Col>
                                                        <Col>
                                                            <InputGroup className="mb-3">
                                                                <InputGroup.Text
                                                                    id="basic-addon1">{IntlMessageValue('de', 'inbox.Support-Typ', Store)}</InputGroup.Text>
                                                                <Form.Select aria-label="Default select example"
                                                                             name={'support_type'}
                                                                             required={true}>
                                                                    <option
                                                                        label={IntlMessageValue('de', 'inbox.Support-Typ.select', Store)}
                                                                        value=""></option>
                                                                    <option value="2"><IntlMessage messageId={"t2.Bug"}
                                                                                                   Store={Store}/></option>
                                                                    <option value="4"><IntlMessage
                                                                        messageId={"t2.Fehlende Funktion"} Store={Store}/></option>
                                                                    <option value="6"><IntlMessage messageId={"t2.Verbesserung"}
                                                                                                   Store={Store}/></option>
                                                                    <option value="7"><IntlMessage
                                                                        messageId={"t2.Individuelle Anpassung"} Store={Store}/>
                                                                    </option>
                                                                </Form.Select>
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                    <div id={'htmleditor'} style={{display: 'block'}}>
                                                        <SunEditor
                                                            key={'test'}

                                                            name={'description'}
                                                            setContents={value}
                                                            setOptions={{
                                                                buttonList: SunEditorConfig(),
                                                                attributesWhitelist: {
                                                                    div: 'id',
                                                                    p: 'id',
                                                                    h: 'id'
                                                                }
                                                            }}
                                                            height={'450px'}
                                                            onChange={(value) => setEditorContent(value)}
                                                        />
                                                    </div>
                                                    <br/>
                                                    <Form.Control name={'creator'} type="hidden"
                                                                  defaultValue={localStorage.getItem('username') as string}/>
                                                </>}
                                                handlesubmit={handlesubmit}
                                                handleShow={handleShow}
                                                show={show}
                                                id={'TaskNew'}
                                                setShow={setShow}
                                                handleClose={CloseDynmaikModal}
                                            />

                                            {refresh(setFetchData, IntlMessageValue('de', 'common.reloadPage', Store))}
                                        </Col>
                                        <br style={{clear: 'both'}}/>
                                    </Row>
                        <Table striped hover>
                            <thead>
                            <tr>
                                <th><IntlMessage
                                    messageId="inbox.date" Store={Store}/></th>
                                <th>Ticket-ID</th>
                                <th><IntlMessage
                                    messageId="inbox.application" Store={Store}/></th>
                                <th><IntlMessage
                                    messageId="inbox.subject" Store={Store}/></th>
                                <th><IntlMessage
                                    messageId="inbox.priority" Store={Store}/></th>
                                <th>Support-Type</th>
                                <th><IntlMessage
                                    messageId="inbox.status" Store={Store}/></th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            </tbody>
                        </Table>
                                    <div>
                                        <SpinnerJSX messageId={'common.loading'} class={'spinnerSS timeoutSpinner'}/>
                                    </div>
                                </Card.Body>
                            </Card>

                    </Row>
                    </div>
                </>
            )
        }
    }

export default Software_SupportOverview;
