import {ResponseReports} from "../../../API/response/ticket";
import React, {useState} from "react";
import { Chart } from "react-google-charts";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Card from "react-bootstrap/Card";
import NavDropdown from "react-bootstrap/NavDropdown";
import {between2digits, savearchivProject, savedeleteProject} from "./MyProjects";
import ProgressBar from "react-bootstrap/ProgressBar";
import {setNavi} from "../../../../../@WUM/core/Function/index";
import Table from 'react-bootstrap/Table';
import Avatar from "@material-ui/core/Avatar";
import {useSelector} from "react-redux";
import {dateFormat, IntlMessageValue} from "../../../../../@WUM/core/Elements/index";
import {CreatePieChart} from "./Dashboard";
import '../css/reports.css'
const Reports = (value:any) => {
    setNavi({
        route:'/reports/TT/'
    })
    const [fetchData, setFetchData] = useState(0);
    let heute = new Date()
    let day = heute.getDate().toString().padStart(2, '0');
    let month = (heute.getMonth()+1).toString().padStart(2, '0');
    let year = heute.getFullYear().toString().padStart(2, '0');
    let DBdate = year+'-'+month+'-01';
    let DBdateend = year+'-'+month+'-30';
    const [startDateData, setStartDate] = useState(DBdate);
    const [enddateData, setEndDate] = useState(DBdateend);
    const Report = ResponseReports(startDateData,enddateData,fetchData);
    const Store = useSelector((state: any) => state)

    const newDate = (date:any,setter:any) => {
        setter(date)
        setFetchData(Date.now())
    }


    if (typeof Report !== "boolean" && Report !== undefined ) {
            const data = [
                ["Task", "Hours per Day"],
                [IntlMessageValue('de','common.open.task',Store), Report.TaskOverview.OpenTask],
                [IntlMessageValue('de','common.completed.task',Store), Report.TaskOverview.CompletedTask],
                [IntlMessageValue('de','common.overdue.task',Store), Report.TaskOverview.Overdue], // CSS-style declaration
            ];

        let TmpData = []
        TmpData.push([Report.TaskOverview.OpenTask, Report.TaskOverview.OpenTask,'#F1AF04'])
        TmpData.push([Report.TaskOverview.CompletedTask, Report.TaskOverview.CompletedTask,'#10B82B'])
        TmpData.push([Report.TaskOverview.Overdue, Report.TaskOverview.Overdue,'#CD0000'])
        const options = {
            pieHole: 0.5,
            is3D: false,
            width:400,
            backgroundColor:'transparent',
            height:300,
            slices:{0:{color: 'F1AF04'},1:{color: '10B82B'},2:{color: 'CD0000'}},
            legend: {position: 'none'}
        };

        let EmployeHolder = [];

        for(let employ = 0;employ < Report.EmployeOverciew.length;employ++) {
            let data = Report.EmployeOverciew[employ]
            let shortly = data.name.split(' ')
            let user = '';

            for(let short = 0;short< shortly.length;short++) {
                user = user + shortly[short].charAt(0)
            }

            EmployeHolder.push(
                <>
                    <TableEmploye name={data.name} media={data.media} short={user.toLocaleUpperCase()} all={data.all} close={data.close} open={data.open} overdue={data.ovverdue}  />
                </>
            )
        }

        return(
            <><div className={'ticketsystem'} style={{paddingLeft: '25px'}}>
                <Row className={'m-3'}>
                    <Row>
                        <Col className={'col-3'}>
                            <h3>{IntlMessageValue('de','common.task.overview',Store)}</h3>
                        </Col>
                        <Col className={'col-3'}>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">Von</InputGroup.Text>
                                <Form.Control type="date" defaultValue={startDateData} onChange={(e) => newDate(e.target.value,setStartDate) }/>
                            </InputGroup>
                        </Col>
                        <Col className={'col-3'}>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">Bis</InputGroup.Text>
                                <Form.Control type="date" defaultValue={enddateData} onChange={(e) => newDate(e.target.value,setEndDate) }/>
                            </InputGroup>
                        </Col>
                        <Col className={'col-3'}>
                        </Col>


                    </Row>
                    <Row>
                        <Col className={'col-3'}>
                            <BuildCardReports all={Report.TaskOverview.All} color={'warning'} Store={Store} title={IntlMessageValue('de','common.open.task',Store)} dataItem={Report.TaskOverview.OpenTask} endDate={enddateData} startDate={startDateData} undertitle={''} />
                        </Col>
                        <Col className={'col-3'}>
                            <BuildCardReports all={Report.TaskOverview.All} color={'danger'} Store={Store} title={IntlMessageValue('de','common.overdue.task',Store)} dataItem={Report.TaskOverview.Overdue} endDate={enddateData} startDate={startDateData} undertitle={''} />
                        </Col>
                        <Col className={'col-3'}>
                            <BuildCardReports all={Report.TaskOverview.All} color={'success'} Store={Store} title={IntlMessageValue('de','common.completed.task',Store)} dataItem={Report.TaskOverview.CompletedTask} endDate={enddateData} startDate={startDateData} undertitle={''} />
                        </Col>
                        <Col className={'col-3'} style={{maxHeight:"136px"}}>
                            {/*<Chart
                                chartType="PieChart"
                                data={data}
                                options={options}
                                style={{marginTop: '-90px'}}
                            />*/}
                            <CreatePieChart date={TmpData} namespace={'allTask'} className={'graphreports'} stroke={3}
                                            height={'150%'} width={'100%'} />
                        </Col>
                    </Row>

                </Row>
                <Row className={'m-3'}>
                    <Row>
                        <Table striped hover size="sm">
                            <thead>
                            <tr style={{height:'60px'}}>
                                <th className={'text-center'} style={{width:'250px'}}>{IntlMessageValue('de','common.task.employe',Store)}</th>
                                <th className={'text-center'}>{IntlMessageValue('de','common.open',Store)}</th>
                                <th className={'text-center'}>{IntlMessageValue('de','common.overdue',Store)}</th>
                                <th className={'text-center'}>{IntlMessageValue('de','common.completed',Store)}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {EmployeHolder}
                            </tbody>
                        </Table>
                    </Row>

                </Row>
            </div>
            </>
        )
    } else {
        return(
            <>

            </>
        )
    }

}

export default Reports;

const BuildCardReports = (props:{title:string,startDate:any,endDate:any,undertitle:any,dataItem:any,all:any,Store:any,color:any}) => {



    return(
        <>
            <Card style={{ width: '100%',marginBottom:'10px',cursor:'move'}}>
                <Card.Body className={'pb-0'}>
                    <Card.Title>
                        <Row>
                            <Col className={'col-9'}><small>
                                <p  style={{marginBottom: '0'}}><strong>{props.title}</strong>
                                    <br/>
                                    <span style={{fontSize:'12px'}}>{
                                        dateFormat(
                                            props.startDate,
                                            localStorage.getItem('locale'),
                                            'us',
                                            false,
                                            false,
                                            'de'
                                        )
                                        } - {
                                        dateFormat(
                                            props.endDate,
                                            localStorage.getItem('locale'),
                                            'us',
                                            false,
                                            false,
                                            'de'
                                        )
                                        }</span>
                                </p>
                            </small>
                            </Col>
                        </Row>
                        <small style={{fontSize:'small'}}></small>
                    </Card.Title>
                    <Card.Text>
                        <ProgressBar style={{height:'5px'}} variant={props.color} now={(100 / (props.all as number) ) * (props.dataItem as number)} />
                        <div id={'cardITem'} className={'mt-3'}>
                            <Row>
                                <Col md={12} xxl={12} xl={12} sm={12}>
                                    <div>
                                    <div className={'border-'+props.color}
                                         style={{borderInlineStart:'solid',writingMode:'horizontal-tb',paddingLeft:'5px',height:'25px'}}>
                                        <p className={'float-start'}>{props.undertitle} {props.dataItem}/{props.all} {IntlMessageValue('de', 'common.table.task', props.Store)} </p>

                                    <p className={'float-end'} style={{textAlign: 'right',color:"red"}}>{((100 / (props.all as number) ) * (props.dataItem as number)).toFixed(0)}%</p>
                                    </div>
                                        <br style={{clear:'both'}} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    )
}

const BuildCard = (props:{title:string,startDate:any,endDate:any,undertitle:any,dataItem:any,all:any}) => {



    return(
        <>
            <Card style={{ width: '100%',marginBottom:'10px',cursor:'move'}}>
                <Card.Body className={'pb-0'}>
                    <Card.Title>
                        <Row>
                            <Col className={'col-9'}><small>
                                <p  style={{marginBottom: '0'}}><strong>{props.title}</strong>
                                    <br/>
                                    <span style={{fontSize:'12px'}}>{props.startDate} - {props.endDate}</span>
                                </p>
                            </small>
                            </Col>
                           {/* <Col className={'col-3'}><NavDropdown
                                className={'float-end text-right'}
                                title={
                                    <span>
                                       <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#999999"
                                            className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                      <path
                                          d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                    </svg>
                                 </span>
                                }
                                id='collasible-nav-dropdown_dashboard_projects'>
                                <NavDropdown.Item >Edit</NavDropdown.Item>
                            </NavDropdown> </Col>*/}
                        </Row>
                        <small style={{fontSize:'small'}}></small>
                    </Card.Title>
                    <Card.Text>
                        <ProgressBar style={{height:'5px'}} variant={between2digits((100 / (props.all as number) ) * (props.dataItem as number))} now={(100 / (props.all as number) ) * (props.dataItem as number)} />
                        <div id={'cardITem'} className={'mt-3'}>
                            <Row>
                                <Col md={8} xxl={8} xl={8} sm={8}>
                                    <div className={'border-'+between2digits((100 / (props.all as number) ) * (props.dataItem as number))}
                                         style={{borderInlineStart:'solid',writingMode:'horizontal-tb',paddingLeft:'5px'}}>{props.undertitle}<br/>
                                        {props.dataItem} Task</div>
                                </Col>
                                <Col  md={4} xxl={4} xl={4} sm={4}><p style={{textAlign:'right'}}><br/>
                                    <p className={'float-end'} style={{textAlign: 'right',color:"red"}}>{((100 / (props.all as number) ) * (props.dataItem as number)).toFixed(0)}%</p>
                                </p></Col>
                            </Row>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    )
}

export const TableEmploye = (props:{name:any,media:any,short:any,all:any,close:any,open:any,overdue:any}) => {
    const Store = useSelector((state: any) => state)
    return(
        <tr>
            <td style={{width:'fit-content'}}>
                <div className={'p-4'}>
                    <Avatar alt="Remy Sharp" src={'data:image/png;base64,'+props.media} className={'float-start'}>{props.short}</Avatar>
                    <span className={'float-start pt-2 pl-2 fw-bold'}>{props.name}</span>
                    <br style={{clear:'both'}} />
                </div>
            </td>
            <td>
                <TableEmployeProgress name={IntlMessageValue('de','common.open.task',Store)} all={props.all} close={props.open} percent={'20'} variant={'warning'} />
            </td>
            <td>
                <TableEmployeProgress name={IntlMessageValue('de','common.overdue.task',Store)} all={props.all} close={props.overdue} percent={'33'} variant={'danger'} />
            </td>
            <td>
                <TableEmployeProgress name={IntlMessageValue('de','common.completed.task',Store)} all={props.all} close={props.close} percent={'71'} variant={'success'} />
            </td>
        </tr>
    )
}

export const TableEmployeProgress = (props:{name:any,close:any,all:any,percent:any,variant:any}) => {

    return(
        <>
            <div className={'p-3'}><p><small>{props.name} Tasks {props.close} / {props.all}</small><span className={'float-end'}>{((100 / (props.all as number) ) * (props.close as number)).toFixed(0)+'%'}</span> </p>
                <ProgressBar now={(100 / (props.all as number) ) * (props.close as number)} title={(100 / (props.all as number) ) * (props.close as number)+'%'}  variant={props.variant} /></div>
        </>
    )
}
