import React from 'react'
import DashbaordOverview from './Pages/overview'
import DashbaordTaxConsultantOverview from './Pages/TaxConsultingOverview'

export const DashbaordPagesConfig = (value: any) => {
    return [
        {
            path: '/',
            elements: <DashbaordOverview value={value} />,
            roles: [
                'superadmin',
                'admin',
                'author',
                'contentcreator',
                'operator',
                'merchant',
                'merchant_ma',
                'teamtakt',
                'ticketsystem',
                'worktime',
                'teamtakt'
            ]
        },{
            path: '/',
            elements: <DashbaordTaxConsultantOverview value={value} />,
        roles: [
        'tax_consultant'
        ]
    },
    ]
}

export const DashbaordMenuConfig = (value: any) => {
    return [
        {
            name: 'Dashboard',
            route: '/',
            icon: 'dashboard',
            roles: [
                'superadmin',
                'admin',
                'author',
                'contentcreator',
                'operator',
                'merchant',
                'merchant_ma',
                'teamtakt',
                'ticketsystem',
                'worktime',
                'teamtakt'
            ]
        }
    ]
}
