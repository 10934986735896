import {chatInterface} from "./chat.interface";
import {requests} from "../../../../../@WUM/core/RestFullApi/api";
import {UserInterfaceApiType} from "./user.interface";
import {groupInterface} from "./group.interface";

export const chat  = {
    getPostsAllChat: (id: any): Promise<chatInterface> =>
        requests.get(`yoptimize/chat/ticket/${id}/`),
    getPostUser: (): Promise<UserInterfaceApiType[]> =>
        requests.get(`yoptimize/chat/ticket/all/`),
    getPostGroup: (): Promise<groupInterface[]> =>
        requests.get(`yoptimize/chat/user/group/`)
}
