import '../css/plugins.css'
import React from "react";
import Table from 'react-bootstrap/Table';
import {Responseregister} from "../../API/response/register";
const PluginOverview = (props: { value: any }) => {

    const response = Responseregister('')

    console.log(response)

    let DataHolder = [];

    if (response !== true && response.length > 0) {
        for(let r=0;r<response.length;r++) {
            let data = response[r]
            DataHolder.push(
                <tr>
                    <td>{data.software}</td>
                    <td>{data.version}</td>
                    <td>{data.platform}</td>
                    <td>{data.instanz}</td>
                    <td>{data.ip}</td>
                    <td>{data.lastping}</td>
                </tr>
            )
        }

    }

    return (
        <>
        <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Service</th>
                    <th>Version</th>
                    <th>CMS System</th>
                    <th>URL</th>
                    <th>Ip</th>
                    <th>Last ping</th>
                </tr>
                </thead>
            <tbody>
                {DataHolder}
                </tbody>
            </Table>
        </>
    )
}
export default PluginOverview


