import {memberApiType} from "./memberApi.interface";
import {requests} from "../../../../@WUM/core/RestFullApi/api";
import {projectApiType} from "./projectAPI.interface";
import {internalApiTYpe} from "./internal.Api,interface";
import {LastActivitiesApiTYpe} from "./LastActivitiesApi.interface";
import {taskColumnApiTYpe} from "./taskColumn.Api,interface";
import {taskSwimelaneApiTYpe} from "./taskSwimelanes.Api,interface";
import {responsibilityApiTYpe} from "./responsibility.Api,interface";
import {projectDashboardApiType} from "./projectDashboardAPI.interface";
import {DuoOnApiType} from "./DuoOn.interface";
import {reportsApiTYpe} from "./reports.Api,interface";
import {stateApiTYpe} from "./state.Api,interface";
import {CpsApiType} from "./Cps.interface";
import {PrioApiType} from "./Prio.interface";
import {LabelsApiType} from "./Labels.interface";
import {UserInterfaceApiType} from "./user.interface";
import {chatInterface} from "./chat.interface";


export const ticket = {
    getPosts: (): Promise<projectApiType[]> =>
        requests.get(`yoptimize/project/`),
    getPostsFetch: (): Promise<projectApiType[]> =>
        requests.get(`fetch/yoptimize/project/`),
    getPostsCPS: (): Promise<CpsApiType[]> =>
        requests.get(`ColorPaletteStandard/yoptimize/`),
    getPostsPRio: (): Promise<PrioApiType[]> =>
        requests.get(`prioritaeten/yoptimize/`),
    getPostsTemplate: (): Promise<PrioApiType[]> =>
        requests.get(`template/task/yoptimize/`),
    getPostsNotifications: (): Promise<PrioApiType[]> =>
        requests.get(`notifications/task/yoptimize/`),
    getPostsLabels: (): Promise<LabelsApiType[]> =>
        requests.get(`label/yoptimize/`),
    getPostsTrigger: (): Promise<LabelsApiType[]> =>
        requests.get(`yoptimize/fetch/system/trigger/TaskManager/`),
    getPostsactivityRecords: (): Promise<projectApiType[]> =>
        requests.get(`yoptimize/project/activity_recording/`),
    getPostsDuo: (responsibility:any): Promise<DuoOnApiType> =>
        requests.get(`yoptimize/${responsibility}/task/`),
    getPostsreports: (startdate:any,enddate:any): Promise<reportsApiTYpe> =>
        requests.get(`yoptimize/reports/task/${startdate}/${enddate}/`),
    getDashboardPosts: (): Promise<projectDashboardApiType[]> =>
        requests.get(`yoptimize/verwaltung/DashboardState/`),
    getPostsSingle: (id:any): Promise<projectApiType[]> =>
        requests.get(`yoptimize/project/${id}/Single/`),
    getPostsDashboard: (archiv:any = 0,del:any=0): Promise<projectApiType[]> =>
        requests.get(`yoptimize/verwaltung/project/all/${archiv}/${del}/`),
    getPostsParent: (id:any,archiv:any = 0,del:any=0): Promise<projectApiType[]> =>
        requests.get(`yoptimize/verwaltung/project/${id}/${archiv}/${del}/`),
    getPostsProjectDashbaord: (): Promise<projectApiType[]> => requests.get(`yoptimize/project/dashboard/`),
    getPostsTaskDashbaord: (): Promise<memberApiType[]> => requests.get(`yoptimize/Task/dashboard/`),
    getPostsAllTaskInternal: (id:any): Promise<internalApiTYpe[]> =>
        requests.get(`yoptimize/internall/project/${id}/task/`),
    getPostsAllTaskColumn: (): Promise<taskColumnApiTYpe[]> =>
        requests.get(`yoptimize/Column/task/All/`),
    getPostsAllTaskState: (): Promise<stateApiTYpe[]> =>
        requests.get(`state/yoptimize/`),
    getPostUser: (): Promise<UserInterfaceApiType[]> =>
        requests.get(`User/fetch/`),
    getPostsAllChat: (id:any): Promise<chatInterface[]> =>
        requests.get(`yoptimize/chat/messages/${id}/`),
    getPostsAllTaskSwimelanes: (id: any,): Promise<taskSwimelaneApiTYpe[]> =>
        requests.get(`yoptimize/swimelanes/project/${id}/`),
    getPostsAllTaskSwimelanesProject: (): Promise<taskSwimelaneApiTYpe[]> =>
        requests.get(`yoptimize/swimelanes/project/`),
    getPostsAllTaskSwimelanesSubProject: (id: any,): Promise<taskSwimelaneApiTYpe[]> =>
        requests.get(`yoptimize/swimelanes/mainproject/${id}/`),
    getPostsAllTaskProject: (): Promise<taskColumnApiTYpe[]> =>
        requests.get(`yoptimize/Column/project/All/`),
    getPostsAllTask: (id:any,archiv:any = 0,del:any=0): Promise<memberApiType[]> =>
        requests.get(`yoptimize/verwaltung/project/${id}/task/${archiv}/${del}/`),
    getPostsAllResponsibility: (): Promise<responsibilityApiTYpe[]> =>
        requests.get(`yoptimize/all/responsibility/`),
    getAPostTask: (id: any,taskid:any): Promise<memberApiType[]> =>
        requests.get(`yoptimize/project/${id}/task/${taskid}/`),
    getAPostLastActivities: (id: any,taskid:any): Promise<LastActivitiesApiTYpe[]> =>
        requests.get(`yoptimize/last_activities/project/${id}/task/${taskid}/`),
    getAPostLastActivitiesProject: (id: any,): Promise<LastActivitiesApiTYpe[]> =>
        requests.get(`yoptimize/last_activities/project/${id}/`),
    getAPostLastActivitiesAll: (): Promise<LastActivitiesApiTYpe[]> =>
        requests.get(`yoptimize/last_activities/`),
    createPost: (post: memberApiType): Promise<memberApiType> =>
        requests.post('posts', post),
    updatePost: (post: any, id: number): Promise<memberApiType> =>
        requests.put(`User/update/${id}/`, post),
    deletePost: (id: any): Promise<void> =>
        requests.delete(`User/delete/${id}/`)
}
