import {useEffect, useState} from "react";
import {error} from "../../../../@WUM/core/Elements/index";
import {support_softwareApiType} from "../request/SupportSoftwareApi.interface";
import {support_software} from "../request/support_software";
import {checkLogin} from "../../../../@WUM/templates/default/Auth/service/AuthService";

export const ResponseAllSupportSoftware = (instanz:any,fetchData:any,load:any,setLoad:any) => {
    const [posts, setPosts] = useState<support_softwareApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        if(load === false) {
            ;(async () => {
                    await support_software
                        .getPosts(instanz)
                        .then((data) => {
                            checkLogin(data)
                            setPosts(data)
                            setLoad(true)
                            //console.log(data);
                        })
                        .catch((err) => {
                            error(err)
                            setIsError(true)
                        })
                })()
        }
    }, [instanz,fetchData])

    return isError ? isError : posts
}
export const ResponseSingleTickets = (id:any,instanz:any,fetchData:any,load:any,setLoad:any,setValue:any) => {
    const [posts, setPosts] = useState<support_softwareApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await support_software
                .getPostsSingle(id,instanz)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    setValue(data[0].description)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id,fetchData,setValue])

    return isError ? isError : posts
}
