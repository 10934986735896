import React from 'react'
import SunEditorConfig from '../components/SuneditorConfig'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import { FormControl, InputGroup, Form } from 'react-bootstrap'
import BtnBack from '../components/btn_Back'
import { useNavigate, useParams } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import AllIssue from '../../API/allIssue'
import { isEmptyArray } from 'formik'
import { SpinnerJSX } from '../components/Spinner'
import { HelperRowOption } from '../helper/HelperRowOption'
import { HelperSwimelaneOption } from '../helper/HelperSwimelaneOption'
import { HelperTopicOption } from '../helper/HelperTopicOption'
import Allrow from '../../API/allrow'
import Allswimelanes from '../../API/allswimelanes'
import Alltopic from '../../API/alltopic'
import Alllabel from '../../API/alllabel'
import { notify_save_intvall_reload } from '../../../../@WUM/core/Function/index'
import EditIssueProps from '../../Interface/editIssue.interface'
import {IntlMessage, IntlMessageValue } from '../../../../@WUM/core/Elements/index'
import { useSelector } from 'react-redux'

const EditIssue = (props: EditIssueProps) => {
    const { id } = useParams<{ id: string | undefined }>()
    const history = useNavigate()
    const Store = useSelector((state: any) => state)
    let issue: any[] = []
    let dummy = AllIssue()
    for (let x = 0; x < dummy.length; x++) {
        // @ts-ignore
        if (dummy[x].id === parseInt(id as string)) {
            issue.push(dummy[x])
        }
    }
    let swimelane = Allswimelanes()

    let topic = Alltopic()

    let row = Allrow()

    let labels = Alllabel()

    function saveIssue(e: React.FormEvent<HTMLFormElement>, id: any) {
        e.preventDefault()
        let data = e.currentTarget

        //var myHeaders = new Headers();
        var formdata = new FormData(data)
        var raw = JSON.stringify({
            title: formdata.get('headline'),
            topic: formdata.get('topic'),
            id: issue[0].id,
            swimlanes: formdata.get('swimelane'),
            row: formdata.get('row'),
            cardColor: formdata.get('cardColor'),
            IssueTranslation: {
                translateable_id: issue[0].id,
                description: formdata.get('description')
            }
        })

        var requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch('http://localhost/api/issue/update/' + 1 + '/', requestOptions)
            .then((response) => response.text())
            .then((result) => reload(result))
            .catch((error) => console.log('error', error))
    }

    const reload = (result: any) => {
        //console.log(result)
        notify_save_intvall_reload(Store)
        setTimeout(
            () => window.location.replace('/ticket/issue/' + issue[0].id),
            3000
        )
    }

    if (
        issue !== undefined &&
        !isEmptyArray(issue) &&
        swimelane !== undefined &&
        !isEmptyArray(swimelane) &&
        topic !== undefined &&
        !isEmptyArray(topic) &&
        row !== undefined &&
        !isEmptyArray(row) &&
        labels !== undefined &&
        !isEmptyArray(labels)
    ) {
        return (
            <>
                <div className={'ContentWrapper'}>
                    <form onSubmit={(e) => saveIssue(e, issue[0].id)}>
                        <h1>
                            <IntlMessage
                                Store={Store}
                                messageId={'create.edit.issue'}
                            />
                        </h1>
                        <hr />
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                                <IntlMessage
                                    Store={Store}
                                    messageId={'common.title'}
                                />
                            </InputGroup.Text>
                            <FormControl
                                placeholder={IntlMessageValue(
                                    '',
                                    'common.title.placeholder',
                                    Store
                                )}
                                aria-label="headline"
                                name={'headline'}
                                defaultValue={issue[0].title}
                                aria-describedby="basic-addon1"
                                required={true}
                            />
                            <InputGroup.Text id="basic-addon1">
                                <IntlMessage
                                    Store={Store}
                                    messageId={'section'}
                                />
                            </InputGroup.Text>
                            <Form.Select name={'topic'} required={true}>
                                <option></option>
                                <HelperTopicOption
                                    topic={topic}
                                    selected={issue[0].topic.name}
                                />
                            </Form.Select>
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                                <IntlMessage
                                    Store={Store}
                                    messageId={'swimelane'}
                                />
                            </InputGroup.Text>
                            <Form.Select name={'swimelane'} required={true}>
                                <HelperSwimelaneOption
                                    swimelane={swimelane}
                                    selected={issue[0].swimelane}
                                />
                            </Form.Select>

                            <InputGroup.Text id="basic-addon1">
                                <IntlMessage Store={Store} messageId={'row'} />
                            </InputGroup.Text>
                            <Form.Select name={'row'} required={true}>
                                <HelperRowOption
                                    row={row}
                                    selected={issue[0].row}
                                />
                            </Form.Select>

                            <InputGroup.Text id="basic-addon1">
                                <IntlMessage
                                    Store={Store}
                                    messageId={'issue.color'}
                                />
                            </InputGroup.Text>
                            <Form.Control
                                type="color"
                                id="cardColor"
                                name={'cardColor'}
                                defaultValue={issue[0].cardColor}
                                title={IntlMessageValue(
                                    '',
                                    'common.color.placeholder',
                                    Store
                                )}
                            />
                        </InputGroup>
                        <label>
                            <IntlMessage
                                Store={Store}
                                messageId={'common.description'}
                            />
                        </label>
                        <SunEditor
                            name={'description'}
                            height={'350px'}
                            defaultValue={issue[0].IssueTranslation.description}
                            setOptions={{
                                buttonList: SunEditorConfig(),
                                attributesWhitelist: {
                                    div: 'id',
                                    p: 'id',
                                    h: 'id'
                                }
                            }}
                        />
                        <br />

                        <hr />
                        <br />
                        <BtnBack
                            history={history}
                            addedClass={''}
                            langKey={'common.back'}
                        />
                        <Button
                            className={'float-end'}
                            variant={'success'}
                            type={'submit'}
                        >
                            <IntlMessage
                                Store={Store}
                                messageId={'common.save'}
                            />
                        </Button>
                        <div style={{ clear: 'both' }}></div>
                    </form>
                </div>
            </>
        )
    } else {
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} />
            </>
        )
    }
}

export default EditIssue
