import Form from 'react-bootstrap/Form';
import {FormCheckType} from "react-bootstrap/FormCheck";
import React from "react";

type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

interface UniChecksProps {
    /** Valide Value = checkbox | radio | switch */
    type:FormCheckType | undefined,
    /** Set Label text on Element */
    label:string,
    /** Set Value text on Element */
    value?:string,
    /** set id on Element in DOM */
    id?:string,
    /** Set custom Classes on Element */
    class?:string,
    /** Set Element Disabled by true and remove Disabled by false */
    disabled?:boolean,
    /** Set Element checked by true and remove checked by false */
    checked?:boolean,
    /** Set DefaultValue on Element */
    defaultValue?:number,
    /** Combine Element with Onchange Function and Event */
    onChange?: React.ChangeEventHandler<FormControlElement>
    name?: string;
}

/**
 * Build Checkbox or Radio Button or Switcher with <strong><a href="https://react-bootstrap.netlify.app/" target="_blank">React Bootstrap</a></strong> Module <a href="https://react-bootstrap.netlify.app/docs/forms/checks-radios" target="_blank">Form.Check</a><br/><br/>
 *  <strong>Code Example:</strong><br/>
 * ```tsx
 * <UniForm validated={validated} Submit={handleSubmit}>
 *  <UniForm.Checks type={'checkbox'} label={'checkbox'} />
 * </UniForm>
 * ```
 * @param props
 * @version 0.0.1
 * @author [Marcel Ugurcu](https://wum-solution.de)
 * @constructor
 */
const UniChecks = (props:UniChecksProps) => {

    let Class:string = props.class === undefined ? 'mb-3' : props.class

    let ID:string = props.id === undefined ? '' : props.id

    return(
        <Form.Check
            disabled={props.disabled !== undefined ? props.disabled : false}
            type={props.type}
            value={props.value}
            label={props.label}
            name={props.name}
            defaultChecked={props.checked}
            id={ID}
            className={Class}
            defaultValue={props.defaultValue}
            onChange={props.onChange}
        />
    )
}

export default UniChecks;
