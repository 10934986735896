import {ResponseAllNotification} from "../api/response/notification";
import React from "react";
import {useSelector} from "react-redux";
import Toast from 'react-bootstrap/Toast';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ApiURL, SpinnerJSX} from "../../Elements/index";
// @ts-ignore
import {Notification} from "create-notifications/notfication";

const NotificationOverview = (value:any) => {
    const Store = useSelector((state: any) => state)
    const notification = ResponseAllNotification("0","");

    const ChangeState = (id:any) => {
        let data = {
            parameter:'notification.state',
            value: '1'
        };
        let raw = JSON.stringify(data);
        let root = ApiURL();
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw,
        };
        fetch(root+"update/parameter/"+id+"/", requestOptions)
            .then(response => response.text())
            .then(result => Notification({rewrite:true,landingpages:'#',type:'success', mode:'classic', title:{text:'Success'}, text:{text: 'Saved successfully'}, insert:'center right',duration:4}))
            .catch(error => Notification({rewrite:true,landingpages:'#',type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Sorry, an error has occurred'}, insert:'center right',duration:4}));

    }

    let NotificationHolder = [];

    for (let n = 1; n < notification.length; n++) {
        NotificationHolder.push(
            <>
                <Col>
                <Toast>
                <Toast.Header>
                    <img src="/assets/icons/icon-48x48.png" height={24} width={24} className="rounded me-2" alt="" />
                    <strong className="me-auto">{notification[n].title}</strong>
                    <small>{notification[n].subtitle}</small>
                </Toast.Header>
                <Toast.Body>{notification[n].message}
                <br/>
                    {notification[n].state === 0 ?
                    <Button variant="success" onClick={()=> ChangeState(notification[n].id)}>Gelesen</Button>
                        : <></>}
                </Toast.Body>
            </Toast>
                </Col>
            </>
        )
    }

    if (notification.length > 0) {
        return (
            <>
                <Row>
                {NotificationHolder}
                </Row>
            </>
        )
    } else{
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}

export default NotificationOverview;
