import React,{
    JSXElementConstructor,
    ReactElement,
    ReactNode,
    ReactPortal
} from "react";
import {useLocation} from "react-router-dom";
import LockMap from './lockMap.json';
import {SingleTaskFetch} from "./Fetch/SingleTaskFetch";
import {SingleTicketFetch} from "./Fetch/SingleTicketFetch";
interface IndexProps{
    /** Allows it to have children and have them reflected. */
    children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined,
}

const Index = (props:IndexProps) => {

    let Map = LockMap
    const location = useLocation();

    for(let x =0;x<Map.length;x++) {
        if(!location.pathname.includes(Map[x].url)  && localStorage.getItem('BlockedRoute') !== null && localStorage.getItem('BlockedRoute') !== undefined && location.pathname !== JSON.parse(localStorage.getItem('BlockedRoute') as string).url){
            SingleTaskFetch({id:JSON.parse(localStorage.getItem('BlockedRoute') as string).id,taskid:JSON.parse(localStorage.getItem('BlockedRoute') as string).taskid,lock:"0",lockby:'',url:'',mode:'remove'})
        } else {

        }
        if(!location.pathname.includes(Map[x].url)  && localStorage.getItem('BlockedRouteTicket') !== null && localStorage.getItem('BlockedRouteTicket') !== undefined && location.pathname !== JSON.parse(localStorage.getItem('BlockedRouteTicket') as string).url){
            SingleTicketFetch({ticketid:JSON.parse(localStorage.getItem('BlockedRouteTicket') as string).ticketid,lock:"0",lockby:'',url:'',mode:'remove'})
        } else {

        }
    }

    return(
        <>
           {props.children}
        </>
    )

}

export default Index;
