import React from 'react'
import Ticketoverview from './Pages/overview'
import Issue from './Pages/issue'
import NewIssue from './Pages/newIssue'
import EditIssue from './Pages/editIssue'
import LabelEdit from './Pages/LabelEdit'
import { LanguageSwitcherValue } from './components/LanguageSwitcher'
import Dashboard from "./new/Pages/Dashboard";
import MyProjects from "./new/Pages/MyProjects";
import MyTask from "./new/Pages/MyTask";
import SingleTask from "./new/Pages/SingleTask";
import MyUnderProjects from "./new/Pages/MyUnderProjects";
import MyTaskCustomer from "./new/Pages/MyTaskCustomer";
import MytaskUser from "./new/Pages/MytaskUser";
import Reports from "./new/Pages/reports";
import CPS from "./new/Pages/CPS";
import ActivityRecordingOverview from "./new/Pages/overview";
import {CryptDecrypt} from "../../../@WUM/core/Elements/crypt/Crypt";
import IssuesList from "./new/Pages/Gitlab";
import IssueBoard from "./new/Pages/IssueBoard";

export const TicketPagesConfig = (value: any) => {
    switch (process.env.REACT_APP_MODE_TICKET) {
        case 'new':
            if(localStorage.getItem('userRights') !== null && localStorage.getItem('userRights') !== undefined && JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string).taskmanager === 0) {
                return []
            } else {
                return [
                    {
                        path: '/ticketdashboard/overview/:page',
                        elements: <Dashboard value={value}/>,
                        roles: ['superadmin', 'admin', 'teamtakt'],
                        permission:['TaskManager','Dashboard']
                    },
                    {
                        path: '/myprojects/new/projects/:page',
                        elements: <MyProjects value={value}/>,
                        roles: ['superadmin', 'admin', 'teamtakt'],
                        permission:['TaskManager','Projects']
                    },
                    {
                        path: '/myprojects/new/projects/:page/:projectid/:name',
                        elements: <MyUnderProjects value={value}/>,
                        roles: ['superadmin', 'admin', 'teamtakt'],
                        permission:['TaskManager','Projects']
                    },
                    {
                        path: '/myprojects/task/all/projects/:id/:name',
                        elements: <MyTask value={value}/>,
                        roles: ['superadmin', 'admin', 'teamtakt'],
                        permission:['TaskManager','Projects']
                    },
                    {
                        path: '/reports/TT/overview',
                        elements: <Reports value={value}/>,
                        roles: ['superadmin', 'admin', 'teamtakt'],
                        permission:['TaskManager','Reports']
                    },
                    {
                        path: '/cps/overview',
                        elements: <CPS value={value}/>,
                        roles: ['superadmin', 'admin', 'teamtakt']
                    },
                    {
                        path: '/myprojects/task/single/:id/:taskid',
                        elements: <SingleTask value={value}/>,
                        roles: ['superadmin', 'admin', 'teamtakt'],
                        permission:['TaskManager','Projects']
                    },
                    {
                        path: '/MytaskUser/overview/1',
                        elements: <MytaskUser value={value}/>,
                        roles: ['superadmin', 'admin', 'teamtakt'],
                        permission:['TaskManager','Mytask']
                    },
                    {
                        path: '/ticket/Issue/:id',
                        elements: <Issue value={value}/>,
                        roles: ['superadmin', 'admin', 'teamtakt'],
                        permission:['TaskManager','Projects']
                    },
                    {
                        path: '/activityrecording/overview/:page',
                        elements: <ActivityRecordingOverview value={value}/>,
                        roles: ['superadmin', 'admin', 'merchant'],
                        permission:['TaskManager','ServiceTracking']
                    },
                    {
                        path: '/ticket/edit/Issue/:id',
                        elements: <EditIssue value={value}/>,
                        roles: ['superadmin', 'admin', 'teamtakt'],
                        permission:['TaskManager','Projects']
                    },
                    {
                        path: '/ticket/new/Issue',
                        elements: <NewIssue value={value}/>,
                        roles: ['superadmin', 'admin', 'teamtakt'],
                        permission:['TaskManager','Projects']
                    },
                    {
                        path:'/gitlab/Issue',
                        elements: <IssueBoard />,
                        roles: ['superadmin','admin','teamtakt'],
                        permission:['TaskManager','Projects']
                    }
                ]
            }
        default:
            if(process.env.REACT_APP_MODE_TICKET_ID !== undefined && process.env.REACT_APP_MODE_TICKET_ID !== null
                && process.env.REACT_APP_MODE_TICKET_NAME !== undefined && process.env.REACT_APP_MODE_TICKET_NAME !== null) {
                return [
                    {
                        path: '/myprojects/task/all/projects/:id/:name',
                        elements: <MyTaskCustomer value={value} />,
                        roles: ['superadmin', 'admin','teamtakt']
                    },
                    {
                        path: '/myprojects/task/single/:id/:taskid',
                        elements: <SingleTask value={value} />,
                        roles: ['superadmin', 'admin','teamtakt']
                    }
                ]
            } else {
                return [
                    {
                        path: '/ticket/overview/:page',
                        elements: <Ticketoverview value={value} />,
                        roles: ['superadmin', 'admin','teamtakt']
                    },
                    {
                        path: '/ticket/Issue/:id',
                        elements: <Issue value={value} />,
                        roles: ['superadmin', 'admin','teamtakt']
                    },
                    {
                        path: '/ticket/edit/Issue/:id',
                        elements: <EditIssue value={value} />,
                        roles: ['superadmin', 'admin','teamtakt']
                    },
                    {
                        path: '/Label/ticket/overview',
                        elements: <LabelEdit value={value} />,
                        roles: ['superadmin', 'admin','teamtakt']
                    }
                ]
            }

    }
}

export const TicketMenuConfig = (value: any) => {

    const CheckTaskmanager = () => {

        let checkTask = 0;

        if(localStorage.getItem('userPermission') !== null &&
            localStorage.getItem('userPermission') !== undefined &&
            JSON.parse(CryptDecrypt(localStorage.getItem('userPermission') as string) as string).TaskManager.Dashboard !== 0) {
            checkTask++
        }
        if(localStorage.getItem('userPermission') !== null &&
            localStorage.getItem('userPermission') !== undefined &&
            JSON.parse(CryptDecrypt(localStorage.getItem('userPermission') as string) as string).TaskManager.Projects !== 0) {
            checkTask++
        }
        if(localStorage.getItem('userPermission') !== null &&
            localStorage.getItem('userPermission') !== undefined &&
            JSON.parse(CryptDecrypt(localStorage.getItem('userPermission') as string) as string).TaskManager.Mytask !== 0) {
            checkTask++
        }
        if(localStorage.getItem('userPermission') !== null &&
            localStorage.getItem('userPermission') !== undefined &&
            JSON.parse(CryptDecrypt(localStorage.getItem('userPermission') as string) as string).TaskManager.Settings !== 0) {
            checkTask++
        }
        if(localStorage.getItem('userPermission') !== null &&
            localStorage.getItem('userPermission') !== undefined &&
            JSON.parse(CryptDecrypt(localStorage.getItem('userPermission') as string) as string).TaskManager.Reports !== 0) {
            checkTask++
        }

        if(checkTask !== 0) {
            return true
        } else {
            return false
        }
    }

    switch (process.env.REACT_APP_MODE_TICKET) {
        case 'new':
            if(localStorage.getItem('userRights') !== null && localStorage.getItem('userRights') !== undefined && JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string).taskmanager === 0) {
                return []
            } else {
                return [
                    {
                        header: 'IT-Support',
                        icon: 'support',
                        roles: ['superadmin', 'admin', 'teamtakt'],
                        show: CheckTaskmanager(),
                        menuitem: [
                            {
                                name: 'common.dashbaord',
                                route: '/ticketdashboard/overview/1',
                                icon: 'developer_board',
                                roles: ['superadmin', 'admin', 'teamtakt'],
                                permission:['TaskManager','Dashboard']
                            },
                            {
                                name: 'common.projects',
                                route: '/myprojects/new/projects/1',
                                icon: 'note_alt',
                                roles: ['superadmin', 'admin', 'teamtakt'],
                                permission:['TaskManager','Projects']
                            },
                            {
                                name: 'common.my.tasks',
                                route: '/MytaskUser/overview/1',
                                icon: 'chat',
                                roles: ['superadmin', 'admin', 'teamtakt'],
                                permission:['TaskManager','Mytask']
                            },
                            {
                                name: 'common.activityrecording',
                                route: '/activityrecording/overview/1',
                                icon: 'more_time',
                                roles: ['superadmin', 'admin', 'merchant'],
                                permission:['TaskManager','ServiceTracking']
                            },
                            {
                                name: 'common.report_stats',
                                route: '/reports/TT/overview',
                                icon: 'query_stats',
                                roles: ['superadmin', 'admin', 'teamtakt'],
                                permission:['TaskManager','Reports']
                            },
                            {
                                name: 'common.generalTM.settings',
                                route: '/cps/overview',
                                icon: 'settings',
                                roles: ['superadmin', 'admin', 'teamtakt'],
                                permission:['TaskManager','Settings']
                            },
                            {
                                name: 'Gitlab Issue',
                                route: '/gitlab/Issue',
                                icon: 'developer_board',
                                roles: ['superadmin', 'admin', 'teamtakt'],
                                permission:['TaskManager','Dashboard']
                            },
                        ]
                    }
                ]
            }
        default:
            if(process.env.REACT_APP_MODE_TICKET_ID !== undefined && process.env.REACT_APP_MODE_TICKET_ID !== null
            && process.env.REACT_APP_MODE_TICKET_NAME !== undefined && process.env.REACT_APP_MODE_TICKET_NAME !== null) {
                return [
                    {
                        header: LanguageSwitcherValue('IT-Support'),
                        icon: 'support',
                        roles: ['superadmin', 'admin','teamtakt'],
                        menuitem: [
                            {
                                name: 'My Project',
                                route: '/myprojects/task/all/projects/'+process.env.REACT_APP_MODE_TICKET_ID+'/'+process.env.REACT_APP_MODE_TICKET_NAME+'',
                                icon: 'note_alt',
                                roles: ['superadmin', 'admin','teamtakt'],
                                permission:false
                            }
                        ]
                    }
                ]
            } else {
                return [
                    {
                        header: LanguageSwitcherValue('IT-Support'),
                        icon: 'support',
                        roles: ['superadmin', 'admin','teamtakt'],
                        menuitem: [
                            {
                                name: 'Dashboard',
                                route: '/ticket/overview/:page',
                                icon: 'developer_board',
                                roles: ['superadmin', 'admin','teamtakt'],
                                permission:false
                            },   {
                                name: "Labels",
                                route: '/Label/ticket/overview',
                                icon: 'label',
                                roles: ['superadmin', 'admin','teamtakt'],
                                permission:false
                            }
                        ]
                    }
                ]
            }

    }
}

export const TicketSingleMenuConfig = (value: any) => {
    switch (process.env.REACT_APP_MODE_TICKET) {
        case 'new':
            return [
                        {
                            name: 'Dashboard',
                            route: '/ticketdashboard/overview/1',
                            icon: 'developer_board',
                            roles: ['superadmin', 'admin','teamtakt']
                        },
                        {
                            name: 'Projects',
                            route: '/myprojects/new/projects/1',
                            icon: 'note_alt',
                            roles: ['superadmin', 'admin','teamtakt']
                        }

            ]
        default:
            if(process.env.REACT_APP_MODE_TICKET_ID !== undefined && process.env.REACT_APP_MODE_TICKET_ID !== null
                && process.env.REACT_APP_MODE_TICKET_NAME !== undefined && process.env.REACT_APP_MODE_TICKET_NAME !== null) {
                return [
                            {
                                name: 'Supportsystem',
                                route: '/myprojects/task/all/projects/'+process.env.REACT_APP_MODE_TICKET_ID+'/'+process.env.REACT_APP_MODE_TICKET_NAME+'',
                                icon: 'developer_board',
                                roles: ['superadmin', 'admin','teamtakt']
                            }

                ]
            } else {
                return [
                            {
                                name: 'Dashboard',
                                route: '/ticket/overview/:page',
                                icon: 'developer_board',
                                roles: ['superadmin', 'admin','teamtakt']
                            },   {
                                name: "Labels",
                                route: '/Label/ticket/overview',
                                icon: 'label',
                                roles: ['superadmin', 'admin','teamtakt']
                            }
                        ]

            }

    }
}


export const itSupport = () => {

    switch (process.env.REACT_APP_MODE_TICKET) {
        case 'new':
            return [
                {
                    title: 'menu.item.dashboard',
                    href: '/ticketdashboard/overview/1',
                    undertitle: LanguageSwitcherValue('IT-Support'),
                    icon: 'developer_board',
                    text: 'menu.item.dashboard.description',
                    roles: ['superadmin', 'admin','teamtakt']
                },
                {
                    title: 'Projects',
                    href: '/myprojects/new/projects/1',
                    undertitle: LanguageSwitcherValue('IT-Support'),
                    icon: 'note_alt',
                    text: 'menu.item.labels.description',
                    roles: ['superadmin', 'admin','teamtakt']
                },
                {
                    title: 'MyTask',
                    href: '/MytaskUser/overview/1',
                    undertitle: LanguageSwitcherValue('IT-Support'),
                    icon: 'chat',
                    text: 'menu.item.labels.description',
                    roles: ['superadmin', 'admin','teamtakt']
                },
                {
                    title: 'common.generalTM.settings',
                    href: '/cps/overview',
                    undertitle: LanguageSwitcherValue('IT-Support'),
                    icon: 'settings',
                    text: 'menu.item.labels.description',
                    roles: ['superadmin', 'admin','teamtakt']
                }
            ]
        default:
            return [
                {
                    title: 'menu.item.dashboard',
                    href: '/ticket/overview/1',
                    undertitle: 'IT-Support',
                    icon: 'developer_board',
                    text: 'menu.item.dashboard.description',
                    roles: ['superadmin', 'admin','teamtakt']
                },
                {
                    title: 'menu.item.labels',
                    href: '/Label/ticket/overview',
                    undertitle: 'IT-Support',
                    icon: 'label',
                    text: 'menu.item.labels.description',
                    roles: ['superadmin', 'admin','teamtakt']
                }
            ]
    }
}


export const itSupportSidebar: string =
    '/ticket/overview/:page,/Label/ticket/overview'
