import {useEffect, useState} from "react";
import {error} from "../../../../../@WUM/core/Elements/index";
import {support_software} from "../request/support_software";
import {checkLogin} from "../../../../../@WUM/templates/default/Auth/service/AuthService";
import {_UserInterface} from "../request/SupportSoftwareApi.interface";

export const ResponseUSerProfil = (id:any) => {
    const [posts, setPosts] = useState<_UserInterface>()
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await support_software
                .getPosts(id)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : posts
}
