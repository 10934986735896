import {IntlMessageValue} from "../../component/const/IntlMessage";
import React from "react";
import {SwitchContainer} from "../../Elements/index";


const DataList = (data: any,i:any,Store:any) => {

    let FetchHolder = [];



    for(let f=0;f<data.fetch.length;f++){

        FetchHolder.push(
            <>
                <option value={data.fetch[f][data.value1] + ' - ' + data.fetch[f][data.value2]} key={f} data-id={''+f+''} />

            </>
        )
    }

    return(
        <>
            <label htmlFor={data.idInput} className="form-label" style={{width: 'max-content',float:'left'}}>{data.label}</label>
            <input className="form-control" list={data.id} name={data.name} id={data.idInput} style={{width: 'max-content',float:'right'}}
                   placeholder={IntlMessageValue('de', 'common.datalist.label', Store)}
                   onFocus={(e) => e.target.value=''}
                   onChange={(e)=>SwitchContainer(data.fetch,data.id, data.idSet,e.target.value)}
            />
            <datalist id={data.id} >
                <option></option>
                {FetchHolder}
            </datalist>

        </>
    )

}

export default DataList;
