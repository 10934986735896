import {IntlMessageValue} from "../component/const/IntlMessage";
import Button from "react-bootstrap/Button";
import React from "react";

interface UpgradeProps {
    Store: any
    float?: 'float-end' | 'float-start' | 'float-none'
}

const UpgradeInfoBox = (props: UpgradeProps) => {

    return (<>
            <h5>{IntlMessageValue('', 'common.header.free.version', props.Store)}</h5>
            <br style={{clear: 'both'}}/>
            <p>{IntlMessageValue('', 'common.description.free.version', props.Store)}</p>
            <Button type={'button'} href={'https://teamtakt.de/kundencenter/'}
                    target={'_blank'}
                    variant="success">{IntlMessageValue('', 'common.button.free.version', props.Store)}</Button>
        </>
    )
}

export default UpgradeInfoBox;

export const UpgradeButton = (props:UpgradeProps) => {

    return(
        <> <div className={props.float !== undefined ? props.float : ''}>
            <h5 title={IntlMessageValue('', 'common.description.free.version', props.Store)}>{IntlMessageValue('', 'common.header.free.version', props.Store)}</h5>
            <Button type={'button'} href={'https://teamtakt.de/kundencenter/'}
                    target={'_blank'} title={IntlMessageValue('', 'common.description.free.version', props.Store)}
                    variant="success">{IntlMessageValue('', 'common.button.free.version', props.Store)}</Button>
            {props.float !== undefined && props.float !== 'float-none' ? <><br style={{clear:'both'}} /><br/></> : <></>}
        </div>
        </>
    )
}

export const ShortUpgradeButton = (props:UpgradeProps) => {

    return(
        <>
            <Button type={'button'} href={'https://teamtakt.de/kundencenter/'}
                    target={'_blank'} title={IntlMessageValue('', 'common.description.free.version', props.Store)}
                    variant="success">{IntlMessageValue('', 'common.button.free.version', props.Store)}</Button>
            {props.float !== undefined && props.float !== 'float-none' ? <><br style={{clear:'both'}} /><br/></> : <></>}

        </>
    )
}