import Form from "react-bootstrap/Form";
import React, {useState} from "react";
import {ResponseSingleTickets} from "../API/response/support_software";
import {useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {SpinnerJSX} from "../../ticketsystem/modules/components/Spinner";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {dateFormat, IntlMessage, IntlMessageValue, error, ApiURL} from "../../../@WUM/core/Elements/index";
import '../css/Software_Support.css'
import {ApiRoot} from "../../../@WUM/core/RestFullApi/api";
import SunEditor from "suneditor-react";
import {init2} from "../../../@WUM/core/form-serilizer/seriliaze/Formserialize";
import {getToken, setNavi} from "../../../@WUM/core/Function/index";
import MultiprogressBar from "./components/MultiprogressBar";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Badge from 'react-bootstrap/Badge';
const SoftwareSupportTicket = (props:{value:any}) =>{
    setNavi({
        route:'/ticket/ticketsystem/'
    })

    const SunEditorConfig = () => {
        return [
            // Default
            ['italic','bold','outdent', 'indent','align', 'list','link']
        ]
    }
    const { id } = useParams<{ id: string }>()
    const Store = useSelector((state: any) => state)
    const [fetchData, setFetchData] = useState(0);
    const [load,setLoad] = useState(false);
    const [activeButton, setactiveButton] = useState('discussion');
    let state;
    let PrioritaetState;
    let BuildLastActivities = [];
    const [value, setValue] = useState('');
    const [valueComment, setValueComment] = useState('');
    const response = ResponseSingleTickets(id,{instanz:window.location.hostname},fetchData,load,setLoad,setValue);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    if (typeof response !== "boolean" && response?.length > 0) {
            if(response[0].last_activities !== undefined && response[0].last_activities !== null) {
                for (let la = response[0].last_activities.length - 1; la >= 0; la--) {
                    let LAData = response[0].last_activities[la]
                    BuildLastActivities.push(
                        <>
                            <li className="timeline-item mb-5" style={{padding:'0px'}}>
                                <h5 className="fw-bold m-0">
                                    <span className={'float-start'} style={{paddingTop:'5px',paddingLeft:'5px'}}><small>{LAData.member}</small></span>
                                </h5>
                                <br style={{clear:'both'}}/>
                                <p className="text-muted mb-2 fw-bold" style={{paddingLeft:'5px',borderRadius:'10px',border:'1px solid #E5E5E5'}}>
                                    <span dangerouslySetInnerHTML={{__html: LAData.regard}}/>
                                    <br/><small>{
                                    dateFormat(
                                        LAData.created_at,
                                        localStorage.getItem('locale'),
                                        'us',
                                        true,
                                        false,
                                        Store
                                    )
                                }</small></p>
                                <p className="text-muted" dangerouslySetInnerHTML={{__html: LAData.content}} style={{padding:'5px',borderRadius:'10px',border:'1px solid #E5E5E5'}} />
                            </li>
                        </>
                    )
                }
            }

            let CommentHolder = [];
            if(response[0].ticket_comment !== undefined && response[0].ticket_comment !== null) {
                for (let c = response[0].ticket_comment.length - 1; c >= 0; c--) {
                    let CData = response[0].ticket_comment[c]
                    CommentHolder.push(
                        <>
                            <Card className={'mb-3'}>
                                <Card.Body>
                                    <Card.Title>{CData.member} <small style={{fontSize:'12px'}}>{CData.created_at}</small></Card.Title>
                                    <Card.Text>
                                        <div dangerouslySetInnerHTML={{__html: CData.comment}} />
                                    </Card.Text></Card.Body>
                            </Card>
                        </>
                    )
                }
        }

            let AttachmentHolder = [];
        if(response[0].attachment !== undefined && response[0].attachment !== null) {
            for (let a = response[0].attachment.length - 1; a >= 0; a--) {
                let AData = response[0].attachment[a]
                AttachmentHolder.push(
                    <>
                        <Card className={'mb-3'}>
                            <Card.Body>
                                <Card.Title>{AData.member} <small style={{fontSize:'12px'}}>{AData.created_at}</small></Card.Title>
                                <Card.Text>
                                    <a href={AData.url} target={'_blank'}>
                                        <span className="material-icons " style={{verticalAlign:'bottom'}}>
                                            attachment
                                        </span>
                                        {AData.name}
                                    </a>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </>
                )
            }
        }
            let TaskHolder = [];

            const displaySwitcher = (id:any,anotherid:any) => {

                let first = document.getElementById(id) as HTMLDivElement;
                setactiveButton(id)

                if(first !== null) {
                    first.style.display = 'block';
                    for(let x=0;x<anotherid.length;x++) {
                        let second = document.getElementById(anotherid[x]) as HTMLDivElement;
                        if(second !== null) {
                            second.style.display = 'none';
                        }

                    }

                }

            }

        const setStateTask = (state:any,tuid:any) => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "ticket_id": id,
                "id":tuid,
                "lastupdatefrom": localStorage.getItem('username'),
                "state": state
            });


            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
            };
            setValueComment('')
            fetch(ApiRoot()+"yoptimize/state/underTask/support/", requestOptions)
                .then(response => response.text())
                .then(result => setFetchData(Date.now()))
                .catch(error => console.log('error', error));
        }


        if(response[0].ticket_undertask !== undefined && response[0].ticket_undertask !== null) {
            for (let tu = 0; tu < response[0].ticket_undertask.length; tu++) {
                let TUData = response[0].ticket_undertask[tu]
                TaskHolder.push(
                    <>
                        {TUData.state === '1' ?
                            <>
                                <p  style={{cursor: 'pointer'}} onClick={()=>setStateTask(0,TUData.id)}>

                                        <span className="material-icons float-start "
                                              style={{verticalAlign: 'bottom', color: 'green'}}>
                                check_circle
                                </span>


                                    <span className={'float-start'} style={{boxSizing: 'unset', paddingLeft: '10px'}} dangerouslySetInnerHTML={{__html: TUData.content}} />
                                    <br style={{clear: 'both'}}/>
                                </p>
                                <br/>
                            </>
                            :
                            <>
                                <p style={{cursor: 'pointer'}} onClick={()=>setStateTask(1,TUData.id)}>
                                <span className="material-icons float-start "
                                      style={{verticalAlign: 'bottom', color: 'gray'}}>
                                check_circle
                                </span>


                                    <span className={'float-start'} style={{boxSizing: 'unset', paddingLeft: '10px'}} dangerouslySetInnerHTML={{__html: TUData.content}} />
                                    <br style={{clear: 'both'}}/>
                                </p>
                                <br/>
                            </>
                        }
                        </>
                )
            }
        }

        if(response[0].ticket_prioritaeten.id === 1) {
            PrioritaetState = {variants:['success','secondary','secondary','secondary','secondary'],title:['Lowest','Low','Medium','High','Urgent']};
        } else if(response[0].ticket_prioritaeten.id === 2) {
            PrioritaetState = {variants:['success','success','secondary','secondary','secondary'],title:['Lowest','Low','Medium','High','Urgent']};
        }else if(response[0].ticket_prioritaeten.id === 3) {
            PrioritaetState = {variants:['success','success','warning','secondary','secondary'],title:['Lowest','Low','Medium','High','Urgent']};
        }else if(response[0].ticket_prioritaeten.id === 4) {
            PrioritaetState = {variants:['success','success','warning','danger','secondary'],title:['Lowest','Low','Medium','High','Urgent']};
        } else if(response[0].ticket_prioritaeten.id === 5) {
            PrioritaetState = {variants:['success','success','warning','danger','danger'],title:['Lowest','Low','Medium','High','Urgent']};
        }  else {
            PrioritaetState = {variants:['secondary','secondary','secondary','secondary','secondary'],title:['Lowest','Low','Medium','High','Urgent']};
        }

        if(response[0].status.id === 1) {
            state = {variants:['success','secondary','secondary','secondary','secondary'],title:['Open','In Progress','Pending','Overdue','Resolve']};
        } else if(response[0].status.id === 2) {
            state = {variants:['success','success','secondary','secondary','secondary'],title:['Open','In Progress','Pending','Overdue','Resolve']};
        }else if(response[0].status.id === 3) {
            state = {variants:['success','success','success','secondary','secondary'],title:['Open','In Progress','Pending','Overdue','Resolve']};
        }else if(response[0].status.id === 4) {
            state = {variants:['success','success','success','warning','secondary'],title:['Open','In Progress','Pending','Overdue','Resolve']};
        }else if(response[0].status.id === 5) {
            state = {variants:['success','success','success','warning','success'],title:['Open','In Progress','Pending','Overdue','Resolve']};
        } else {
            state = {variants:['secondary','secondary','secondary','secondary','secondary'],title:['Open','In Progress','Pending','Overdue','Resolve']};
        }

        const saveComment = () => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "ticket_id": id,
                "comment": (document.getElementsByName('CommentField')[0] as HTMLTextAreaElement).value,
                "member": localStorage.getItem('username')
            });


            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
            };
            setValueComment('')
           fetch(ApiRoot()+"yoptimize/save/ticket/comment/support/", requestOptions)
                .then(response => response.text())
                .then(result => setFetchData(Date.now()))
                .catch(error => console.log('error', error));
        }

        const setEditorContent = (value:any,textarea?:any) =>{
            setValue(value)
            if(textarea !== undefined) {
                (document.getElementById('descriptionTextarea') as HTMLTextAreaElement).value = value
            }
        }

        const CloseDynmaikModal = () => {
            handleClose();
        }

        const successForm = (result:any) => {
            setFetchData(Date.now())
            setValue('')
            handleClose()
        }

        const Reloadsuccess = () => {
            setFetchData(Date.now())
            setLoad(false)
        }

        const errorForm = (error:any)=> {
            handleClose()
        }

        const handlesubmit = (e:any) => {
            const form = e.currentTarget
            e.preventDefault()
            let Data = init2(form)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer "+ getToken());

            var raw = JSON.stringify(
                {
                    "id": id,
                    "regard": Data.regard,
                    "description": Data.description,
                    "due_on": Data.due_on,
                    "lastupdatefrom": localStorage.getItem('username')
                }
            );

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
            };

            setValue('');
            fetch(ApiURL() +"yoptimize/save/ticket/support/", requestOptions)
                .then(response => response.text())
                .then(result => {successForm(result)})
                .catch(error => errorForm(error));
        };

        const saveUnderTask = () => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "ticket_id": id,
                "content": (document.getElementsByName('TaskField')[0] as HTMLTextAreaElement).value,
                "member": localStorage.getItem('username'),
                "state": "0"
            });


            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
            };
            setValueComment('')
            fetch(ApiRoot()+"yoptimize/state/underTask/support/", requestOptions)
                .then(response => response.text())
                .then(result => setFetchData(Date.now()))
                .catch(error => console.log('error', error));
        }



        let sendAttachComment = () => {

            let formfile = (document.getElementById('fileuploadAttachment') as HTMLInputElement).files

            if(formfile !== null) {
                for (let f = 0; f < formfile?.length; f++) {
                    let formDatas = new FormData()
                    formDatas.append('customer', process.env.REACT_APP_CDN_CLIENT as string)
                    formDatas.append('context', 'pielers_element_text_image')
                    // @ts-ignore
                    formDatas.append(`file${0}`, formfile[f])
                    var requestOptions = {
                        method: 'POST',
                        body: formDatas
                    }
                    let link
                    if (process.env.REACT_APP_MODE === 'LOCAL') {
                        link = process.env.REACT_APP_CDN_API_URL_LOCAL
                    } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
                        link = process.env.REACT_APP_CDN_API_URL_DEVELOPMENT
                    } else if (process.env.REACT_APP_MODE === 'LIVE') {
                        link = process.env.REACT_APP_CDN_API_URL_LIVE
                    }

                    fetch(link + 'upload/', requestOptions)
                        .then((response) => response.text())
                        .then((result) => {

                            var raw = JSON.stringify({
                                url: JSON.parse(result).cdn_url,
                                name: JSON.parse(result).name,
                                ticket_id: id,
                                member: localStorage.getItem('username'),
                                type:JSON.parse(result).content_type
                            })

                            var requestOptions = {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization:
                                        'Bearer ' +
                                        JSON.parse(localStorage.getItem('token') as string)
                                },
                                body: raw
                            }

                                   fetch(ApiRoot()+'yoptimize/ticket/save/attachdocument/support/', requestOptions)
                                       .then((response) => response.text())
                                       .then((result) => successForm(result))
                                       .catch((error) => errorForm(error))

                        })
                        .catch((e) => {
                            error(e)
                        })
                }
            }
        }


    return(
        <>
            <div className={'ticketsystem'} style={{paddingLeft: '25px',paddingRight: '25px'}}>
                <h3 className={'mb-5'}>
                    <Link className={'btn btn-info float-start mr-2'}
                          to={'/support/software/overview/1'}
                          style={{background: 'white', borderColor: '#23AAE1', color: '#23AAE1'}}>
                        Back
                    </Link> <IntlMessage messageId="ticket.headline" Store={Store} />
                    <Button variant="secondary" className={'float-end ml-3'} style={{position:'relative',right:'5px',zIndex:'96'}}
                            onClick={(e) => Reloadsuccess()}>
                                        <span className="material-icons" style={{verticalAlign:'bottom'}}>
                                            autorenew
                                        </span>
                        Reload Page
                    </Button>
                    {/*<DynamikOffCanvas
                        openButtonLabel={<><span className="material-icons pr-2" style={{verticalAlign:'bottom'}}>
                                edit_calendar
                            </span>
                            <IntlMessage messageId="ticket.button.edittask" Store={Store} /></>}
                        saveButtonLabel={IntlMessageValue('de', 'common.save', Store)}
                        closeButtonLabel={IntlMessageValue('de', 'common.close', Store)}
                        title={'Create new ticket'}
                        ClassOpenButton={'float-end'}
                        ClassSaveButton={'float-end'}
                        body={<>

                            <div className="modal-dialog" style={{marginRight: '-23px'}}>

                            </div>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Title</InputGroup.Text>
                                <Form.Control name={'regard'} type="title" defaultValue={(response[0].regard !== null ? response[0].regard :'')} />
                                </InputGroup>
                            <div id={'htmleditor'} style={{display: 'block'}}>
                                <SunEditor
                                    key={'test'}

                                    name={'description'}
                                    setContents={value}
                                    setOptions={{
                                        buttonList: SunEditorConfig(),
                                        attributesWhitelist: {
                                            div: 'id',
                                            p: 'id',
                                            h: 'id'
                                        }
                                    }}
                                    height={'450px'}
                                    onChange={(value) => setEditorContent(value)}
                                />
                            </div>
                            <br/>
                            <Row>
                                <Col className={'col-6'}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1"><IntlMessage messageId="common.ticket.dueOn" Store={Store} /></InputGroup.Text>

                                        <Form.Control type="date" name={'due_on'}  defaultValue={(response[0].due_on !== null ? response[0].due_on :'')}/>

                                    </InputGroup>
                                </Col>

                                <Col className={'col-6'}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">Support-Typ</InputGroup.Text>
                                        <Form.Select aria-label="Default select example"
                                                     name={'support_type'} defaultValue={(response[0].support_type_id !== null ? response[0].support_type_id: '2')}
                                                     required={true}>
                                            <option value="2">Bug</option>
                                            <option value="4">Fehlende Funktion</option>
                                            <option value="6">Verbesserung</option>
                                            <option value="7">Individuelle Anpassung</option>
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Form.Control name={'creator'} type="hidden"
                                          defaultValue={localStorage.getItem('username') as string}/>
                        </>}
                        handlesubmit={handlesubmit}
                        handleShow={handleShow}
                        show={show}
                        id={'TaskNew'}
                        setShow={setShow}
                        handleClose={CloseDynmaikModal}
                    />*/}
                </h3>

                <Card>
                    <Card.Body className={'p-0'}>
                        <Row style={{minHeight: '80vh'}}>
                            <Col  style={{padding:'20px',paddingLeft:'30px'}}>
                        <h3 className={'mt-2 ml-2'}>{response[0].regard}
                            {(response[0].support_type_id !== null ? <Badge bg="secondary" className={'float-end'}>{(response[0].support_type_id !== null ? response[0].ticket_type.type : '')}</Badge> : <></>)}

                        </h3>
                        <br/>
                        <Row className={'TableTicket'}>
                            <Col>
                                <Row className={'pb-3'}><Col><IntlMessage messageId="ticket.button.ticketnumber" Store={Store} /></Col></Row>
                                <Row><Col className={'fw-bolder pb-3'}>#{response[0].id}</Col></Row>
                            </Col>
                            <Col>
                                <Row  className={'pb-3'}><Col><IntlMessage messageId="ticket.button.dateissue" Store={Store} /></Col></Row>
                                <Row><Col className={'fw-bolder pb-3'}>{
                                    dateFormat(
                                        response[0].created_at,
                                        localStorage.getItem('locale'),
                                        'us',
                                        true,
                                        false,
                                        Store
                                    )
                                }</Col></Row>
                            </Col>
                            <Col>
                                <Row  className={'pb-3'}><Col><IntlMessage messageId="ticket.button.duedate" Store={Store} /></Col></Row>
                                <Row><Col className={'fw-bolder pb-3'}>{response[0].due_on}</Col></Row>
                            </Col>
                            <Col>
                                <Row  className={'pb-3'}><Col><IntlMessage messageId="ticket.button.issueby" Store={Store} /></Col></Row>
                                <Row><Col className={'fw-bolder pb-3'}>{response[0].creator}</Col></Row>
                            </Col>
                            <Col>
                                <Row  className={'pb-3'}><Col><IntlMessage messageId="ticket.button.status" Store={Store} /></Col></Row>
                                <Row><Col className={'fw-bolder pb-3'}>{(response[0].status.id !== null ? response[0].status.state : '')} </Col></Row>
                            </Col>
                            <Col>
                                <Row  className={'pb-3'}><Col><IntlMessage messageId="ticket.button.prio" Store={Store} /></Col></Row>
                                <Row><Col className={'fw-bolder pb-3'}>{(response[0].priority !== null ? response[0].ticket_prioritaeten.name :' ')} </Col></Row>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col className={'col-6'}>
                                <Row style={{background:'#FAFAFA',margin:'15px',paddingTop:'20px',marginLeft:'0px'}}>
                                    <Col className={'col-2 LabelMulti'}>
                                        <p>Status:</p>
                                    </Col>
                                    <Col className={'col-10'}>
                                        <MultiprogressBar end={5} data={state} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row style={{background:'#FAFAFA',margin:'15px',paddingTop:'20px',marginLeft:'0px'}}>
                                    <Col className={'col-2 LabelMulti'}>
                                        <p>Priority:</p>
                                    </Col>
                                    <Col className={'col-10'}>
                                        <MultiprogressBar end={5} data={PrioritaetState} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <h3><IntlMessage messageId="ticket.button.description" Store={Store} /></h3>
                            <div dangerouslySetInnerHTML={{__html: response[0].description}} />
                        </Row>
                            </Col>
                            <Col className={'col-3'} style={{position:'relative'}}>
                                <Card style={{height:'100%',background:'#E5E5E5'}}>
                                    <Card.Body>
                                        <ButtonGroup aria-label="Basic example">
                                        <Button variant={(activeButton === 'discussion' ? 'primary' : 'secondary')} onClick={()=>displaySwitcher('discussion',['attachment','timeline'])}>Discussion</Button>
                                        <Button variant={(activeButton === 'attachment' ? 'primary' : 'secondary')} onClick={()=>displaySwitcher('attachment',['discussion','timeline'])}>Attachment</Button>
                                        <Button variant={(activeButton === 'timeline' ? 'primary' : 'secondary')} onClick={()=>displaySwitcher('timeline',['discussion','attachment'])}>Log</Button>
                                        </ButtonGroup>
                                        <hr className={'mb-2'} />
                                        <br />
                                        <div id={'discussion'} style={{display:'block'}}>
                                            {CommentHolder}
                                            <div style={{position:'absolute',bottom:'5px',width:'90%'}}>
                                                <SunEditor
                                                    key={'test'}

                                                    name={'CommentField'}
                                                    setContents={valueComment}
                                                    placeholder={'Write comments here'}
                                                    setOptions={{
                                                        buttonList: SunEditorConfig(),
                                                        attributesWhitelist: {
                                                            div: 'id',
                                                            p: 'id',
                                                            h: 'id'
                                                        }
                                                    }}
                                                    height={'150px'}
                                                    onChange={(value) => setValueComment(value)}
                                                />
                                                <Button variant="success" className={'float-end mt-3'} onClick={()=>saveComment()}>
                                                <span className="material-icons">
                                                    send
                                                </span>
                                                </Button>
                                            </div>
                                    </div>
                                        <div id={'attachment'} style={{display:'none'}}>
                                            {AttachmentHolder}
                                            <div style={{position:'absolute',bottom:'5px',width:'90%'}}>
                                                <Form.Group controlId="formFileMultiple" className="mb-3">
                                                    <Form.Control id={'fileuploadAttachment'} name={'file'} type="file" multiple />
                                                </Form.Group>
                                                <Button variant="success" className={'float-end mt-3'} onClick={()=>sendAttachComment()}>
                                                <span className="material-icons">
                                                    send
                                                </span>
                                                </Button>
                                            </div>

                                        </div>
                                        <div id={'timeline'} style={{display:'none'}}>
                                            <div  style={{position:'absolute',top:'75px',paddingLeft:'10px',width:'90%',height:'85%',overflowX:'hidden',overflowY:'auto',marginBottom:'10px'}}>

                                                <ul className="timeline">
                                                    {BuildLastActivities}
                                                </ul>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

            </div>
        </>
    )
} else {
        return(
            <>
                <SpinnerJSX messageId={'common.loading'} />
            </>
        )
    }
}

export default SoftwareSupportTicket;


