import React from 'react'
import Form from 'react-bootstrap/Form'
import legende from './helper/legende'
import { ValidateMessage, IntlMessageValue, IntlMessage } from '../../Elements/index'

function textarea(
    data: any,
    i: number,
    optionalFuncSecond: any = false,
    Store: any
) {
    //console.log(data)
    let required = false
    if (data.required !== undefined && data.required !== null) {
        required = true
    }

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            alert(
                <IntlMessage
                    Store={Store}
                    messageId="notification.enter_forbidden"
                />
            )
        }
    }
    const handlefunc = (e: any) => {
        optionalFuncSecond(e)
    }
    let maxContent = data.max !== undefined ? data.max : 250

    const setFormattedContent = (value: any, max: any) => {
        let chars = max - value.length

        // @ts-ignore
        document.getElementById('content' + i + data.label).innerHTML =
            IntlMessageValue(
                'de',
                'common.remaining_chars',
                Store,
                'replace',
                '[n]',
                chars
            )
        if (data.seo !== undefined) {
            handlefunc(value)
        }
    }
    const JSONAppend = () => {
        let JsonP = JSON.parse(
            (document.getElementById(data.jsonID) as HTMLInputElement).value
        )

        let dataValue = (
            document.getElementById(data.id) as HTMLInputElement
        ).value
            .replaceAll('"', '\\"')
            .replaceAll('\\n', '')

        //console.log(JsonP);

        JsonP.item[data.appendJson][data.appendJsonField] = dataValue
        ;(document.getElementById(data.jsonID) as HTMLInputElement).value =
            JSON.stringify(JsonP)

        //console.log(JsonP);
    }

    let InitialChars =
        maxContent -
        (data.value === null || data.value === undefined
            ? 0
            : data.value.length)
    if (data.jsonID !== undefined) {
        return (
            <>
                <Form.Group
                    className="mb-3"
                    controlId={data.id + '' + i}
                    key={'Form.ControlTextarea' + data.label}
                >
                    <Form.Label>{data.label}</Form.Label>
                    <Form.Control
                        id={data.id}
                        name={data.name}
                        as={data.type}
                        disabled={data.disabled}
                        defaultValue={data.value}
                        required={required}
                        onChange={() => JSONAppend()}
                    />
                    <ValidateMessage
                        valid={
                            data.valid !== undefined ? data.valid : undefined
                        }
                        Store={Store}
                    />
                </Form.Group>
            </>
        )
    } else if (data.nochar !== undefined && data.nochar === 'no') {
        //console.log('value: '+data.value);
        return (
            <>
                <Form.Group
                    className="mb-3"
                    controlId={data.id + '' + i}
                    key={'Form.ControlTextarea' + data.label}
                >
                    <Form.Label>{data.label}</Form.Label>
                    <Form.Control
                        id={data.id}
                        name={data.name}
                        as={data.type}
                        disabled={data.disabled}
                        defaultValue={
                            data.typeFormat !== undefined &&
                            data.typeFormat === 'json'
                                ? JSON.stringify(data.value)
                                : data.value
                        }
                        required={required}
                    />
                    <ValidateMessage
                        valid={
                            data.valid !== undefined ? data.valid : undefined
                        }
                        Store={Store}
                    />
                </Form.Group>
            </>
        )
    } else if (data.forbidden === true) {
        return (
            <>
                <Form.Group
                    className="mb-3"
                    controlId={data.id + '' + i}
                    key={'Form.ControlTextarea' + data.label}
                >
                    <Form.Label>{data.label}</Form.Label>
                    <Form.Control
                        name={data.name}
                        as={data.type}
                        rows={data.row}
                        maxLength={maxContent}
                        defaultValue={data.value}
                        required={required}
                        disabled={data.disabled}
                        onKeyDown={(e) => handleKeyPress(e)}
                        onChange={(event) =>
                            setFormattedContent(event.target.value, maxContent)
                        }
                    />
                    <ValidateMessage
                        valid={
                            data.valid !== undefined ? data.valid : undefined
                        }
                        Store={Store}
                    />
                    <p id={'content' + i + data.label}>
                        <IntlMessage
                            Store={Store}
                            messageId="common.remaining_chars"
                            preperator="replace"
                            param="[n]"
                            replace={InitialChars}
                        />
                    </p>
                </Form.Group>
                {legende(data)}
            </>
        )
    } else if (data.nochar !== undefined) {
        return (
            <>
                <Form.Group
                    className="mb-3"
                    controlId={data.id + '' + i}
                    key={'Form.ControlTextarea' + data.label}
                >
                    <Form.Label>{data.label}</Form.Label>
                    <Form.Control
                        name={data.name}
                        as={data.type}
                        rows={data.row}
                        maxLength={maxContent}
                        disabled={data.disabled}
                        defaultValue={data.value}
                        required={required}
                        onChange={(event) =>
                            setFormattedContent(event.target.value, maxContent)
                        }
                    />
                    <ValidateMessage
                        valid={
                            data.valid !== undefined ? data.valid : undefined
                        }
                        Store={Store}
                    />
                    <p id={'content' + i + data.label}>
                        <IntlMessage
                            Store={Store}
                            messageId="common.remaining_chars"
                            preperator="replace"
                            param="[n]"
                            replace={InitialChars}
                        />
                    </p>
                </Form.Group>
                {legende(data)}
            </>
        )
    } else {
        return (
            <>
                <Form.Group
                    className="mb-3"
                    controlId={data.id + '' + i}
                    key={'Form.ControlTextarea' + data.label}
                >
                    <Form.Label>{data.label}</Form.Label>
                    <Form.Control
                        name={data.name}
                        as={data.type}
                        rows={data.row}
                        disabled={data.disabled}
                        maxLength={maxContent}
                        defaultValue={data.value}
                        required={required}
                        onChange={(event) =>
                            setFormattedContent(event.target.value, maxContent)
                        }
                    />
                    <ValidateMessage
                        valid={
                            data.valid !== undefined ? data.valid : undefined
                        }
                        Store={Store}
                    />
                    <p id={'content' + i + data.label}>
                        <IntlMessage
                            Store={Store}
                            messageId="common.remaining_chars"
                            preperator="replace"
                            param="[n]"
                            replace={InitialChars}
                        />
                    </p>
                </Form.Group>
                {legende(data)}
            </>
        )
    }
}

export default textarea
