import Pagination from "react-bootstrap/Pagination";
import React from "react";
import Page from "./Page";


const PaginationYoptimize = (props:{lenght:any,setstartPage:any,setEndPage:any,active:any,setactive:any,pageDelimiter:any,setFetchData:any}) => {

    let max = Math.ceil(props.lenght / props.pageDelimiter);

    let active = props.active;
    let items = [];
    for (let number = 1; number <= max; number++) {
        items.push(
            <Pagination.Item key={number} active={number === active} onClick={()=>Page(number,props.setstartPage,props.setEndPage,props.setactive,props.pageDelimiter,props.setFetchData)}>
                {number}
            </Pagination.Item>,
        );
    }

    return(
        <Pagination size="sm" className={'float-end'}>{items}</Pagination>
    )

}


export default PaginationYoptimize;
