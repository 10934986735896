import {setNavi} from "../../../@WUM/core/Function/index";

const Reports = (value:any) => {

    setNavi({
        route:'/report/activityrecording/'
    })

    return(
        <>

        </>
    )
}
export default Reports;
