import React from 'react'
import './assets/css/App.css'
import './assets/css/style.css'
interface TemplateProviderDefaultProps {
    routing: any
}

const TemplateProviderDefault = (props: TemplateProviderDefaultProps) => {
    return <>{props.routing}</>
}

export default TemplateProviderDefault
