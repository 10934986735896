
import {requests} from "../../../../../@WUM/core/RestFullApi/api";
import {_UserInterface} from "./SupportSoftwareApi.interface";

export const support_software = {
    getPosts: (id:any): Promise<_UserInterface> =>
        requests.get(`yoptimize/company/${id}/`),
    updatePost: (post: any, id: any): Promise<_UserInterface> =>
        requests.post(`yoptimize/company/${id}/`, post),
}
