import  React,{JSXElementConstructor, ReactElement, ReactNode, ReactPortal} from "react";

interface InfoButtonWrapperProps {
    /** Allows it to have children and have them reflected. */
    children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined
}

export const InfoButtonWrapper = (props:InfoButtonWrapperProps)=>{
return <div style={{display:"flex", justifyContent:"space-between", alignItems:'center'}}>{props.children}</div>
}

export default InfoButtonWrapper;
