import InputGroup from 'react-bootstrap/InputGroup';
import React,{JSXElementConstructor, ReactElement, ReactNode, ReactPortal} from "react";

interface UniInputGroupProps {
    /** Allows it to have children and have them reflected. */
    children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined,
    /** Set custom Classes on Element */
    class?:string,
    /** Set id on Element in DOM */
    id?:string
}

/**
 * Easily extend form controls by adding text, buttons, or button groups on either side of textual inputs, custom selects, and custom file inputs with <strong><a href="https://react-bootstrap.netlify.app/" target="_blank">React Bootstrap</a></strong> Module <a href="https://react-bootstrap.netlify.app/docs/forms/input-group" target="_blank">InputGroup</a>.<br/><br/>
 *  <strong>Code Example:</strong><br/>
 * ```tsx
 * <UniForm validated={validated} Submit={handleSubmit}>
 *    <UniForm.InputGroup>
 *       ....
 *    </UniForm.InputGroup>
 * </UniForm>
 * ```
 * @param props
 * @version 0.0.1
 * @author [Marcel Ugurcu](https://wum-solution.de)
 * @constructor
 */
const UniInputGroup = (props:UniInputGroupProps) => {
    let Class:string = props.class === undefined ? 'mb-3' : props.class

    let ID:string = props.id === undefined ? '' : props.id

    return(
        <>
            <InputGroup className={Class} id={ID}>
                {props.children}
            </InputGroup>
        </>
    )
}

export default UniInputGroup;
