

import React from 'react'
import {SunEditorBig, IntlMessage} from "../../Elements/index";
import {SunEditorConfig} from "../../component/const/SunEditorConfig";
import PielersForm from '../../form-serilizer/index'

function LoopSunEditor(data: any, i: any) : any {

    let SunHolder : any = [];

    let x = 0;


    const LoopSun = (response: any, index: number) => {

        return SunEditorBig(
            response,
            data.keyEditor,
            data.newElement,
            { lang: 'de', height: '350px;', config: SunEditorConfig() },
            'topics['+x+']['+data.keyEditor+']',
            data.label + index + " :",
            '',
            '',
            '',
            '',
            'classic',
            '200'
        )
        }

        const LoopID = (response: any, index: number) => {

            return {
                formType: 'input',
                label: "id"+ index + " :",
                required: true,
                type: 'hidden',
                name: 'topics['+x+'][id]',
                value: data.newElement === false  ? response.id : ''
            }
        }
    data.value[data.valueKey].map((response:any, i:any) => {
        if(data.func !== undefined && data.func !== null) {
            SunHolder.push(data.func(response, i));
        } else {
            SunHolder.push(LoopSun(response, i))
            SunHolder.push(LoopID(response, i))
            x++;
        }

    });
    return (

        <>
            {PielersForm(
                SunHolder
            )}
        </>
    )
}

export default LoopSunEditor
