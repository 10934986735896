import React,{ElementType, JSXElementConstructor, ReactElement, ReactNode, ReactPortal} from "react";
import Form from 'react-bootstrap/Form';

interface WK24FormGroupProps {
    /** Allows it to have children and have them reflected. */
    children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined,
    /** Set custom Classes on Element */
    class?:string,
    /** Set id on Element in DOM */
    id?:string,
    /** Valide Value Col or Row Element */
    as?:ElementType<any> | undefined
}

/**
 * Create FormGroup with <strong><a href="https://react-bootstrap.netlify.app/" target="_blank">React Bootstrap</a></strong> Module <a href="https://react-bootstrap.netlify.app/docs/forms/form-control" target="_blank">Form.Group</a>.<br/><br/>
 *  <strong>Code Example:</strong><br/>
 * ```tsx
 * <Wk24Form validated={validated} Submit={handleSubmit}>
 *    <Wk24Form.FormGroup>
 *       ....
 *    </Wk24Form.FormGroup>
 * </Wk24Form>
 * ```
 * @param props
 * @version 0.0.1
 * @author [Marcel Ugurcu](https://wum-solution.de)
 * @constructor
 */

const WK24FormGroup = (props:WK24FormGroupProps) => {

    let Class:string = props.class === undefined ? 'mb-3' : props.class

    let ID:string = props.id === undefined ? '' : props.id

    if(props.as !== undefined) {
        return(
            <>
                <Form.Group as={props.as} className={Class} controlId={ID}>
                    {props.children}
                </Form.Group>
            </>
        )
    } else {
        return(
            <>
                <Form.Group className={Class} controlId={ID}>
                    {props.children}
                </Form.Group>
            </>
        )
    }

}

export default WK24FormGroup;
