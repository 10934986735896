import React,{createContext, PropsWithChildren, ReactNode, useEffect, useState} from 'react'
type Props = {
    children: ReactNode
}
export const WebSocketRoot = () => {
    let apiRoot: string | URL = ''
    if (process.env.REACT_APP_MODE === 'LOCAL') {
        apiRoot = process.env.REACT_APP_WEBSOCKET_API_URL_LOCAL as string
    } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
        apiRoot = process.env.REACT_APP_WEBSOCKET_API_URL_DEVELOPMENT as string
    } else if (process.env.REACT_APP_MODE === 'LIVE') {
        apiRoot = process.env.REACT_APP_WEBSOCKET_API_URL_LIVE as string
    } else {
        apiRoot = 'ws://localhost:3001'
    }
    return apiRoot
}

const socket = new WebSocket(WebSocketRoot());
export const SocketContext = createContext<WebSocket | null>(null);

export const SocketProvider = ({ children } : Props) => {




    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};


export default SocketProvider;
