import React, {useState, useEffect, useRef, CSSProperties} from 'react';
import './draganddrop.css';
import Card from "react-bootstrap/Card";

interface DraggableDivProps {
    content: React.ReactNode;
    className:string;
    /** Set inline style for Element */
    style?:CSSProperties | undefined
    y:any,
    x:any,
    w:any,
    h:any,
    id?:any
    onDropID?:any
    Header?:any
    onDelete?:any
}

const DraggableDivGrid: React.FC<DraggableDivProps> = ({content,className,style,y,x,w,h,onDropID,id,Header,onDelete}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const boxRef = useRef<HTMLDivElement>(null);

    const WidthSingle = 375;
    const HeightSingle = 238

    const isClicked = useRef<boolean>(false);

    const coords = useRef<{
        startX: number,
        startY: number,
        lastX: number,
        lastY: number
    }>({
        startX: x,
        startY: y,
        lastX: x*WidthSingle,
        lastY: y*HeightSingle
    })

    useEffect(() => {
        if (!boxRef.current || !containerRef.current) return;

        const boxs = boxRef.current;
        const box = boxs.querySelectorAll('.draggable-header-Grid')[0] as HTMLDivElement;
        const container = containerRef.current;

        const onMouseDown = (e: MouseEvent) => {
            isClicked.current = true;

            coords.current.startX = e.clientX;
            coords.current.startY = e.clientY;
        }

        const onMouseUp = (e: MouseEvent) => {
            isClicked.current = false;
            boxs.style.zIndex = '0'
            boxs.style.userSelect = 'unset'
            coords.current.lastX = boxs.offsetLeft;
            coords.current.lastY = boxs.offsetTop;
            onDropID(id,coords.current.lastX/WidthSingle,coords.current.lastY/HeightSingle,w,h)
        }

        const onMouseMove = (e: MouseEvent) => {
            if (!isClicked.current) return;

            const nextX = e.clientX - coords.current.startX + coords.current.lastX;
            const nextY = e.clientY - coords.current.startY + coords.current.lastY;
            console.log(e.clientX+'-'+coords.current.startX+'+'+coords.current.lastX)
            console.log(e.clientY+'-'+coords.current.startY+'+'+coords.current.lastY)
            boxs.style.zIndex = '9999999999'
            boxs.style.userSelect = 'none'
            boxs.style.top = `${nextY}px`;
            boxs.style.left = `${nextX}px`;
        }

        box.addEventListener('mousedown', onMouseDown);
        box.addEventListener('mouseup', onMouseUp);
        container.addEventListener('mousemove', onMouseMove);
        container.addEventListener('mouseleave', onMouseUp);

        const cleanup = () => {
            box.removeEventListener('mousedown', onMouseDown);
            box.removeEventListener('mouseup', onMouseUp);
            container.removeEventListener('mousemove', onMouseMove);
            container.removeEventListener('mouseleave', onMouseUp);
        }

        return cleanup;
    }, [])


    return (
        <>
            <Card ref={containerRef} style={style} className={className}>
                <div ref={boxRef} className="boxDragGrid border rounded" style={{top:(HeightSingle*y === 0 ? 0 : HeightSingle*y),left:(WidthSingle*x === 0 ? 0 : WidthSingle*x),width:WidthSingle*w,height:HeightSingle*h,overflow:'hidden'}}>
                    <Card.Body className={'p-0'}>
                        <div className={'draggable-header-Grid'}><h5 className={'m-0 p-0'}>
                            <span className="material-icons-outlined pr-2" style={{verticalAlign: 'text-bottom'}}>
                                open_with
                            </span>
                            {Header}
                            <span
                                className="material-icons"
                                style={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px',
                                    zIndex: '1001',
                                    cursor: 'pointer'
                                }}
                                onClick={(() => onDelete(id))}>
                            delete
                        </span>
                        </h5>
                        </div>
                        {content}
                    </Card.Body>
                </div>
            </Card>
        </>
    );
};

export default DraggableDivGrid;
