import React from 'react'
import ReactDOM from 'react-dom/client'
import './@WUM/templates/default/assets/css/style.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import 'bootstrap/dist/css/bootstrap.css'
import { Provider } from 'react-redux'
import store from '../src/store/index'
import { AuthRoute } from './@WUM/templates/default/Auth/service/AuthService'
import './App.css'
import 'create-notifications/style.css'
import { ProSidebarProvider } from 'react-pro-sidebar'
import {useNavigate} from "react-router-dom";
import UniNotification from "./@WUM/core/notification/Pages/Notification.Service";
import SocketProvider from "./@WUM/core/chat/websocket/Provider/SocketProvider";

ReactDOM.createRoot(document.getElementById('root') as HTMLDivElement).render(
    <React.StrictMode>
        <UniNotification>
            <ReactNotifications />
            <ProSidebarProvider>
                <Provider store={store}>
                    <SocketProvider>
                        <AuthRoute navigate={useNavigate} />
                        <App />
                    </SocketProvider>
                </Provider>
            </ProSidebarProvider>
        </UniNotification>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
