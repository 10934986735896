import '../../css/chat.css'
import Avatar from "@material-ui/core/Avatar";
// @ts-ignore
import { AudioRecorder } from 'react-audio-voice-recorder';
import React, {useContext, useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import {CryptDecrypt, CryptEncrypt} from "../../../../../@WUM/core//Elements/crypt/Crypt";
import {ResponseAllChat, ResponseAllGroup, ResponseAllUser} from "../../API/response/chat";
import Card from "react-bootstrap/Card";
import Button from 'react-bootstrap/Button';
import {IntlMessageValue, MIcon, UniForm} from "../../../../../@WUM/core//Elements";
import {ApiRoot} from "../../../../../@WUM/core/RestFullApi/api";
import {useSelector} from "react-redux";
import {SocketContext} from "../../../../../@WUM/core/chat/websocket/Provider/SocketProvider";
import {dateFormat} from "../../../../../@WUM/core/component/const/dateFormat";
import ChatForm from "../components/Form";
import NotificationChat from "../components/Notification";
import DesktopNotification from "../components/Notification";

interface messafesProps {

            date: any;
            username: any;
            text: string,
            from_uid: any
            to_uid: any
            group_id:any
            messager:any
            audio:any
            image:any
            type:any
            timestamp: any
            avatar: any

}

const Chat = (props:{value:any}) => {
    const Store = useSelector((state: any) => state)
    const [toUser,SetToUSer] = useState('0');
    const [toUserID,SetToUSerID] = useState('0');
    const [groupID,SetGroupID] = useState(0);
    const [countMessage,setCountMessage] = useState(0)
    const fromUser = localStorage.getItem('userid')
    const [fetchData, setFetchData] = useState(0);
    let AllUSer = ResponseAllUser()
    let Chatss = ResponseAllChat(toUser,fetchData)
    let Chats = JSON.parse(localStorage.getItem('chats') as string)
    let UserHolder:any = [];

    /*const Group = ResponseAllGroup(fetchData)
    console.log(Group)*/
    const socket = useContext(SocketContext);
    const activ = (ide:any,id:any,userid:any='0') => {
        SetToUSer(''+id)
        SetToUSerID(userid);
        setFetchData(Date.now())
        let RemoveHolder = document.getElementsByClassName('ActiveHolder');

        for(let r=0;r<RemoveHolder.length;r++) {
            (RemoveHolder[r] as HTMLDivElement).classList.remove("active");
            (RemoveHolder[r] as HTMLDivElement).classList.remove("text-white");
            (RemoveHolder[r] as HTMLDivElement).classList.add("bg-white");
        }


        (document.getElementById(ide)as HTMLDivElement).classList.add("active");
        (document.getElementById(ide) as HTMLDivElement).classList.add("text-white");
        (document.getElementById(ide) as HTMLDivElement).classList.remove("bg-white");

    }


    useEffect(() => {
        var elem = document.getElementById('messages-boxlist');
        if(elem !== null) {
            elem.scrollTop = elem.scrollHeight;
        }

    })

    const [messages, setMessages] = useState<any[]>([]); // Array von beliebigen Datentypen
    const [messageInput, setMessageInput] = useState('');



    useEffect(() => {
       /* console.log(socket);*/


        socket!.onmessage = (event: { data: string; }) => {
            const receivedMessage: messafesProps = JSON.parse(event.data) ; // Konvertieren in String

            setMessages([...messages, receivedMessage]);

            /*console.log(messages);*/
        };


    }, [messages]);

    const sendMessage = (from_uid:any,to_uid:any,group_id:any,messager:any,audio:any,image:any,type:any) => {
        if (messageInput.trim() !== '') {
            const message = {
                from_uid: from_uid,
                to_uid: toUserID,
                group_id:group_id,
                messager:messager,
                username:localStorage.getItem('username'),
                audio:audio,
                image:image,
                type:type,
                timestamp: new Date().toISOString(),
                avatar: localStorage.getItem("avatar"),
            };
            socket!.send(JSON.stringify(message));
            setMessageInput('');
        }
    };

    const searchUsername = (id:any) => {
        if (AllUSer !== true && typeof AllUSer !== "boolean" && AllUSer?.length > 0) {
            for (let us = 0; us < AllUSer?.length; us++) {
                if(id == AllUSer[us].id) {
                    return AllUSer[us].username
                }
            }
        }
    }

    if (AllUSer !== true && typeof AllUSer !== "boolean" && AllUSer?.length > 0) {
        for (let us = 0; us < AllUSer?.length; us++) {

            if (fromUser != AllUSer[us].id) {
                UserHolder.push(
                    <>
                        <div id={'uCase' + AllUSer[us].id}
                             className="ActiveHolder list-group-item list-group-item-action list-group-item-light rounded-0 bg-white  border-0"
                             onClick={(e) => activ('uCase' +
                                 // @ts-ignore
                                 AllUSer[us].id,
                                 // @ts-ignore
                                 AllUSer[us].id,
                                 // @ts-ignore
                                 AllUSer[us].user_id)}>
                            <div className="media">

                                <div className="media-body ml-4">
                                    <div className="d-flex align-items-center justify-content-between mb-1">
                                        <h6 className="mb-0">{AllUSer[us].company}</h6>
                                    </div>
                                    <p className="font-italic text-muted mb-0 text-small">{AllUSer[us].subject}</p>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        }
    }

  let GroupHolder: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | null | undefined = [];
    /*if (Group !== true && typeof Group !== "boolean" && Group?.length > 0) {
        for (let g = 0; g < Group?.length; g++) {

            GroupHolder.push(
                <>
                    <a className="list-group-item list-group-item-action list-group-item-light rounded-0 bg-white border-0">
                        <div className="media">
                            <Avatar alt={Group[g].name} src="">{AvatarName(Group[g].name)}</Avatar>
                            <div className="media-body ml-4">
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <h6 className="mb-0">{Group[g].name}</h6>
                                </div>
                                <p className="font-italic text-muted mb-0 text-small">{Group[g].description}</p>
                            </div>
                        </div>
                    </a>
                </>
            )
        }

    } else {
        GroupHolder.push(
        <p>{IntlMessageValue('de', 'common.no.group', Store)}</p>
        )
    }*/

    let ChatHolder = [];

    const YouTubeVideo = (videoId: any) => {

        return (
            <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/` + videoId.videoId}
                title="YouTube video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
        );
    };

    const TypeMessage = (Data: any) => {


        switch (Data.type) {
            case 'msg':

                const compontent = (data: string) => {
                    const youtubeRegex = /https:\/\/www.youtube.com\/watch\?v=([^\s]+)/g;
                    const linkRegex = /(https?:\/\/|www\.)([^\s]+)/g;
                    let text = data.replace(linkRegex, (match) => {
                        return `<a href="${match}">${match}</a>`;
                    });


                    if (data.includes('?v=')) {
                        const videoId: any = data.split('?v=')[1];
                        return <YouTubeVideo videoId={videoId}/>;

                    } else {
                        return <>
                            <div dangerouslySetInnerHTML={{__html: text}}/>
                        </>
                    }
                }

                return compontent(CryptDecrypt(Data.messager))


            case 'audio':
                return(
                    <>
                        <audio controls>
                            <source src={'data:audio/webm;base64,'+CryptDecrypt(Data.audio)} type="audio/webm"/>
                            Your browser does not support the audio tag.
                        </audio>
                    </>
                )
            case 'pdf':

                return(
                    <>
                        <object
                            data={CryptDecrypt(Data.image)}
                            width={'300px'}
                            height={CryptDecrypt(Data.image).includes('data:application/pdf;base64') ? '400px' : 'auto'}
                            aria-label={'pdf'}
                            className={'mb-3'}
                        >
                            {/*<embed src={CryptDecrypt(Data.image)} type="application/pdf"/>*/}
                        </object>
                    </>
                )
            default:
                return CryptEncrypt(Data.messager)
        }

    }

    if (Chats !== null  && Chats !== true && typeof Chats !== "boolean" && Chats !== undefined && Chats.chats !== undefined && Chats.chats.length > 0) {
        console.log(Chats)
            // @ts-ignore
            for (let d = 0; d < Chats.chats.length; d++) {
                if (Chats.chats[d].from_uid == '0') {
                    ChatHolder.push(
                        <>
                            <div className="media w-50 ml-auto mb-3">

                                <div className="media-body">
                                    <div className="rounded py-2 px-3 mb-2" style={{background: 'rgb(243, 243, 243)'}}>
                                        <p className={'float-start mb-0'}
                                           style={{
                                               color: '#2EB0D9',
                                               fontWeight: 'bold'
                                           }}>{Chats.chats[d].username}</p>
                                        <p className="small text-muted float-end font-weight-bold pl-3 mb-0 text-white">{dateFormat(
                                            Chats.chats[d].date,
                                            localStorage.getItem('locale'),
                                            'us',
                                            true,
                                            true,
                                            Store
                                        )
                                        }</p>
                                        <br className={'clear-both'}/>
                                        <p className="text-small mb-0 text-muted">{
                                            /*Chats.chats[d].messager*/
                                             TypeMessage(Chats.chats[d])
                                        }</p>
                                    </div>
                                </div>
                                <Avatar alt={Chats.chats[d].username} className={'ml-3'}
                                        style={{marginTop: "7px"}}
                                        src={Chats.chats[d].media}></Avatar>
                            </div>
                        </>
                    )
                } else {


                    ChatHolder.push(
                        <>
                            <div className="media w-50 mb-3">
                                <Avatar alt={Chats.chats[d].username}
                                        src={Chats.chats[d].media}
                                        style={{marginTop: "7px"}}>{Chats.chats[d].username}</Avatar>
                                <div className="media-body ml-3">
                                    <div className="bg-light rounded py-2 px-3 mb-2 bg-white">
                                        <p className={'float-start mb-0'}
                                           style={{
                                               color: '#2EB0D9',
                                               fontWeight: 'bold'
                                           }}>{Chats.chats[d].username}</p>
                                        <p className="small text-muted float-end font-weight-bold pl-3 mb-0">{dateFormat(
                                            Chats.chats[d].date,
                                            localStorage.getItem('locale'),
                                            'us',
                                            true,
                                            true,
                                            Store
                                        )
                                        }</p>
                                        <br className={'clear-both'}/>
                                        <p className="text-small mb-0 text-muted">{TypeMessage(Chats.chats[d])}</p>


                                    </div>

                                </div>
                            </div>
                        </>
                    )
                }

        }
    }



    const convertBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    /*console.log(CryptEncrypt('test'));
    console.log(CryptDecrypt(CryptEncrypt('test')));*/
    const addAudioElement = async (blob: Blob) => {

        const url = await convertBase64(blob);
        const meassges = (url as string).split('base64,')[1]

        var raw = JSON.stringify({
            id: 0,
            from_uid: fromUser as string,
            to_uid: ''+toUser,
            group_id:groupID,
            messager:'',
            audio:CryptEncrypt(meassges),
            image:'',
            date:new Date().toISOString(),
            type:'audio',
        })

        let Input = (document.getElementById('messageInput') as HTMLInputElement);
        if(Input !== null) {
            Input.value = '';
        }

        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }
        sendMessage(fromUser as string,'' + toUser,groupID,'',CryptEncrypt(meassges),'','audio')
       fetch(ApiRoot()+'yoptimize/save/chat/messages/', requestOptions)
            .then((response) => response.text())
            .then((result) => setFetchData(Date.now()))
            .catch((error) => console.log(error))

    };



    const handleClick = (id:any) => {
        let hiddenFileInput = document.getElementById(id) as HTMLInputElement
        hiddenFileInput.click();
    }
    const onFilechange = async ( e:any ) => {
        const [file] = e.target.files
        if (file !== null ) {
            const base64 = await convertBase64(file);

            var raw = JSON.stringify({
                id: 0,
                from_uid: fromUser as string,
                to_uid: '' + toUser,
                group_id: 0,
                messager: '',
                audio: '',
                image: CryptEncrypt(base64 as string),
                date:new Date().toISOString(),
                type: 'pdf',
            })

            let Input = (document.getElementById('messageInput') as HTMLInputElement);
            if (Input !== null) {
                Input.value = '';
            }

            let requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization:
                        'Bearer ' +
                        JSON.parse(localStorage.getItem('token') as string)
                },
                body: raw
            }
            sendMessage(fromUser as string,'' + toUser,groupID,'','',CryptEncrypt(base64 as string),'pdf')
            fetch(ApiRoot() + 'yoptimize/save/chat/messages/', requestOptions)
                .then((response) => response.text())
                .then((result) => setFetchData(Date.now()))
                .catch((error) => console.log(error))


        }

    }




    if(ChatHolder.length === 0) {
        ChatHolder.push(
            <>
                <Card style={{position: 'absolute',top: '10px',width: '94%'}}>
                    <Card.Body>
                        <p>
                            {IntlMessageValue('de', 'common.first.chat', Store)}
                        </p>
                    </Card.Body>
                </Card>
            </>
        )

    }

    const send = (e:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)

        var raw = JSON.stringify({
            id: 0,
            from_uid: formdata.get('from_uid'),
            to_uid: '0',
            group_id:formdata.get('group_id'),
            messager:CryptEncrypt(formdata.get('messager') as string),
            audio:formdata.get('audio'),
            image:formdata.get('image'),
            media:"https://api.yoptimize.tech/thumbnail/marcel-ugurcu_18.jpg",
            date:new Date().toISOString(),
            type:formdata.get('type'),
            chat_ticket: toUser,
            username:localStorage.getItem('username'),
        })


        let Input = (document.getElementById('messageInput') as HTMLInputElement);
        if(Input !== null) {
            Input.value = '';
        }

        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }
        sendMessage(formdata.get('from_uid'),formdata.get('to_uid'),formdata.get('group_id'),CryptEncrypt(formdata.get('messager') as string),formdata.get('audio'),formdata.get('image'),formdata.get('type'))
        fetch(ApiRoot()+'yoptimize/save/support/chat/messages/', requestOptions)
             .then((response) => response.text())
             .then((result) => setFetchData(Date.now()))
             .catch((error) => console.log(error))


    }


    return(
        <>
            <div style={{height:'90vh'}}>
                <div className="row rounded-lg overflow-hidden shadow" style={{height:'100%'}}>

                    <div className="col-3 px-0 bg-white">
                        <div className="bg-white">
                            <div className="bg-white px-4 py-2 bg-light">
                                <p className="h5 mb-0 py-1">Support Messages</p>
                            </div>

                            <div className="messages-box">
                                <div className="list-group rounded-0">
                                    {UserHolder}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-9 px-0 bgChat chatHolder">
                        <div id={'messages-boxlist'} className="px-4 py-5 chat-box ">

                            {ChatHolder}
                            {/*<div className="chat-messages">*/}
                                {messages.map((message: messafesProps, index) => (<>
                                    {message.hasOwnProperty('data') === undefined ? <></> : (message.from_uid == toUser && message.to_uid == fromUser ? <>
                                        <DesktopNotification username={searchUsername(message.from_uid) as string} setFetchData={setFetchData} setMessages={setMessages} />


                                        </> : <></>)}
                                </>))}
                            {/*</div>*/}
                        </div>


                        <Form
                            className="bg-light" id={'chatForm'}
                            noValidate
                            onSubmit={(e) =>
                                send(e)
                            }
                        >
                            <div className="input-group">
                                {/*<button id="button-addon2" type="submit" className="btn btn-link" onClick={() => (document.getElementById('emojiHolder') as HTMLDivElement).style.display =
                                    ((document.getElementById('emojiHolder') as HTMLDivElement).style.display === 'none' ? 'block' :
                                        (document.getElementById('emojiHolder') as HTMLDivElement).style.display === 'block' ? 'none' : 'none' )
                                }>
                                    <span className="material-icons">
                                    sentiment_satisfied
                                    </span>
                                </button> */}

                                {/* <div className={'emoji'} id={'emojiHolder'}>
                                <EmojiPicker  onEmojiClick={(emojiData: any)=> injecktEmoji(emojiData)}/>
                                </div>*/}

                                <UniForm.FormControl name={'from_uid'} id={'from_uid'} defaultValue={'0'} required={true}
                                                     type={'hidden'}/>
                                <UniForm.FormControl name={'to_uid'} id={'to_uid'} defaultValue={''+toUserID} required={true}
                                                     type={'hidden'}/>
                                <UniForm.FormControl name={'group_id'} id={'group_id'} defaultValue={'0'} required={true}
                                                     type={'hidden'}/>
                                <UniForm.FormControl name={'audio'} id={'audio'} defaultValue={''} required={true}
                                                     type={'hidden'}/>
                                <UniForm.FormControl name={'image'} id={'image'} defaultValue={''} required={true}
                                                     type={'hidden'}/>
                                <UniForm.FormControl name={'type'} id={'type'} defaultValue={'msg'} required={true}
                                                     type={'hidden'}/>
                                <textarea name={'messager'} id={'messageInput'} className="form-control rounded-0 ml-0 sendButton textMessager" onChange={(e) => setMessageInput(e.target.value)} style={{paddingTop:'0px !important'}}>

                                </textarea>
                               {/* <input type="text"  name={'messager'} placeholder="Type a message" id={'messageInput'} aria-describedby="button-addon2"
                                       className="form-control rounded-0 py-4 sendButton" onChange={(e) => setMessageInput(e.target.value)}/>*/}

                                <Button type={'submit'} variant="primary">
                                    <MIcon children={'send'} variant={'-outlined'} />
                                </Button>

                                <div className="input-group-append">
                                    {/*} <button id="button-addon2" type="button" className="btn btn-link" onClick={() => handleClick('photo_camera')}>
                                            <span className="material-icons">
                                            photo_camera
                                            </span>
                                        </button>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            id={'photo_camera'}
                                            style={{display: 'none'}} // Make the file input element invisible
                                            onChange={(e)=>addImageElement(e)}
                                        />*/}
                                    <button id="button-addon2" type="button" className="btn btn-link" onClick={() => handleClick('attach_file')}>
                                            <span className="material-icons">
                                            attach_file
                                            </span>
                                    </button>
                                    <input
                                        type="file"
                                        accept="application/pdf,image/* "
                                        id={'attach_file'}
                                        onChange={(e)=>onFilechange(e)}
                                        style={{display: 'none'}} // Make the file input element invisible
                                    />
                                    <AudioRecorder
                                        onRecordingComplete={addAudioElement}
                                        showVisualizer={true}
                                        audioTrackConstraints={{
                                            noiseSuppression: true,
                                            echoCancellation: true,
                                            // autoGainControl,
                                            // channelCount,
                                            // deviceId,
                                            // groupId,
                                            // sampleRate,
                                            // sampleSize,
                                        }}
                                        onNotAllowedOrFound={(err:any) => console.table(err)}
                                        downloadOnSavePress={false}
                                        downloadFileExtension="webm"
                                        mediaRecorderOptions={{
                                            audioBitsPerSecond: 128000,
                                        }}
                                    />
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Chat;


const injecktEmoji = (e:any) => {



    let send = document.getElementById('messageInput') as HTMLInputElement;

    send.value = send.value + e.emoji;
}

export const AvatarName = (name:any) => {
    let Name = '';

    if(name === null || name === undefined) {
        return;
    }
    let Hold = name.split(' ');

    for(let x=0;x<Hold.length;x++) {
        Name = Name + Hold[x].charAt(0)
    }
    return Name;
}
