import de from './Language/de_DE.json'
import deTicketsystemMessages from '../modules/ticketsystem/modules/localization/locales/de_DE.json'
import deSupportSoftware from '../modules/support_system/localization/locales/de_DE.json'
import deTicketsystem2 from '../modules/ticketsystem2.0/localization/locales/de_DE.json'
import deWorkTimeTracking from '../modules/worktimetracking/localization/locales/de_DE.json'
import deDashbaord from '../modules/CMS/dashboard/modules/localization/locales/de_DE.json'
import deChat from '../@WUM/core/chat/localization/locales/de_DE.json'
import deEmail from '../modules/settings/email/localization/locales/de_DE.json'

import en from './Language/en_EN.json'
import enTicketsystemMessages from '../modules/ticketsystem/modules/localization/locales/en_US.json'
import enSupportSoftware from '../modules/support_system/localization/locales/en_US.json'
import enTicketsystem2 from '../modules/ticketsystem2.0/localization/locales/en_US.json'
import enWorkTimeTracking from '../modules/worktimetracking/localization/locales/en_US.json'
import {SettingsLanguageDE, SettingsLanguageEN} from "../modules/settings/RegisterLang";
import enChat from '../@WUM/core/chat/localization/locales/en_US.json'
import enDashbaord from '../modules/CMS/dashboard/modules/localization/locales/en_US.json'
import enEmail from '../modules/settings/email/localization/locales/en_US.json'

export const RegisterLanguageDE = {
    ...de,
    ...deTicketsystemMessages,
    ...{'IT-Support':'Task Manager'},
    ...deSupportSoftware,
    ...deTicketsystem2,
    ...deWorkTimeTracking,
    ...SettingsLanguageDE,
    ...deChat,
    ...deDashbaord,
    ...deEmail
}

export const RegisterLanguageEN = {
    ...en,
    ...enTicketsystemMessages,
    ...{'IT-Support':'Task Manager'},
    ...enSupportSoftware,
    ...enTicketsystem2,
    ...enWorkTimeTracking,
    ...SettingsLanguageEN,
    ...enChat,
    ...enDashbaord,
    ...enEmail
}

const deLang = {
    messages: {
        ...RegisterLanguageDE
    },
    locale: 'de'
}
const EnLang = {
    messages: {
        ...RegisterLanguageEN
    },
    locale: 'us'
}

export const AppLocale: any = {
    de: deLang,
    us: EnLang
}
