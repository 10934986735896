import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {Form} from "react-bootstrap";

const UniOffCanvas = (props:{headline:any,field:any,saveButtonLabel:any,CloseButtonLabel:any,ButtonLabel:any,handlesubmit:any}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const Formsend = (e:any) => {
        props.handlesubmit(e)
        handleClose()
    }

    return (
        <>
            <Button style={{background:'transparent',border:0,color:"black",margin:0,padding:0,width:'100%',textAlign:'left'}} onClick={handleShow}>
                {props.ButtonLabel}
            </Button>

            <Offcanvas show={show} onHide={handleClose} backdrop={false} placement={'start'} style={{zIndex:'9999999999',minWidth:'400px',width: '40%'}}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{props.headline}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body  style={{overflowX:'hidden'}}>
                    <Form
                        id={'form001'}
                        className={'formControl'}
                        onSubmit={(e) =>Formsend(e)} >
                        {props.field}
                        <Button id={'save'} className={'float-end'}
                                variant="primary" type={'submit'} >
                            {props.saveButtonLabel}
                        </Button>
                        <Button variant="secondary" onClick={handleClose}>
                            {props.CloseButtonLabel}
                        </Button>
                    </Form>

                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default UniOffCanvas;
