import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import { alpha, styled } from '@mui/material/styles'
import TreeView from '@mui/lab/TreeView'
// @ts-ignore
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem'
import Collapse from '@mui/material/Collapse'
import { useSpring, animated } from '@react-spring/web'
import { TransitionProps } from '@mui/material/transitions'
import { Row, Col } from 'react-bootstrap'
import ReactDOM from 'react-dom'
import Button from 'react-bootstrap/Button'
import { IntlMessageValue } from './../../Elements/index'
import Form from 'react-bootstrap/Form'
import { useSelector } from 'react-redux'
function MinusSquare(props: SvgIconProps) {
    return (
        <SvgIcon
            fontSize="inherit"
            style={{ width: 14, height: 14 }}
            {...props}
        >
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    )
}

function PlusSquare(props: SvgIconProps) {
    return (
        <SvgIcon
            fontSize="inherit"
            style={{ width: 14, height: 14 }}
            {...props}
        >
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    )
}

function CloseSquare(props: SvgIconProps) {
    return (
        <SvgIcon
            className="close"
            fontSize="inherit"
            style={{ width: 14, height: 14 }}
            {...props}
        >
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
    )
}

function TransitionComponent(props: TransitionProps) {
    const style = useSpring({
        from: {
            opacity: 0,
            transform: 'translate3d(20px,0,0)'
        },
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`
        }
    })

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    )
}

const StyledTreeItem = styled((props: TreeItemProps) => (
    <>
        <Row>
            <Col>
                <TreeItem
                    {...props}
                    TransitionComponent={TransitionComponent}
                />
            </Col>
            <Col style={{ float: 'right', marginRight: '10px' }}>
                <Form.Check aria-label="option 1" style={{ float: 'right' }} />
            </Col>
        </Row>
    </>
))(
    ({
        // @ts-ignore
        theme
    }) => ({
        [`& .${treeItemClasses.iconContainer}`]: {
            '& .close': {
                opacity: 0.3
            }
        },
        [`& .${treeItemClasses.group}`]: {
            marginLeft: 15,
            paddingLeft: 18,
            borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`
        }
    })
)

const childtree = (child: any) => {
    let childStructur = []
    if (child === undefined) {
        return <></>
    } else {
        for (let c = 0; c < child.length; c++) {
            if (child[c].hasOwnProperty('children')) {
                childStructur.push(
                    <StyledTreeItem
                        nodeId={child[c].id}
                        label={child[c].title}
                        onClick={() => Pages(child[c].id)}
                    >
                        {childtree(child[c].children)}
                    </StyledTreeItem>
                )
            } else {
                childStructur.push(
                    <StyledTreeItem
                        nodeId={child[c].id}
                        label={child[c].title}
                        onClick={() => Pages(child[c].id)}
                    />
                )
            }
        }
        return childStructur
    }
}

const Pages = (id: any) => {
    let el = document.getElementById('tempItem')
    if (el !== undefined && el !== null) {
        el.remove()
    }
    const Store = useSelector((state: any) => state)
    //let newElement = (<PageDetailTree id={id}></PageDetailTree>);
    let newElement = <></>
    let temp = document.createElement('div')
    temp.id = 'tempItem'
    ReactDOM.render(newElement, temp)
    let ele = document.getElementById('content')
    if (ele !== null) {
        ele.appendChild(temp)
    }

    let elDelete = document.getElementById('tempItemDelete')
    if (elDelete !== undefined && elDelete !== null) {
        elDelete.remove()
    }

    let newElementDelete = (
        <>
            <Button
                style={{ border: '0' }}
                type={'button'}
                variant={'danger'}
                title={IntlMessageValue('de', 'del.Taxon', Store)}
                className={'float-end overviewButtonDel'}
            >
                {' '}
                <span style={{ color: 'white' }}>Löschen</span>
            </Button>
        </>
    )
    let tempDelete = document.createElement('div')
    tempDelete.id = 'tempItemDelete'
    ReactDOM.render(newElementDelete, tempDelete)
    let eleDelete = document.getElementById('delete')

    if (eleDelete !== null) {
        eleDelete.style.marginTop = '-52px'
        eleDelete.style.paddingLeft = '15px'
        eleDelete.style.float = 'left'
        eleDelete.appendChild(tempDelete)
    }
}

export default function CustomizedTreeView(props: { response: any }) {
    let treeStructur = []
    for (let tree = 0; tree < props.response.length; tree++) {
        treeStructur.push(
            <StyledTreeItem
                nodeId={props.response[tree].id}
                label={props.response[tree].title}
                onClick={() => Pages(props.response[tree].id)}
            >
                {childtree(props.response[tree].children)}
            </StyledTreeItem>
        )
    }

    return (
        <div style={{ background: 'white', padding: '10px' }}>
            <Row>
                <Col xl={2} xs={2} md={2}>
                    <TreeView
                        aria-label="customized"
                        defaultExpanded={['0']}
                        defaultCollapseIcon={<MinusSquare />}
                        defaultExpandIcon={<PlusSquare />}
                        defaultEndIcon={<CloseSquare />}
                        sx={{
                            height: 800,
                            flexGrow: 1,
                            maxWidth: 400,
                            overflowY: 'auto'
                        }}
                    >
                        <StyledTreeItem nodeId="0" label="Main">
                            {treeStructur}
                        </StyledTreeItem>
                    </TreeView>
                </Col>
                <Col id={'wrapperPages'} className={'append'}>
                    <div id={'content'}></div>
                    <div id={'delete'}></div>
                </Col>
            </Row>
        </div>
    )
}

/*
        <StyledTreeItem nodeId="2" label="Hello" />
        <StyledTreeItem nodeId="3" label="Subtree with children">
          <StyledTreeItem nodeId="6" label="Hello" />
          <StyledTreeItem nodeId="7" label="Sub-subtree with children">
            <StyledTreeItem nodeId="9" label="Child 1" />
            <StyledTreeItem nodeId="10" label="Child 2" />
            <StyledTreeItem nodeId="11" label="Child 3" />
          </StyledTreeItem>
          <StyledTreeItem nodeId="8" label="Hello" />
        </StyledTreeItem>
        <StyledTreeItem nodeId="4" label="World" />
        <StyledTreeItem nodeId="5" label="Something something" />
*/
