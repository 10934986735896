import React, {useEffect, useState} from "react";
import moment from "moment";
import {IntlMessage} from "./../../Elements/index";
import {useSelector} from "react-redux";
import '../css/WUMSalender.css'
import Button from 'react-bootstrap/Button';
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
const WUMCalender = (props:{
    dontShow?: Boolean;
    breacklabel?: any;
    breackAll?: any;
    data:any,
    All?:any,
    label?:any}) => {
    const Store = useSelector((state: any) => state)

    let currentDate = new Date();
    let startDate = new Date(currentDate.getFullYear(), 0, 1);
    /* @ts-ignore */
    let days = Math.floor((currentDate - startDate) /
        (24 * 60 * 60 * 1000));

    let weekNumber = Math.ceil(days / 7);

    const [week, setWeek] = useState(''+weekNumber+'');
    const [year, setYear] = useState(2024);
    const [mode, setMode] = useState('calender');
    const setToday = () => {
        setWeek(''+weekNumber+'')
        setYear(currentDate.getFullYear())
    }
    const SetWeekprev = () => {
        if(parseInt(week) - 1 === 0) {
            setWeek('52')
            setYear(year - 1)
        } else {
            setWeek(''+(parseInt(week) - 1)+'')
        }
    }
    const SetWeeknext = () => {
        if(parseInt(week) + 1 === 53) {
            setWeek('1')
            setYear(year + 1)
        } else {
            setWeek(''+(parseInt(week) + 1)+'')
        }

    }
    let Weeks = week.padStart(2, '0')

    let WeekHolder = [];

    let day1 = moment(year+"W"+Weeks).toDate();
    let day2 = new Date (moment(year+"W"+Weeks).toDate().setDate(moment(year+"W"+Weeks).toDate().getDate() + 1));
    let day3 = new Date (moment(year+"W"+Weeks).toDate().setDate(moment(year+"W"+Weeks).toDate().getDate() + 2));
    let day4 = new Date (moment(year+"W"+Weeks).toDate().setDate(moment(year+"W"+Weeks).toDate().getDate() + 3));
    let day5 = new Date (moment(year+"W"+Weeks).toDate().setDate(moment(year+"W"+Weeks).toDate().getDate() + 4));
    let day6 = new Date (moment(year+"W"+Weeks).toDate().setDate(moment(year+"W"+Weeks).toDate().getDate() + 5));
    let day7 = new Date (moment(year+"W"+Weeks).toDate().setDate(moment(year+"W"+Weeks).toDate().getDate() + 6));



    const calculateTime = (d1:any) => {
        const startTime = new Date('2012/10/09 '+d1).getTime();
        // Differenz berechnen
        var diff = d1;
        // Werte berechnen
        var tag = Math.floor(diff / (1000*60*60*24));
        diff = diff % (1000*60*60*24);
        var std = Math.floor(diff / (1000*60*60));
        diff = diff % (1000*60*60);
        var min = Math.floor(diff / (1000*60));
        diff = diff % (1000*60);
        var sec = Math.floor(diff / 1000);

        return std.toString().padStart(2, '0')+":"+min.toString().padStart(2, '0')  + ":" + sec.toString().padStart(2, '0');

    };

    const Data = props.data

    let timeHolder = [];

    let Ranges = ['','double','tripple','four','five','six','seven','eight','nine','ten','eleven','twoteen','thirteen','fourteen','fiveteen','sixteen','seventeen','eightteen','nineteen','twenty','twentyone','twentytwo','twentythree','twentyfour','twentyfive']

    let worktime = 0;

    let breack = 0;

    const zeroPad = (num:any, numZeros:any) => {
        var n = Math.abs(num);
        var zeros = Math.max(0, numZeros - Math.floor(n).toString().length );
        var zeroString = Math.pow(10,zeros).toString().substr(1);
        if( num < 0 ) {
            zeroString = '-' + zeroString;
        }

        return zeroString+n;
    }

    const TableEntry= (d:any) => {
        let backHolder = [];
        switch(d) {
            case 0:
                for(let da=0;da<Data.length;da++) {
                    console.log(day1.getFullYear()+'-'+zeroPad(day1.getMonth() + 1,2)+'-'+day1.getDate())
                if(Data[da].date.split(' ')[0] === day1.getFullYear()+'-'+zeroPad(day1.getMonth() + 1,2)+'-'+day1.getDate()) {
                    backHolder.push(
                        <>
                            <Card className={'mt-3 mb-3'}>
                                <Card.Body style={{background: Data[da].color,color:Data[da].tcolor}}>
                                    <Card.Title>{Data[da].title}</Card.Title>
                                    <Card.Text>
                                        <strong>{Data[da].date.split(' ')[1]}</strong> - <strong>{Data[da].enddate.split(' ')[1]}</strong>
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer className="text-muted"> <strong>{calculateTime(Data[da].time)}</strong></Card.Footer>
                            </Card>
                        </>
                    )
                    }
                }
                break
            case 1:
                for(let da=0;da<Data.length;da++) {
                    if(Data[da].date.split(' ')[0] === day2.getFullYear()+'-'+zeroPad(day2.getMonth()+1,2)+'-'+day2.getDate()) {
                        backHolder.push(
                            <>
                                <Card className={'mt-3 mb-3'}>
                                    <Card.Body style={{background: Data[da].color,color:Data[da].tcolor}}>
                                        <Card.Title>{Data[da].title}</Card.Title>
                                        <Card.Text>
                                            <strong>{Data[da].date.split(' ')[1]}</strong> - <strong>{Data[da].enddate.split(' ')[1]}</strong>
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer className="text-muted"> <strong>{calculateTime(Data[da].time)}</strong></Card.Footer>
                                </Card>
                            </>
                        )
                    }
                }
                break
            case 2:
                for(let da=0;da<Data.length;da++) {
                    if(Data[da].date.split(' ')[0] === day3.getFullYear()+'-'+zeroPad(day3.getMonth()+1,2)+'-'+day3.getDate()) {
                        backHolder.push(
                            <>
                                <Card className={'mt-3 mb-3'}>
                                    <Card.Body style={{background: Data[da].color,color:Data[da].tcolor}}>
                                        <Card.Title>{Data[da].title}</Card.Title>
                                        <Card.Text>
                                            <strong>{Data[da].date.split(' ')[1]}</strong> - <strong>{Data[da].enddate.split(' ')[1]}</strong>
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer className="text-muted"> <strong>{calculateTime(Data[da].time)}</strong></Card.Footer>
                                </Card>
                            </>
                        )
                    }
                }
                break
            case 3:
                for(let da=0;da<Data.length;da++) {
                    if(Data[da].date.split(' ')[0] === day4.getFullYear()+'-'+zeroPad(day4.getMonth()+1,2)+'-'+day4.getDate()) {
                        backHolder.push(
                            <>
                                <Card className={'mt-3 mb-3'}>
                                    <Card.Body style={{background: Data[da].color,color:Data[da].tcolor}}>
                                        <Card.Title>{Data[da].title}</Card.Title>
                                        <Card.Text>
                                            <strong>{Data[da].date.split(' ')[1]}</strong> - <strong>{Data[da].enddate.split(' ')[1]}</strong>
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer className="text-muted"> <strong>{calculateTime(Data[da].time)}</strong></Card.Footer>
                                </Card>
                            </>
                        )
                    }
                }
                break
            case 4:
                for(let da=0;da<Data.length;da++) {
                    if(Data[da].date.split(' ')[0] === day5.getFullYear()+'-'+zeroPad(day5.getMonth()+1,2)+'-'+day5.getDate()) {
                        backHolder.push(
                            <>
                                <Card className={'mt-3 mb-3'}>
                                    <Card.Body style={{background: Data[da].color,color:Data[da].tcolor}}>
                                        <Card.Title>{Data[da].title}</Card.Title>
                                        <Card.Text>
                                            <strong>{Data[da].date.split(' ')[1]}</strong> - <strong>{Data[da].enddate.split(' ')[1]}</strong>
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer className="text-muted"> <strong>{calculateTime(Data[da].time)}</strong></Card.Footer>
                                </Card>
                            </>
                        )
                    }
                }
                break
            case 5:
                for(let da=0;da<Data.length;da++) {
                    if(Data[da].date.split(' ')[0] === day6.getFullYear()+'-'+zeroPad(day6.getMonth()+1,2)+'-'+day6.getDate()) {
                        backHolder.push(
                            <>
                                <Card className={'mt-3 mb-3'}>
                                    <Card.Body style={{background: Data[da].color,color:Data[da].tcolor}}>
                                        <Card.Title>{Data[da].title}</Card.Title>
                                        <Card.Text>
                                            <strong>{Data[da].date.split(' ')[1]}</strong> - <strong>{Data[da].enddate.split(' ')[1]}</strong>
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer className="text-muted"> <strong>{calculateTime(Data[da].time)}</strong></Card.Footer>
                                </Card>
                            </>
                        )
                    }
                }
                break
            case 6:
                for(let da=0;da<Data.length;da++) {
                    if(Data[da].date.split(' ')[0] === day7.getFullYear()+'-'+zeroPad(day7.getMonth()+1,2)+'-'+day7.getDate()) {
                        backHolder.push(
                            <>
                                <Card className={'mt-3 mb-3'}>
                                    <Card.Body style={{background: Data[da].color,color:Data[da].tcolor}}>
                                        <Card.Title>{Data[da].title}</Card.Title>
                                        <Card.Text>
                                            <strong>{Data[da].date.split(' ')[1]}</strong> - <strong>{Data[da].enddate.split(' ')[1]}</strong>
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer className="text-muted"> <strong>{calculateTime(Data[da].time)}</strong></Card.Footer>
                                </Card>
                            </>
                        )
                    }
                }
                break
        }

        return backHolder;
    }

    const SelectEntry = (d:any,time:any) => {
        switch(d) {
            case 0:
                let backHolder = [];
                let Holder1:any = [];
                for(let da=0;da<Data.length;da++) {
                    let startDate = new Date(Data[da].date);
                    let endDate = new Date(Data[da].enddate);
                    let Range = ((endDate.getTime() - startDate.getTime())/60000 ) / 30
                    if(Data[da].date.split(' ')[0] === day1.getFullYear()+'-'+zeroPad(day1.getMonth()+1,2)+'-'+day1.getDate()) {
                        let ConvertDate = parseInt(Data[da].date.split(' ')[1].split(':')[0]) + ':'+(Data[da].date.split(' ')[1].split(':')[1] < 30 ? '00' : '30')
                        if(time === ConvertDate) {
                            if(Data[da].breack !== true) {
                                worktime = worktime+Data[da].time
                            } else {
                                breack = breack+Data[da].time
                            }
                            Holder1.push(
                                {
                                    range:  (100 / 40) * ((40 * Range)),
                                    content: <>{Data[da].title} <strong>{calculateTime(Data[da].time)}</strong></>,
                                    color:Data[da].color,
                                    on_Click:(Data[da].on_Click !== undefined ? Data[da].on_Click  : undefined),
                                    description: Data[da].content,
                                    tcolor: (Data[da].tcolor !== undefined ? Data[da].tcolor : 'black')
                                }

                            )
                        }
                    }
                }
                for(let f=0;f<Holder1.length;f++) {
                    let w = 100 / Holder1.length
                    if(Holder1[f].on_Click !== undefined) {
                        backHolder.push(
                            <div className={"event "+
                                /* @ts-ignore */
                                +' float-start '} style={{width:w+'%',background:Holder1[f].color,color:Holder1[f].tcolor, height:Holder1[f].range+'%'}}
                            onClick={()=>Holder1[f].on_Click(Holder1[f].description)}
                            >
                                <input id="check" type="checkbox"  className="checkbox"/>
                                <label htmlFor="check" />{Holder1[f].content}
                            </div>
                        )
                    } else {
                        backHolder.push(
                            <div className={"event float-start "} style={{width:w+'%',background:Holder1[f].color,color:Holder1[f].tcolor, height:Holder1[f].range+'%'}}>
                                <input id="check" type="checkbox"  className="checkbox"/>
                                <label htmlFor="check" />{Holder1[f].content}
                            </div>
                        )
                    }

                }
                return backHolder;
            case 1:
                let backHolder2 = [];
                let Holder2 = [];
                for(let da=0;da<Data.length;da++) {
                    let startDate = new Date(Data[da].date);
                    let endDate = new Date(Data[da].enddate);
                    let Range = ((endDate.getTime() - startDate.getTime())/60000 ) / 30

                    if(Data[da].date.split(' ')[0] === day2.getFullYear()+'-'+zeroPad(day2.getMonth()+1,2)+'-'+day2.getDate()) {
                        let ConvertDate = parseInt(Data[da].date.split(' ')[1].split(':')[0]) + ':'+(Data[da].date.split(' ')[1].split(':')[1] < 30 ? '00' : '30')
                        if(time === ConvertDate) {
                            if(Data[da].breack !== true) {
                                worktime = worktime+Data[da].time
                            } else {
                                breack = breack+Data[da].time
                            }
                            Holder2.push(
                                {
                                    range:  (100 / 40) * ((40 * Range)),
                                    content: <>{Data[da].title} <strong>{calculateTime(Data[da].time)}</strong></>,
                                    color:Data[da].color,
                                    on_Click:(Data[da].on_Click !== undefined ? Data[da].on_Click  : undefined),
                                    description: Data[da].content,
                                    tcolor: (Data[da].tcolor !== undefined ? Data[da].tcolor : 'black')
                                }

                            )
                        }
                    }
                }
                for(let f=0;f<Holder2.length;f++) {
                    let w = 100 / Holder2.length
                    backHolder2.push(
                        <div className={"event float-start "} style={{width:w+'%',background:Holder2[f].color,color:Holder2[f].tcolor, height:Holder2[f].range+'%'}}>
                            <input id="check" type="checkbox"  className="checkbox"/>
                            <label htmlFor="check" />{Holder2[f].content}
                        </div>
                    )
                }
                return backHolder2;
            case 2:
                let backHolder3 = [];
                let Holder3 = [];
                for(let da=0;da<Data.length;da++) {
                    let startDate = new Date(Data[da].date);
                    let endDate = new Date(Data[da].enddate);
                    let Range = ((endDate.getTime() - startDate.getTime())/60000 ) / 30
                    if(Data[da].date.split(' ')[0] === day3.getFullYear()+'-'+zeroPad(day3.getMonth()+1,2)+'-'+day3.getDate()) {
                        let ConvertDate = parseInt(Data[da].date.split(' ')[1].split(':')[0]) + ':'+(Data[da].date.split(' ')[1].split(':')[1] < 30 ? '00' : '30')
                        if(time === ConvertDate) {
                            if(Data[da].breack !== true) {
                                worktime = worktime+Data[da].time
                            } else {
                                breack = breack+Data[da].time
                            }
                            Holder3.push(
                                {
                                    range: Ranges[Math.round(Range)],
                                    content: <>{Data[da].title} <strong>{calculateTime(Data[da].time)}</strong></>,
                                    color:Data[da].color,
                                    on_Click:(Data[da].on_Click !== undefined ? Data[da].on_Click  : undefined),
                                    description: Data[da].content,
                                    tcolor: (Data[da].tcolor !== undefined ? Data[da].tcolor : 'black')
                                }
                            )
                        }
                    }
                }
                for(let f=0;f<Holder3.length;f++) {
                    let w = 100 / Holder3.length
                    backHolder3.push(
                        <div className={"event "+
                            /* @ts-ignore */
                            Holder3[f].range+' float-start '} style={{width:w+'%',background:Holder3[f].color,color:Holder3[f].tcolor}}>
                            <input id="check" type="checkbox"  className="checkbox"/>
                            <label htmlFor="check" />{Holder3[f].content}
                        </div>
                    )
                }
                return backHolder3;
            case 3:
                let backHolder4 = [];
                let Holder4 = [];
                for(let da=0;da<Data.length;da++) {
                    let startDate = new Date(Data[da].date);
                    let endDate = new Date(Data[da].enddate);
                    let Range = ((endDate.getTime() - startDate.getTime())/60000 ) / 30
                    if(Data[da].date.split(' ')[0] === day4.getFullYear()+'-'+zeroPad(day4.getMonth()+1,2)+'-'+day4.getDate()) {
                        let ConvertDate = parseInt(Data[da].date.split(' ')[1].split(':')[0]) + ':'+(Data[da].date.split(' ')[1].split(':')[1] < 30 ? '00' : '30')
                        if(time === ConvertDate) {
                            if(Data[da].breack !== true) {
                                worktime = worktime+Data[da].time
                            } else {
                                breack = breack+Data[da].time
                            }
                            Holder4.push(
                                {
                                    range: Ranges[Math.round(Range)],
                                    content: <>{Data[da].title} <strong>{calculateTime(Data[da].time)}</strong></>,
                                    color:Data[da].color,
                                    on_Click:(Data[da].on_Click !== undefined ? Data[da].on_Click  : undefined),
                                    description: Data[da].content,
                                    tcolor: (Data[da].tcolor !== undefined ? Data[da].tcolor : 'black')
                                }
                            )
                        }
                    }
                }
                for(let f=0;f<Holder4.length;f++) {
                    let w = 100 / Holder4.length
                    backHolder4.push(
                        <div className={"event "+
                            /* @ts-ignore */
                            Holder4[f].range+' float-start '} style={{width:w+'%',background:Holder4[f].color,color:Holder4[f].tcolor}}>
                            <input id="check" type="checkbox"  className="checkbox"/>
                            <label htmlFor="check" />{Holder4[f].content}
                        </div>
                    )
                }
                return backHolder4;
            case 4:
                let backHolder5 = [];
                let Holder5 = [];
                for(let da=0;da<Data.length;da++) {
                    let startDate = new Date(Data[da].date);
                    let endDate = new Date(Data[da].enddate);
                    let Range = ((endDate.getTime() - startDate.getTime())/60000 ) / 30
                    if(Data[da].date.split(' ')[0] === day5.getFullYear()+'-'+zeroPad(day5.getMonth()+1,2)+'-'+day5.getDate()) {
                        let ConvertDate = parseInt(Data[da].date.split(' ')[1].split(':')[0]) + ':'+(Data[da].date.split(' ')[1].split(':')[1] < 30 ? '00' : '30')
                        if(time === ConvertDate) {
                            if(Data[da].breack !== true) {
                                worktime = worktime+Data[da].time
                            } else {
                                breack = breack+Data[da].time
                            }
                            Holder5.push(
                                {
                                    range: Ranges[Math.round(Range)],
                                    content: <>{Data[da].title} <strong>{calculateTime(Data[da].time)}</strong></>,
                                    color:Data[da].color,
                                    on_Click:(Data[da].on_Click !== undefined ? Data[da].on_Click  : undefined),
                                    description: Data[da].content,
                                    tcolor: (Data[da].tcolor !== undefined ? Data[da].tcolor : 'black')
                                }
                            )
                        }
                    }
                }
                for(let f=0;f<Holder5.length;f++) {
                    let w = 100 / Holder5.length
                    backHolder5.push(
                        <div className={"event "+
                            /* @ts-ignore */
                            Holder5[f].range+' float-start '} style={{width:w+'%',background:Holder5[f].color,color:Holder5[f].tcolor}}>
                            <input id="check" type="checkbox"  className="checkbox"/>
                            <label htmlFor="check" />{Holder5[f].content}
                        </div>
                    )
                }
                return backHolder5;
            case 5:
                let backHolder6 = [];
                let Holder6 = [];
                for(let da=0;da<Data.length;da++) {
                    let startDate = new Date(Data[da].date);
                    let endDate = new Date(Data[da].enddate);
                    let Range = ((endDate.getTime() - startDate.getTime())/60000 ) / 30
                    if(Data[da].date.split(' ')[0] === day6.getFullYear()+'-'+zeroPad(day6.getMonth()+1,2)+'-'+day6.getDate()) {
                        let ConvertDate = parseInt(Data[da].date.split(' ')[1].split(':')[0]) + ':'+(Data[da].date.split(' ')[1].split(':')[1] < 30 ? '00' : '30')
                        if(time === ConvertDate) {
                            if(Data[da].breack !== true) {
                                worktime = worktime+Data[da].time
                            } else {
                                breack = breack+Data[da].time
                            }

                            Holder6.push(
                                {
                                    range: Ranges[Math.round(Range)],
                                    content: <>{Data[da].title}  <strong>{calculateTime(Data[da].time)}</strong></>,
                                    color:Data[da].color,
                                    on_Click:(Data[da].on_Click !== undefined ? Data[da].on_Click  : undefined),
                                    description: Data[da].content,
                                    tcolor: (Data[da].tcolor !== undefined ? Data[da].tcolor : 'black')
                                }
                            )
                        }
                    }
                }
                for(let f=0;f<Holder6.length;f++) {
                    let w = 100 / Holder6.length
                    backHolder6.push(
                        <div className={"event "+
                            /* @ts-ignore */
                            Holder6[f].range+' float-start '} style={{width:w+'%',background:Holder6[f].color,color:Holder6[f].tcolor}}>
                            <input id="check" type="checkbox"  className="checkbox"/>
                            <label htmlFor="check" />{Holder6[f].content}
                        </div>
                    )
                }
                return backHolder6;
            case 6:
                let backHolder7 = [];
                let Holder7 = [];
                for(let da=0;da<Data.length;da++) {
                    let startDate = new Date(Data[da].date);
                    let endDate = new Date(Data[da].enddate);
                    let Range = ((endDate.getTime() - startDate.getTime())/60000 ) / 30
                    if(Data[da].date.split(' ')[0] === day7.getFullYear()+'-'+zeroPad(day7.getMonth()+1,2)+'-'+day7.getDate()) {
                        let ConvertDate = parseInt(Data[da].date.split(' ')[1].split(':')[0]) + ':'+(Data[da].date.split(' ')[1].split(':')[1] < 30 ? '00' : '30')
                        if(time === ConvertDate) {
                            worktime = worktime+Data[da].time
                            Holder7.push(
                                {
                                    range: Ranges[Math.round(Range)],
                                    content: <>{Data[da].title} <strong>{calculateTime(Data[da].time)}</strong></>,
                                    color:Data[da].color,
                                    on_Click:(Data[da].on_Click !== undefined ? Data[da].on_Click  : undefined),
                                    description: Data[da].content,
                                    tcolor: (Data[da].tcolor !== undefined ? Data[da].tcolor : 'black')
                                }
                            )
                        }
                    }
                }
                for(let f=0;f<Holder7.length;f++) {
                    let w = 100 / Holder7.length
                    backHolder7.push(
                        <div className={"event "+
                            /* @ts-ignore */
                            Holder7[f].range+' float-start '} style={{width:w+'%',background:Holder7[f].color,color:Holder7[f].tcolor}}>
                            <input id="check" type="checkbox"  className="checkbox"/>
                            <label htmlFor="check" />{Holder7[f].content}
                        </div>
                    )
                }
                return backHolder7;
            default:
                return(<></>)
            // code block
        }
    }

    let Times = [
        '0:30','1:00','1:30','2:00','2:30','3:00','3:30','4:00','4:30','5:00','5:30','6:00','6:30','7:00','7:30','8:00','8:30','9:00','9:30','10:00','10:30','11:00','11:30','12:00',
        '12:30','13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','18:00','18:30','19:00','19:30','20:00','20:30','21:00','21:30','22:00','22:30','23:00','23:30','24:00']

    for(let t=0;t<48;t++) {

        timeHolder.push(
            <tr className={'trCell'}>
                <td className="headcol trCell"  >{Times[t]}</td>
                <td  className={'trCell'}>
                    {SelectEntry(0,Times[t])}
                </td>
                <td  className={'trCell'}>{SelectEntry(1,Times[t])}</td>
                <td  className={'trCell'}>{SelectEntry(2,Times[t])}</td>
                <td  className={'trCell'}>{SelectEntry(3,Times[t])}</td>
                <td  className={'trCell'}>{SelectEntry(4,Times[t])}</td>
                <td  className={'trCell'}>{SelectEntry(5,Times[t])}</td>
                <td  className={'trCell'}>{SelectEntry(6,Times[t])}</td>
            </tr>
        )
    }


let TableHolder = [];
       TableHolder.push(
            <tr>
                <td className="headcol"></td>
                <td>
                    {TableEntry(0)}
                </td>
                <td>
                    {TableEntry(1)}
                </td>
                <td>
                    {TableEntry(2)}
                </td>
                <td>
                    {TableEntry(3)}
                </td>
                <td>
                    {TableEntry(4)}
                </td>
                <td>
                    {TableEntry(5)}
                </td>
                <td>
                    {TableEntry(6)}
                </td>
            </tr>
        )





    const setDay = (id:any) => {
        let Day = ['day.monday','day.tuesday','day.wednesday','day.thursday','day.friday','day.saturday','day.sunday']

        return(
            <IntlMessage messageId={Day[id]} Store={Store} />
        )
    }

    const today = (day:any) => {

        let tday = new Date()

       if(day.getFullYear()+'-'+(day.getMonth()+1)+'-'+day.getDate() === tday.getFullYear()+'-'+(tday.getMonth()+1)+'-'+tday.getDate()) {
           return 'today';
       } else {
           return '';
       }

    }

    WeekHolder.push(
        <tr>
            <th className="headcol" style={{top:'1px'}}/>
            <th className={today(day1)}>{setDay(day1.getUTCDay())},{day1.getDate()}</th>
            <th className={today(day2)}>{setDay(day2.getUTCDay())},{day2.getDate()}</th>
            <th className={today(day3)}>{setDay(day3.getUTCDay())},{day3.getDate()}</th>
            <th className={today(day4)}>{setDay(day4.getUTCDay())},{day4.getDate()}</th>
            <th className={today(day5)}>{setDay(day5.getUTCDay())},{day5.getDate()}</th>
            <th className={"secondary "+today(day6)}>{setDay(day6.getUTCDay())},{day6.getDate()}</th>
            <th className={"secondary "+today(day7)}>{setDay(day7.getUTCDay())},{day7.getDate()}</th>
        </tr>
    )

    const ProgressState = (givenNumber:any) => {
        if(givenNumber >= 0 && givenNumber <= 30) {
            return 'danger'
        } else if(
            givenNumber  >= 31 && givenNumber <= 70
        ) {
            return 'warning'
        } else if(
            givenNumber >= 71 && givenNumber <= 100
        ) {
            return 'success'
        } else {
            return 'danger'
        }
    }

    useEffect(() => {
        let wrapper = document.getElementById('calenderWrapper');
        if(wrapper !== null) {
            wrapper.scrollTo({
                top: 500,
                behavior: "smooth",
            });
        }
    })


    return(
        <div className="calendar">
            {props.dontShow !== undefined && props.dontShow === true ? <></> : <>
                <Card className={'CardTarget'}>
                    <Card.Body className={'pt-0 pb-0'}>
                        <Row>
                            <Col>
                                <p className={'mb-1'}>
                                    {props.label} <strong>{(worktime / 1000 / 60 / 60).toFixed(2)}h</strong> / {props.All}h
                                    <ProgressBar variant={ProgressState((100 / props.All * (worktime / 1000 / 60 / 60)))}
                                                 now={(100 / props.All * (worktime / 1000 / 60 / 60))}
                                                 style={{height: '5px',marginTop:'5px'}}/>
                                </p>

                            </Col>
                            <Col>
                                <p className={'mb-1'}>
                                    {props.breacklabel} <strong>{(breack / 1000 / 60 / 60).toFixed(2)}h</strong> / {props.breackAll}h
                                    <ProgressBar
                                        variant={ProgressState((100 / props.breackAll * (breack / 1000 / 60 / 60)))}
                                        now={(100 / props.breackAll * (breack / 1000 / 60 / 60))} style={{height: '5px',marginTop:'5px'}}/>
                                </p>

                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </>
            }


            <header>
                <button className="secondary" style={{alignSelf:'flex-start',flex:'0 0 1'}} onClick={()=>setToday()}>
                    <span className="material-icons" style={{verticalAlign:'middle'}}>
                        today
                    </span>
                    Today</button>

                <Button variant="primary" className={'ml-2 prevforButton'} onClick={()=>SetWeekprev()}>
                        <span className="material-icons" style={{fontSize:'12px'}}>
                            arrow_back_ios
                        </span>
                </Button>
                <h1 className="" style={{flex:'1',paddingTop:'5px'}}><strong>{day1.getDate()} {setMonth(day1.getMonth(),Store)} {day7.getFullYear()} – {day7.getDate()} {setMonth(day7.getMonth(),Store)} {day7.getFullYear()}</strong> (KW {week})</h1>
                <Button variant="primary" className={'ml-2 mr-2 prevforButton'} onClick={()=>SetWeeknext()}>
                        <span className="material-icons" style={{fontSize:'12px'}}>
                            arrow_forward_ios
                        </span>
                </Button>
                <button className={"secondary"} style={{alignSelf:'flex-start',flex:'0 0 1',background:(mode === 'calender' ? '#015b91': 'rgb(255, 255, 255)')}} onClick={()=>setMode('calender')}>
                    <span className="material-icons" style={{verticalAlign:'bottom',color:(mode === 'calender' ? 'white': 'rgba(0, 0, 0, 0.4)')}}>
                        calendar_month
                    </span>
                </button>
                <button className={"secondary"} style={{alignSelf:'flex-start',flex:'0 0 1',background:(mode === 'table' ? '#015b91': 'rgb(255, 255, 255)')}} onClick={()=>setMode('table')}>
                    <span className="material-icons" style={{verticalAlign:'bottom',color:(mode === 'table' ? 'white': 'rgba(0, 0, 0, 0.4)')}}>
                        table_chart
                    </span>
                </button>
                <div style={{alignSelf:'flex-start',flex:'0 0 1'}}/>
            </header>

            <div className="outer" style={{display: (mode === 'calender' ? 'block': 'none')}}>


                <table>
                    <thead>
                    {WeekHolder}
                    </thead>
                </table>

                <div id={'calenderWrapper'} className="wrap" >
                    <table className="offset">

                        <tbody>
                        {timeHolder}
                        </tbody>
                    </table>
                </div>

            </div>
            <div id={'TableWrapper'} style={{display: (mode === 'table' ? 'block': 'none')}}>
                <table className="offset">
                    <thead>
                    {WeekHolder}
                    </thead>
                    <tbody>
                    {TableHolder}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default WUMCalender;


export const Month = ['month.January','month.February','month.March','month.April','month.May','month.June','month.July','month.August','month.September','month.October','month.November','month.December']

export const setMonth = (id:any,Store:any) => {

    return(
        <IntlMessage messageId={Month[id]} Store={Store} />
    )
}
