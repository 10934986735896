import '../css/Software_Support.css'
import React from "react";

const Usestats = (props:{value:any}) =>{

    return(
        <>

        </>
    )

}

export default Usestats;
