import {support_softwareApiType} from "./SupportSoftwareApi.interface";
import {requests} from "../../../../@WUM/core/RestFullApi/api";

export const support_software = {
    getPosts: (post: support_softwareApiType): Promise<support_softwareApiType[]> =>
        requests.post('yoptimize/ticket/instanz/name/support/', post),
    updatePost: (post: any, id: number): Promise<support_softwareApiType> =>
        requests.put(`User/update/${id}/support/`, post),
    deletePost: (id: any): Promise<void> =>
        requests.delete(`User/delete/${id}/support/`),
    getPostsSingle: (id:any,post: support_softwareApiType): Promise<support_softwareApiType[]> =>
        requests.post(`yoptimize/ticket/instanz/${id}/support/`, post),
}
