import Form from 'react-bootstrap/Form';
import React from "react";
interface UniFormTextProps {
    /** Set Text on Element */
    text:string,
    /** Set DefaultValue on Element */
    defaultValue?:string,
}

/**
 * Create block-level or inline-level form text. with <strong><a href="https://react-bootstrap.netlify.app/" target="_blank">React Bootstrap</a></strong> Module <a href="https://react-bootstrap.netlify.app/docs/forms/form-text" target="_blank">Form.Text</a> for Element Description Text .<br/><br/>
 *  <strong>Code Example:</strong><br/>
 * ```tsx
 * <UniForm validated={validated} Submit={handleSubmit}>
 *    <UniForm.FormGroup>
 *         <UniForm.Label text={'text'} />
 *         <UniForm.FormControl type={'type'} name={'name'} placeholder={'placeholder'}  />
 *         <UniForm.Text text={'Your Text'} />
 *    </UniForm.FormGroup>
 * </UniForm>
 * ```
 * @param props
 * @version 0.0.1
 * @author [Marcel Ugurcu](https://wum-solution.de)
 * @constructor
 */
const UniFormText = (props:UniFormTextProps) => {
    return(
        <>
            <Form.Text id="passwordHelpBlock" muted defaultValue={props.defaultValue}>
                {props.text}
            </Form.Text>
        </>
    )
}

export default UniFormText;
