import Form from 'react-bootstrap/Form';
import React from "react";
interface UniControlFeedbackProps {
    /** Set Message for Validate */
    text:string
}

/**
 * Create validated Message with <strong><a href="https://react-bootstrap.netlify.app/" target="_blank">React Bootstrap</a></strong> Module <a href="https://react-bootstrap.netlify.app/docs/forms/validation" target="_blank">Form.Control.Feedback</a>.<br/> If a required field is missing, sets the validation message within a FormGroup or an InputGroup<br/><br/>
 * <strong>Code Example:</strong><br/>
 * ```tsx
 * < UniForm validated={validated} Submit={handleSubmit}>
 *      <UniForm.FormGroup>
 *          <UniForm.FormControl type={'text'} name={'Name'} placeholder={'placeholder'} />
 *          <UniForm.ControlFeedback text={'Your Validate Message'} />
 *      </UniForm.FormGroup>
 * </UniForm>
 * ```
 * @param props
 * @version 0.0.1
 * @author [Marcel Ugurcu](https://wum-solution.de)
 * @constructor
 */
const WK24ControlFeedback = (props:UniControlFeedbackProps) => {

    return(
        <Form.Control.Feedback type="invalid">
            {props.text}
        </Form.Control.Feedback>
    )
}

export default WK24ControlFeedback;
