import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import React, {useState} from "react";
import {ApiRoot} from "../../../@WUM/core/RestFullApi/api";
import {notify_save} from "../../../@WUM/core/component/const/notification.service";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import ReactDOM from "react-dom";
import {setNavi} from "../../../@WUM/core/Function/index";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import {collapse} from "./components/Collapse";
import Card from 'react-bootstrap/Card';
import {Editor, IntlMessageValue} from "../../../@WUM/core/Elements/index";
import {useSelector} from "react-redux";
import {UniForm} from "../../../@WUM/core/Elements";
import SunEditorConfig from "./components/SuneditorConfig";
import SunEditor from "suneditor-react";
import {
    ResponseAllNotifictions,
    ResponseAllTemplate,
    ResponseAllTriggerTicket,
    ResponseEmailSettings
} from '../API/response/ticketsystem2'
import MIcon from "../../../@WUM/core/Elements/MIcon";
import {CryptDecrypt} from "../../../@WUM/core/Elements/crypt/Crypt";
import UpgradeInfoBox, {UpgradeButton} from "../../../@WUM/core/upgrade";
import InputGroup from 'react-bootstrap/InputGroup';
const CPS = (value:any) => {
    const [fetchData, setFetchData] = useState(0);
    const Store = useSelector((state: any) => state)
    const [count, setCount] = useState(0)
    const [templateCount, setTemplateCount] = useState(0)
    const [countEmailSetting, setCountEmailSetting] = useState(0)
    setNavi({
        route:'/generell/Settings/ticket'
    })

    const [state, setState] = useState({
        background: '#fff',
    });

    const AllTrigger = ResponseAllTriggerTicket()
    let TriggerHolder: any  = [];
    if (typeof AllTrigger !== "boolean" && AllTrigger?.length > 0) {
        for (let us = 0; us < AllTrigger.length; us++) {
            TriggerHolder.push(
                <>
                    <option value={AllTrigger[us].id}>{AllTrigger[us].name}</option>
                </>
            )
        }
    }

    const Template = ResponseAllTemplate(fetchData,setTemplateCount)
    const Email = ResponseEmailSettings(fetchData,setCountEmailSetting);

    let AllTemplateHoldder= [];
    if (typeof Template !== "boolean" && Template?.length > 0) {
        for (let c = 0; c < Template.length; c++) {
            AllTemplateHoldder.push(
                <>
                    <tr>
                        <td style={{width: '150px'}}>{Template[c].name}</td>
                        <td style={{width: '40px'}}><Button variant="primary" onClick={()=>AddedFunc3(EditTemplate,{id:Template[c].id,name:Template[c].name,content:Template[c].content})}>{IntlMessageValue('','cps.table.edit',Store)}</Button></td>
                        <td style={{width: '40px'}}><Button variant="danger" onClick={()=>deleteTemplate(Template[c].id)}>{IntlMessageValue('','cps.table.delete',Store)}</Button></td>
                    </tr>
                </>
            )
        }
    }
    const Notification = ResponseAllNotifictions(fetchData,setCount)

    let AllNotificationHoldder= [];

    let NotificationNumber = 0;
    if (typeof Notification !== "boolean" && Notification?.length > 0) {
        for (let c = 0; c < Notification.length; c++) {

            NotificationNumber++;
            AllNotificationHoldder.push(
                <>
                    <tr>
                        <td style={{width: '200px'}}>{Notification[c].name}</td>
                        <td style={{width: '250px'}}>{Notification[c].subject}</td>
                        <td style={{width: '50px'}}>{(Notification[c].state === "0" || Notification[c].state === null ? 'inaktiv' : 'aktiv')}</td>
                        <td style={{width: '50px'}}>{Notification[c].type}</td>
                        {/*<td style={{width: '50px',}} className={'text-center'}>
                            {(Notification[c].to_staff === "0" || Notification[c].to_staff === null ?
                                <MIcon children={'radio_button_unchecked'} variant={'fill'}/>:
                                <MIcon children={'radio_button_checked'} variant={'fill'}/>)}
                             </td>
                        <td style={{width: '50px'}} className={'text-center'}>
                            {(Notification[c].to_customer === "0" || Notification[c].to_customer === null ?
                                <MIcon children={'radio_button_unchecked'} variant={'fill'}/>:
                                <MIcon children={'radio_button_checked'} variant={'fill'}/>)}
                        </td>
                        <td style={{width: '50px'}} className={'text-center'}>
                            {(Notification[c].to_admin === "0" || Notification[c].to_admin === null ?
                                <MIcon children={'radio_button_unchecked'} variant={'fill'}/>:
                                <MIcon children={'radio_button_checked'} variant={'fill'}/>)}
                        </td>*/}
                        <td style={{width: '40px'}} className={'text-center'}>
                            <Button variant="primary" onClick={() => AddedFunc3(EditNotifications, {
                                id: Notification[c].id,
                                name: Notification[c].name,
                                content: Notification[c].content,
                                to_staff: Notification[c].to_staff,
                                to_customer: Notification[c].to_customer,
                                to_admin: Notification[c].to_admin,
                                subject: Notification[c].subject,
                                state: Notification[c].state
                            })}>
                                {IntlMessageValue('', 'cps.table.edit', Store)}
                            </Button>
                        </td>
                        <td style={{width: '40px'}} className={'text-center'}>
                            <Button variant="danger"
                                    onClick={() => deleteNotifications(Notification[c].id)}>{IntlMessageValue('', 'cps.table.delete', Store)}</Button>
                        </td>
                    </tr>
                </>
            )
        }
    }


    const CloseMember = (indiId?:any,indclear?:any) => {
        let Dialog = document.getElementById((indiId !== undefined && indiId !== null ? indiId : 'addDialogdynamik')) as HTMLDivElement;
        Dialog.style.display = 'none';

        if(indclear !== null && indclear !== undefined) {
            for(let i =0;i<indclear.length;i++) {
                let tmp = document.getElementById(indclear[i].id) as HTMLInputElement;

                if(tmp !== null) {
                    tmp.value = indclear[i].value;
                }
            }
        }

        let Task = document.getElementById('TaskId') as HTMLInputElement;

        if(Task !== null) {
            Task.value = '';
        }

        let Project = document.getElementById('ProjectId') as HTMLInputElement;

        if(Project !== null){
            Project.value = ''
        }
    }

    const AddedFunc3 = (form:any,formParam:any) => {

        let Dialog = document.getElementById('addDialogdynamik') as HTMLDivElement;
        Dialog.classList.add("show");
        Dialog.style.display = 'block';
        if(form !== undefined) {
            let newElement = form(formParam)
            let temp = document.createElement('div')
            ReactDOM.render(newElement, temp)
            let ele = document.getElementById('injectModalTask') as HTMLDivElement
            if(ele !== null) {
                ele.innerHTML = ''
                ele.prepend(temp)
            }
        }
    }


    const EditTemplate = (data:any) => {
        return(<>

                <div className="modal-dialog">
                    <Form
                        id={'form001'}
                        className={'formControl'}
                        noValidate
                        onSubmit={(e) =>
                            editTemplate(e,CloseMember,'addDialogdynamik')
                        }
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title h4">Template</div>
                                <button type="button" className="btn-close" aria-label="Close"
                                        onClick={() => CloseMember()}/>
                            </div>
                            <div className="modal-body pt-3" id={'FormBody'}>
                                <UniForm.InputGroup>
                                    <UniForm.InputGroupText text={IntlMessageValue('de', 'common.name', Store)}/>
                                    <UniForm.FormControl name={'name'} defaultValue={data.name} required={true}
                                                         type={'text'}/>
                                </UniForm.InputGroup>

                                <UniForm.Label text={'Inhalt'} InfoButton={{ display: true,text: '' }} />
                                <SunEditor
                                    key={'test'}

                                    name={'content'}
                                    setContents={data.content}
                                    setOptions={{
                                        buttonList: SunEditorConfig(),
                                        attributesWhitelist: {
                                            div: 'id|class',
                                            p: 'id|class',
                                            h: 'id|class'
                                        }
                                    }}
                                    height={'450px'}
                                />

                                <input name={'id'} type="hidden" id="id" className="form-control" defaultValue={data.id} />
                            </div>
                            <div className="modal-footer mt-3">
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => CloseMember()}>Schließen
                                </button>
                                <button type="submit" id={'AttachDocumentSendButton'} className="btn btn-primary float-end" >{IntlMessageValue('de', 'common.save', Store)}</button>
                            </div>


                        </div>
                    </Form>
                </div>
            </>
        )}

    const editTemplate = (e:any,CloseMember:any,CloseMemberID:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            id: formdata.get('id'),
            name: formdata.get('name'),
            content: formdata.get('content')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'template/ticket/create/yoptimize/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,CloseMember))
            .catch((error) => errorForm(error,CloseMember))
    }

    const deleteTemplate = (Lid:any) => {

        var requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
        };

        fetch(ApiRoot()+'template/ticket/delete/yoptimize/'+Lid+'/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,CloseMember))
            .catch((error) => errorForm(error,CloseMember))

    }


    const EditNotifications = (data:any) => {



        return(<>

                <div className="modal-dialog">
                    <Form
                        id={'form001'}
                        className={'formControl'}
                        noValidate
                        onSubmit={(e) =>
                            editNotifications(e,CloseMember,'addDialogdynamik')
                        }
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title h4">Template</div>
                                <button type="button" className="btn-close" aria-label="Close"
                                        onClick={() => CloseMember()}/>
                            </div>
                            <div className="modal-body pt-3" id={'FormBody'}>
                                <UniForm.InputGroup>
                                    <UniForm.InputGroupText text={IntlMessageValue('de', 'common.name', Store)}/>
                                    <UniForm.FormControl name={'name'} defaultValue={data.name} required={true}
                                                         type={'text'}/>
                                </UniForm.InputGroup>
                                <UniForm.InputGroup>
                                    <UniForm.InputGroupText
                                        text={IntlMessageValue('de', 'common.ticket.subject', Store)}/>
                                    <UniForm.FormControl name={'subject'} defaultValue={data.subject} required={true}
                                                         type={'text'}/>
                                </UniForm.InputGroup>
                                <UniForm.Label text={'Inhalt'} InfoButton={{display: true, text: ''}}/>
                                <SunEditor
                                    key={'test'}

                                    name={'content'}
                                    setContents={data.content}
                                    setOptions={{
                                        buttonList: SunEditorConfig(),
                                        attributesWhitelist: {
                                            div: 'id|class',
                                            p: 'id|class',
                                            h: 'id|class'
                                        }
                                    }}
                                    height={'450px'}
                                />
                                    {/*<UniForm.InputGroup class={'mt-3 mb-3 Checkbolder'}>
                                        <UniForm.InputGroupText class={'mb-0'} text={IntlMessageValue('de', 'recipient', Store)}/>
                                    <Form.Check
                                        inline
                                        style={{paddingTop:'10px',marginLeft:' 5px',marginRight:'0px',paddingRight:'10px',fontWeight:"bolder",background:'#dee2e6',borderRight:'2px solid white'}}

                                        label={IntlMessageValue('de', 'common.to_staff', Store)}
                                        defaultChecked={data.to_staff  === "1" ? true : false}
                                        name={'to_staff'}
                                        type={'checkbox'}
                                        id={'to_staff'}
                                    />
                                    <Form.Check
                                        inline
                                        style={{paddingTop:'10px',marginLeft:' 5px',marginRight:'0px',paddingRight:'10px',fontWeight:"bolder",background:'#dee2e6',borderRight:'2px solid white'}}

                                        label={IntlMessageValue('de', 'common.to_customer', Store)}
                                        name={'to_customer'}
                                        defaultChecked={data.to_customer === "1" ? true : false }
                                        type={'checkbox'}
                                        id={'to_customer'}
                                    />
                                    <Form.Check
                                        inline
                                        style={{paddingTop:'10px',marginLeft:' 5px',marginRight:'0px',paddingRight:'10px',fontWeight:"bolder",background:'#dee2e6',borderRight:'2px solid white'}}
                                        label={IntlMessageValue('de', 'common.to_admin', Store)}
                                        name={'to_admin'}
                                        defaultChecked={data.to_admin  === "1" ? true : false}
                                        type={'checkbox'}
                                        id={'to_admin'}
                                    />
                                    </UniForm.InputGroup>*/}
                                <UniForm.InputGroup class={'mt-3 mb-3 Checkbolder'}>
                                    <UniForm.InputGroupText class={'mb-0'} text={IntlMessageValue('de', 'common.type', Store)}/>

                                    <Form.Select aria-label="Default select example" name={'type'}
                                                 defaultValue={data.type}>
                                        <option label={IntlMessageValue('de', 'notification.placeholder', Store)}
                                                value=""></option>
                                        {TriggerHolder}
                                    </Form.Select>
                                </UniForm.InputGroup>
                                <UniForm.InputGroup class={'mt-3 mb-3 Checkbolder'}>
                                    <UniForm.InputGroupText class={'mb-0'} text={IntlMessageValue('de', 'common.status', Store)}/>
                                    <Form.Select aria-label="Default select example" name={'state'} defaultValue={data.state}>
                                        <option value="1">{IntlMessageValue('de', 'common.activated', Store)}</option>
                                        <option value="0">{IntlMessageValue('de', 'common.deactivated', Store)}</option>
                                    </Form.Select>
                                </UniForm.InputGroup>
                                <input name={'id'} type="hidden" id="id" className="form-control"
                                       defaultValue={data.id}/>
                            </div>
                            <div className="modal-footer mt-3">
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => CloseMember()}>Schließen
                                </button>
                                <button type="submit" id={'AttachDocumentSendButton'}
                                        className="btn btn-primary float-end">Speichern
                                </button>
                            </div>


                        </div>
                    </Form>
                </div>
            </>
        )}

    const SendEmail = (e:any) =>  {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            id: formdata.get('id'),
            refresh_intervall: formdata.get('refresh_intervall'),
            auto_duretime: formdata.get('auto_duretime'),
            auto_close:formdata.get('auto_close'),
            email_close_text:formdata.get('email_close_text')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'yoptimize/tickets/settings/save/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,CloseMember))
            .catch((error) => errorForm(error,CloseMember))
    }

    const editNotifications = (e:any,CloseMember:any,CloseMemberID:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            id: formdata.get('id'),
            name: formdata.get('name'),
            content: formdata.get('content'),
            /* to_staff:formdata.get('to_staff'),
             to_customer:formdata.get('to_customer'),
             to_admin:formdata.get('to_admin'),*/
            subject:formdata.get('subject'),
            state:formdata.get('state'),
            type:formdata.get('type')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'notifications/ticket/create/yoptimize/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,CloseMember))
            .catch((error) => errorForm(error,CloseMember))
    }

    const deleteNotifications = (Lid:any) => {

        var requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
        };

        fetch(ApiRoot()+'notifications/ticket/delete/yoptimize/'+Lid+'/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,CloseMember))
            .catch((error) => errorForm(error,CloseMember))

    }

    const successForm = (result:any,close:any,mode?:any,indiclear?:any) => {
        setFetchData(Date.now())
        notify_save()
        if(mode !== undefined && mode !== null) {
            close(mode,indiclear)
        } else {
            close()
        }

    }

    const errorForm = (error:any,close:any,mode?:any)=> {
        if(mode !== undefined && mode !== null) {
            close(mode)
        } else {
            close()
        }
    }

    const collapseGeneralSettings = (e:any,id:any,arrowShow:any=true,noneShow:any = [],menuid:any,noneShowmenu:any = []) => {

        let Collapse = document.getElementById(id) as HTMLDivElement;
        if (Collapse.classList.contains("d-none") === true) {
            Collapse.classList.remove("d-none");
            Collapse.classList.add("d-block");
            let CollapseInfo = document.getElementById(id + '_info') as HTMLDivElement;
            CollapseInfo.classList.remove("d-none");
            CollapseInfo.classList.add("d-block");

            for (let ns = 0; ns < noneShow.length; ns++) {
                let CollapseClose = document.getElementById(noneShow[ns]) as HTMLDivElement;
                CollapseClose.classList.add("d-none");
                CollapseClose.classList.remove("d-block");
                let CollapseInfo = document.getElementById(noneShow[ns] + '_info') as HTMLDivElement;
                CollapseInfo.classList.add("d-none");
                CollapseInfo.classList.remove("d-block");

            }
        }
        let CollapseMenu = document.getElementById(menuid) as HTMLDivElement;
            CollapseMenu.classList.add("d-flex");
            CollapseMenu.classList.remove("d-none");
        for (let ns = 0; ns < noneShowmenu.length; ns++) {
            let CollapseMenusec = document.getElementById(noneShowmenu[ns]) as HTMLDivElement;
            CollapseMenusec.classList.add("d-none");
            CollapseMenusec.classList.remove("d-flex");
        }
    }

    return (
        <>
            <div className={'ticketsystem'} style={{ paddingLeft: '25px' }}>
                <Row id={'general'} className={'d-flex'}>
                    <Col xs={2} md={2} xl={2} xxl={2}>
                        <h3 id={'headline'}>{IntlMessageValue('', 'cps.ticket.headline', Store)}</h3>
                        <h4 onClick={(e) => collapse(e, 'actions')}>{IntlMessageValue('', 'cps.actions', Store)}
                            <span id={'actions_arrow'} className="material-icons" style={{ verticalAlign: 'bottom' }}>
                            expand_less
                        </span>
                        </h4>
                        <div id={'actions'} className={'d-block'}>
                            <Navbar className="bg-body-transparent">
                                <Container>
                                    <Navbar.Brand style={{ fontSize: '16px', cursor: 'pointer' }}
                                                  onClick={(e) => collapseGeneralSettings(e, 'Template', false, [''], 'general', ['Notification','emailnav'])}>
                                  <span className="material-icons" style={{ verticalAlign: 'middle' }}>
                                description
                            </span>
                                        Template</Navbar.Brand>
                                </Container>
                            </Navbar>
                            <Navbar className="bg-body-transparent">
                                <Container>
                                    <Navbar.Brand style={{ fontSize: '16px', cursor: 'pointer' }}
                                                  onClick={(e) => collapseGeneralSettings(e, 'cps', false, [''], 'Notification', ['general','emailnav'])}>
                                        <MIcon variant={'fill'} className={'mr-3'} style={{ verticalAlign: 'middle' }}>
                                            schedule_send
                                        </MIcon>
                                        {IntlMessageValue('', 'common.ticket.notification', Store)}
                                    </Navbar.Brand>
                                </Container>
                            </Navbar>
                            <Navbar className="bg-body-transparent">
                                <Container>
                                    <Navbar.Brand style={{ fontSize: '16px', cursor: 'pointer' }}
                                                  onClick={(e) => collapseGeneralSettings(e, 'email', false, [''], 'emailnav', ['general','Notification'])}>
                                        <MIcon variant={'fill'} className={'mr-3'} style={{ verticalAlign: 'middle' }}>
                                            schedule_send
                                        </MIcon>
                                        {IntlMessageValue('', 'common.email', Store)}
                                    </Navbar.Brand>
                                </Container>
                            </Navbar>
                        </div>
                    </Col>
                    <Col xs={4} md={4} xl={4} xxl={4}>

                        <Row style={{ marginTop: '2em', marginLeft: '-12px', marginRight: '-12px' }}
                             className={'d-block'} id={'Template'}>
                            <Col style={{ width: 'max-content' }}>
                                <div style={{ width: '450px', paddingRight: '0px', marginBottom: '10px' }}>
                                    {localStorage.getItem('userRights') !== null && localStorage.getItem('userRights') !== undefined && JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string).ticketsystemAmountPredefineResponse <= templateCount ? <>
                                            <div><UpgradeButton float={'float-end'} Store={Store} /></div>
                                        </> :
                                        <>
                                            <Button variant="success" className={'float-end'}
                                                    onClick={() => AddedFunc3(EditTemplate, {
                                                        id: 0,
                                                        name: '',
                                                        content: ''
                                                    })}>{IntlMessageValue('', 'cps.button.new.taskTemplate', Store)}</Button>
                                            <br style={{ clear: 'both' }} />
                                        </>
                                    }
                                </div>
                                <Table striped bordered hover style={{ width: '450px' }}>
                                    <thead>
                                    <tr>
                                        <th style={{ width: '50px' }}>{IntlMessageValue('', 'common.name', Store)}</th>
                                        <th style={{ width: '40px' }}>{IntlMessageValue('', 'cps.table.edit', Store)}</th>
                                        <th style={{ width: '40px' }}>{IntlMessageValue('', 'cps.table.delete', Store)}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {AllTemplateHoldder}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={6} md={6} xl={6} xxl={6}>
                        <Row style={{
                            marginTop: '2em',
                            marginLeft: '-12px',
                            marginRight: '-12px',
                            width: '500px',
                            float: 'right'
                        }} className={'d-block'} id={'cps'}>
                            <Col style={{ width: 'max-content' }}>

                                <Card id={'Template_info'} className={'d-block'}>
                                    <Card.Body>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: IntlMessageValue('', 'cps.template', Store) }} />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                </Row>
                <Row id={'Notification'} className={'d-none'}>
                    <Col xs={2} md={2} xl={2} xxl={2}>
                        <h3 id={'headline'}>{IntlMessageValue('', 'cps.headline', Store)}</h3>
                        <h4 onClick={(e) => collapse(e, 'actions')}>{IntlMessageValue('', 'cps.actions', Store)}
                            <span id={'actions_arrow'} className="material-icons" style={{ verticalAlign: 'bottom' }}>
                            expand_less
                        </span>
                        </h4>
                        <div id={'actions'} className={'d-block'}>
                            <Navbar className="bg-body-transparent">
                                <Container>
                                    <Navbar.Brand style={{ fontSize: '16px', cursor: 'pointer' }}
                                                  onClick={(e) => collapseGeneralSettings(e, 'Template', false, [''], 'general', ['Notification', 'emailnav'])}>
                                  <span className="material-icons" style={{ verticalAlign: 'middle' }}>
                                description
                            </span>
                                        Template</Navbar.Brand>
                                </Container>
                            </Navbar>
                            <Navbar className="bg-body-transparent">
                                <Container>
                                    <Navbar.Brand style={{ fontSize: '16px', cursor: 'pointer' }}
                                                  onClick={(e) => collapseGeneralSettings(e, 'cps', false, [''], 'Notification', ['general', 'emailnav'])}>
                                        <MIcon variant={'fill'} className={'mr-3'} style={{ verticalAlign: 'middle' }}>
                                            schedule_send
                                        </MIcon>
                                        {IntlMessageValue('', 'common.ticket.notification', Store)}
                                    </Navbar.Brand>
                                </Container>
                            </Navbar>
                            <Navbar className="bg-body-transparent">
                                <Container>
                                    <Navbar.Brand style={{ fontSize: '16px', cursor: 'pointer' }}
                                                  onClick={(e) => collapseGeneralSettings(e, 'email', false, [''], 'emailnav', ['general', 'Notification'])}>
                                        <MIcon variant={'fill'} className={'mr-3'} style={{ verticalAlign: 'middle' }}>
                                            schedule_send
                                        </MIcon>
                                        {IntlMessageValue('', 'common.email', Store)}
                                    </Navbar.Brand>
                                </Container>
                            </Navbar>
                        </div>
                    </Col>
                    <Col xs={9} md={9} xl={9} xxl={9} className={'col'}>
                        <Row style={{ marginTop: '2em', marginLeft: '-12px', marginRight: '-12px' }}
                             className={'d-block'} id={'Template'}>
                            <Card>
                                <Card.Body>
                                    <div style={{ width: '100%', paddingRight: '0px', marginBottom: '10px' }}>
                                        <h3 className={'float-start'}>{IntlMessageValue('', 'common.ticket.notification', Store)} ({NotificationNumber})</h3>

                                        {localStorage.getItem('userRights') !== null && localStorage.getItem('userRights') !== undefined && JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string).ticketsystemAmountEmailTemplates <= count ? <>
                                                <div><UpgradeButton float={'float-end'} Store={Store} /></div>
                                            </> :
                                            <>
                                                <Button variant="success" className={'float-end'}
                                                        onClick={() => AddedFunc3(EditNotifications, {
                                                            id: 0,
                                                            name: '',
                                                            content: '',
                                                            to_staff: 0,
                                                            to_customer: 0,
                                                            to_admin: 0,
                                                            subject: '',
                                                            state: 1
                                                        })}>{IntlMessageValue('', 'common.ticket.notificationAdd', Store)}</Button>
                                                <br style={{ clear: 'both' }} />
                                            </>
                                        }
                                    </div>
                                    <Table striped bordered hover style={{ width: '100%' }}>
                                        <thead>
                                        <tr>
                                            <th style={{ width: '150px' }}>{IntlMessageValue('', 'common.name', Store)}</th>
                                            <th style={{ width: '40px' }}>{IntlMessageValue('', 'common.ticket.subject', Store)}</th>
                                            <th style={{ width: '40px' }}>{IntlMessageValue('', 'common.status', Store)}</th>
                                            <th style={{ width: '10px' }}>{IntlMessageValue('', 'common.type', Store)}</th>
                                            {/* <th style={{width: '50px'}}
                                                className={'text-center'}>{IntlMessageValue('', 'common.to_staff', Store)}</th>
                                            <th style={{width: '40px'}}
                                                className={'text-center'}>{IntlMessageValue('', 'common.to_customer', Store)}</th>
                                            <th style={{width: '40px'}}
                                                className={'text-center'}>{IntlMessageValue('', 'common.to_admin', Store)}</th>*/}
                                            <th style={{ width: '40px' }}></th>
                                            <th style={{ width: '40px' }}></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {AllNotificationHoldder}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Row>
                    </Col>
                    <div id={'Notification_info'} className={'d-none'}>
                    </div>
                </Row>
                <Row id={'emailnav'} className={'d-none'}>
                <Col xs={2} md={2} xl={2} xxl={2}>
                        <h3 id={'headline'}>{IntlMessageValue('', 'cps.headline', Store)}</h3>
                        <h4 onClick={(e) => collapse(e, 'actions')}>{IntlMessageValue('', 'cps.actions', Store)}
                            <span id={'actions_arrow'} className="material-icons" style={{ verticalAlign: 'bottom' }}>
                            expand_less
                        </span>
                        </h4>
                        <div id={'actions'} className={'d-block'}>
                            <Navbar className="bg-body-transparent">
                                <Container>
                                    <Navbar.Brand style={{ fontSize: '16px', cursor: 'pointer' }}
                                                  onClick={(e) => collapseGeneralSettings(e, 'Template', false, [''], 'general', ['Notification','emailnav'])}>
                                  <span className="material-icons" style={{ verticalAlign: 'middle' }}>
                                description
                            </span>
                                        Template</Navbar.Brand>
                                </Container>
                            </Navbar>
                            <Navbar className="bg-body-transparent">
                                <Container>
                                    <Navbar.Brand style={{ fontSize: '16px', cursor: 'pointer' }}
                                                  onClick={(e) => collapseGeneralSettings(e, 'cps', false, [''], 'Notification', ['general','emailnav'])}>
                                        <MIcon variant={'fill'} className={'mr-3'} style={{ verticalAlign: 'middle' }}>
                                            schedule_send
                                        </MIcon>
                                        {IntlMessageValue('', 'common.ticket.notification', Store)}
                                    </Navbar.Brand>
                                </Container>
                            </Navbar>
                            <Navbar className="bg-body-transparent">
                                <Container>
                                    <Navbar.Brand style={{ fontSize: '16px', cursor: 'pointer' }}
                                                  onClick={(e) => collapseGeneralSettings(e, 'email', false, [''], 'emailnav', ['general','Notification'])}>
                                        <MIcon variant={'fill'} className={'mr-3'} style={{ verticalAlign: 'middle' }}>
                                            schedule_send
                                        </MIcon>
                                        {IntlMessageValue('', 'common.email', Store)}
                                    </Navbar.Brand>
                                </Container>
                            </Navbar>
                        </div>
                    </Col>
                    <Col xs={9} md={9} xl={9} xxl={9} className={'col'}>
                        <Row style={{ marginTop: '2em', marginLeft: '-12px', marginRight: '-12px' }}
                             className={'d-block'} id={'email'}>
                            <Card>
                                <Card.Body>
                                    <div style={{ width: '100%', paddingRight: '0px', marginBottom: '10px' }}>
                                        <h3 className={'float-start'}>E-Mail Settings</h3>

                                        {/*{localStorage.getItem('userRights') !== null && localStorage.getItem('userRights') !== undefined && JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string).ticketsystemAmountEmailTemplates <= count ? <>
                                                <div><UpgradeButton float={'float-end'} Store={Store} /></div>
                                            </> :
                                            <>
                                                <Button variant="success" className={'float-end'}
                                                        onClick={() => AddedFunc3(EditNotifications, {
                                                            id: 0,
                                                            name: '',
                                                            content: '',
                                                            to_staff: 0,
                                                            to_customer: 0,
                                                            to_admin: 0,
                                                            subject: '',
                                                            state: 1
                                                        })}>{IntlMessageValue('', 'common.ticket.notificationAdd', Store)}</Button>
                                                <br style={{ clear: 'both' }} />
                                            </>
                                        }*/}
                                    </div>


                                    {typeof Email !== "boolean" && Email?.length > 0 ?
                                    <Form onSubmit={(e)=>SendEmail(e)}>
                                        <Form.Control
                                            aria-describedby="basic-addon1"
                                            type={'hidden'} name={'id'} defaultValue={Email[0].id}
                                        />
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">{IntlMessageValue('', 'cps.email.refresh_intervall', Store)}</InputGroup.Text>
                                            <Form.Select aria-label="Default select example" name={'refresh_intervall'} defaultValue={Email[0].refresh_intervall}>
                                                <option label={''} />cps.email.minutes
                                                <option value="15">15 {IntlMessageValue('', 'cps.email.minutes', Store)}</option>
                                                <option value="30">30 {IntlMessageValue('', 'cps.email.minutes', Store)}</option>
                                                <option value="60">60 {IntlMessageValue('', 'cps.email.minutes', Store)}</option>
                                                <option value="120">2 {IntlMessageValue('', 'cps.email.hours', Store)}</option>
                                                <option value="180">3 {IntlMessageValue('', 'cps.email.hours', Store)}</option>
                                                <option value="360">6 {IntlMessageValue('', 'cps.email.hours', Store)}</option>
                                                <option value="720">12 {IntlMessageValue('', 'cps.email.hours', Store)}</option>
                                                <option value="1440">24 {IntlMessageValue('', 'cps.email.hours', Store)}</option>
                                            </Form.Select>
                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">{IntlMessageValue('', 'cps.email.auto_duretime', Store)}</InputGroup.Text>
                                            <Form.Control
                                                aria-describedby="basic-addon1"
                                                type={'number'} name={'auto_duretime'} defaultValue={Email[0].auto_duretime}
                                                min={1}
                                                max={99}
                                            />
                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">{IntlMessageValue('', 'cps.email.autoClose', Store)}</InputGroup.Text>
                                            <Form.Control
                                                aria-describedby="basic-addon1"
                                                type={'number'} name={'auto_close'} defaultValue={Email[0].auto_close}
                                                min={1}
                                                max={99}
                                            />
                                        </InputGroup>
                                        <InputGroup>
                                            <InputGroup.Text>{IntlMessageValue('', 'cps.email.closeText', Store)}</InputGroup.Text>
                                            <Editor lang={'de'} config={"formatting"} height={'450px'}
                                                    mode={"classic"}
                                                    name={'email_close_text'}
                                                    defaultValue={ Email[0].email_close_text}/>
                                        </InputGroup>
                                        <br />
                                        <Button className={'float-right'} variant="success" type={'submit'} >{IntlMessageValue('', 'common.save', Store)}</Button>
                                    </Form>
                                    : <>
                                            <Form onSubmit={(e)=>SendEmail(e)}>
                                                <Form.Control
                                                    aria-describedby="basic-addon1"
                                                    type={'hidden'} name={'id'} defaultValue={'0'}
                                                />
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{IntlMessageValue('', 'cps.email.refresh_intervall', Store)}</InputGroup.Text>
                                                    <Form.Select aria-label="Default select example" name={'refresh_intervall'} >
                                                        <option label={''} />
                                                        <option value="15">15/M</option>
                                                        <option value="30">30/M</option>
                                                        <option value="60">60/M</option>
                                                        <option value="120">2/H</option>
                                                        <option value="180">3/H</option>
                                                        <option value="360">6/H</option>
                                                        <option value="720">12/H</option>
                                                        <option value="1440">24/H</option>
                                                    </Form.Select>
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{IntlMessageValue('', 'cps.email.auto_duretime', Store)}</InputGroup.Text>
                                                    <Form.Control
                                                        aria-describedby="basic-addon1"
                                                        type={'number'} name={'auto_duretime'}
                                                        min={1}
                                                        max={99}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{IntlMessageValue('', 'cps.email.autoClose', Store)}</InputGroup.Text>
                                                    <Form.Control
                                                        aria-describedby="basic-addon1"
                                                        type={'number'} name={'auto_close'}
                                                        min={1}
                                                        max={99}
                                                    />
                                                </InputGroup>
                                                <InputGroup>
                                                    <InputGroup.Text>{IntlMessageValue('', 'cps.email.closeText', Store)}</InputGroup.Text>
                                                    <Editor lang={'de'} config={"formatting"} height={'450px'}
                                                            mode={"classic"}
                                                            name={'email_close_text'}
                                                            defaultValue={''}/>


                                                </InputGroup>
                                                <br />
                                                <Button className={'float-right'} variant="success" type={'submit'} >{IntlMessageValue('', 'common.save', Store)}</Button>
                                            </Form>
                                        </>}
                                </Card.Body>
                            </Card>
                        </Row>
                    </Col>
                </Row>
                <div id={'email_info'} className={'d-none'}>
                </div>
            </div>
            <div role="dialog" aria-modal="true" className="offcanvas offcanvas-end"
                 id="addDialogdynamik"
                 style={{overflowY: 'auto', minWidth: '400px', top: '60px', right: '10px', width: '40%'}}>

                <div className="offcanvas-body">
                    <div id="injectModalTask"></div>
                </div>
            </div>
        </>
    )
}

export default CPS;
