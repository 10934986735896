/**
 * @module Crypt
 * @description Provides functions for encrypting and decrypting messages using the `crypto-ts` package.
 */
/* @ts-ignore */
import * as CryptoTS from 'crypto-ts';

/**
 * Encrypts a message using the AES algorithm.
 *
 * @param {string} message The message to encrypt.
 * @param {string} secret The secret key for encryption.
 * @returns {string} The encrypted message as a Base64-encoded string.
 */
export const CryptEncrypt = (message: string, secret: string = 'n432bJ^&*F$5432k'): string => {
    try {
        const ciphertext = CryptoTS.AES.encrypt(message, secret);
        // Converting ciphertext to a Base64-encoded string
        return ciphertext.toString();
    } catch (error:any) {
        throw new Error(`Encryption failed: ${error.message}`);
    }
};

/**
 * Decrypts a message encrypted with AES.
 *
 * @param {string} ciphertext The message to decrypt (Base64-encoded string).
 * @param {string} secret The secret key for decryption.
 * @returns {string} The decrypted message.
 */
export const CryptDecrypt = (ciphertext: string, secret: string= 'n432bJ^&*F$5432k'): string => {
    try {
        const bytes = CryptoTS.AES.decrypt(ciphertext, secret);
        return bytes.toString(CryptoTS.enc.Utf8);
    } catch (error:any) {
        throw new Error(`Decryption failed: ${error.message}`);
    }
};
