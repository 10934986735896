export const complex = [
    ['undo', 'redo'],
    ['fontSize', 'formatBlock'],
    ['paragraphStyle', 'blockquote', 'bold', 'underline', 'italic'],
    ['strike', 'subscript', 'superscript'],
    ['removeFormat'],
    ['fontColor', 'hiliteColor', 'textStyle'],
    ['outdent', 'indent'],
    ['align', 'horizontalRule', 'list', 'lineHeight', 'table'],
    ['link', 'imageGallery', 'video'],
    ['fullScreen', 'showBlocks', 'codeView'],
    ['preview', 'template']
]
