import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import NavDropdown from "react-bootstrap/NavDropdown";
import ProgressBar from "react-bootstrap/ProgressBar";
import Row from "react-bootstrap/Row";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@material-ui/core/Avatar";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import ReactDOM from "react-dom";
import Form from "react-bootstrap/Form";
import {init2} from "../../../../../@WUM/core/form-serilizer/seriliaze/Formserialize";
import {getToken} from "../../../../../@WUM/core/Function/index";
import {ApiURL} from "../../../../../@WUM/core/component/const/ApiURL";
import {swimmLane} from "../Pages/MyUnderProjects";
import {allowSaveMove, savearchivProject, savedeleteProject} from "../Pages/MyProjects";
import {dateFormat, IntlMessage, IntlMessageValue} from "../../../../../@WUM/core/Elements/index";
import Stack from "react-bootstrap/Stack";
import {colorSwitcher, savearchiv, savedelete} from "../Pages/MyTask";
import {Regex} from "../../../../../@WUM/core/Elements";
import {ResponseAllLabels} from "../../../API/response/ticket";
import {FetchwithMattermostANDsuccessFormWithClose} from "./Mattermost";
import {ApiRoot} from "../../../../../@WUM/core/RestFullApi/api";
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Badge from "react-bootstrap/Badge";


const CreateProjectCard = (props:{Store:any,ClassN?:any,id:any,added?:any,cid?:any,openNew?:any,isMain?:any,archiv?:any,delete?:any,swimelane?:any,background?:any,Buildsubproject?: JSX.Element[],BuildAllsubproject?:JSX.Element[],SetSwimlaneID?: (value: ((prevState: string) => string) | string) => void ,setFetchData?:(value: ((prevState: number) => number) | number) => void ,parent?:any,description?:any,position?:any,title?:any,now?:any,variant?:any,status?:any,daysColor?:any,daysLeft?:any,task?:any,allTask?:any,teamMember?:any,date?:any,avatarID?:any}) => {



    const AddedFunc = (form?:any,footer?:any,mode?:any,modeID?:any) => {

        if(mode !== undefined && mode !== null) {
            let Dialogs = document.getElementById((modeID !== undefined && modeID !== null && modeID !== '' ? modeID :'addDialogInternalConnection')) as HTMLDivElement;
            Dialogs.classList.add("show");
            Dialogs.style.display = 'block';
        } else {
            let Dialog = document.getElementById('addDialogdynamik') as HTMLDivElement;
            Dialog.classList.add("show");
            Dialog.style.display = 'block';
            if(form !== undefined) {
                let newElement = form
                let temp = document.createElement('div')
                ReactDOM.render(newElement, temp)
                let ele = document.getElementById('injectModalTask') as HTMLDivElement
                if(ele !== null) {
                    ele.innerHTML = ''
                    ele.prepend(temp)
                }
            }
        }
    }

    const CloseMember = (indiId?:any,indclear?:any) => {
        let Dialog = document.getElementById((indiId !== undefined && indiId !== null ? indiId : 'addDialogMember')) as HTMLDivElement;
        Dialog.style.display = 'none';

        if(indclear !== null && indclear !== undefined) {
            for(let i =0;i<indclear.length;i++) {
                let tmp = document.getElementById(indclear[i].id) as HTMLInputElement;

                if(tmp !== null) {
                    tmp.value = indclear[i].value;
                }
            }
        }

        let Task = document.getElementById('TaskId') as HTMLInputElement;

        if(Task !== null) {
            Task.value = '';
        }

        let Project = document.getElementById('ProjectId') as HTMLInputElement;

        if(Project !== null){
            Project.value = ''
        }
    }

    const EditProject = () => {
        return(<>

                <div className="modal-dialog">
                    <Form
                        id={'form001'}
                        className={'formControl'}
                        noValidate
                        onSubmit={(e) =>
                           editProject(e,props.id)
                        }
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title h4">{IntlMessageValue('de', 'project.edit.headline', props.Store)}</div>
                                <button type="button" className="btn-close" aria-label="Close"
                                        onClick={() => CloseMember('addDialogdynamik',[{id:'editSwimelanename',value:''},{id:'editSwimelanecolor',value:'#10B82B'}])}/>
                            </div>
                            <div className="modal-body" id={'FormBody'}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>{IntlMessageValue('de', 'common.title', props.Store)}</Form.Label>
                                    <Form.Control type="text" name={'title'} defaultValue={props.title} onKeyPress={(e)=>Regex(e,/[ A-Za-z0-9&.]/,IntlMessageValue('de', 'error.regex', props.Store))} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>{IntlMessageValue('de', 'common.description', props.Store)}</Form.Label>
                                    <Form.Control as="textarea" rows={6} name={'description'} defaultValue={props.description} />
                                </Form.Group>
                                <Form.Label>{IntlMessageValue('de', 'common.ticket.color', props.Store)}</Form.Label>
                                <Form.Control
                                    type="color"
                                    name={'color'}
                                    id={'saveSwimelanecolor'}
                                    defaultValue="#FFFFFF"
                                />
                               <Form.Control name={'id'} type="hidden" defaultValue={props.id} />
                                <br/>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => CloseMember('addDialogdynamik',[{id:'editSwimelanename',value:''},{id:'editSwimelanecolor',value:'#10B82B'}])}>
                                    {IntlMessageValue('de', 'common.close', props.Store)}
                                </button>
                                <button type="submit" id={'AttachDocumentSendButton'} className="btn btn-primary float-end" >
                                    {IntlMessageValue('de', 'common.save', props.Store)}
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </>
        )}
    const editProject = (e:any,id:any) => {
        const form = e.currentTarget
        e.preventDefault()
        let Data = init2(form)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+ getToken());

        var raw = JSON.stringify(Data);


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        fetch(ApiURL() +"yoptimize/edit/project/"+id+'/', requestOptions)
            .then(response => response.text())
            .then(result => successForm(result))
            .catch(error => errorForm(error));
    };

    const successForm = (result:any) => {
        if (props.setFetchData) {
            props.setFetchData(Date.now())
        }
        CloseMember('addDialogdynamik',[{id:'editSwimelanename',value:''},{id:'editSwimelanecolor',value:'#10B82B'}])
    }

    const errorForm = (error:any)=> {
        CloseMember('addDialogdynamik',[{id:'editSwimelanename',value:''},{id:'editSwimelanecolor',value:'#10B82B'}])
    }




    const setSwim = (e:any) => {

        if (props.SetSwimlaneID !== undefined) {
            props.SetSwimlaneID(e.target.value)
        }

        if (props.setFetchData !== undefined) {
            props.setFetchData(Date.now())
        }
    }
    const MoveProject = () => {
        /* @ts-ignore */
        let SwimLaneHolder = [];
        let localSwimLanes = JSON.parse(localStorage.getItem('swimlanesSubprojectLocal') as string)

        for (let us = 0; us < localSwimLanes?.length; us++) {
            SwimLaneHolder.push(
                <>
                    <option value={localSwimLanes[us].id}>{localSwimLanes[us].name}</option>
                </>
            )
        }
        return(<>

                <div className="modal-dialog">
                    <Form
                        id={'form001'}
                        className={'formControl'}
                        onSubmit={(e) =>
                            moveProject(e)
                        }
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title h4">{IntlMessageValue('de', 'common.move.project.headline', props.Store)}</div>
                                <button type="button" className="btn-close" aria-label="Close"
                                        onClick={() => CloseMember('addDialogdynamik',[{id:'editSwimelanename',value:''},{id:'editSwimelanecolor',value:'#10B82B'}])}/>
                            </div>
                            <div className="modal-body" id={'FormBody'}>
                                <Form.Select name={'parent'} aria-label="Default select example" required={true}
                                             onChange={(e) => swimmLane(e, props.SetSwimlaneID, props.setFetchData)}>
                                    <option label={IntlMessageValue('de', 'common.project.select', props.Store)}
                                            value={''}></option>
                                    <option label={IntlMessageValue('de', 'project.normal.project', props.Store)}
                                            value={'0'}></option>
                                    {props.BuildAllsubproject}
                                </Form.Select>
                                <Form.Control name={'id'} type="hidden" defaultValue={props.id}/>
                                <br/>
                                <Form.Select name={'swimlane_id'} id={'MoveHolderProject'} aria-label="Default select example"
                                             required={true}>
                                </Form.Select>
                                <br/>
                                <Form.Check
                                    className={'text-danger'}
                                    type={'checkbox'}
                                    label={IntlMessageValue('de', 'common.projects.info', props.Store)}
                                    id={`disabled-default-`}
                                    onClick={(e)=> allowSaveMove(e,'CreateEditProjects')}
                                />
                                <br/>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => CloseMember('addDialogdynamik',[{id:'editSwimelanename',value:''},{id:'editSwimelanecolor',value:'#10B82B'}])}>
                                    {IntlMessageValue('de', 'common.close', props.Store)}
                                </button>
                                <button type="submit" id={'CreateEditProjects'} className="btn btn-primary float-end" disabled={true}>{IntlMessageValue('de', 'common.move', props.Store)}</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </>
        )}

    const moveProject = (e:any) => {
        const form = e.currentTarget
        e.preventDefault()
        var formdata = new FormData(form)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+ getToken());

        var raw = JSON.stringify({
            swimlane_id: formdata.get('swimlane_id')
        })


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        fetch(ApiURL() +"yoptimize/move/project/"+formdata.get('id')+"/main/"+formdata.get('parent')+"/", requestOptions)
            .then(response => response.text())
            .then(result => successForm(result))
            .catch(error => errorForm(error));
    };

    let ColorMapStatus = [
        {title: 'Open',color:'#F8621B'},
        {title: 'In Progess',color:'#2EB0D9'}
    ]

    let color;

    for(let x=0;x<ColorMapStatus.length;x++){
        if(ColorMapStatus[x].title === props.status){
            color = ColorMapStatus[x].color;
        }
    }

    let ColorMapProgressBar = [
        {title: 'danger',color:'#dc3545'},
        {title: 'success',color:'#28a745'},
        {title: 'warning',color:'#ffc107'}
    ]

    let colorProess

    for(let x=0;x<ColorMapProgressBar.length;x++){
        if(ColorMapProgressBar[x].title === props.variant){
            colorProess = ColorMapProgressBar[x].color;
        }
    }



    /*if(props.isMain === "1") {
        return;
    }*/
    return(<Col className={props.ClassN} data-projectID={props.id} data-swimelane={props.swimelane}
                data-position={props.position} style={{
            padding:
                (props.teamMember !== undefined && props.teamMember !== '' ? 0 : 'calc(var(--bs-gutter-x) * .5)')
        }}>

            <Card style={{
                background: props.background,
                width: '100%',
                color: (props.background !== undefined && colorSwitcher(props.background, false) === false ? 'white' : 'black'),
                marginBottom: '10px',
                cursor: 'move',
                /*boxShadow:'0 8px 10px -5px #0003, 0 16px 24px 2px #00000024, 0 6px 30px 5px #0000001f'*/
            }}>
                <Card.Body>
                    <Card.Title>
                        <Row>
                            <Col className={'col-9'}><small>
                                <Link state={{projectid: props.id}}
                                      to={(props.isMain !== undefined && props.isMain !== null && props.isMain !== '0' ? '/myprojects/new/projects/1/' + props.id + '/' + props.title : '/myprojects/task/all/projects/' + props.id + '/' + props.title)}
                                      style={{textDecoration: 'none', color: 'black'}}>
                                    <p style={{
                                        marginBottom: '0',
                                        color: (props.background !== undefined && colorSwitcher(props.background, false) === false ? 'white' : 'black')
                                    }}>{props.title !== undefined && props.title !== '' ? props.title : 'Project App XYZ'}</p>
                                </Link></small></Col>
                            <Col className={'col-3'}><NavDropdown
                                className={'float-end text-right'}
                                title={
                                    <span>
                                       <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#999999"
                                            className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                      <path
                                          d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                    </svg>
                                 </span>
                                }
                                id='collasible-nav-dropdown_dashboard_projects'>
                                <Link className={'dropdown-item'}
                                      to={(props.isMain !== undefined && props.isMain !== null && props.isMain !== '0' ? '/myprojects/new/projects/1/' + props.id + '/' + props.title : '/myprojects/task/all/projects/' + props.id + '/' + props.title)}>
                                    {IntlMessageValue('de', 'project.navi.open', props.Store)}
                                </Link>
                                <NavDropdown.Item
                                    onClick={(e) => AddedFunc(EditProject())}>{IntlMessageValue('de', 'project.navi.edit', props.Store)}</NavDropdown.Item>
                                <NavDropdown.Item
                                    onClick={(e) => AddedFunc(MoveProject())}>{IntlMessageValue('de', 'project.navi.move', props.Store)}</NavDropdown.Item>
                                <NavDropdown.Item
                                    onClick={(e) => savearchivProject(e, props.id, (props.archiv === "1" ? 0 : 1), props.setFetchData)}>{(props.archiv === "1" ? IntlMessageValue('de', 'project.navi.restorearchiv', props.Store) : IntlMessageValue('de', 'project.navi.archived', props.Store))} </NavDropdown.Item>
                                <NavDropdown.Item
                                    onClick={(e) => savedeleteProject(e, props.id, (props.delete === "1" ? 0 : 1), props.setFetchData)}>{(props.delete === "1" ? IntlMessageValue('de', 'project.navi.restoredelete', props.Store) : IntlMessageValue('de', 'project.navi.delete', props.Store))} </NavDropdown.Item>
                            </NavDropdown> </Col>
                            {/*<Col className={'col-1'} style={{marginTop:'-5px'}}>

                             <span className="material-icons float-right" onClick={() => (document.getElementById('cardITem'+props.id) as HTMLDivElement).style.display = 'none'}>
                                    expand_less
                                </span>

                                <br/>
                                <span className="material-icons float-right" onClick={() => (document.getElementById('cardITem'+props.id) as HTMLDivElement).style.display = 'block'}>
                                    expand_more
                                </span>

                            </Col>*/}
                        </Row>
                        <small style={{fontSize: 'small'}}>{
                            dateFormat(
                                props.date,
                                localStorage.getItem('locale'),
                                'us',
                                true,
                                false,
                                props.Store
                            )
                        }</small>
                    </Card.Title>
                    <Card.Text>
                        {props.isMain !== undefined && props.isMain === '1'
                            ?
                            <></>
                            :
                            <>
                                <div>
                                    <p className={'float-start'}>{IntlMessageValue('de', 'common.task.done', props.Store)} {props.task}/{props.allTask}</p>
                                    <p className={'float-end'}
                                       style={{textAlign: 'right', color: colorProess}}>{props.now}%</p>

                                    <br style={{clear: 'both'}}></br>
                                </div>
                                <ProgressBar style={{height: '5px'}}
                                             variant={props.variant !== undefined && props.variant !== '' ? props.variant : 'danger'}
                                             now={props.now}/>
                            </>
                        }


                        <br/>
                        <div id={'cardITem' + props.id} style={{display: 'none'}}>
                            {props.teamMember !== undefined && props.teamMember !== '' ?
                                <>
                                    <Row>
                                        <Col md={8} xxl={8} xl={8} sm={8}>Team Member<br/>

                                            <AvatarGroup max={4} className={'float-start'}
                                                         id={props.avatarID !== undefined && props.avatarID !== '' ? props.avatarID + 'avatarGroup' : 'avatarGroup'}>
                                                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg"/>
                                                <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg"/>
                                                <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg"/>
                                                <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg"/>
                                                <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg"/>
                                            </AvatarGroup>

                                            {/*  <Avatar className={'float-end teamMember '} style={{border: '1px solid #bdbdbd',background: 'white',color: 'blue'}}>
                                    <span className="material-icons">
                                        add
                                        </span>
                                            </Avatar>*/}
                                        </Col>
                                        <Col md={4} xxl={4} xl={4} sm={4}><p style={{textAlign: 'right'}}><br/>
                                            <span className="badge rounded-pill "
                                                  style={{
                                                      marginTop: '10px',
                                                      backgroundColor: 'rgba(' + props.daysColor + ',0.4)',
                                                      color: 'rgba(' + props.daysColor + ')'
                                                  }}>
                                    {props.daysLeft} days left</span>
                                        </p></Col>
                                    </Row>
                                </>
                                : (<>
                                    <Row>
                                        <Col>Status<br/>
                                            <span style={{
                                                color: color,
                                                textTransform: 'uppercase'
                                            }}><strong>{props.status !== undefined && props.status !== '' ? props.status : 'Open'}</strong></span></Col>
                                        <Col><p style={{textAlign: 'right'}}><br/> <span className="badge rounded-pill "
                                                                                         style={{
                                                                                             backgroundColor: 'rgba(' + props.daysColor + ',0.4)',
                                                                                             color: 'rgba(' + props.daysColor + ')'
                                                                                         }}>
                                    {props.daysLeft} days left</span></p></Col>
                                    </Row>
                                </>)}
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        {props.added !== undefined && props.added === true ?
            <p style={{cursor: 'pointer',fontSize:'12px'}} onClick={() => props.openNew(props.cid)} className={'text-center'}>
                            <span className="material-icons" style={{verticalAlign: 'bottom',fontSize:'12px'}}>
                                add
                            </span>
                {IntlMessageValue('de', 'project.button.offcanvas', props.Store)}
            </p>:<></>}
        </Col>
    )
}

export default CreateProjectCard;


export const CreateTaskCard = (props: {
    Store: any,
    state?: any,
    media?: any,
    added?: any,
    cid?: any,
    Swimelaneid?: any,
    openNew?: any,
    responsibility?: any,
    background?: any,
    Labels?: any,
    ClassN?: any,
    Due_On?: any,
    ColPadding?: any,
    archiv?: any,
    delete?: any,
    setFetchData?: any,
    label?: any,
    priorität?: any,
    comment?: any,
    swimelane?: any,
    id: any,
    position?: any,
    title?:any, ProjectId?:any,now?:any,variant?:any,status?:any,daysColor?:any,daysLeft?:any,task?:any,allTask?:any,teamMember?:any,date?:any,avatarID?:any,text?:any}) => {


    let due_onDiff = (new Date() > new Date(props.Due_On) ? true : false);

    let ColorMapStatus = [
        {title: 'Open',color:'#F8621B'},
        {title: 'In Progess',color:'#2EB0D9'}
    ]

    let color;

    for(let x=0;x<ColorMapStatus.length;x++){
        if(ColorMapStatus[x].title === props.status){
            color = ColorMapStatus[x].color;
        }
    }

    let ColorMapProgressBar = [
        {title: 'danger',color:'#dc3545'},
        {title: 'success',color:'#28a745'},
        {title: 'warning',color:'#ffc107'}
    ]

    let colorProess

    for(let x=0;x<ColorMapProgressBar.length;x++){
        if(ColorMapProgressBar[x].title === props.variant){
            colorProess = ColorMapProgressBar[x].color;
        }
    }

    let colorset = [
        {color:'#4D4D4D'},
        {color:'#B71234'},
        {color:'#B71234'},
        {color:'#2EA02E'},
        {color:'#2EA02E'},
        {color:'#FFA5006E'},
        {color:'#FFA5006E'}
    ]

    let StackBadgeHolder = [];
    if(props.label !== undefined && props.label !== null) {
        for (let sl = 0; sl < props.label.length; sl++) {

            StackBadgeHolder.push(
                <span className="badge rounded-pill float-start"
                      style={{background: props.label[sl].color,fontSize:'12px',color:( colorSwitcher(props.label[sl].color,false) === false ? 'white' : 'black')}} >{props.label[sl].name}</span>
            )
        }
    }

    let AllLabelsHoldder= [];
    if (typeof props.Labels !== "boolean" && props.Labels?.length > 0 && props.Labels !== undefined) {
        for (let c = 0; c < props.Labels.length; c++) {
            AllLabelsHoldder.push(
                <>
                    <option id={props.Labels[c].name} value={props.Labels[c].name} data-color={props.Labels[c].color}>{props.Labels[c].name} </option>
                </>
            )
        }
    }
    let sendLabel = (e:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            name: formdata.get('name'),
            color: formdata.get('color'),
            task_id: props.id,
            project_id:props.ProjectId,
            member: localStorage.getItem('username')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }
        fetch(ApiRoot()+'yoptimize/save/label/', requestOptions)
            .then((response) => response.text())
            .then((result) => props.setFetchData(Date.now()))
            .catch((error) =>console.log(error))

    }


    const SetterLabel = (e:any,tid:any,cid:any) => {

        let Color = document.getElementById(cid) as HTMLInputElement;
        let ColorPre = document.getElementById(e.target.value) as HTMLOptionElement;
        if(Color !== null && ColorPre !== null) {
            Color.value = ColorPre.dataset.color as string
        }
        let Button = document.getElementById('submitButton'+props.id+''+props.ProjectId) as HTMLButtonElement;

        if(Button !== null) {
            Button.click()
        }
    }


    const setShow = (id:any) => {
        let Labels = document.getElementById(id) as HTMLDivElement;
        if(Labels !== null) {
            if(Labels.style.display === 'none') {
                Labels.style.display = 'block'
            } else {
                Labels.style.display = 'none'
            }

        }
    }


    const CloseTask = () => {


        var raw = ''

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'yoptimize/project/'+props.ProjectId+'/task/'+props.id+'/close/', requestOptions)
            .then((response) => response.text())
            .then((result) =>props.setFetchData(Date.now()))
            .catch((error) => console.log('error', error))
    }


    return(<Col className={props.ClassN} data-projectID={props.ProjectId} data-taskID={props.id}
                data-swimelane={props.swimelane} data-position={props.position} style={{
            marginBottom: '10px',
            padding:
                (props.teamMember !== undefined && props.teamMember !== '' ? (props.ColPadding !== undefined ? props.ColPadding : '0') : 'calc(var(--bs-gutter-x) * .5)')
        }}>

            <Card style={{
                background: (props.background !== undefined ? props.background : 'white'),
                color: (props.background !== undefined && colorSwitcher(props.background, false) === false ? 'white' : 'black'),
                width: '100%', marginBottom: '0px', cursor: 'move',
                /*boxShadow:'0 8px 10px -5px #0003, 0 16px 24px 2px #00000024, 0 6px 30px 5px #0000001f'*/
            }}>
                <Card.Body>
                    <Card.Title>
                        <Row>
                            <Col className={'col-8'} style={{paddingRight:0}}><small>
                                <Link to={'/myprojects/task/single/' + props.ProjectId + '/' + props.id}
                                      style={{textDecoration: 'none', color: 'black'}}>
                                    <p style={{
                                        marginBottom: '0', height:'44px',overflow:'hidden',
                                        color: (props.background !== undefined && colorSwitcher(props.background, false) === false ? 'white' : 'black')
                                    }}>{props.title !== undefined && props.title !== '' ? props.title : 'Task App XYZ'}</p>
                                </Link></small>
                            </Col><Col className={'col-4'} style={{padding: '0'}}>
                            <span className={'float-start'} style={{marginTop: '-5px',marginLeft: '5px'}}>
                            <small style={{fontSize: 'small'}}>
                                <strong>{props.id}</strong>
                            </small>
                            </span>
                            <NavDropdown
                                className={'text-right'}
                                title={
                                    <span>
                                       <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#999999"
                                            className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                      <path
                                          d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                    </svg>
                                 </span>
                                }
                                id='collasible-nav-dropdown_dashboard_projects'>
                                <Link className={'dropdown-item'}
                                      to={'/myprojects/task/single/' + props.ProjectId + '/' + props.id}>
                                    {IntlMessageValue('de', 'task.navi.open', props.Store)}
                                </Link>
                                <NavDropdown.Item
                                    onClick={(e) => savearchiv(e, props.ProjectId, props.id, (props.archiv === "1" ? 0 : 1), props.setFetchData)}>{(props.archiv === "1" ? IntlMessageValue('de', 'task.navi.restorearchiv', props.Store) : IntlMessageValue('de', 'task.navi.archived', props.Store))} </NavDropdown.Item>
                                <NavDropdown.Item
                                    onClick={(e) => savedelete(e, props.ProjectId, props.id, (props.delete === "1" ? 0 : 1), props.setFetchData)}>{(props.delete === "1" ? IntlMessageValue('de', 'task.navi.restoredelete', props.Store) : IntlMessageValue('de', 'task.navi.delete', props.Store))} </NavDropdown.Item>
                                <NavDropdown.Divider/>
                                <NavDropdown.Item onClick={(e) => CloseTask()}>
                                    <IntlMessage messageId="single.short.task.close" Store={props.Store}/>
                                </NavDropdown.Item>
                                <NavDropdown.Divider/>
                                <NavDropdown.Item onClick={() => setShow('form001' + props.id + '' + props.ProjectId)}>
                                    {IntlMessageValue('de', 'added.labels.short', props.Store)}
                                </NavDropdown.Item>
                            </NavDropdown> </Col>
                            {/*<Col className={'col-1'} style={{marginTop:'-5px',padding:'0'}}>

                             <span className="material-icons float-right" onClick={() => (document.getElementById('cardITem'+props.id) as HTMLDivElement).style.display = 'none'}>
                                    expand_less
                                </span>

                                <br/>
                                <span className="material-icons float-right" onClick={() => (document.getElementById('cardITem'+props.id) as HTMLDivElement).style.display = 'block'}>
                                    expand_more
                                </span>

                        </Col>*/}
                        </Row>
                        {/*<div>
                            <small style={{fontSize: 'small'}}><strong>{props.id} | </strong></small>
                            <small style={{fontSize: 'small'}}>{dateFormat(
                                props.date,
                                localStorage.getItem('locale'),
                                'us',
                                true,
                                false,
                                props.Store
                            )}</small> <small style={{fontSize: 'small'}}>
                            <span style={{
                                paddingLeft: '5px',
                                paddingRight: '5px',
                                borderRadius: '5px',
                                color: (props.priorität !== undefined && props.priorität !== null && props.priorität !== '' ? 'white' : 'black'),
                                background: (props.priorität !== undefined && props.priorität !== null && props.priorität !== '' ? colorset[props.priorität].color : 'white')
                            }}> P{(props.priorität !== undefined && props.priorität !== null && props.priorität !== '' ? props.priorität : 0)} </span>
                            <span title={'now ' + props.comment + ' comments'}>
                            <span className="material-icons"
                                  style={{verticalAlign: 'middle', width: '30px', color: 'rgb(223, 176, 255)'}}>
                                mode_comment
                            </span>
                                <span style={{
                                    color: 'white',
                                    marginLeft: '-25px',
                                    marginBottom: '5px'
                                }}>{props.comment}</span>
                            </span>
                        </small>
                        </div>*/}
                        <div style={{marginBottom:'5px'}}>
                            {props.Due_On !== '' && props.Due_On !== null ? <><p className={'m-0'}><Avatar alt={props.responsibility}
                                                                                         title={props.responsibility}
                                                                                         src={props.media}
                                                                                         className={'cardAvatarMini'}>{AvatarName(props.responsibility)}</Avatar><small
                                style={{fontSize: '12px', verticalAlign: 'super'}}><strong><IntlMessage
                                messageId="common.ticket.dueOnShort" Store={props.Store}/>: <span
                                style={{color: due_onDiff === false ? (colorSwitcher(props.background, false) === false ? 'white' : 'black') : 'Red'}}>{
                                dateFormat(
                                    props.Due_On,
                                    localStorage.getItem('locale'),
                                    'us',
                                    false,
                                    false,
                                    props.Store
                                )
                            }</span></strong></small><p className={'float-end'} style={{marginTop:'5px',marginBottom:'5px'}}>
                                 <span className="badge float-start"
                                       style={{
                                           fontSize: '12px',
                                           marginRight:'3px',
                                           color: (props.priorität !== undefined && props.priorität !== null && props.priorität !== '' ? 'white' : 'black'),
                                           background: (props.priorität !== undefined && props.priorität !== null && props.priorität !== '' ? colorset[props.priorität].color : 'white')
                                       }}>
                                        P{(props.priorität !== undefined && props.priorität !== null && props.priorität !== '' ? props.priorität : 0)}
                                    </span>
                                <span className="badge float-start"
                                      style={{
                                          fontSize: '12px',
                                          fontWeight: 'bolder',
                                          color: 'rgb(16, 80, 183)',
                                          background: 'white',
                                          border: '1px solid rgb(16, 80, 183)'
                                      }}>
                                    {props.state}
                                </span></p></p>
                            </> : <>
                                <p className={'m-0'}>
                                    <Avatar alt={props.responsibility} title={props.responsibility}
                                            src={'data:image/png;base64,' + props.media} className={'cardAvatarMini'}>
                                        {AvatarName(props.responsibility)}
                                    </Avatar><p className={'float-end'}  style={{marginTop:'5px',marginBottom:'5px'}}>
                                 <span className="badge  float-start"
                                       style={{
                                           fontSize: '12px',
                                           marginRight:'3px',
                                           color: (props.priorität !== undefined && props.priorität !== null && props.priorität !== '' ? 'white' : 'black'),
                                           background: (props.priorität !== undefined && props.priorität !== null && props.priorität !== '' ? colorset[props.priorität].color : 'white')
                                       }}>
                                        P{(props.priorität !== undefined && props.priorität !== null && props.priorität !== '' ? props.priorität : 0)}
                                    </span>
                                <span className="badge float-start"
                                      style={{
                                          fontSize: '12px',
                                          fontWeight: 'bolder',
                                          color: 'rgb(16, 80, 183)',
                                          background: 'white',
                                          border: '1px solid rgb(16, 80, 183)'
                                      }}>
                                    {props.state}
                                </span></p></p>
                            </>}

                        </div>

                        <div>
                            <p style={{
                                height: '55px',
                                overflow: 'hidden',
                                marginBottom: 0,
                                paddingBottom: 0,
                                width: '100%'
                            }}>

                                <Stack direction="horizontal" gap={2} className={'flex-wrap'}>
                                {StackBadgeHolder}
                            </Stack>
                        </p>
                        <span style={{clear: 'both'}}/>
                    </div>
                    {props.Labels !== undefined ?
                        <Form
                            id={'form001' + props.id + '' + props.ProjectId}
                            style={{display: 'none'}}
                            onSubmit={(e) =>
                                sendLabel(e)
                                }
                            >


                                <Row>
                                    <Col>

                                        <Form.Select aria-label="Default select example"
                                                     onChange={(e) => SetterLabel(e, 'LabelID' + props.id + '' + props.ProjectId, 'LabelColor' + props.id + '' + props.ProjectId)}
                                                     name={'name'} id={'LabelID' + props.id + '' + props.ProjectId}
                                                     required={true}
                                        >
                                            <option
                                                label={IntlMessageValue('de', 'added.label.placeholder', props.Store)}></option>
                                            {AllLabelsHoldder}
                                        </Form.Select>


                                        <Form.Control
                                            required={true}
                                            type="hidden"
                                            name={'color'}
                                            id={'LabelColor' + props.id + '' + props.ProjectId}
                                        />

                                        <Button variant="success" type={'submit'} className={'d-none'}
                                                id={'submitButton' + props.id + '' + props.ProjectId}>
                                        <span className="material-icons-outlined">
                                            send
                                            </span>
                                        </Button>
                                    </Col>
                                </Row>
                            </Form> : <></>}

                    </Card.Title>
                    <Card.Text id={'cardITem' + props.id} style={{display: 'none'}}>
                        <div>
                            <p className={'float-start'}>{props.text}</p>
                            <p className={'float-end'} style={{textAlign: 'right', color: colorProess}}>{props.now}%</p>
                            <br style={{clear: 'both'}}></br>
                        </div>
                        <ProgressBar style={{height: '5px'}}
                                     variant={props.variant !== undefined && props.variant !== '' ? props.variant : 'danger'}
                                     now={props.now}/>
                        <br/>
                        {props.teamMember !== undefined && props.teamMember !== '' ?
                            <>
                                <Row>
                                    <Col md={8} xxl={8} xl={8} sm={8}>Team Member<br/>

                                        <AvatarGroup max={4} className={'float-start'}
                                                     id={props.avatarID !== undefined && props.avatarID !== '' ? props.avatarID + 'avatarGroup' : 'avatarGroup'}>
                                            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg"/>
                                            <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg"/>
                                            <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg"/>
                                            <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg"/>
                                            <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg"/>
                                        </AvatarGroup>

                                        {/*  <Avatar className={'float-end teamMember '} style={{border: '1px solid #bdbdbd',background: 'white',color: 'blue'}}>
                                    <span className="material-icons">
                                        add
                                        </span>
                                            </Avatar>*/}
                                    </Col>
                                    <Col md={4} xxl={4} xl={4} sm={4}><p style={{textAlign: 'right'}}><br/>
                                        <span className="badge rounded-pill "
                                              style={{
                                                  marginTop: '10px',
                                                  backgroundColor: 'rgba(' + props.daysColor + ',0.4)',
                                                  color: 'rgba(' + props.daysColor + ')'
                                              }}>
                                    {props.daysLeft} days left</span>
                                    </p></Col>
                                </Row>
                            </>
                            : (<>
                                <Row>
                                    <Col>Status<br/>
                                        <span style={{
                                            color: color,
                                            textTransform: 'uppercase'
                                        }}><strong>{props.status !== undefined && props.status !== '' ? props.status : 'Open'}</strong></span></Col>
                                    <Col><p style={{textAlign: 'right'}}><br/> <span className="badge rounded-pill "
                                                                                     style={{
                                                                                         backgroundColor: 'rgba(' + props.daysColor + ',0.4)',
                                                                                         color: 'rgba(' + props.daysColor + ')'
                                                                                     }}>
                                    {props.daysLeft} days left</span></p></Col>
                                </Row>
                            </>)}

                    </Card.Text>
                </Card.Body>
            </Card>
            {props.added !== undefined && props.added === true ?
            <p onClick={() => props.openNew(props.cid, props.Swimelaneid)} className={'text-center p-1 m-1'}
               style={{cursor: 'pointer',fontSize:'12px'}}>
                                        <span className="material-icons" style={{verticalAlign: 'bottom',fontSize:'12px'}}>
                                            add
                                        </span>
                {IntlMessageValue('de', 'task.add.new', props.Store)}
            </p> : <></>}
        </Col>
    )
}

export const CreateTaskCardDashbaord = (props: {
    Store: any,
    state?: any,
    responsibility?: any,
    background?: any,
    ClassN?: any,
    Due_On?: any,
    ColPadding?: any,
    archiv?: any,
    delete?: any,
    setFetchData?: (value: ((prevState: number) => number) | number) => void,
    label?: any,
    priorität?: any,
    comment?: any,
    swimelane?: any,
    id: any,
    position?: any,
    title?: any,
    ProjectId?: any,
    now?: any,
    variant?: any,
    status?: any,
    daysColor?: any,daysLeft?:any,task?:any,allTask?:any,teamMember?:any,date?:any,avatarID?:any,text?:any}) => {


    let due_onDiff = (new Date() > new Date(props.Due_On) ? true : false);

    let ColorMapStatus = [
        {title: 'Open',color:'#F8621B'},
        {title: 'In Progess',color:'#2EB0D9'}
    ]

    let color;

    for(let x=0;x<ColorMapStatus.length;x++){
        if(ColorMapStatus[x].title === props.status){
            color = ColorMapStatus[x].color;
        }
    }

    let ColorMapProgressBar = [
        {title: 'danger',color:'#dc3545'},
        {title: 'success',color:'#28a745'},
        {title: 'warning',color:'#ffc107'}
    ]

    let colorProess

    for(let x=0;x<ColorMapProgressBar.length;x++){
        if(ColorMapProgressBar[x].title === props.variant){
            colorProess = ColorMapProgressBar[x].color;
        }
    }

    let colorset = [
        {color:'#4D4D4D'},
        {color:'#B71234'},
        {color:'#B71234'},
        {color:'#2EA02E'},
        {color:'#2EA02E'},
        {color:'#FFA5006E'},
        {color:'#FFA5006E'}
    ]

    let StackBadgeHolder = [];
    if(props.label !== undefined && props.label !== null) {
        for (let sl = 0; sl < props.label.length; sl++) {

            StackBadgeHolder.push(
                <span className="badge rounded-pill float-start"
                      style={{background: props.label[sl].color,fontSize:'12px',color:( colorSwitcher(props.label[sl].color,false) === false ? 'white' : 'black')}} >{props.label[sl].name}</span>
            )
        }
    }


    return(

            <Card style={{ background:(props.background !== undefined ? props.background : 'white'),
                color:(props.background !== undefined && colorSwitcher(props.background,false) === false ? 'white' : 'black'),
                width: '100%',marginBottom:'10px',cursor:'move'}}>
                <Card.Body>
                    <Card.Title>
                        <Row>
                            <Col className={'col-9'}><small>

                                    <p style={{marginBottom: '0',
                                        color:(props.background !== undefined && colorSwitcher(props.background,false) === false ? 'white' : 'black')}}>{props.title !== undefined && props.title !== ''  ? props.title : 'Task App XYZ'}</p>
                                </small>
                            </Col><Col className={'col-3'} style={{padding: '0'}}>
                            <NavDropdown
                                className={'text-right'}
                                title={
                                    <span>
                                       <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#999999"
                                            className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                      <path
                                          d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                    </svg>
                                 </span>
                                }
                                id='collasible-nav-dropdown_dashboard_projects'>
                                <Link className={'dropdown-item'} to={'/myprojects/task/single/'+props.ProjectId+'/'+props.id} >
                                    Open
                                </Link>
                                <NavDropdown.Item onClick={(e)=>savearchiv(e,props.ProjectId,props.id,(props.archiv === "1" ? 0 : 1),props.setFetchData)}>{(props.archiv === "1" ? 'Restore ticket from archiv' : 'Archive ticket')} </NavDropdown.Item>
                                <NavDropdown.Item onClick={(e)=>savedelete(e,props.ProjectId,props.id,(props.delete === "1" ? 0 : 1),props.setFetchData)}>{(props.delete === "1" ? 'Restore ticket from trash' : 'Delete ticket')} </NavDropdown.Item>
                            </NavDropdown> </Col>
                            {/*<Col className={'col-1'} style={{marginTop:'-5px',padding:'0'}}>

                             <span className="material-icons float-right" onClick={() => (document.getElementById('cardITem'+props.id) as HTMLDivElement).style.display = 'none'}>
                                    expand_less
                                </span>

                                <br/>
                                <span className="material-icons float-right" onClick={() => (document.getElementById('cardITem'+props.id) as HTMLDivElement).style.display = 'block'}>
                                    expand_more
                                </span>

                        </Col>*/}
                        </Row>
                        <div>
                            <small style={{fontSize:'small'}}><strong>{props.id} | </strong></small>
                            <small style={{fontSize:'small'}}>{dateFormat(
                                props.date,
                                localStorage.getItem('locale'),
                                'us',
                                true,
                                false,
                                props.Store
                            )}</small> <small style={{fontSize:'small'}}>
                            <span style={{paddingLeft:'5px',paddingRight:'5px',borderRadius: '5px',color: (props.priorität !== undefined && props.priorität !== null && props.priorität !== '' ?'white' : 'black'),background: (props.priorität !== undefined && props.priorität !== null && props.priorität !== '' ? colorset[props.priorität].color : 'white')}}> P{(props.priorität !== undefined && props.priorität !== null && props.priorität !== '' ? props.priorität : 0)} </span>
                            <span title={'now '+props.comment+' comments'}>
                            <span className="material-icons" style={{verticalAlign:'middle',width:'30px',color:'rgb(223, 176, 255)'}}>
                                mode_comment
                            </span>
                                <span style={{color:'white',marginLeft:'-25px',marginBottom:'5px'}}>{props.comment}</span>
                            </span>
                        </small>
                        </div>
                        <div>
                            {props.Due_On !== '' && props.Due_On !== null ? <><p><Avatar alt={props.responsibility} title={props.responsibility} src="/static/images/avatar/1.jpg" className={'cardAvatarMini'}>{AvatarName(props.responsibility)}</Avatar><small style={{fontSize:'small'}}><strong><IntlMessage messageId="common.ticket.dueOn" Store={props.Store}/>: <span style={{color: due_onDiff === false ? (colorSwitcher(props.background,false) === false ? 'white' : 'black') : 'Red'}}>{
                                dateFormat(
                                    props.Due_On,
                                    localStorage.getItem('locale'),
                                    'us',
                                    false,
                                    false,
                                    props.Store
                                )
                            }</span></strong></small></p></> : <><Avatar alt={props.responsibility} title={props.responsibility} src="/static/images/avatar/1.jpg" className={'cardAvatarMini mb-3'}>{AvatarName(props.responsibility)}</Avatar></>}

                        </div>

                        <div>
                            <Stack direction="horizontal" gap={2} className={'flex-wrap'}>
                                <span className="badge"
                                      style={{fontSize:'12px', fontWeight:'bolder',color:'rgb(16, 80, 183)',background:'white',border: '1px solid rgb(16, 80, 183)'}}>
                                    {props.state}
                                </span>
                                {StackBadgeHolder}
                            </Stack>
                            <br style={{clear:'both'}} />
                        </div>



                    </Card.Title>
                    <Card.Text id={'cardITem'+props.id} style={{display:'none'}}>
                        <div>
                            <p className={'float-start'}>{props.text}</p>
                            <p className={'float-end'} style={{textAlign: 'right',color:colorProess}}>{props.now}%</p>
                            <br style={{clear:'both'}}></br>
                        </div>
                        <ProgressBar style={{height:'5px'}} variant={props.variant !== undefined && props.variant !== ''  ? props.variant : 'danger'} now={props.now} />
                        <br/>
                        {props.teamMember !== undefined && props.teamMember !== '' ?
                            <>
                                <Row>
                                    <Col md={8} xxl={8} xl={8} sm={8}>Team Member<br/>

                                        <AvatarGroup max={4} className={'float-start'} id={props.avatarID !== undefined && props.avatarID !== ''  ? props.avatarID+'avatarGroup' : 'avatarGroup'}>
                                            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                                            <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                                            <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
                                            <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
                                            <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
                                        </AvatarGroup>

                                        {/*  <Avatar className={'float-end teamMember '} style={{border: '1px solid #bdbdbd',background: 'white',color: 'blue'}}>
                                    <span className="material-icons">
                                        add
                                        </span>
                                            </Avatar>*/}
                                    </Col>
                                    <Col  md={4} xxl={4} xl={4} sm={4}><p style={{textAlign:'right'}}><br/>
                                        <span className="badge rounded-pill "
                                              style={{marginTop: '10px',backgroundColor: 'rgba('+props.daysColor+',0.4)',color:'rgba('+props.daysColor+')'}}>
                                    {props.daysLeft} days left</span>
                                    </p></Col>
                                </Row>
                            </>
                            :(<>
                                <Row>
                                    <Col>Status<br/>
                                        <span style={{color: color,textTransform:'uppercase'}}><strong>{props.status !== undefined && props.status !== ''  ? props.status : 'Open'}</strong></span></Col>
                                    <Col><p style={{textAlign:'right'}}><br/> <span className="badge rounded-pill "
                                                                                    style={{backgroundColor: 'rgba('+props.daysColor+',0.4)',color:'rgba('+props.daysColor+')'}}>
                                    {props.daysLeft} days left</span></p></Col>
                                </Row>
                            </>)}

                    </Card.Text>
                </Card.Body>
            </Card>

    )
}


export const AvatarName = (name:any) => {
    let Name = '';

    if(name === null || name === undefined) {
        return;
    }
    let Hold = name.split(' ');

    for(let x=0;x<Hold.length;x++) {
        Name = Name + Hold[x].charAt(0)
    }
    return Name;
}
