import React from "react";
import Software_SupportOverview from './Pages/overview'
import Reports from "./Pages/reports";
import SoftwareSupportTicket from "./Pages/ticket";
import {ChatSupportMenuConfig, ChatSupportPagesConfig} from "./support_chat/modules";
import {CryptDecrypt} from "../../@WUM/core/Elements/crypt/Crypt";

export const Software_SupportConfig = (value:any) => {
    if (localStorage.getItem('userRights') !== null && localStorage.getItem('userRights') !== undefined && JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string).taskmanagerChatSupport === 0
        && JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string).ticketsystemChatSupport === 0 ) {
        return []
    } else {
    return [
        {
            path: '/support/software/overview/:page',
            elements: <Software_SupportOverview value={value} />,
            roles: ['superadmin', 'admin',
                'ticketsystem',
                'worktime',
                'teamtakt']
        },
        {
            path: '/support/ticket/software/:id',
            elements: <SoftwareSupportTicket value={value} />,
            roles: ['superadmin', 'admin',
                'ticketsystem',
                'worktime',
                'teamtakt']
        },
        {
            path: '/software/report/support/:page',
            elements: <Reports value={value} />,
            roles: ['superadmin', 'admin',
                'ticketsystem',
                'worktime',
                'teamtakt']
        },
        ...ChatSupportPagesConfig(value)
    ]
}
}

export const Software_SupportMenuConfig = (value:any) => {


    let Chatsupport = (
        localStorage.getItem('userRights') !== null && localStorage.getItem('userRights') !== undefined && JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string).taskmanagerChatSupport === 0
        && JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string).ticketsystemChatSupport === 0 ?
        [] : ChatSupportMenuConfig(value));

    let Support =
        (
            localStorage.getItem('userRights') !== null && localStorage.getItem('userRights') !== undefined && JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string).taskmanagerSupportLevel >= 2
            && JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string).ticketsystemSupportLevel >= 2 ?
        [{
            name: 'Support Ticket',
            route: '/support/software/overview/1',
            icon: 'add_task',
            roles: ['superadmin', 'admin',
                'ticketsystem',
                'worktime',
                'teamtakt']
        }]
                : [])

    if (localStorage.getItem('userRights') !== null && localStorage.getItem('userRights') !== undefined && JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string).taskmanagerChatSupport === 0
    && JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string).ticketsystemChatSupport === 0
    && JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string).taskmanagerSupportLevel === 1
    && JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string).ticketsystemSupportLevel === 1) {
        return []
    } else {
        return [
            {
                header: 'Software-Support',
                icon: 'help',
                roles: ['superadmin', 'admin',
                    'ticketsystem',
                    'worktime',
                    'teamtakt'],
                menuitem: [
                    ...Support,
                    ...Chatsupport
                    /*

                    {
                        name: 'Berichte',
                        route: '/software/report/support/1',
                        icon: 'query_stats',
                        roles: ['superadmin', 'admin', 'merchant']
                    }*/
                ]
            }
        ]
    }
}

export const Software_Support = () => [
    {
        title: 'Support Ticket',
        href: '/support/software/overview/1',
        undertitle: 'Software-Support',
        icon: 'manage_accounts',
        text: 'Software-Support',
        roles: ['superadmin', 'admin',
            'ticketsystem',
            'worktime',
            'teamtakt']
    }
]
