import '../css/company.css'
import React, {useState} from "react";
import {
    FileUploadButton, IntlMessageValue,
    UniButton,
    UniCards,
    UniCol,
    UniForm,
    UniRow,
    UniSpinner
} from "../../../../@WUM/core/Elements";
import {ResponseUSerProfil} from "../API/response/support_software";
import {_UserInterface} from "../API/request/SupportSoftwareApi.interface";
import {config, output} from "./configuration/mapping";
import {support_software} from "../API/request/support_software";
import { notify_save } from '../../../../@WUM/core/component/const/notification.service';
import { error, IntlMessage } from '../../../../@WUM/core/Elements/index'
import init from '../../../../@WUM/core/form-serilizer/seriliaze/Formserialize'
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import { Form } from 'react-bootstrap'
import Stats from "../../../../@WUM/core/Elements/Stats";
import Country from "../../../../@WUM/core/Elements/Country";

const CompanyOverview = (props:{value:any}) =>{


    const Profils = ResponseUSerProfil(1);
    const Store = useSelector((state: any) => state)
    const history = useNavigate()
    // @ts-ignore
    let Profil:_UserInterface[] = [Profils !== undefined ? Profils: undefined]
    /**
     * Neuladen der Seite
     */
    const routeChange = (path: string) => {
        let paths = path
        history(paths)
    }
    const [currentTutorial, setCurrentTutorial] = useState<_UserInterface[]>(
        typeof Profil !== "boolean" && Profil !== undefined ? Profil : []
    )
    const Send = (event:any) => {
        event.preventDefault()
        event.stopPropagation()

        /*
               *  Wichtig zum Rausholen der Datan aus Formular und senden
               *  Das Interface muss immer angepasst werden
               */
        let Data = init()

        let reauestData: _UserInterface[] & config = Object.assign(
            {},
            currentTutorial,
            output(Data)
        )

        /*
         *  Senden der Erhaltenen Daten an das Frontend
         */
        support_software
            .updatePost(reauestData, 1)
            .then((response) => {
                setCurrentTutorial({ ...currentTutorial })
                notify_save(Store)
                routeChange(`/company/overview/1` )
            })
            .catch((e:any) => {
                error(e)
            })
    }



    if (Profils !== true && typeof Profils !== "boolean" && Profils !== undefined) {

        return (
            <>
                <Form
                    id={'form001'}
                    noValidate
                    onSubmit={(e) =>
                        Send(e)
                    }
                >
                    <div className={'ticketsystem'} style={{padding: '25px'}}>
                        <UniCards class={'p-3'}>
                            <h3><IntlMessage Store={Store} messageId="company.header" /></h3>
                            <p>
                                <IntlMessage Store={Store} messageId="company.description" />
                            </p>
                            <UniRow>
                                <UniCol class={'col-3 p-3'}>
                                    <FileUploadButton
                                        classNameInput={'d-none'}
                                        img={{width: '300px', height: '50px'}}
                                        classNameButton={'mt-3'} placeholderImg={'/assets/img.png'}
                                        styleButton={{width: '100%'}} defaultValue={Profil[0].logo} defaultSRC={Profil[0].logo}
                                        ButtonLabel={IntlMessageValue('de', 'logo.button.label', Store)}
                                        DelButtonLabel={IntlMessageValue('de', 'logo.delbutton.label', Store)}
                                        name={'logo'}
                                        accept={"image/png, image/jpeg, image/webp"}/>
                                    <div className={'mt-5'}>
                                    <FileUploadButton
                                        classNameInput={'d-none'} placeholderImg={'/assets/img.png'}
                                        img={{width: '200px', height: '200px'}}
                                        classNameButton={'mt-3'}
                                        styleButton={{width: '100%'}} defaultValue={Profil[0].favicon} defaultSRC={Profil[0].favicon}
                                        ButtonLabel={IntlMessageValue('de', 'favicon.button.label', Store)}
                                        DelButtonLabel={IntlMessageValue('de', 'favicon.delbutton.label', Store)}
                                        name={'favicon'}
                                        accept={"image/png, image/jpeg, image/webp"}/>
                                    </div>
                                    <Stats
                                        preText={IntlMessageValue('de', 'valid.company.preText', Store)}
                                        afterText={IntlMessageValue('de', 'valid.company.afterText', Store)}
                                        validText={IntlMessageValue('de', 'valid.company.validText', Store)}
                                        replaceName={'[PercentHolder]'} validAfterName={IntlMessageValue('de', 'valid.company.validAfterName', Store)} validIcon={'verified_user'} ValidIconvariant={'-outlined'}
                                        item={[
                                        {title:'Logo',percent:13,check: (Profil[0].logo !== undefined && Profil[0].logo !== null && Profil[0].logo !== '' ? true : false)},
                                        {title:'Favicon',percent:10,check: (Profil[0].favicon !== undefined && Profil[0].favicon !== null && Profil[0].favicon !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.companyname', Store),percent:5,check: (Profil[0].name !== undefined && Profil[0].name !== null && Profil[0].name !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.employer', Store),percent:5,check: (Profil[0].owner !== undefined && Profil[0].owner !== null && Profil[0].owner !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.street', Store),percent:5,check: (Profil[0].street !== undefined && Profil[0].street !== null && Profil[0].street !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.zip', Store),percent:5,check: (Profil[0].zip !== undefined && Profil[0].zip !== null && Profil[0].zip !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.city', Store),percent:5,check: (Profil[0].cityL !== undefined && Profil[0].cityL !== null && Profil[0].cityL !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.country', Store),percent:5,check: (Profil[0].country !== undefined && Profil[0].country !== null && Profil[0].country !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.phone', Store),percent:5,check: (Profil[0].phone !== undefined && Profil[0].phone !== null && Profil[0].phone !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.email', Store),percent:5,check: (Profil[0].mail !== undefined && Profil[0].mail !== null && Profil[0].mail !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.url', Store),percent:5,check: (Profil[0].website !== undefined && Profil[0].website !== null && Profil[0].website !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.vat', Store),percent:4,check: (Profil[0].vat !== undefined && Profil[0].vat !== null && Profil[0].vat !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.tax', Store),percent:4,check: (Profil[0].tax !== undefined && Profil[0].tax !== null && Profil[0].tax !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.accountHolder', Store),percent:4,check: (Profil[0].accountHolder !== undefined && Profil[0].accountHolder !== null && Profil[0].accountHolder !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.accountNumber', Store),percent:4,check: (Profil[0].accountNumber !== undefined && Profil[0].accountNumber !== null && Profil[0].accountNumber !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.blz', Store),percent:4,check: (Profil[0].blz !== undefined && Profil[0].blz !== null && Profil[0].blz !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.bank', Store),percent:4,check: (Profil[0].bank !== undefined && Profil[0].bank !== null && Profil[0].bank !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.iban', Store),percent:4,check: (Profil[0].iban !== undefined && Profil[0].iban !== null && Profil[0].iban !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.bic', Store),percent:4,check: (Profil[0].bic !== undefined && Profil[0].bic !== null && Profil[0].bic !== '' ? true : false)}
                                    ]} />
                                </UniCol>
                                <UniCol>

                                    <UniRow>
                                        <UniCol class={'border-right  p-3'}>
                                            <UniForm.FormControl name={'id'} defaultValue={Profil[0].id} required={true}
                                                                 type={'hidden'}/>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.companyname', Store)}/>
                                                <UniForm.FormControl name={'name'} defaultValue={Profil[0].name} required={true}
                                                                     type={'name'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.employer', Store)}/>
                                                <UniForm.FormControl name={'owner'} defaultValue={Profil[0].owner} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <br/>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.street', Store)}/>
                                                <UniForm.FormControl name={'street'} defaultValue={Profil[0].street} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.zip', Store)}/>
                                                <UniForm.FormControl name={'zip'} defaultValue={Profil[0].zip} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>

                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.city', Store)}/>
                                                <UniForm.FormControl name={'cityL'} defaultValue={Profil[0].cityL} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                                <UniForm.InputGroup>
                                                    <UniForm.InputGroupText  text={IntlMessageValue('de', 'common.country', Store)}/>
                                                    <Country  defaultValue={Profil[0].country} mode={'country'} name={'country'} className={'mb-3'} />
                                                </UniForm.InputGroup>
                                            <br/>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.phone', Store)}/>
                                                <UniForm.FormControl name={'phone'} defaultValue={Profil[0].phone} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.email', Store)}/>
                                                <UniForm.FormControl name={'mail'} defaultValue={Profil[0].mail} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.url', Store)}/>
                                                <UniForm.FormControl name={'website'} defaultValue={Profil[0].website} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                        </UniCol>
                                        <UniCol class={'p-3'}>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.vat', Store)}/>
                                                <UniForm.FormControl name={'vat'} defaultValue={Profil[0].vat} type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.tax', Store)}/>
                                                <UniForm.FormControl name={'tax'} defaultValue={Profil[0].tax} type={'text'}/>
                                            </UniForm.InputGroup>
                                            <br/>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.accountHolder', Store)}/>
                                                <UniForm.FormControl name={'accountHolder'} defaultValue={Profil[0].accountHolder} type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.accountNumber', Store)}/>
                                                <UniForm.FormControl name={'accountNumber'} defaultValue={Profil[0].accountNumber} type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.blz', Store)}/>
                                                <UniForm.FormControl name={'blz'} defaultValue={Profil[0].blz} type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.bank', Store)}/>
                                                <UniForm.FormControl name={'bank'} defaultValue={Profil[0].bank} type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.iban', Store)}/>
                                                <UniForm.FormControl name={'iban'} defaultValue={Profil[0].iban} type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.bic', Store)}/>
                                                <UniForm.FormControl name={'bic'} defaultValue={Profil[0].bic} type={'text'}/>
                                            </UniForm.InputGroup>
                                        </UniCol>
                                    </UniRow>
                                </UniCol>
                            </UniRow>
                            <br/><br/>

                            <UniButton type={'submit'} variant={'success'}>{IntlMessageValue('de', 'common.save', Store)}</UniButton>
                        </UniCards>
                    </div>
                </Form>
            </>
        )
    } else {
        return(
            <UniSpinner text={'Loading...!'} variant={'secondary'} />
        )
    }
}

export default CompanyOverview;
