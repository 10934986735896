import React from 'react'
const clear = () => {
    return (
        <>

        </>
    )
}

export default clear;
