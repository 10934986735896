import {JSONServerUrl} from "./enviroment";

const PutRoute = (data:any,id:any,part:any,setFavi:any) => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let Holder = [];

    for(let x=0;x<data.favos.length;x++) {
        if(data.favos[x] !== undefined) {
            Holder.push(data.favos[x])
        }
    }

    data.favos = Holder;

    var raw = JSON.stringify(data);

    var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
    };

    fetch(JSONServerUrl+"/"+part+"/"+id, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));

    return;
}

export default PutRoute;
