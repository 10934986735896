import {timetrackingApiType} from "./TimetrackingApi.interface";
import {requests} from "../../../../../@WUM/core/RestFullApi/api";

export const dashboard = {
    getPosts: (): Promise<timetrackingApiType[]> =>
        requests.get(`widget/lastTask/`),
    getPostsDashboard: (id:any): Promise<timetrackingApiType[]> =>
        requests.get(`widget/dashboard/${id}/`),
    getPostsMTW: (responsibility:any): Promise<timetrackingApiType[]> =>
        requests.get(`widget/Mytask/${responsibility}/`),

}
