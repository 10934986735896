import React from 'react'
import { LanguageSwitcherValue } from '../../ticketsystem/modules/components/LanguageSwitcher'
import MemberOverview from './Pages/overview'
import MemberDetail from './Pages/Detail'
import MemberNew from './Pages/new'
import {EmailSettingConfig, EmailSettingMenuConfig} from "../email";
import {CompanyConfig, CompanyMenuConfig} from "../company";
import {UsestatsConfig, UsestatsMenuConfig} from "../usestats";
import {ProfilConfig, ProfilMenuConfig} from "../profil";
import {OrganisationConfig, OrganisationMenuConfig} from "../organisation";

export const MemberPagesConfig = (value: any) => {
    return [
        {
            path: '/Member/overview/:page',
            elements: <MemberOverview value={value} />,
            roles: ['superadmin', 'admin', 'merchant']
        },
        {
            path: '/Member/Detail/:id',
            elements: <MemberDetail value={value} />,
            roles: ['superadmin', 'admin', 'merchant']
        },
        {
            path: '/Member/Delete/:page/:deleteID',
            elements: <MemberOverview value={value} />,
            roles: ['superadmin', 'admin', 'merchant']
        },
        {
            path: '/Member/new/:page',
            elements: <MemberNew value={value} />,
            roles: ['superadmin', 'admin', 'merchant']
        },
        ...CompanyConfig(value),
        ...UsestatsConfig(value),
        ...EmailSettingConfig(value),
        ...ProfilConfig(value),
        ...OrganisationConfig(value)
    ]
}

export const MemberMenuConfig = (value: any) => {
    return [   {
        header: LanguageSwitcherValue('common.settings'),
        icon: 'settings',
        roles: ['superadmin', 'admin'],
        menuitem: [
            ...ProfilMenuConfig(value),
            ...CompanyMenuConfig(value),
            ...UsestatsMenuConfig(value),
            ...EmailSettingMenuConfig(value),
            ...OrganisationMenuConfig(value),
        {
            name: LanguageSwitcherValue('settings.Member.header'),
            route: '/Member/overview/1',
            icon: 'manage_accounts',
            roles: ['superadmin', 'admin', 'merchant']
        },

        ]}
    ]
}
export const Member = () => [
    {
        title: 'settings.Member.header',
        href: '/Member/overview/1',
        undertitle: 'Userverwaltung',
        icon: 'manage_accounts',
        text: 'settings.member.description',
        roles: ['superadmin', 'admin', 'merchant']
    }
]
