// Import German messages from the local JSON file
import deMessages from './de_DE.json'

// Import English messages from the local JSON file
import enMessages from './en_EN.json'

// Import German messages for Stripe integration from the JSON file
import deStripeMessages from '../../../core/stripe/localization/de_DE.json'

// Import English messages for Stripe integration from the JSON file
import enStripeMessages from '../../../core/stripe/localization/en_US.json'

// Import language registration for German and English from the external module
import {
    RegisterLanguageDE,
    RegisterLanguageEN
} from '../../../../Register/RegisterLanguage'

/**
 * German language object containing all localized messages for the application, including general messages, Stripe integration messages, and additional registered messages.
 * @constant
 * @type {Object}
 */
const deLang = {
    messages: {
        ...deMessages,
        ...deStripeMessages,
        ...RegisterLanguageDE
    },
    locale: 'de'
}

/**
 * English language object containing all localized messages for the application, including general messages, Stripe integration messages, and additional registered messages.
 * @constant
 * @type {Object}
 */
const EnLang = {
    messages: {
        ...enMessages,
        ...enStripeMessages,
        ...RegisterLanguageEN
    },
    locale: 'us'
}

/**
 * Contains the language configurations for the application. The keys represent the language codes, and the values are the respective language objects.
 * @constant
 * @type {Object.<string, {messages: Object, locale: string}>}
 */
const AppLocale: any = {
    de: deLang,
    us: EnLang
}

export default AppLocale
