export interface config {
    email: any
    password: any
    discr: any
    first_name: any
    isDeleted: any
    is_active: any
    is_staff: any
    is_superuser: any
    last_login: any
    last_name: any
    locale_code: any
    roles: any
    updated_at: any
    username: any
    username_canonical: any
    verified_at: any
    uid: any
    media: any
    position: any
    responsibility: any
    gender: any
    birthday: any
    phone: any
    standardLang:any
    country: any
    city:any
    department: any
    federalState: any
    firstStandIn: any
    secondStandIn: any
}

export function output(Data: any) {
    let back = {
        email: Data.email,
        password: Data.password,
        discr: Data.discr,
        first_name: Data.first_name,
        isDeleted: Data.isDeleted,
        is_active: Data.is_active,
        is_staff: Data.is_staff,
        is_superuser: Data.is_superuser,
        last_login: Data.last_login,
        last_name: Data.last_name,
        locale_code: Data.locale_code,
        roles: Data.roles,
        updated_at: Data.updated_at,
        username: Data.username,
        username_canonical: Data.username_canonical,
        verified_at: Data.verified_at,
        uid: Data.uid,
        media: Data.media,
        position: Data.position,
        responsibility: Data.responsibility,
        gender: Data.gender,
        birthday: Data.birthday,
        phone: Data.phone,
        standardLang: Data.standardLang,
        country: Data.country,
        city: Data.city,
        department: Data.department,
        federalState: Data.federalState,
        firstStandIn: Data.firstStandIn,
        secondStandIn: Data.secondStandIn
    }

    return back
}
