import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/gitlbabBoard.css'
import {colorSwitcher, UserAvatar} from "./MyTask";
import Avatar from "@material-ui/core/Avatar";

interface RootObject {
    issues: any;
    label: any;
    id: number;
    name: string;
    hide_backlog_list: boolean;
    hide_closed_list: boolean;
    project: Project;
    lists: List[];
}
interface List {
    id: number;
    label: Label;
    position: number;
}
interface Label {
    id: number;
    name: string;
    description: string;
    description_html: string;
    text_color: string;
    color: string;
}
interface Project {
    id: number;
    description: string;
    name: string;
    name_with_namespace: string;
    path: string;
    path_with_namespace: string;
    created_at: string;
    tag_list: any[];
    topics: any[];
    ssh_url_to_repo: string;
    http_url_to_repo: string;
    web_url: string;
    avatar_url?: any;
    star_count: number;
    last_activity_at: string;
    namespace: Namespace;
}
interface Namespace {
    id: number;
    name: string;
    path: string;
    kind: string;
    full_path: string;
    parent_id?: any;
    avatar_url?: any;
    web_url: string;
}

interface RootObjectLabels {
    id: number;
    name: string;
    description: string;
    description_html: string;
    text_color: string;
    color: string;
    subscribed: boolean;
    priority?: any;
    is_project_label: boolean;
}

const IssueBoard = () => {
    const [issueBoardData, setIssueBoardData] = useState<RootObject[]>([]);

    const [allLabels, setAllLabels] = useState<RootObjectLabels[]>([])

    useEffect(() => {
        const fetchIssueBoard = async () => {
            const accessToken = 'glpat-rraCSzaH8uy7h8izdHKn'; // Replace with your actual token
            const repositoryId = 286; // Replace with the actual repository ID

            // Fetch issue board structure
            const boardResponse = await axios.get(`https://gitlab.wum-solution.tech/api/v4/projects/${repositoryId}/boards`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const clearRows = boardResponse.data
            // Fetch all issues for the project
            const issuesResponse = await axios.get(`https://gitlab.wum-solution.tech/api/v4/projects/${repositoryId}/issues?scope=all`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            // Fetch all Labels for the project
            const allLabel = await axios.get(`https://gitlab.wum-solution.tech/api/v4/projects/${repositoryId}/labels`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });



            let openLine = {
                "id": 0,
                "label": {
                    "id": 0,
                    "name": "Open",
                    "description": "Tickets die öffen sind.",
                    "description_html": "Tickets die öffen sind.",
                    "text_color": "black",
                    "color": "transparent"
                },
                "position": 0
            }
            let closeLine = {
                "id": 0,
                "label": {
                    "id": 0,
                    "name": "Closed",
                    "description": "Tickets die geschlossen sind.",
                    "description_html": "Tickets die geschlossen sind.",
                    "text_color": "black",
                    "color": "transparent"
                },
                "position": 0
            }

            boardResponse.data[0].lists.unshift(openLine)
            boardResponse.data[0].lists.push(closeLine)
            const issueBoardData = boardResponse.data;

            const allIssues = issuesResponse.data;

            // Filter issues based on list (label)
            const mappedLists = issueBoardData[0].lists.map((list: { label: { name: any; }; }) => {
                const listLabel = list.label.name;
                let IssueHolder =  [];
                for(let x=0;x<allIssues.length;x++) {
                    let added = 0;
                    for(let l=0;l<allIssues[x].labels.length;l++) {
                        if(listLabel.toLowerCase() === 'completed')
                            console.log((allIssues[x].labels[l] as string).toLowerCase() +' == '+ (listLabel as string).toLowerCase());
                        if ((allIssues[x].labels[l] as string).toLowerCase().includes((listLabel as string).toLowerCase())) {
                            added++;
                            if(listLabel.toLowerCase() === 'completed')
                                console.log(added)

                        }
                    }
                    if(listLabel.toLowerCase() === 'completed')
                        console.log(added)
                    if( added !== 0) {
                        IssueHolder.push(allIssues[x])
                    }
                }

                if(listLabel === 'Open') {
                    IssueHolder = CheckRow(clearRows[0].lists,allIssues,'Open',IssueHolder)
                }
                if(listLabel === 'Closed') {
                    IssueHolder = CheckRow(clearRows[0].lists,allIssues,'Closed',IssueHolder)
                }

                const filteredIssues = IssueHolder;
                return { ...list, issues: filteredIssues };
            });

            setAllLabels(allLabel.data)
            setIssueBoardData(mappedLists); // Update state with filtered data
        };
        fetchIssueBoard();
    }, []);




    return (
        <div className="issue-board">
            {issueBoardData.map((list) => (
                <ul key={list.id} className="issue-list">
                    <h3 className="issue-list-header" style={{color:list.label.text_color,background:list.label.color}}>{list.label.name}</h3>  {/* Added class */}
                    {list.issues.map((issue: {
                        labels: any;
                        author: any;
                        assignee:any;
                        id: React.Key | null | undefined; title: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }) => (
                        <li key={issue.id} className="issue-card">
                            {/* Display issue details */}
                            <h4 className="issue-card-title">{issue.title}</h4>  {/* Added class */}
                            {CreateLabel(allLabels,issue.labels)}
                            {issue.author !== undefined ?
                                <Avatar alt="Remy Sharp" src={issue.author.avatar_url} style={{width:'24px',height:'24px'}} title={'Ersteller: '+issue.author.username}
                                        className={'float-end'}>{issue.author.username}</Avatar>

                                :
                                issue.assignee && (
                                <Avatar alt="Remy Sharp" src={issue.assignee.avatar_url} style={{width:'24px',height:'24px'}} title={'Verantwortlicher:'+issue.assignee.username}
                                        className={'float-end'}>{issue.assignee.username}</Avatar>
                                )}
                            {/* Add more issue details as needed (e.g., labels, due date) */}
                        </li>
                    ))}
                </ul>
            ))}
        </div>
    );
};

export default IssueBoard;


export const CreateLabel = (allLabels:any,Label:any) => {



    let LabelHolder = [];

    for(let l=0;l<allLabels.length;l++) {
        for (let sl = 0; sl < Label.length;sl++) {
            if (Label[sl] == allLabels[l].name) {
                LabelHolder.push(<span className="badge rounded-pill"
                             style={{
                                 background: allLabels[l].color,
                                 fontSize: '12px',
                                 marginRight:'5px',
                                 color: allLabels[l].text_color
                             }}>{allLabels[l].name}
                        </span>)
            }
        }
    }

    return LabelHolder;

}


export const CheckRow = (allRow:any,allIssues:any,listLabel:any,IssueHolder:any) => {


    for(let x=0;x<allIssues.length;x++) {
        let forwardCheck = 0;
        for (let l = 0; l < allIssues[x].labels.length; l++) {

            for (let ar = 0; ar < allRow.length; ar++) {

                    if (allRow[ar].label.name.toLowerCase() == allIssues[x].labels[l].toLowerCase()) {
                        forwardCheck = forwardCheck + 1;
                    }
            }

            //console.log(forwardCheck)
            if (forwardCheck === 0 && allIssues[x].state == "closed" && listLabel == 'Closed' && allIssues[x].state !== "opened" && listLabel !== 'Open' ) {
                IssueHolder.push(allIssues[x])
            }
             else if (forwardCheck === 0 && allIssues[x].state == "opened" && listLabel == 'Open' && allIssues[x].state !== "closed" && listLabel !== 'Closed' ) {
                IssueHolder.push(allIssues[x])
            }
            //console.log(IssueHolder)
        }
    }
    return IssueHolder;
}
