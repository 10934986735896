import React from 'react'
import Form from 'react-bootstrap/Form'
import legende from './helper/legende'
import { ControlChecked } from '../../Elements/index'

function checkbox(data: any) {
    let required = false
    if (
        data.required !== undefined &&
        data.required !== null &&
        data.required !== false
    ) {
        required = true
    }

    if (data.onclick !== undefined) {
        return (
            <>
                <Form.Group
                    controlId={'checkbox' + data.label}
                    key={'checkbox' + data.label}
                    className="mb-3"
                >
                    <Form.Check
                        onClick={() =>
                            ControlChecked(data.identifierClass, data.excluded)
                        }
                        name={data.name}
                        className={data.class}
                        label={data.label}
                        id={data.id}
                        aria-label="option 1"
                        defaultChecked={
                            data.visible === 1 || data.visible === '1'
                        }
                        required={required}
                    />
                </Form.Group>
                {legende(data)}
            </>
        )
    } else if (data.id !== undefined) {
        return (
            <>
                <Form.Group
                    controlId={'checkbox' + data.label}
                    key={'checkbox' + data.label}
                    className="mb-3"
                >
                    <Form.Check
                        name={data.name}
                        className={data.class}
                        label={data.label}
                        id={data.id}
                        aria-label="option 1"
                        defaultChecked={
                            data.visible === 1 || data.visible === '1'
                        }
                        required={required}
                    />
                </Form.Group>
                {legende(data)}
            </>
        )
    } else if (data.br !== undefined) {
        return (
            <>
                <br />
                <Form.Group
                    controlId={'checkbox' + data.label}
                    key={'checkbox' + data.label}
                    className="mb-3"
                >
                    <Form.Check
                        name={data.name}
                        label={data.label}
                        id={data.id}
                        aria-label="option 1"
                        defaultChecked={
                            data.visible === 1 || data.visible === '1'
                        }
                        required={required}
                    />
                </Form.Group>
                {legende(data)}
            </>
        )
    } else {
        return (
            <>
                <Form.Group
                    controlId={'checkbox' + data.label}
                    key={'checkbox' + data.label}
                    className="mb-3"
                >
                    <Form.Check
                        name={data.name}
                        label={data.label}
                        aria-label="option 1"
                        defaultChecked={
                            data.visible === 1 || data.visible === '1'
                        }
                        required={required}
                    />
                </Form.Group>
                {legende(data)}
            </>
        )
    }
}

export default checkbox
