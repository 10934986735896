import React from "react";
import CompanyOverview from './Pages/overview'
import {CryptDecrypt} from "../../../@WUM/core/Elements/crypt/Crypt";

export const CompanyConfig = (value:any) => {
    if(localStorage.getItem('userRights') !== null && localStorage.getItem('userRights') !== undefined && JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string).isMandant === false) {
        return []
    } else {
        return [
            {
                path: '/company/overview/:page',
                elements: <CompanyOverview value={value}/>,
                roles: ['superadmin', 'admin',
                    'ticketsystem',
                    'worktime',
                    'teamtakt']
            }
        ]
    }
}

export const CompanyMenuConfig = (value:any) => {
    if(localStorage.getItem('userRights') !== null && localStorage.getItem('userRights') !== undefined && JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string).isMandant === false) {
        return [{
            name: 'Unternehmen',
            route: '/company/overview/1',
            icon: 'apartment',
            roles: ['superadmin', 'admin',
                'ticketsystem',
                'worktime',
                'teamtakt'],
            permission:['General','Comapny']
        }]
    } else {
        return [

            {
                name: 'Unternehmen',
                route: '/company/overview/1',
                icon: 'apartment',
                roles: ['superadmin', 'admin',
                    'ticketsystem',
                    'worktime',
                    'teamtakt']
            }

        ]
    }
}

export const Software_Support = () => [
    {
        title: 'Support Ticket',
        href: '/support/software/overview/1',
        undertitle: 'Software-Support',
        icon: 'manage_accounts',
        text: 'Software-Support',
        roles: ['superadmin', 'admin',
            'ticketsystem',
            'worktime',
            'teamtakt']
    }
]
