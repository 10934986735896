import {useEffect, useState} from "react";
import {error} from "../../../../../@WUM/core/Elements/index";
import {timetrackingApiType} from "../request/TimetrackingApi.interface";
import {dashboard} from "../request/activity_recording";
import {checkLogin} from "../../../../../@WUM/templates/default/Auth/service/AuthService";

export const ResponseWidgetLastActivitat = (fetchData:any) => {
    const [posts, setPosts] = useState<timetrackingApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await dashboard
                .getPosts()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [fetchData])

    return isError ? isError : posts
}

export const ResponseWidgetDashboard = (id:any,fetchData:any,setCount:any) => {
    const [posts, setPosts] = useState<timetrackingApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await dashboard
                .getPostsDashboard(id)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    setCount(data.length)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id,fetchData])

    return isError ? isError : posts
}

export const ResponseWidgetMTW = (responsibility:any,fetchData:any) => {
    const [posts, setPosts] = useState<timetrackingApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await dashboard
                .getPostsMTW(responsibility)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [responsibility,fetchData])

    return isError ? isError : posts
}
