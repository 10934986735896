
import {requests} from "../../../../../@WUM/core/RestFullApi/api";
import {support_softwareApiType} from "./SupportSoftwareApi.interface";

export const email = {
    getPosts: (id:any): Promise<support_softwareApiType> =>
        requests.get(`yoptimize/email/settings/${id}/`),
    getPostsMailbox: (id:any): Promise<support_softwareApiType[]> =>
        requests.get(`yoptimize/email/settings/`),
}
