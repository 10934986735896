import { TicketMenuConfig } from '../modules/ticketsystem/modules'
import { AuthPagesConfig } from '../@WUM/templates/default/Auth/modules'
import { TicketPagesConfig } from '../modules/ticketsystem/modules'

const components = {
    TicketMenuConfig: TicketMenuConfig,
    AuthPagesConfig: AuthPagesConfig,
    TicketPagesConfig: TicketPagesConfig
}

export default components
