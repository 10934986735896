import {ApiRoot} from "../../../RestFullApi/api";

interface SingleTaskFetchPRops {
    url:string,
    id:string  | undefined,
    taskid:string  | undefined,
    lock:"0"|"1",
    lockby:string | null
    mode: "add" | "remove"
    setFetchData?:any
}
export const SingleTaskFetch = (props:SingleTaskFetchPRops) => {

    if(props.mode === 'add') {
        localStorage.setItem('BlockedRoute',JSON.stringify({url:props.url,taskid:props.taskid,id:props.id}))
    } else if(props.mode === 'remove') {
        localStorage.removeItem('BlockedRoute')
    }


    var raw = JSON.stringify(
        {
            lock:props.lock,
            lockby:props.lockby
        })

    var requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization:
                'Bearer ' +
                JSON.parse(localStorage.getItem('token') as string)
        },
        body: raw
    }

    fetch(ApiRoot()+"yoptimize/locked/Task/"+props.id+"/"+props.taskid+"/", requestOptions)
        .then(response => response.text())
        .then(result => (props.setFetchData !== undefined ? props.setFetchData(Date.now()) :console.log(result)))
        .catch(error => console.log('error', error));


}
