import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import NavDropdown from "react-bootstrap/NavDropdown";
import React, {useEffect, useState} from 'react'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {ResponseAllTemplate, ResponseAllTickets} from "../API/response/ticketsystem2";
import {SpinnerJSX} from "../../ticketsystem/modules/components/Spinner";
import {dateFormat} from "../../../@WUM/core/component/const/dateFormat";
import {useSelector} from "react-redux";
import {colorSwitcher} from "../../ticketsystem/modules/new/Pages/MyTask";
import {IntlMessage, IntlMessageValue, DynamikOffCanvas, ApiURL} from "../../../@WUM/core/Elements/index";
import {getToken, setNavi} from "../../../@WUM/core/Function/index";
import {useLocation, useNavigate} from "react-router-dom";
import PaginationYoptimize from "./components/PaginationYoptimize";
import {closeEsc, refresh} from "../../ticketsystem/modules/new/Pages/SingleTask";
import Button from "react-bootstrap/Button";
import SunEditor from "suneditor-react";
import SunEditorConfig from "../../ticketsystem/modules/components/SuneditorConfig";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import {init2} from "../../../@WUM/core/form-serilizer/seriliaze/Formserialize";
import {ResponseAllTResponsibility} from "../../ticketsystem/API/response/ticket";
import {FilterResponse} from "../../ticketsystem/modules/new/Pages/MyProjects";
import UniOffCanvas from "../../../@WUM/core/component/const/UniOffCanvas";
import Avatar from "@material-ui/core/Avatar";
import {CryptDecrypt} from "../../../@WUM/core/Elements/crypt/Crypt";
import {ResponseDeparmtent} from "../../settings/organisation/API/response/support_software";

const Inbox = (props:{value:any}) =>{
    setNavi({
        route:'/ticket/ticketsystem/'
    })
    let { state } = useLocation();
    console.log(JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string))
    const Store = useSelector((state: any) => state)
    const [fetchData, setFetchData] = useState(0);
    const [fetchSortData, setFetchSortData] = useState([]);
    const [filter, setfilter] = useState([]);
    const [archiv,setArchiv] = useState('0');
    const [prioHolder,setPrioHolder] = useState('');
    const [stateHolder,setStateHolder] = useState('');
    const [clearFilter,setClearFilter] = useState(true);
    const response =  ResponseAllTickets(fetchData,archiv);
    const [pageDelimiter, setpageDelimiter] = useState(10)
    const [startpage, setstartPage] = useState(0)
    const [endpage, setEndPage] = useState(pageDelimiter)
    const [active, setactive] = useState(1)
    const [value, setValue] = useState("");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let AllUSer = ResponseAllTResponsibility(fetchData)
    let UserHolder = [];
    let DepartmentHoldder= [];
    const Department = ResponseDeparmtent('1');
    let DepartmentNumber = 0;
    if (Department !== null && Department !== undefined && typeof Department !== "boolean" && Department.length !== 0) {
        for (let c = 0; c < Department.length; c++) {

            DepartmentNumber++;
            DepartmentHoldder.push(
                <>
                    <option value={Department[c].id}>{
                        IntlMessageValue('de',  Department[c].name, Store)
                    }</option>
                </>
            )
        }
    }

    const SearchUserID = (username: any) => {
        if (AllUSer !== true && typeof AllUSer !== "boolean" && AllUSer?.length > 0) {
            for (let us = 0; us < AllUSer?.length; us++) {
                if(username as string === AllUSer[us].username) {
                    return AllUSer[us].id
                }
            }
        }
        return 0;
    }
    if (AllUSer !== true && typeof AllUSer !== "boolean" && AllUSer?.length > 0) {
        for (let us = 0; us < AllUSer?.length; us++) {
            UserHolder.push(
                <>
                    <option value={AllUSer[us].username}>{AllUSer[us].username}</option>
                </>
            )
        }
    }

    const Template = ResponseAllTemplate(fetchData)
    let TemplateHolder = [];
    if (Template !== true && typeof Template !== "boolean" && Template?.length > 0) {
        for (let us = 0; us < Template?.length; us++) {
            TemplateHolder.push(
                <>
                    <option value={Template[us].content}>{Template[us].name}</option>
                </>
            )
        }
    }

    const switchTemplate = (e:any) => {

        setValue(e.target.value)
    }


    let DataHolder = [];
    const navigate = useNavigate();

    const reloadRow = (e:any) => {
        setpageDelimiter(parseInt(e.target.value));
        setstartPage(0)
        setEndPage(parseInt(e.target.value))
        setactive(1)
        setFetchData(Date.now())
    }

    let colorset = [
        {color:'#2EA02E'},
        {color:'#bfbfc4'},
        {color:'#6f80d1'},
        {color:'#f2f299'},
        {color:'#eca453'},
        {color:'#e9a89b'}
    ]

    let colorsetState = [
        {color:'#2EA02E'},
        {color:'#001ca4'},
        {color:'#fcfc40'},
        {color:'#ff8800'},
        {color:'#f82c00'},
        {color:'#14b814'}
    ]

    const handlesubmit = (e:any) => {
        const form = e.currentTarget
        e.preventDefault()
        let Data = init2(form)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+ getToken());

        var raw = JSON.stringify(
            {
                "regard": Data.regard,
                "status_id": Data.status_id,
                "member": Data.member,
                "plugin": 'System',
                "description": Data.description,
                "due_on": Data.due_on,
                "support_type_id":Data.support_type,
                "priority": Data.priority,
                 "did": Data.did,
                "instanz": Data.instanz,
                "responsibility": Data.responsibility,
                "responsibilityID": SearchUserID(Data.responsibility),
                "creator": localStorage.getItem('username'),
                "lastupdatefrom": localStorage.getItem('username'),
                "created_at": new Date().toISOString().replace('T',' ').split('.')[0]
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        setValue('');
        fetch(ApiURL() +"yoptimize/new/ticket/", requestOptions)
            .then(response => response.text())
            .then(result => {successForm(result)})
            .catch(error => errorForm(error));
    };

    const Closesubmit = (id:any) => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+ getToken());

        var raw = JSON.stringify(
            {
                "member": localStorage.getItem('username'),
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        setValue('');
        fetch(ApiURL() +"yoptimize/ticket/"+id+"/close/", requestOptions)
            .then(response => response.text())
            .then(result => {successForm(result)})
            .catch(error => errorForm(error));
    };

    const ResponsibilitySubmit = (id:any) => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+ getToken());

        var raw = JSON.stringify(
            {
                "member": localStorage.getItem('username'),
                "responsibilityID": SearchUserID(localStorage.getItem('username')),
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        setValue('');
        fetch(ApiURL() +"yoptimize/ticket/"+id+"/responsibility/", requestOptions)
            .then(response => response.text())
            .then(result => {successForm(result)})
            .catch(error => errorForm(error));
    };

    let PrioEditHolder:any = []
    const setterEditor = (id:any,id2:any) => {
        (document.getElementById(id) as HTMLDivElement).style.display = 'block';
        (document.getElementById(id2) as HTMLDivElement).style.display = 'none'
    }


    const setEditorContent = (value:any,textarea?:any) =>{
        setValue(value)
        if(textarea !== undefined) {
            (document.getElementById('descriptionTextarea') as HTMLTextAreaElement).value = value
        }
    }

    const CloseDynmaikModal = () => {
        handleClose();
    }

    const successForm = (result:any) => {
        setFetchData(Date.now())
        setValue('')
        handleClose()
    }

    const errorForm = (error:any)=> {
        handleClose()
    }

    const handlesubmitEdit = (e:any) => {
        const form = e.currentTarget
        e.preventDefault()
        let Data = init2(form)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+ getToken());

        var raw = JSON.stringify(
            {
                "id": Data.id,
                "regard": Data.regard,
                "status_id": Data.status_id,
                "description": Data.description,
                "due_on": Data.due_on,
                "did": Data.did,
                "priority": Data.priority,
                "instanz": Data.instanz,
                "responsibility": Data.responsibility,
                "responsibilityID": SearchUserID(Data.responsibility),
                "support_type_id":Data.support_type,
                "lastupdatefrom": localStorage.getItem('username')
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        setValue('');
        fetch(ApiURL() +"yoptimize/save/ticket/", requestOptions)
            .then(response => response.text())
            .then(result => {successForm(result)})
            .catch(error => errorForm(error));
    };
    const [stop, setStop] = useState(false);




    let mapInstanz = ["t2_telephone","t2_email","t2_website","t2_other"]

    if (typeof response !== "boolean" && response?.length > 0) {

        let PluginHolder = [];

        let DataSort = (clearFilter === true ? response : fetchSortData);

        for(let pl=0;pl<response?.length;pl++) {
            PluginHolder.push(response[pl].plugin)
        }
        const removeDuplicates = (array:any) => {
            const uniqueSet = new Set(array);
            return Array.from(uniqueSet);
        };
        PluginHolder = removeDuplicates(PluginHolder);

        let OptionPlugin = [];

        for(let plh=0;plh<PluginHolder.length;plh++){
            OptionPlugin.push(
                <option value={PluginHolder[plh] as string}>{PluginHolder[plh] as string}</option>
            )
        }


        for (let r = startpage; r < (endpage > DataSort.length ? DataSort.length : endpage);r++){
            let Data = DataSort[r];

            DataHolder.push(
                <tr onDoubleClick={() => navigate('/ticket/single/ticketsystem/' + Data.id)}>
                    <td>{
                        dateFormat(
                            Data.created_at,
                            localStorage.getItem('locale'),
                            'us',
                            true,
                            false,
                            Store
                        )
                    }</td>
                    <td>{Data.creator}</td>
                    <td>{Data.plugin}</td>
                    <td>{Data.regard}</td>
                    <td>
                        <span className="badge " style={{
                            background: (Data.priority !== null ? colorset[Data.priority].color : colorset[0].color),
                            color: (colorSwitcher((Data.priority !== null ? colorset[Data.priority].color : colorset[0].color), false) ? 'black' : 'white')
                        }}>
                            {(Data.priority !== null ? IntlMessageValue('de', 'inbox.priority.' + Data.ticket_prioritaeten.name, Store) : 'Low')}
                        </span>
                    </td>
                    <td>
                        <span className="badge rounded-pill" style={{
                            background: (Data.status_id !== null ? colorsetState[Data.status_id].color : colorsetState[0].color),
                            color: (colorSwitcher((Data.status_id !== null ? colorsetState[Data.status_id].color : colorsetState[0].color), false) ? 'black' : 'white')
                        }}>
                            {IntlMessageValue('de', 'inbox.status.' + Data.status.state, Store)}
                        </span>
                    </td>
                    <td><IntlMessage messageId={Data.instanz} Store={Store}/></td>
                    <td>{(Data.support_type_id !== null ? <><IntlMessage messageId={"t2." + Data.ticket_type.type}
                                                                         Store={Store}/></> : '')}</td>
                    <td>{
                        dateFormat(
                            Data.due_on,
                            localStorage.getItem('locale'),
                            'us',
                            false,
                            false,
                            Store
                        )
                    }</td>
                    <td>{(Data.responsibility !== null ?
                        <>

                            <Avatar alt={Data.responsibility} title={Data.responsibility}
                                    src={'data:image/png;base64,' + Data.media}
                                    className={'cardAvatarMini mb-3'}>{AvatarName(Data.responsibility)}</Avatar>

                        </>
                        : <></>)}</td>
                    <td style={{width: '20px'}}>
                        <NavDropdown
                            title={
                                <span className="material-icons">
                            more_horiz
                        </span>
                            }
                            id='collasible-nav-dropdown_dashboard_projects'>
                            <NavDropdown.Item onClick={() => navigate('/ticket/single/ticketsystem/' + Data.id)}>
                                {IntlMessageValue('de', 'inbox.open.ticket', Store)}
                            </NavDropdown.Item>
                            <NavDropdown.Item href={'/ticket/single/ticketsystem/' + Data.id} target={'_blank'}>
                                {IntlMessageValue('de', 'inbox.open.newtab.ticket', Store)}
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">
                                <UniOffCanvas saveButtonLabel={'save'}
                                              ButtonLabel={IntlMessageValue('de', 'inbox.edit.ticket', Store)}
                                              CloseButtonLabel={IntlMessageValue('de', 'common.close', Store)} field={
                                    <>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text
                                                id="basic-addon1">{IntlMessageValue('de', 'common.title', Store)}</InputGroup.Text>
                                            <Form.Control name={'regard'} type="title" defaultValue={Data.regard}/>
                                            <Form.Control name={'id'} type="hidden" defaultValue={Data.id}/>
                                        </InputGroup>
                                        <Row>
                                            <Col className={'col-6'}>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text
                                                        id="basic-addon1">{IntlMessageValue('de', 'common.status', Store)}</InputGroup.Text>
                                                    <Form.Select aria-label="Default select example" name={'status_id'}
                                                                 defaultValue={Data.status_id}>
                                                        <option
                                                            value="1">{IntlMessageValue('de', 'inbox.status.Open', Store)}</option>
                                                        <option
                                                            value="2">{IntlMessageValue('de', 'inbox.status.In Progress', Store)}</option>
                                                        <option
                                                            value="3">{IntlMessageValue('de', 'inbox.status.Pending', Store)}</option>
                                                        <option
                                                            value="5">{IntlMessageValue('de', 'inbox.status.Resolve', Store)}</option>
                                                    </Form.Select>
                                                </InputGroup>
                                            </Col>
                                            <Col className={'col-6'}>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text
                                                        id="basic-addon1">{IntlMessageValue('de', 'inbox.Support-Typ', Store)}</InputGroup.Text>
                                                    <Form.Select aria-label="Default select example"
                                                                 name={'support_type'}
                                                                 defaultValue={Data.support_type_id}
                                                                 required={true}>
                                                        <option
                                                            label={IntlMessageValue('de', 'inbox.Support-Typ.select', Store)}
                                                            value=""></option>
                                                        <option value="2"><IntlMessage messageId={"t2.Bug"}
                                                                                       Store={Store}/></option>
                                                        <option value="4"><IntlMessage
                                                            messageId={"t2.Fehlende Funktion"} Store={Store}/></option>
                                                        <option value="6"><IntlMessage messageId={"t2.Verbesserung"}
                                                                                       Store={Store}/></option>
                                                        <option value="7"><IntlMessage
                                                            messageId={"t2.Individuelle Anpassung"} Store={Store}/>
                                                        </option>
                                                        <option value="8"><IntlMessage messageId={"t2.Anwenderfrage"}
                                                                                       Store={Store}/></option>
                                                        <option value="9"><IntlMessage
                                                            messageId={"t2.Dokumentationsanfrage"}
                                                            Store={Store}/></option>
                                                        <option value="10"><IntlMessage messageId={"t2.Hardwarefrage"}
                                                                                        Store={Store}/></option>
                                                        <option value="11"><IntlMessage
                                                            messageId={"t2.Sicherheitsprobleme"}
                                                            Store={Store}/></option>
                                                        <option value="12"><IntlMessage
                                                            messageId={"t2.Leistungsprobleme"}
                                                            Store={Store}/></option>
                                                        <option value="13"><IntlMessage messageId={"t2.Kompatibilität"}
                                                                                        Store={Store}/></option>
                                                        <option value="14"><IntlMessage
                                                            messageId={"t2.Berechtigungsprobleme"}
                                                            Store={Store}/></option>
                                                        <option value="15"><IntlMessage messageId={"t2.Feedback"}
                                                                                        Store={Store}/></option>
                                                        <option value="16"><IntlMessage
                                                            messageId={"t2.Produktvorschläge"}
                                                            Store={Store}/></option>
                                                        <option value="17"><IntlMessage
                                                            messageId={"t2.source.Allgemeine Anfrage"}
                                                            Store={Store}/></option>
                                                    </Form.Select>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className={'col-6'}>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1"><IntlMessage
                                                        messageId="common.ticket.dueOn"
                                                        Store={Store}/></InputGroup.Text>

                                                    <Form.Control type="date" name={'due_on'}
                                                                  defaultValue={Data.due_on}/>

                                                </InputGroup>
                                            </Col>

                                            <Col className={'col-6'}>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text
                                                        id="basic-addon1">{IntlMessageValue('de', 'common.priority', Store)}</InputGroup.Text>
                                                    <Form.Select aria-label="Default select example" name={'priority'}
                                                                 defaultValue={Data.priority}
                                                                 required={true}>
                                                        <option
                                                            label={IntlMessageValue('de', 'inbox.priority.select', Store)}
                                                            value=""></option>
                                                        <option
                                                            value="1">{IntlMessageValue('de', 'inbox.priority.Lowest', Store)}</option>
                                                        <option
                                                            value="2">{IntlMessageValue('de', 'inbox.priority.Low', Store)}</option>
                                                        <option
                                                            value="3">{IntlMessageValue('de', 'inbox.priority.Medium', Store)}</option>
                                                        <option
                                                            value="4">{IntlMessageValue('de', 'inbox.priority.High', Store)}</option>
                                                        <option
                                                            value="5">{IntlMessageValue('de', 'inbox.priority.Urgent', Store)}</option>
                                                    </Form.Select>
                                                </InputGroup>

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className={'col-6'}>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1"><IntlMessage
                                                        messageId={"t2.responsibility"}
                                                        Store={Store}/></InputGroup.Text>
                                                    <Form.Select aria-label="Default select example"
                                                                 name={'responsibility'}
                                                                 defaultValue={Data.responsibility}
                                                                 required={true}>
                                                        <option label="Responsibility select" value=""></option>
                                                        {UserHolder}
                                                    </Form.Select>
                                                </InputGroup>
                                            </Col>
                                            <Col className={'col-6'}>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1"><IntlMessage
                                                        messageId={"t2.source"} Store={Store}/></InputGroup.Text>
                                                    <Form.Select aria-label="Default select example" name={'instanz'}
                                                                 disabled={(mapInstanz.includes(Data.instanz) === true ? false : true)}
                                                                 defaultValue={Data.instanz}
                                                                 required={true}>
                                                        <option
                                                            label={(mapInstanz.includes(Data.instanz) === true ? "Origin select" : Data.instanz)}
                                                            value=""></option>
                                                        <option value="t2_telephone"><IntlMessage
                                                            messageId="t2_telephone" Store={Store}/></option>
                                                        <option value="t2_email"><IntlMessage messageId="t2_email"
                                                                                              Store={Store}/></option>
                                                        <option value="t2_website"><IntlMessage messageId="t2_website"
                                                                                                Store={Store}/></option>
                                                        <option value="t2_other"><IntlMessage messageId="t2_other"
                                                                                              Store={Store}/></option>
                                                    </Form.Select>
                                                </InputGroup>

                                            </Col>
                                        </Row>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text
                                                id="basic-addon1">{IntlMessageValue('de', 'common.department', Store)}</InputGroup.Text>
                                            <Form.Select aria-label="Default select example" name={'did'}
                                                         onChange={(e) => FilterResponse(response, setFetchSortData, setFetchData, 'status_id', e, filter, setfilter, true, setClearFilter)}

                                                         defaultValue={Data.did}>
                                                <option label={IntlMessageValue('de', 'common.filter.all', Store)}/>
                                                {DepartmentHoldder}
                                                <option value={'null'}>Nicht zugewiesen</option>
                                            </Form.Select>
                                        </InputGroup>
                                        <div id={'htmleditor'} style={{display: 'block'}}>
                                            <SunEditor
                                                key={'test'}

                                                name={'description'}
                                                setContents={Data.description}
                                                setOptions={{
                                                    buttonList: SunEditorConfig(),
                                                    attributesWhitelist: {
                                                        div: 'id',
                                                        p: 'id',
                                                        h: 'id'
                                                    }
                                                }}
                                                height={'450px'}
                                            />
                                        </div>
                                        <br/>
                                    </>
                                } headline={'Ticket bearbeiten #' + Data.id} handlesubmit={handlesubmitEdit}/>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => ResponsibilitySubmit(Data.id)}>
                                {IntlMessageValue('de', 'inbox.Assign_ticket_to_me', Store)}
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => Closesubmit(Data.id)}>
                                {IntlMessageValue('de', 'inbox.close_and_archive_ticket', Store)}
                            </NavDropdown.Item>
                            {/*<NavDropdown.Item href="#action/3.2" style={{background:'#E5E5E5'}}>
                                Task erstellen
                            </NavDropdown.Item>*/}
                        </NavDropdown>
                    </td>
                </tr>
            )

        }

        if(state !== null && stop === false) {
            switch(state.searchFilter) {
                case "priority":
                    FilterResponse(response, setFetchSortData, setFetchData, 'priority', null, filter, setfilter, false, setClearFilter,state.searchParam)
                    setPrioHolder(state.searchParam)
                    break;
                case "state":
                    FilterResponse(response, setFetchSortData, setFetchData, 'status_id', null, filter, setfilter, false, setClearFilter,state.searchParam)
                    setStateHolder(state.searchParam)
                    break;
                default:
                // code block
            }
            setStop(true)
        }

        return (
            <>
                <div className={'ticketsystem'} style={{paddingLeft: '25px', paddingRight: '25px'}}>
                    <h3 className={'mb-5'}>Ticket {refresh(setFetchData, IntlMessageValue('de', 'common.reloadPage', Store))}
                        <DynamikOffCanvas
                            openButtonLabel={<><span className="material-icons" style={{verticalAlign: 'bottom'}}>
                    add
                    </span>
                        {IntlMessageValue('de', 'common.add.new.ticket', Store)}</>}
                    saveButtonLabel={IntlMessageValue('de', 'common.save', Store)}
                    closeButtonLabel={IntlMessageValue('de', 'common.close', Store)}
                    title={IntlMessageValue('de', 'common.add.new.ticket', Store)}
                    ClassOpenButton={'float-end'}
                    ClassSaveButton={'float-end'}
                    body={<>

                        <div className="modal-dialog" style={{marginRight: '-23px'}}>

                        </div>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">{IntlMessageValue('de', 'common.title', Store)}</InputGroup.Text>
                            <Form.Control name={'regard'} type="title"  required={true}/>
                        </InputGroup>
                        <Row>
                            <Col className={'col-6'}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">{IntlMessageValue('de', 'common.status', Store)}</InputGroup.Text>
                                    <Form.Select aria-label="Default select example" name={'status_id'} required={true}>
                                        <option value="1">{IntlMessageValue('de', 'inbox.status.Open', Store)}</option>
                                        <option value="2">{IntlMessageValue('de', 'inbox.status.In Progress', Store)}</option>
                                        <option value="3">{IntlMessageValue('de', 'inbox.status.Pending', Store)}</option>
                                        <option value="5">{IntlMessageValue('de', 'inbox.status.Resolve', Store)}</option>
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                            <Col className={'col-6'}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">{IntlMessageValue('de', 'inbox.Support-Typ', Store)}</InputGroup.Text>
                                    <Form.Select aria-label="Default select example"
                                                 name={'support_type'}
                                                 required={true}>
                                        <option label={IntlMessageValue('de', 'inbox.Support-Typ.select', Store)}
                                                value=""></option>
                                        <option value="2"><IntlMessage messageId={"t2.Bug"} Store={Store}/></option>
                                        <option value="4"><IntlMessage messageId={"t2.Fehlende Funktion"}
                                                                       Store={Store}/></option>
                                        <option value="6"><IntlMessage messageId={"t2.Verbesserung"} Store={Store}/>
                                        </option>
                                        <option value="7"><IntlMessage messageId={"t2.Individuelle Anpassung"}
                                                                       Store={Store}/></option>
                                        <option value="8"><IntlMessage messageId={"t2.Anwenderfrage"}
                                                                       Store={Store}/></option>
                                        <option value="9"><IntlMessage messageId={"t2.Dokumentationsanfrage"}
                                                                       Store={Store}/></option>
                                        <option value="10"><IntlMessage messageId={"t2.Hardwarefrage"}
                                                                       Store={Store}/></option>
                                        <option value="11"><IntlMessage messageId={"t2.Sicherheitsprobleme"}
                                                                       Store={Store}/></option>
                                        <option value="12"><IntlMessage messageId={"t2.Leistungsprobleme"}
                                                                       Store={Store}/></option>
                                        <option value="13"><IntlMessage messageId={"t2.Kompatibilität"}
                                                                       Store={Store}/></option>
                                        <option value="14"><IntlMessage messageId={"t2.Berechtigungsprobleme"}
                                                                       Store={Store}/></option>
                                        <option value="15"><IntlMessage messageId={"t2.Feedback"}
                                                                       Store={Store}/></option>
                                        <option value="16"><IntlMessage messageId={"t2.Produktvorschläge"}
                                                                       Store={Store}/></option>
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={'col-6'}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1"><IntlMessage messageId="common.ticket.dueOn"
                                                                                    Store={Store}/></InputGroup.Text>

                                    <Form.Control type="date" name={'due_on'} required={true}/>

                                </InputGroup>
                            </Col>

                            <Col className={'col-6'}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text
                                        id="basic-addon1">{IntlMessageValue('de', 'common.priority', Store)}</InputGroup.Text>
                                    <Form.Select aria-label="Default select example" name={'priority'}
                                                 required={true}>
                                        <option label={IntlMessageValue('de', 'inbox.priority.select', Store)}
                                                value=""></option>
                                        <option
                                            value="1">{IntlMessageValue('de', 'inbox.priority.Lowest', Store)}</option>
                                        <option value="2">{IntlMessageValue('de', 'inbox.priority.Low', Store)}</option>
                                        <option
                                            value="3">{IntlMessageValue('de', 'inbox.priority.Medium', Store)}</option>
                                        <option
                                            value="4">{IntlMessageValue('de', 'inbox.priority.High', Store)}</option>
                                        <option
                                            value="5">{IntlMessageValue('de', 'inbox.priority.Urgent', Store)}</option>
                                    </Form.Select>
                                </InputGroup>

                            </Col>
                        </Row>
                        <Row>
                            <Col className={'col-6'}>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1"><IntlMessage messageId={"t2.responsibility"} Store={Store} /></InputGroup.Text>
                                <Form.Select aria-label="Default select example" name={'responsibility'}
                                             required={true}>
                                    <option label={IntlMessageValue('de', 'common.responsibility.select', Store)} value=""></option>
                                    {UserHolder}
                                </Form.Select>
                            </InputGroup>
                            </Col>
                            <Col className={'col-6'}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1"><IntlMessage messageId={"t2.source"} Store={Store} /></InputGroup.Text>
                                    <Form.Select aria-label="Default select example" name={'instanz'}
                                                 required={true}>
                                        <option label={IntlMessageValue('de', 't2.source.select', Store)} value=""></option>
                                        <option value="t2_telephone"><IntlMessage messageId="t2_telephone" Store={Store} /></option>
                                        <option value="t2_email"><IntlMessage messageId="t2_email" Store={Store} /></option>
                                        <option value="t2_website"><IntlMessage messageId="t2_website" Store={Store} /></option>
                                        <option value="t2_other"><IntlMessage messageId="t2_other" Store={Store} /></option>
                                    </Form.Select>
                                </InputGroup>

                            </Col>
                        </Row>
                        <Row>
                            <Col><InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">Template</InputGroup.Text>
                                <Form.Select aria-label="Default select example" onChange={(e)=>switchTemplate(e)}>
                                    <option label={IntlMessageValue('de', 'common.no_template', Store)} value=""></option>
                                    {TemplateHolder}
                                </Form.Select>
                            </InputGroup></Col>
                            <Col><InputGroup className="mb-3">
                                <InputGroup.Text
                                    id="basic-addon1">{IntlMessageValue('de', 'common.department', Store)}</InputGroup.Text>
                                <Form.Select aria-label="Default select example" name={'did'}  >
                                    <option label={IntlMessageValue('de', 'common.filter.all', Store)}/>
                                    {DepartmentHoldder}
                                    <option value={'null'}>Nicht zugewiesen</option>
                                </Form.Select>
                            </InputGroup>
                            </Col>
                        </Row>

                        <div id={'htmleditor'} style={{display: 'block'}}>
                            <SunEditor
                                key={'test'}

                                name={'description'}
                                setContents={value}
                                setOptions={{
                                    buttonList: SunEditorConfig(),
                                    attributesWhitelist: {
                                        div: 'id',
                                        p: 'id',
                                        h: 'id'
                                    }
                                }}
                                height={'450px'}
                                onChange={(value) => setEditorContent(value)}
                            />
                        </div>
                        <br/>

                        <Form.Control name={'creator'} type="hidden"
                                      defaultValue={localStorage.getItem('username') as string}/>
                    </>}
                    handlesubmit={handlesubmit}
                    handleShow={handleShow}
                    show={show}
                    id={'TaskNew'}
                    setShow={setShow}
                    handleClose={CloseDynmaikModal}
                />


            </h3>
                <Row>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">{IntlMessageValue('de', 'inbox.subject', Store)}</InputGroup.Text>
                            <Form.Control aria-describedby="basic-addon1"
                                          onChange={(e) => FilterResponse(response, setFetchSortData, setFetchData, 'regard', e, filter, setfilter,false,setClearFilter)}/>
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">{IntlMessageValue('de', 'inbox.application', Store)}</InputGroup.Text>
                            <Form.Select aria-label="Default select example"
                                         onChange={(e) => FilterResponse(response, setFetchSortData, setFetchData, 'plugin', e, filter, setfilter,false,setClearFilter)}
                                         defaultValue={''}>
                                <option label={IntlMessageValue('de', 'common.filter.all', Store)}
                                        value=""></option>
                                {OptionPlugin}
                            </Form.Select>
                           {/* <Form.Control aria-describedby="basic-addon1"
                                          onChange={(e) => FilterResponse(response, setFetchSortData, setFetchData, 'plugin', e, filter, setfilter,false,setClearFilter)}/>*/}
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">{IntlMessageValue('de', 'inbox.priority', Store)}</InputGroup.Text>
                            <Form.Select aria-label="Default select example"
                                         onChange={(e) => FilterResponse(response, setFetchSortData, setFetchData, 'priority', e, filter, setfilter, false, setClearFilter)}
                                         defaultValue={prioHolder} >
                                <option label={IntlMessageValue('de', 'common.filter.all', Store)}
                                        value=""></option>
                                <option value="1">{IntlMessageValue('de', 'inbox.priority.Lowest', Store)}</option>
                                <option value="2">{IntlMessageValue('de', 'inbox.priority.Low', Store)}</option>
                                <option value="3">{IntlMessageValue('de', 'inbox.priority.Medium', Store)}</option>
                                <option value="4">{IntlMessageValue('de', 'inbox.priority.High', Store)}</option>
                                <option value="5">{IntlMessageValue('de', 'inbox.priority.Urgent', Store)}</option>
                            </Form.Select>
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Text
                                id="basic-addon1">{IntlMessageValue('de', 'common.status', Store)}</InputGroup.Text>
                            <Form.Select aria-label="Default select example"
                                         onChange={(e) => FilterResponse(response, setFetchSortData, setFetchData, 'status_id', e, filter, setfilter, true, setClearFilter)}
                                         defaultValue={stateHolder}>
                                <option label={IntlMessageValue('de', 'common.filter.all', Store)}
                                        value=""></option>
                                <option value="1">{IntlMessageValue('de', 'inbox.status.Open', Store)}</option>
                                <option value="2">{IntlMessageValue('de', 'inbox.status.In Progress', Store)}</option>
                                <option value="3">{IntlMessageValue('de', 'inbox.status.Pending', Store)}</option>
                                <option value="5">{IntlMessageValue('de', 'inbox.status.Resolve', Store)}</option>
                            </Form.Select>
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Text
                                id="basic-addon1">{IntlMessageValue('de', 'common.department', Store)}</InputGroup.Text>
                            <Form.Select aria-label="Default select example"
                                         onChange={(e) => FilterResponse(response, setFetchSortData, setFetchData, 'status_id', e, filter, setfilter, true, setClearFilter)}
                                         defaultValue={stateHolder}>
                                <option label={IntlMessageValue('de', 'common.filter.all', Store)}/>
                                {DepartmentHoldder}
                                <option value={'null'}>Nicht zugewiesen</option>
                            </Form.Select>
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Text
                                id="basic-addon1">{IntlMessageValue('de', 'ticket.filter.responsibility', Store)}</InputGroup.Text>
                            <Form.Select aria-label="Default select example"
                                         onChange={(e) => FilterResponse(response, setFetchSortData, setFetchData, 'responsibility', e, filter, setfilter,false,setClearFilter)}>
                                <option label={IntlMessageValue('de', 'common.filter.all', Store)} />
                                {UserHolder}
                                <option value={'null'}>Nicht zugewiesen</option>
                            </Form.Select>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className={'text-center mb-3'}>
                        <ButtonGroup aria-label="Basic example">
                            <Button variant="secondary"  onClick={()=>setArchiv('0')}>
                    <span className="material-icons" style={{verticalAlign:'bottom'}}>
                        table_chart
                    </span>
                                Tickets
                            </Button>
                            <Button variant="secondary"  onClick={()=>setArchiv('1')}>
                    <span className="material-icons" style={{verticalAlign:'bottom'}}>
                        archive
                    </span>
                                Archiv
                            </Button>

                        </ButtonGroup>
                    </Col>
                </Row>

            <Table hover >
                <thead>
                <tr style={{background: '#F3F3F3', height: '60px'}}>
                    <th style={{background: '#F3F3F3', verticalAlign: 'middle', width: '10%'}}><IntlMessage
                        messageId="inbox.date" Store={Store}/><br/>
                        <span className="material-icons" style={{verticalAlign: 'middle'}}
                              onClick={() => SortOnly('asc', 'string', 'created_at', DataSort, DataSort, setFetchSortData, setFetchData, setClearFilter)}>
                    expand_less
                    </span>
                        <span className="material-icons" style={{verticalAlign: 'middle'}}
                              onClick={() => SortOnly('desc', 'string', 'created_at', DataSort, DataSort, setFetchSortData, setFetchData, setClearFilter)}>
                    expand_more
                    </span>
                    </th>
                    <th style={{background: '#F3F3F3', verticalAlign: 'middle', width: '10%'}}><IntlMessage
                        messageId="inbox.sender" Store={Store}/><br/>
                        <span className="material-icons" style={{verticalAlign: 'middle'}}
                              onClick={() => SortOnly('asc', 'string', 'creator', DataSort, DataSort, setFetchSortData, setFetchData, setClearFilter)}>
                    expand_less
                    </span>
                        <span className="material-icons" style={{verticalAlign: 'middle'}}
                              onClick={() => SortOnly('desc', 'string', 'creator', DataSort, DataSort, setFetchSortData, setFetchData, setClearFilter)}>
                    expand_more
                    </span>
                    </th>
                    <th style={{background: '#F3F3F3', verticalAlign: 'middle', width: '10%'}}><IntlMessage
                        messageId="inbox.application" Store={Store}/><br/>
                        <span className="material-icons" style={{verticalAlign: 'middle'}}
                              onClick={() => SortOnly('asc', 'string', 'plugin', DataSort, DataSort, setFetchSortData, setFetchData, setClearFilter)}>
                    expand_less
                    </span>
                        <span className="material-icons" style={{verticalAlign: 'middle'}}
                              onClick={() => SortOnly('desc', 'string', 'plugin', DataSort, DataSort, setFetchSortData, setFetchData, setClearFilter)}>
                    expand_more
                    </span>
                    </th>
                    <th style={{background: '#F3F3F3', verticalAlign: 'middle', width: '15%'}}><IntlMessage
                        messageId="inbox.subject" Store={Store}/><br/>
                        <span className="material-icons" style={{verticalAlign: 'middle'}}
                              onClick={() => SortOnly('asc', 'string', 'regard', DataSort, DataSort, setFetchSortData, setFetchData, setClearFilter)}>
                    expand_less
                    </span>
                        <span className="material-icons" style={{verticalAlign: 'middle'}}
                              onClick={() => SortOnly('desc', 'string', 'regard', DataSort, DataSort, setFetchSortData, setFetchData, setClearFilter)}>
                    expand_more
                    </span>
                    </th>
                    <th style={{background: '#F3F3F3', verticalAlign: 'middle', width: '10%'}}><IntlMessage
                        messageId="inbox.priority" Store={Store}/><br/>
                        <span className="material-icons" style={{verticalAlign: 'middle'}}
                              onClick={() => SortOnly('asc', 'integer', 'priority', DataSort, DataSort, setFetchSortData, setFetchData, setClearFilter)}>
                    expand_less
                    </span>
                        <span className="material-icons" style={{verticalAlign: 'middle'}}
                              onClick={() => SortOnly('desc', 'integer', 'priority', DataSort, DataSort, setFetchSortData, setFetchData, setClearFilter)}>
                    expand_more
                    </span>
                    </th>
                    <th style={{background: '#F3F3F3', verticalAlign: 'middle', width: '10%'}}><IntlMessage
                        messageId="inbox.status" Store={Store}/><br/>
                        <span className="material-icons" style={{verticalAlign: 'middle'}}
                              onClick={() => SortOnly('asc', 'integer', 'status_id', DataSort, DataSort, setFetchSortData, setFetchData, setClearFilter)}>
                    expand_less
                    </span>
                        <span className="material-icons" style={{verticalAlign: 'middle'}}
                              onClick={() => SortOnly('desc', 'integer', 'status_id', DataSort, DataSort, setFetchSortData, setFetchData, setClearFilter)}>
                    expand_more
                    </span>
                    </th>
                    <th style={{background: '#F3F3F3', verticalAlign: 'middle', width: '10%'}}><IntlMessage
                        messageId="inbox.media" Store={Store}/><br/>
                        <span className="material-icons" style={{verticalAlign: 'middle'}}
                              onClick={() => SortOnly('asc', 'string', 'instanz', DataSort, DataSort, setFetchSortData, setFetchData, setClearFilter)}>
                    expand_less
                    </span>
                        <span className="material-icons" style={{verticalAlign: 'middle'}}
                              onClick={() => SortOnly('desc', 'string', 'instanz', DataSort, DataSort, setFetchSortData, setFetchData, setClearFilter)}>
                    expand_more
                    </span>
                    </th>
                    <th style={{background: '#F3F3F3', verticalAlign: 'middle', width: '10%'}}>Support-Type<br/>
                        <span className="material-icons" style={{verticalAlign: 'middle'}}
                              onClick={() => SortOnly('asc', 'integer', 'support_type_id', DataSort, DataSort, setFetchSortData, setFetchData, setClearFilter)}>
                    expand_less
                    </span>
                        <span className="material-icons" style={{verticalAlign: 'middle'}}
                              onClick={() => SortOnly('desc', 'integer', 'support_type_id', DataSort, DataSort, setFetchSortData, setFetchData, setClearFilter)}>
                    expand_more
                    </span>
                    </th>
                    <th style={{background: '#F3F3F3', verticalAlign: 'middle', width: '10%'}}><IntlMessage
                        messageId="ticket.button.duedate" Store={Store}/><br/>
                        <span className="material-icons" style={{verticalAlign: 'middle'}}
                              onClick={() => SortOnly('asc', 'string', 'due_on', DataSort, DataSort, setFetchSortData, setFetchData, setClearFilter)}>
                    expand_less
                    </span>
                        <span className="material-icons" style={{verticalAlign: 'middle'}}
                              onClick={() => SortOnly('desc', 'string', 'due_on', DataSort, DataSort, setFetchSortData, setFetchData, setClearFilter)}>
                    expand_more
                    </span>
                    </th>
                    <th style={{background: '#F3F3F3', verticalAlign: 'middle', width: '10%'}}>
                            <span className="material-icons">
                                person
                            </span><br/>
                        <span className="material-icons" style={{verticalAlign: 'middle'}}
                              onClick={() => SortOnly('asc', 'string', 'responsibility', DataSort, DataSort, setFetchSortData, setFetchData, setClearFilter)}>
                    expand_less
                    </span>
                        <span className="material-icons" style={{verticalAlign: 'middle'}}
                              onClick={() => SortOnly('desc', 'string', 'responsibility', DataSort, DataSort, setFetchSortData, setFetchData, setClearFilter)}>
                    expand_more
                    </span>
                    </th>
                    <th style={{background: '#F3F3F3', verticalAlign: 'middle', width: '3%'}}/>
                </tr>
                </thead>
                <tbody>
                {DataHolder}

                </tbody>
            </Table>
                    <div className={'float-start'}>
                        <Form.Select style={{width: '70px'}} className={'float-start'} onChange={(e) => reloadRow(e)}>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={40}>40</option>
                        <option value={50}>50</option>
                    </Form.Select>
                    <span className={'float-start p-2'}><IntlMessage messageId="inbox.rowperpage" Store={Store} /></span>
                </div>

            <PaginationYoptimize lenght={(clearFilter === true ? response.length : fetchSortData.length)} setstartPage={setstartPage} setEndPage={setEndPage} active={active} setactive={setactive} pageDelimiter={pageDelimiter} setFetchData={setFetchData} />
            <br style={{clear:'both'}}/>
            </div>
        </>
    )
} else {
    return(
        <>
            <div className={'ticketsystem'} style={{paddingLeft: '25px',paddingRight: '25px'}}>
                <h3 className={'mb-5'}>{IntlMessageValue('de', 'inbox.headline', Store)}  {refresh(setFetchData)}
                    <DynamikOffCanvas
                        openButtonLabel={<><span className="material-icons" style={{verticalAlign: 'bottom'}}>
                    add
                    </span>
                            {IntlMessageValue('de', 'common.add.new.ticket', Store)}</>}
                        saveButtonLabel={IntlMessageValue('de', 'common.save', Store)}
                        closeButtonLabel={IntlMessageValue('de', 'common.close', Store)}
                        title={'Create new ticket'}
                        ClassOpenButton={'float-end'}
                        ClassSaveButton={'float-end'}
                        body={<>

                            <div className="modal-dialog" style={{marginRight: '-23px'}}>

                            </div>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">Title</InputGroup.Text>
                                <Form.Control name={'regard'} type="title"  />
                            </InputGroup>
                            <Row>
                                <Col className={'col-6'}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">Status</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" name={'status_id'}>
                                            <option value="1">Open</option>
                                            <option value="2">In Progress</option>
                                            <option value="3">Pending</option>
                                            <option value="5">Resolve</option>
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                                <Col className={'col-6'}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">Support-Typ</InputGroup.Text>
                                        <Form.Select aria-label="Default select example"
                                                     name={'support_type'}
                                                     required={true}>
                                            <option label="Support-Typ select" value=""></option>
                                            <option value="2"><IntlMessage messageId={"t2.Bug"} Store={Store}/></option>
                                            <option value="4"><IntlMessage messageId={"t2.Fehlende Funktion"}
                                                                           Store={Store}/></option>
                                            <option value="6"><IntlMessage messageId={"t2.Verbesserung"} Store={Store}/>
                                            </option>
                                            <option value="7"><IntlMessage messageId={"t2.Individuelle Anpassung"}
                                                                           Store={Store}/></option>
                                            <option value="8"><IntlMessage messageId={"t2.Anwenderfrage"}
                                                                           Store={Store}/></option>
                                            <option value="9"><IntlMessage messageId={"t2.Dokumentationsanfrage"}
                                                                           Store={Store}/></option>
                                            <option value="10"><IntlMessage messageId={"t2.Hardwarefrage"}
                                                                            Store={Store}/></option>
                                            <option value="11"><IntlMessage messageId={"t2.Sicherheitsprobleme"}
                                                                            Store={Store}/></option>
                                            <option value="12"><IntlMessage messageId={"t2.Leistungsprobleme"}
                                                                            Store={Store}/></option>
                                            <option value="13"><IntlMessage messageId={"t2.Kompatibilität"}
                                                                            Store={Store}/></option>
                                            <option value="14"><IntlMessage messageId={"t2.Berechtigungsprobleme"}
                                                                            Store={Store}/></option>
                                            <option value="15"><IntlMessage messageId={"t2.Feedback"}
                                                                            Store={Store}/></option>
                                            <option value="16"><IntlMessage messageId={"t2.Produktvorschläge"}
                                                                            Store={Store}/></option>
                                            <option value="17"><IntlMessage
                                                messageId={"t2.source.Allgemeine Anfrage"}
                                                Store={Store}/></option>
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={'col-6'}>
                                <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1"><IntlMessage messageId="common.ticket.dueOn"
                                                                                        Store={Store}/></InputGroup.Text>

                                        <Form.Control type="date" name={'due_on'}/>

                                    </InputGroup>
                                </Col>

                                <Col className={'col-6'}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">Priority</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" name={'priority'}
                                                     required={true}>
                                            <option label="Priority select" value=""></option>
                                            <option value="1">Lowest</option>
                                            <option value="2">Low</option>
                                            <option value="3">Medium</option>
                                            <option value="4">High</option>
                                            <option value="5">Urgent</option>
                                        </Form.Select>
                                    </InputGroup>

                                </Col>
                            </Row>
                            <Row>
                                <Col className={'col-6'}>
                                <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1"><IntlMessage messageId={"t2.responsibility"} Store={Store} /></InputGroup.Text>
                                        <Form.Select aria-label="Default select example" name={'responsibility'}
                                                     required={true}>
                                            <option label="Responsibility select" value=""></option>
                                            {UserHolder}
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                                <Col className={'col-6'}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1"><IntlMessage messageId={"t2.source"} Store={Store} /></InputGroup.Text>
                                        <Form.Select aria-label="Default select example" name={'instanz'}
                                                     required={true}>
                                            <option label="Origin select" value=""></option>
                                            <option value="t2_telephone"><IntlMessage messageId="t2_telephone" Store={Store} /></option>
                                            <option value="t2_email"><IntlMessage messageId="t2_email" Store={Store} /></option>
                                            <option value="t2_website"><IntlMessage messageId="t2_website" Store={Store} /></option>
                                            <option value="t2_other"><IntlMessage messageId="t2_other" Store={Store} /></option>
                                        </Form.Select>
                                    </InputGroup>

                                </Col>
                            </Row>
                            <div id={'htmleditor'} style={{display: 'block'}}>
                                <SunEditor
                                    key={'test'}

                                    name={'description'}
                                    setContents={value}
                                    setOptions={{
                                        buttonList: SunEditorConfig(),
                                        attributesWhitelist: {
                                            div: 'id',
                                            p: 'id',
                                            h: 'id'
                                        }
                                    }}
                                    height={'450px'}
                                    onChange={(value) => setEditorContent(value)}
                                />
                            </div>
                            <br/>

                            <Form.Control name={'creator'} type="hidden"
                                          defaultValue={localStorage.getItem('username') as string}/>
                        </>}
                        handlesubmit={handlesubmit}
                        handleShow={handleShow}
                        show={show}
                        id={'TaskNew'}
                        setShow={setShow}
                        handleClose={CloseDynmaikModal}
                    />
                </h3>

                <Row>
                    <Col className={'text-center mb-3'}>
                        <ButtonGroup aria-label="Basic example">
                            <Button variant="secondary"  onClick={()=>setArchiv('0')}>
                    <span className="material-icons" style={{verticalAlign:'bottom'}}>
                        table_chart
                    </span>
                                Tickets
                            </Button>
                            <Button variant="secondary"  onClick={()=>setArchiv('1')}>
                    <span className="material-icons" style={{verticalAlign:'bottom'}}>
                        archive
                    </span>
                                Archiv
                            </Button>

                        </ButtonGroup>
                    </Col>
                </Row>
                <Table hover >
                    <thead>
                    <tr style={{background: '#F3F3F3', height: '60px'}}>
                        <th style={{background: '#F3F3F3', verticalAlign: 'middle', width: '10%'}}><IntlMessage
                            messageId="inbox.date" Store={Store}/></th>
                        <th style={{background: '#F3F3F3', verticalAlign: 'middle', width: '10%'}}><IntlMessage
                            messageId="inbox.sender" Store={Store}/></th>
                        <th style={{background: '#F3F3F3', verticalAlign: 'middle', width: '10%'}}><IntlMessage
                            messageId="inbox.application" Store={Store}/></th>
                        <th style={{background: '#F3F3F3', verticalAlign: 'middle', width: '27%'}}><IntlMessage
                            messageId="inbox.subject" Store={Store}/></th>
                        <th style={{background: '#F3F3F3', verticalAlign: 'middle', width: '10%'}}><IntlMessage
                            messageId="inbox.priority" Store={Store}/></th>
                        <th style={{background: '#F3F3F3', verticalAlign: 'middle', width: '10%'}}><IntlMessage
                            messageId="inbox.status" Store={Store}/></th>
                        <th style={{background: '#F3F3F3', verticalAlign: 'middle', width: '10%'}}><IntlMessage
                            messageId="inbox.media" Store={Store}/></th>
                        <th style={{background: '#F3F3F3', verticalAlign: 'middle', width: '10%'}}>Support-Type</th>
                        <th style={{background: '#F3F3F3', verticalAlign: 'middle', width: '10%'}}><IntlMessage
                            messageId="ticket.button.duedate" Store={Store}/></th>
                        <th style={{background: '#F3F3F3', verticalAlign: 'middle', width: '3%'}}>
                            <span className="material-icons">
                                person
                            </span>
                        </th>
                        <th style={{background: '#F3F3F3', verticalAlign: 'middle', width: '3%'}}/>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    </tbody>
                </Table>
                <SpinnerJSX messageId={'common.loading'} class={'spinnerSS timeoutSpinner'} />
                <br style={{clear:'both'}}/>
            </div>

        </>
    )
    }
}


export default Inbox;

export const AvatarName = (name:any) => {
    let Name = '';

    if(name === null || name === undefined) {
        return;
    }
    let Hold = name.split(' ');

    for(let x=0;x<Hold.length;x++) {
        Name = Name + Hold[x].charAt(0)
    }
    return Name;
}

export const SortOnly = (sortmode:any,sorttype:any,part:any,responseOld:any,response:any,setFetchSortData:any,setFetchData:any,setClearFilter:any) => {
    let Data = (response.length === 0 ? responseOld : response);

    let Sortback;

    if(sortmode === 'asc') {
        Sortback = Data.sort(function(a:any, b:any) {
            switch (sorttype) {
                case 'string':
                    if(part.includes('.') === true) {
                        return (a[part.split('.')[0]][part.split('.')[1]] > b[part.split('.')[0]][part.split('.')[1]]) ? 1 : ((a[part.split('.')[0]][part.split('.')[1]] < b[part.split('.')[0]][part.split('.')[1]]) ? -1 : 0)
                    } else {
                        return (a[part] > b[part]) ? 1 : ((a[part] < b[part]) ? -1 : 0)
                    }

                case 'date':
                    if(part.includes('.') === true) {
                        return new Date(a[part.split('.')[0]][part.split('.')[1]]).getTime() - new Date(b[part.split('.')[0]][part.split('.')[1]]).getTime()
                    } else {
                        return new Date(a[part]).getTime() - new Date(b[part]).getTime()
                    }
                case 'due_on':
                    if(part.includes('.') === true) {
                        return new Date(a[part.split('.')[0]][part.split('.')[1]]).getTime() - new Date(b[part.split('.')[0]][part.split('.')[1]]).getTime()
                    } else {
                        return new Date(a[part]).getTime() - new Date(b[part]).getTime()
                    }
                case 'integer':
                    if(part.includes('.') === true) {
                        return  a[part.split('.')[0]][part.split('.')[1]] - b[part.split('.')[0]][part.split('.')[1]]
                    } else {
                        return  a[part] - b[part]
                    }

            }
        });
    } else if(sortmode === 'desc') {
        Sortback = Data.sort(function(a:any, b:any) {
            switch (sorttype) {
                case 'string':
                    if(part.includes('.') === true) {
                        return (b[part.split('.')[0]][part.split('.')[1]] > a[part.split('.')[0]][part.split('.')[1]]) ? 1 : ((b[part.split('.')[0]][part.split('.')[1]] < a[part.split('.')[0]][part.split('.')[1]]) ? -1 : 0)
                    } else {
                        return (b[part] > a[part]) ? 1 : ((b[part] < a[part]) ? -1 : 0)
                    }

                case 'date':
                    if(part.includes('.') === true) {
                        return new Date(b[part.split('.')[0]][part.split('.')[1]]).getTime() - new Date(a[part.split('.')[0]][part.split('.')[1]]).getTime()
                    } else {
                        return new Date(b[part.split('.')[0]][part.split('.')[1]]).getTime() - new Date(a[part.split('.')[0]][part.split('.')[1]]).getTime()
                    }
                case 'due_on':
                    if(part.includes('.') === true) {
                        return new Date(a[part.split('.')[0]][part.split('.')[1]]).getTime() - new Date(b[part.split('.')[0]][part.split('.')[1]]).getTime()
                    } else {
                        return new Date(a[part]).getTime() - new Date(b[part]).getTime()
                    }
                case 'integer':
                    if(part.includes('.') === true) {
                        return  b[part.split('.')[0]][part.split('.')[1]] - a[part.split('.')[0]][part.split('.')[1]]
                    }else{
                        return  b[part] - a[part]
                    }

            }
        });
    }
    setClearFilter(false)
    setFetchSortData(Sortback)
    setFetchData(Date.now())

    return Sortback;

}
