import '../css/worktimetracking.css'
import React, {useEffect, useState} from "react";
import {setNavi} from "../../../@WUM/core/Function/index";
const WorkTimeTrackingOverview = (props:{value:any}) => {
    setNavi({
        route: '/support/software/overview/'
    })


    return (
        <>

        </>
    )
}
export default WorkTimeTrackingOverview;
