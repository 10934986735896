import {ApiRoot} from "../../../RestFullApi/api";

interface SingleTaskFetchPRops {
    url:string,
    ticketid:string  | undefined,
    lock:"0"|"1",
    lockby:string | null
    mode: "add" | "remove"
    setFetchData?:any
}
export const SingleTicketFetch = (props:SingleTaskFetchPRops) => {

    if(props.mode === 'add') {
        localStorage.setItem('BlockedRouteTicket',JSON.stringify({url:props.url,ticketid:props.ticketid}))
    } else if(props.mode === 'remove') {
        localStorage.removeItem('BlockedRouteTicket')
    }


    var raw = JSON.stringify(
        {
            lock:props.lock,
            lockby:props.lockby
        })

    var requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization:
                'Bearer ' +
                JSON.parse(localStorage.getItem('token') as string)
        },
        body: raw
    }

    fetch(ApiRoot()+"yoptimize/locked/Ticket/"+props.ticketid+"/", requestOptions)
        .then(response => response.text())
        .then(result => (props.setFetchData !== undefined ? props.setFetchData(Date.now()) :console.log(result)))
        .catch(error => console.log('error', error));


}
