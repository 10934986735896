import ProgressBar from 'react-bootstrap/ProgressBar';
import UniCards from "../Cards";
import MIcon from '../MIcon';
import {UniRow} from "../index";
import UniCol from "../Col";
import React from 'react'
interface StatsProps{
    item:item[]
    preText:string,
    afterText:string,
    validText:string,
    replaceName:string,
    validAfterName:string,
    validIcon:string,
    ValidIconvariant?: 'fill' | '-outlined' | '-rounded' | '-sharp' | undefined
}

interface item{
    title:string,
    percent:number,
    check:boolean
}

const Stats = (props:StatsProps) => {


    let PercentHolder = 0;

    let setValid = false;
    let ValideHolder = <></>

    for(let x=0;x<props.item.length;x++){
        if(props.item[x].check === true) {
            PercentHolder = PercentHolder + props.item[x].percent;
        } else {
            if(setValid === false) {
                ValideHolder = <>
                    <p dangerouslySetInnerHTML={{__html: props.validText}}/>
                    <UniCards class={'border-danger'}>
                        <UniCards.Body>
                            <UniCards.Text>
                                <UniRow>
                                    <UniCol class={'col-2'}> <MIcon newMaterial={true} children={props.validIcon}
                                                                    variant={props.ValidIconvariant}/></UniCol>
                                    <UniCol> <span dangerouslySetInnerHTML={{__html:props.item[x].title}} /> <span dangerouslySetInnerHTML={{__html:props.validAfterName}} />
                                    </UniCol>
                                    <UniCol class={'col-3'}>+ {props.item[x].percent}%</UniCol>
                                </UniRow>

                            </UniCards.Text>
                        </UniCards.Body>
                    </UniCards>
                </>
            }
        }
    }


    return (
        <>
        <hr/>
            <p dangerouslySetInnerHTML={{__html: props.preText.replace(props.replaceName,''+PercentHolder)}} />
            <ProgressBar now={PercentHolder} label={`${PercentHolder}%`} visuallyHidden /><br/>
            <p dangerouslySetInnerHTML={{__html: props.afterText}} />
            {ValideHolder}

        </>
    )
}

export default Stats;
