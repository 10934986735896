import Card from 'react-bootstrap/Card';
import React,{CSSProperties, ElementType, JSXElementConstructor, ReactElement, ReactNode, ReactPortal} from "react";
import {UniCardsBody} from "./Elements/UniCardsBody";
import {UniCardsFooter} from "./Elements/UniCardsFooter";
import {UniCardsHeader} from "./Elements/UniCardsHeader";
import {UniCardsImg} from "./Elements/UniCardsImg";
import {UniCardsImgOverlay} from "./Elements/UniCardsImgOverlay";
import {UniCardsLink} from "./Elements/UniCardsLink";
import {UniCardsSubtitle} from "./Elements/UniCardsSubtitle";
import {UniCardsText} from "./Elements/UniCardsText";
import {UniCardsTitle} from "./Elements/UniCardsTitle";
import {UniCardsGroup} from "./Elements/UniCardsGroup";
import {UniForm} from "../index";

export interface UniCardsProps {
    /** Allows it to have children and have them reflected. */
    children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined,
    bg?:'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light'
    text?:'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light' | 'white' | 'muted'
    border?:'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light'
    body?:boolean
    /** You can use a custom element type for this component. */
    as?:ElementType<any> | undefined
    /** Set custom Classes on Element */
    class?:string
    /** Set id on Element in DOM */
    id?: string,
    /** Set inline style for Element */
    style?:CSSProperties | undefined
}

const UniCards = (props:UniCardsProps) => {

    return(
        <>
            <Card id={props.id}  className={props.class} body={props.body} border={props.border} as={props.as} text={props.text} bg={props.bg} style={props.style}>
                {props.children}
            </Card>
        </>
    )
}

UniCards.Body = UniCardsBody
UniCards.Footer = UniCardsFooter
UniCards.Header = UniCardsHeader
UniCards.Img = UniCardsImg
UniCards.ImgOverlay = UniCardsImgOverlay
UniCards.Link = UniCardsLink
UniCards.Subtitle = UniCardsSubtitle
UniCards.Text = UniCardsText
UniCards.Title = UniCardsTitle
UniCards.Group = UniCardsGroup

export default UniCards;
