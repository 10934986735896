import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import {ResponseAllTimesbyUserSheets} from "../API/response/worktimetracking";
import {useState} from "react";
import Form from 'react-bootstrap/Form';
import {dateFormat, IntlMessageValue} from "../../../@WUM/core/Elements/index";
import {useSelector} from "react-redux";
import Button from 'react-bootstrap/Button';
const TimeSheet = (props:{value:any}) => {
    const Store = useSelector((state: any) => state)
    const [fetchData, setFetchData] = useState(0);
    let NowDate = new Date()
    let today = NowDate.getFullYear() + '-'+(NowDate.getMonth()+1)+'-'+NowDate.getDate()
    const [date, setDate] = useState(today);

    const setData = (dateChange:any) => {
        setDate(dateChange)
        setFetchData(Date.now())
    }

    const response = ResponseAllTimesbyUserSheets(localStorage.getItem('userid'),date,fetchData)
    let DataHolder = [];
    let alltime = 0;
    let allbreak = 0;

    const calculateTime = (d1:any) => {
        const startTime = new Date('2012/10/09 '+d1).getTime();
        // Differenz berechnen
        var diff = d1;
        // Werte berechnen
        var tag = Math.floor(diff / (1000*60*60*24));
        diff = diff % (1000*60*60*24);
        var std = Math.floor(diff / (1000*60*60));
        diff = diff % (1000*60*60);
        var min = Math.floor(diff / (1000*60));
        diff = diff % (1000*60);
        var sec = Math.floor(diff / 1000);

        return std.toString().padStart(2, '0')+":"+min.toString().padStart(2, '0')  + ":" + sec.toString().padStart(2, '0');

    };

    if (typeof response !== "boolean" && response?.length > 0) {
        for (let x = 0; x < response.length; x++) {
            let dataSet = response[x]
            if (dataSet.grund === 'Arbeit' || dataSet.grund === 'Urlaub') {
                alltime = alltime + dataSet.dauer * 1000
            }
            if (dataSet.grund === 'Pause'){
                allbreak = allbreak + dataSet.dauer * 1000
            }
            DataHolder.push(
                <>
                    <tr>
                        <td>{IntlMessageValue('de','wtt.'+dataSet.grund,Store)}</td>
                        <td>{dataSet.time_start}</td>
                        <td>{dataSet.time_end}</td>
                        <td>{calculateTime(dataSet.dauer * 1000)}</td>
                    </tr>
                </>
            )
        }
    }

    return(
        <>
            <div id={'timeSheets'} className={'ticketsystem'} style={{paddingLeft: '25px',paddingRight: '25px'}}>
                <Card >
                    <Card.Body className={'pb-0'}>
            <h3>{IntlMessageValue('de','wtt.timesheet.headline',Store)}
                <Button variant="secondary" className="float-end ml-3"  id={'printButton'} onClick={()=>window.print()}>
                    <span className="material-icons" style={{verticalAlign:'bottom'}}>
                        print
                    </span>
                </Button>
                <Form.Group className="mb-3 float-end" controlId="exampleForm.ControlInput1" id={'ChangeDateWTM'}>
                    <Form.Control type="date" placeholder="name@example.com" defaultValue={date} onChange={(e)=>setData(e.target.value)}/>
                </Form.Group>

            </h3>
            <table>
                <tr>
                    <td width={80}>Name</td>
                    <td>{localStorage.getItem('first_name')} {localStorage.getItem('last_name')}</td>
                </tr>
                <tr>
                    <td>{IntlMessageValue('de','common.date',Store)}</td>
                    <td>{
                        dateFormat(
                            date,
                            localStorage.getItem('locale'),
                            'us',
                            false,
                            false,
                           Store
                        )}</td>
                </tr>
            </table>
                <br/>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>{IntlMessageValue('de','wtt.table.reason',Store)}</th>
                    <th>{IntlMessageValue('de','wtt.table.from',Store)}</th>
                    <th>{IntlMessageValue('de','wtt.table.to',Store)}</th>
                    <th>{IntlMessageValue('de','wtt.time',Store)}</th>
                </tr>
                </thead>
                <tbody>
                {DataHolder}
                </tbody>
                <tfoot>
                <tr>
                    <td colSpan={3} className={'text-right'}><strong>{IntlMessageValue('de','wtt.break.time',Store)}</strong></td>
                    <td>{calculateTime(allbreak)}</td>
                </tr><tr>
                    <td colSpan={3} className={'text-right'}><strong>{IntlMessageValue('de','wtt.work.time',Store)}</strong></td>
                    <td>{calculateTime(alltime)}</td>
                </tr>
                <tr>
                    <td colSpan={3} className={'text-right'}><strong>{IntlMessageValue('de','wtt.time',Store)}</strong></td>
                    <td>{calculateTime(alltime+allbreak)}</td>
                </tr>
                </tfoot>
            </Table>
                        <br style={{clear:'both'}} />
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}

export default TimeSheet;
