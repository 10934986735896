import React from "react";
import Profil from './Pages/overview'

export const ProfilConfig = (value: any) => {

    return [
        {
            path: '/profil/overview/:page',
            elements: <Profil value={value}/>,
            roles: ['superadmin', 'admin',
                'ticketsystem',
                'worktime',
                'teamtakt']
        }
    ]
}

export const ProfilMenuConfig = (value: any) => {
    return [

        {
            name: 'dashboard.yourAccount',
            route: '/profil/overview/1',
            icon: 'person',
            roles: ['superadmin', 'admin',
                'ticketsystem',
                'worktime',
                'teamtakt']

        }
    ]
}

export const ProfilSettings = () => [
    {
        title: 'Support Ticket',
        href: '/support/software/overview/1',
        undertitle: 'Software-Support',
        icon: 'manage_accounts',
        text: 'Software-Support',
        roles: ['superadmin', 'admin',
            'ticketsystem',
            'worktime',
            'teamtakt']
    }
]
