import {useEffect, useState} from "react";
import {error} from "../../../../../@WUM/core/Elements/index";
import {registerApiType} from "../request/registerApiType";
import {register} from "../request/register";
import {checkLogin} from "../../../../../@WUM/templates/default/Auth/service/AuthService";

export const Responseregister = (fetchData:any) => {
    const [posts, setPosts] = useState<registerApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await register
                .getPosts()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [fetchData])

    return isError ? isError : posts
}
