import {useEffect, useState} from "react";
import {error} from "../../../Elements/index";
import {favo} from "../request/favo";
import {favoApiType} from "../request/favo.interface";

export const ResponseAllTimes = (user:any,fetchData:any) => {
    const [posts, setPosts] = useState<favoApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await favo
                .getPosts(user)
                .then((data) => {
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [user,fetchData])

    return isError ? isError : posts
}
