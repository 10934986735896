import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Avatar from "@material-ui/core/Avatar";
import InputGroup from 'react-bootstrap/InputGroup';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import NavDropdown from 'react-bootstrap/NavDropdown';
import React, {useEffect, useRef, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {
    ResponseAllCPS, ResponseAllLabels,
    ResponseAllLastActivities, ResponseAllPrio,
    ResponseAllProject,
    ResponseAllTaskInternall,
    ResponseAllTaskState,
    ResponseAllTaskSwimelanes,
    ResponseAllTResponsibility,
    ResponseSingleProject,
    ResponseTaskDetail
} from "../../../API/response/ticket";
import PielersForm from '../../../../../@WUM/core/form-serilizer'
import Button from 'react-bootstrap/Button';
import ReactDOM from "react-dom";

import { SpinnerJSX, IntlMessage, IntlMessageValue } from '../../../../../@WUM/core/Elements/index'
import {useDispatch, useSelector} from "react-redux";
import SunEditorConfig from '../../components/SuneditorConfig'
import SunEditor from 'suneditor-react'
import {ApiRoot} from "../../../../../@WUM/core/RestFullApi/api";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Markdown from 'react-markdown'
import MDEditor from "@uiw/react-md-editor";
import MarkdownEditor from '@uiw/react-markdown-editor';
import remarkGfm from 'remark-gfm'
/* @ts-ignore */
import mermaid from "mermaid";

/* @ts-ignore */
import Gluejar from 'react-gluejar'
import {notify_bucket, notify_save} from "../../../../../@WUM/core/component/const/notification.service";
import '../css/timeline.css'
import {Search} from "./MyProjects";
import {dateFormat, error} from "../../../../../@WUM/core/Elements/index";
import {AppDispatch} from "../../../../../store";
import * as actionSingleTask from "../store/Actions/Singletask/SingleTaskAction";
import Auth, {
    FetchwithMattermostANDsuccessForm,
    FetchwithMattermostANDsuccessFormWithClose, FetchwithMattermostANDsuccessFormWithClosedivIdandTrue,
    FetchwithMattermostOnlysetFetchData
} from "../components/Mattermost";
import SendMessage from "../components/Mattermost";
import {AddedColor, colorSwitcher, savearchiv, UserAvatar} from "./MyTask";
import {
    Accordion as ReactAccordion,
    AccordionItem as ReactAccordionItem,
    AccordionItemHeading as ReactAccordionItemHeading,
    AccordionItemButton as ReactAccordionItemButton,
    AccordionItemPanel as ReactAccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import {checkLocation} from "../../../../../@WUM/core/sidebar/ItemMenu";
import {setNavi} from "../../../../../@WUM/core/Function/index";
import {AvatarName} from "../components/CreateProjectCard";
import {LockScreen} from "../../../../../@WUM/core/Elements";
import {SingleTaskFetch} from "../../../../../@WUM/core/Elements/CheckRoute/Fetch/SingleTaskFetch";
import {CryptDecrypt} from "../../../../../@WUM/core/Elements/crypt/Crypt";
import UpgradeInfoBox from "../../../../../@WUM/core/upgrade";
const SingleTask = (props:{value:any}) => {
    const { id,taskid } = useParams<{ id: string,taskid:string }>()
    const [showActions, setShowActions] = useState(true);
    const Store = useSelector((state: any) => state)
    const location = useLocation();
    let ProjectId;
    let nosearch;
    if(process.env.REACT_APP_MODE_TICKET_ID !== undefined && process.env.REACT_APP_MODE_TICKET_ID !== null
        && process.env.REACT_APP_MODE_TICKET_NAME !== undefined && process.env.REACT_APP_MODE_TICKET_NAME !== null) {
        ProjectId = process.env.REACT_APP_MODE_TICKET_ID;
        nosearch = false;
    } else {
        ProjectId = id;
        nosearch = true;
    }

    setNavi({
        route:'/myprojects/task/single/'
    })
    const dispatch: AppDispatch = useDispatch()
    const [fetchData, setFetchData] = useState(0);
    const AllCPS = ResponseAllCPS(fetchData);
    let ColorHolder:any = [];
    if (typeof AllCPS !== "boolean" && AllCPS?.length > 0) {
        for (let c = 0; c < AllCPS.length; c++) {
            ColorHolder.push({color:AllCPS[c].color,title:AllCPS[c].title})
        }
    }
    const Prio = ResponseAllPrio(fetchData)
    let PrioHolder = [];
    let PrioEditHolder:any = []
    let regex = /[A-Z]/ig;
    if (typeof Prio !== "boolean" && Prio?.length > 0) {
        for (let c = 0; c <Prio.length; c++) {

            PrioEditHolder.push(
                <option value={Prio[c].name.replace(regex,'')}>{Prio[c].name}</option>
            )

            PrioHolder.push(
                <>
                    <div className="agile-row table-border-left" style={{background: Prio[c].color}}>
                        <div className="table-list-details agile-table-list-title">
                            <strong>{Prio[c].name}</strong>
                        </div>
                        <div className="table-list-details agile-table-list-details p-0 m-0 text-center">
                            <ul className="p-0 m-0">
                                <li className="">{Prio[c].underline}</li>
                            </ul>
                        </div>
                    </div>
                </>
            )
        }
    }
    const [value, setValue] = useState("");
    const [commentEdit, setCommentEdit] = useState( "0");
    const response = ResponseTaskDetail(ProjectId,taskid,fetchData,setValue,commentEdit,dispatch)
    const LastActivities = ResponseAllLastActivities(ProjectId,taskid,fetchData)
    const [fetchDataID, setFetchDataID] = useState(ProjectId);
    const FetchTask = ResponseAllTaskInternall(fetchDataID)
    const AllState = ResponseAllTaskState(fetchDataID);
    let AllUSer = ResponseAllTResponsibility(fetchData)
    /* @ts-ignore */
    let UserHolder:any = [];
    let StateHolder = [];
    let StackBadgeHolder = [];
    const navigate = useNavigate();

    const SearchUserID = (username:any) => {
        if (AllUSer !== true && typeof AllUSer !== "boolean" && AllUSer?.length > 0) {
            for (let us = 0; us < AllUSer?.length; us++) {
                if(username as string === AllUSer[us].username) {
                    return AllUSer[us].id
                }
            }
        }
        return 0;
    }


    if (AllUSer !== true && typeof AllUSer !== "boolean" && AllUSer?.length > 0) {
        for (let us = 0; us < AllUSer?.length; us++) {
            UserHolder.push(
                <>
                    <option value={AllUSer[us].username}>{AllUSer[us].username}</option>
                </>
            )
        }
    }

    if (AllState !== true && typeof AllState !== "boolean" && AllState?.length > 0) {
        for (let us = 0; us < AllState?.length; us++) {
            StateHolder.push(
                <>
                    <option value={AllState[us].id}>{AllState[us].state}</option>
                </>
            )
        }
    }

    const [swimlaneID, setSwimlaneID] = useState('0');


    const setSwim = (e:any) => {
        setSwimlaneID(e.target.value)
        setFetchData(Date.now())
    }
    let Swimelanes = ResponseAllTaskSwimelanes(swimlaneID,fetchData)

    const ALLProject =
        process.env.REACT_APP_MODE_TICKET_ID !== undefined && process.env.REACT_APP_MODE_TICKET_ID !== null
        && process.env.REACT_APP_MODE_TICKET_NAME !== undefined && process.env.REACT_APP_MODE_TICKET_NAME !== null ?
        [] :
    ResponseAllProject(fetchData);

    const [showCloseTask, setShowCloseTask] = useState(false);
    const [LoadDuplicate, setLoadDuplicate] = useState(false);
    const handleCloseCloseTask = () => setShowCloseTask(false);
    const handleShowCloseTask = () => setShowCloseTask(true);

    let BuildComments = [];
    let BuildAttachtment = [];
    let BuildExternalConnections = [];
    let BuildInternalConnections = [];
    let BuildSubTask = [];
    let BuildLastActivities = [];

    if (typeof LastActivities !== "boolean" && LastActivities?.length > 0) {
        if(localStorage.getItem('userRights') !== null && localStorage.getItem('userRights')!== undefined && JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string).taskmanagerTaskLog === 0) {
            BuildLastActivities.push(
                <>
                    <li  style={{padding:'10px'}}>
                        <UpgradeInfoBox Store={Store} />
                    </li>

                </>
            )
        } else {
        for (let la = 0; la < LastActivities.length; la++) {



            if(LastActivities[la].system === '9') {
                BuildLastActivities.push(
                    <>
                        <li className="timeline-item mb-5" style={{padding:'10px'}}>
                            <h5 className="fw-bold"><Avatar className={'float-start'} alt={LastActivities[la].member} src={UserAvatar(AllUSer,'',LastActivities[la].member)} />
                                <span className={'float-start'} style={{paddingTop:'5px',paddingLeft:'5px'}}><small>{LastActivities[la].member}</small></span>
                                <span className={'float-end pr-3 btn btn-info'} style={{cursor:'default',background:'#23AAE1',color:'white'}}><small><strong>Extern</strong></small></span>
                                <Button variant="primary" className={"pr-3 float-end"} style={{padding:'5px',background:'white',borderColor:'#23AAE1',color:'#23AAE1'}}>
                                    <span className="material-icons" style={{verticalAlign:'middle',marginLeft:'13px'}} onClick={()=>navigate('/myprojects/task/single/'+LastActivities[la].project_id+'/'+LastActivities[la].task_id)}>
                                    logout
                                </span></Button>
                            </h5>
                            <br style={{clear:'both'}}/>
                            <p className="text-muted mb-2 fw-bold">{LastActivities[la].regard}<br/><small>{LastActivities[la].created_at}</small></p>
                            <p className="text-muted" dangerouslySetInnerHTML={{__html: LastActivities[la].content}} style={{padding:'15px',borderRadius:'10px',border:'1px solid #E5E5E5'}} />
                        </li>

                    </>
                )
            } else {
                BuildLastActivities.push(
                    <>
                        <li className="timeline-item mb-5">
                            <h5 className="fw-bold"><Avatar className={'float-start'} alt={LastActivities[la].member} src={UserAvatar(AllUSer,'',LastActivities[la].member)}>
                                {AvatarName(LastActivities[la].member)}
                            </Avatar><span className={'float-start'} style={{paddingTop:'5px',paddingLeft:'5px'}}><small>{LastActivities[la].member}</small></span>
                                <Button variant="primary" className={"pr-3 float-end"} style={{padding:'5px',background:'white',borderColor:'#23AAE1',color:'#23AAE1'}}>
                                    <span className="material-icons" style={{verticalAlign:'middle',marginLeft:'13px'}} onClick={()=>navigate('/myprojects/task/single/'+LastActivities[la].project_id+'/'+LastActivities[la].task_id)}>
                                    logout
                                </span></Button> </h5>
                            <br style={{clear:'both'}}/>
                            <p className="text-muted mb-2 fw-bold">{LastActivities[la].regard}<br/><small>{LastActivities[la].created_at}</small></p>
                            <p className="text-muted" dangerouslySetInnerHTML={{__html: LastActivities[la].content}} style={{padding:'15px',borderRadius:'10px',border:'1px solid #E5E5E5'}} />
                        </li>

                    </>
                )
            }

            }
        }
    }

    const deleteLabel = (Lid:any,name:any,color:any) => {

        var requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
        };

        FetchwithMattermostOnlysetFetchData(
            {
                endpoint:'yoptimize/delete/label/'+Lid+'/',
                requestOptions:requestOptions,
                setFetchData:setFetchData,
                preText: '## Label wurde gelöscht :label:\n',
                text:'[Projekt: ***'+(response !== true && typeof response !== "boolean" && response?.length > 0 ? response[0].project.title : '')+'***]('+window.location.origin+'/myprojects/task/all/projects/'+id+'/system) | [Ticket ***'+(response !== true && typeof response !== "boolean" && response?.length > 0 ? response[0].regard : '')+'***]('+window.location.origin+'/myprojects/task/single/'+id+'/'+taskid+')\n \n:adult: '+localStorage.getItem('username') +' \n Label ***'+name+'*** mit der Farbe `'+color+'`wurde gelöscht.'
            }
        )

    }

    if (typeof response !== "boolean" && response?.length > 0) {





        for (let x = 0; x < response.length; x++) {
            if(response[x].support_label !== undefined && response[x].support_label !== null) {
                for (let sl = 0; sl < response[x].support_label.length; sl++) {

                    StackBadgeHolder.push(
                        <span className="badge rounded-pill"
                              style={{background: response[x].support_label[sl].color,fontSize:'16px',color:( colorSwitcher(response[x].support_label[sl].color,false) === false ? 'white' : 'black')}}>{response[x].support_label[sl].name}
                            <span className="material-icons" style={{fontSize:'16px',verticalAlign:'bottom',cursor:'pointer'}} onClick={()=>deleteLabel(response[x].support_label[sl].id,response[x].support_label[sl].name,response[x].support_label[sl].color)}>
                                delete
                            </span>
                        </span>
                    )
                }
            }
            const send = (id:any,data:any,Cid:any,created:any) => {
                setCommentEdit(id)
                AddedFunc3(EditComment,{comment:data,cid:Cid,created:created})

            }

            if(response[x].Comments !== undefined && response[x].Comments !== null) {
            let CommentsHolder = [];

            for (let r = response[x].Comments.length - 1; r > -1; r--) {
                CommentsHolder.push(response[x].Comments[r])
            }
            let Comments = CommentsHolder
            for (let c = 0; c < Comments.length; c++) {


                let kurzel = ''

                for (let k = 0; k < Comments[c].member.split(' ').length; k++) {
                    kurzel = kurzel + Comments[c].member.split(' ')[k].charAt(0).toLocaleUpperCase()
                }



                BuildComments.push(
                    <>
                        <Card className={'mb-5'}>
                            <Card.Header><Avatar alt="Remy Sharp" src={UserAvatar(AllUSer,Comments[c].memberId)}
                                                 className={'float-start'}>{kurzel}</Avatar>
                                <p className={'float-start'} style={{marginTop: '0.5rem', marginLeft: '1rem'}}>
                                    <small><strong>{Comments[c].member}</strong> </small>
                                    <small>Erstellt am: {Comments[c].created_at} </small></p>

                                {Comments[c].member === localStorage.getItem('username')
                                ?
                                    <NavDropdown title={
                                        <>
                                    <span className="material-icons" style={{marginTop: '0.5rem'}}>
                                                settings
                                            </span>
                                        </>
                                    } id="nav-dropdown" className={'float-end'}>
                                        <NavDropdown.Item onClick={(e) => send(c,Comments[c].comment,Comments[c].id,Comments[c].created_at)}>{IntlMessageValue('','common.edit',Store)}</NavDropdown.Item>
                                    </NavDropdown>
                                : <></>
                                }

                                <br style={{clear: 'both'}}/>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    {Comments[c].comment !== null ?
                                        <div
                                            dangerouslySetInnerHTML={{__html: JSON.parse(JSON.stringify(Comments[c].comment.replaceAll(/\\\"/g, '"')))}}/>
                                        : <></>}
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer
                                className="text-muted"><small><strong>{Comments[c].member}</strong> Aktualisiert
                                am: {Comments[c].updatet_at}</small></Card.Footer>
                        </Card>
                    </>
                )
            }
        }
            if(response[x].attachment !== undefined && response[x].attachment !== null) {
                for(let a=0;a<response[x].attachment.length;a++){
                    let AttachHolder = [];
                    let type = response[x].attachment[a].type;
                    switch (true) {
                        case type.includes("image"):
                            AttachHolder.push(<Card.Img className={'img-thumbnail'} variant="top"
                                                        src={response[x].attachment[a].url} style={{
                                width: '250px',
                                height: '105px',
                                objectFit: 'contain'
                            }}/>)
                            break
                        case type.includes("pdf"):
                            AttachHolder.push(<>
                                <Card.Img className={'img-thumbnail'} variant="top"
                                          src="/assets/images/img.png" style={{
                                    width: '250px',
                                    height: '105px',
                                    objectFit: 'contain'
                                }}/>
                                </>
                                )
                            break
                        case type.includes("blob"):
                            AttachHolder.push(<>
                                    <Card.Img className={'img-thumbnail'} variant="top"
                                              src={response[x].attachment[a].url} style={{
                                        width: '250px',
                                        height: '105px',
                                        objectFit: 'contain'
                                    }}/>
                                </>
                            )
                            break
                        case type.includes("video"):
                            AttachHolder.push(<>
                                    <video controls style={{width:'100%',height:'100%'}}>
                                        <source src={response[x].attachment[a].url} type={response[x].attachment[a].type}/>

                                        Your browser does not support the video tag.
                                    </video>

                                </>
                            )
                            break
                        default:
                            AttachHolder.push(<>
                                <Card.Img className={'img-thumbnail'} variant="top"
                                          src="/assets/images/noimage.jpg" style={{
                                    width: '250px',
                                    height: '105px',
                                    objectFit: 'contain'
                                }}/>

                            </>)
                    }
                    BuildAttachtment.push(<>
                            <Card className={'float-start mr-3 mt-3'} style={{width: '250px'}}>
                                <Card.Header style={{overflow:'hidden'}}>
                                    <a target={'new'} href={response[x].attachment[a].url} title={response[x].attachment[a].name}
                                       style={{fontSize: '14.4px'}}>
                                        {response[x].attachment[a].name}
                                    </a><br/>
                                    Datei Type: {type} <br/>
                                </Card.Header>
                                <Card.Body>
                                <a target={'new'} href={response[x].attachment[a].url} title={response[x].attachment[a].name}
                                   style={{fontSize: '14.4px'}}>
                                {AttachHolder}
                                    </a>
                                </Card.Body>
                                <Card.Footer className="text-muted">
                                    <Row>
                                        <Col className={'col-8'}>

                                            <small>{response[x].attachment[a].created_at} <br/>
                                                {response[x].attachment[a].member}</small>

                                        </Col>
                                        <Col className={'col-4'}>
                                            <button type="button" className="btn btn-danger"
                                                    onClick={()=>DeleteAttachment(response[x].attachment[a].url,response[x].attachment[a].id,response[x].attachment[a].name,response,setFetchData,id,taskid)}>
                                        <span className="material-icons">
                                            delete_forever
                                        </span>
                                            </button>
                                        </Col>
                                    </Row>


                                </Card.Footer>
                            </Card>
                        </>
                    )
                }
            } if(response[x].external_Connections !== undefined && response[x].external_Connections !== null) {

                let externalConnectionsHolder = [];

                let ecData = response[x].external_Connections

                for(let ec = 0;ec<ecData.length;ec++) {
                    externalConnectionsHolder.push(
                        <>
                            <tr>
                                <th>{ecData[ec].type}
                                </th>
                                <th><a target={'new'} href={ecData[ec].url as string}>{ecData[ec].title}</a></th>
                                <th>{ecData[ec].dependency}</th>
                                <th>{ecData[ec].creator}</th>
                                <th>{
                                    dateFormat(
                                        ecData[ec].created_at,
                                        localStorage.getItem('locale'),
                                        'us',
                                        true,
                                        false,
                                        Store
                                    )
                                    }
                                    <NavDropdown title={
                                        <>
                                    <span className="material-icons">
                                                settings
                                            </span>
                                        </>
                                    } id="nav-dropdown" className={'float-end'}>
                                        <NavDropdown.Item onClick={(e) => AddedFunc3(EditExternalConnections,{id:ecData[ec].id,url:ecData[ec].url,type:ecData[ec].type,title:ecData[ec].title,dependency:ecData[ec].dependency,creator:ecData[ec].creator,created_at:ecData[ec].created_at})}>{IntlMessageValue('','common.edit',Store)}</NavDropdown.Item>
                                        <NavDropdown.Item onClick={(e) => deleteExternalConnections(ecData[ec].id,CloseMember)}>{IntlMessageValue('','common.delete',Store)}</NavDropdown.Item>
                                    </NavDropdown>
                                </th>
                            </tr>
                        </>
                    )
                }
//

                BuildExternalConnections.push(<>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>{IntlMessageValue('', 'common.type', Store)}</th>
                            <th>{IntlMessageValue('', 'common.title', Store)}</th>
                            <th>{IntlMessageValue('', 'common.dependency', Store)}</th>
                            <th>{IntlMessageValue('', 'common.issue.creator', Store)}</th>
                            <th>{IntlMessageValue('', 'common.date', Store)}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {externalConnectionsHolder}
                        </tbody>
                    </Table>
                </>)
            }

            if(response[x].internal_connections !== undefined && response[x].internal_connections !== null) {

                let internalConnectionsHolder = [];

                let ecData = response[x].internal_connections

                for(let ec = 0;ec<ecData.length;ec++) {
                    internalConnectionsHolder.push(
                        <>
                            <tr>
                                <td>
                                    <Link className={''} to={'/myprojects/task/single/'+ecData[ec].in_project+'/'+ecData[ec].url}>
                                        {ecData[ec].url} {ecData[ec].title}
                                    </Link>
                                </td>
                                <td>{(ecData[ec].responsibility === 'assign a user' ? IntlMessageValue('','common.responsibility.notAssgin',Store) : ecData[ec].responsibility) }</td>
                                <td>{
                                    dateFormat(
                                    ecData[ec].created_at,
                                    localStorage.getItem('locale'),
                                    'us',
                                    true,
                                    false,
                                    Store
                                    )
                                }
                                    <NavDropdown title={
                                        <>
                                    <span className="material-icons">
                                                settings
                                            </span>
                                        </>
                                    } id="nav-dropdown" className={'float-end'}>
                                        <NavDropdown.Item onClick={(e) => deleteInternalConnections(ecData[ec].project_id,ecData[ec].task_id,ecData[ec].url,ecData[ec].title,response[0].regard,ecData[ec].in_project,CloseMember)}>{IntlMessageValue('','common.delete',Store)}</NavDropdown.Item>
                                    </NavDropdown>
                                </td>
                            </tr>
                        </>
                    )
                }


                BuildInternalConnections.push(<>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>{IntlMessageValue('','single.task.internalConnection.table.linkedTo',Store)}</th>
                            <th>{IntlMessageValue('','common.ticket.responsibility',Store)}</th>
                            <th>{IntlMessageValue('','common.date',Store)}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {internalConnectionsHolder}
                        </tbody>
                    </Table>
                </>)
            }

            if(response[x].support_subtask !== undefined && response[x].support_subtask !== null) {

                let SupportSubtaskHolder = [];

                let ecData = response[x].support_subtask

                for(let ec = 0;ec<ecData.length;ec++) {
                    SupportSubtaskHolder.push(
                        <>

                                <tr>
                                    <th><Form.Check
                                        type={'checkbox'}
                                        label={ecData[ec].title}
                                        id={'todo'}
                                        defaultChecked={(ecData[ec].complete !== "0" && ecData[ec].complete !== null  ? true : false)}
                                        className={'float-start'}
                                        onClick={(e)=>editSubtaskCompleted(ecData[ec],e.target)}
                                    /></th>
                                    <th>{(ecData[ec].responsibility === 'assign a user' ? IntlMessageValue('','common.responsibility.notAssgin',Store) : ecData[ec].responsibility) }</th>
                                    <th>{ecData[ec].description}</th>
                                    <th>{
                                        dateFormat(
                                            ecData[ec].due_on.replaceAll('T',' '),
                                            localStorage.getItem('locale'),
                                            'us',
                                            true,
                                            false,
                                            Store
                                        )
                                        }</th>
                                    <th>{
                                        dateFormat(
                                            ecData[ec].created_at,
                                            localStorage.getItem('locale'),
                                            'us',
                                            true,
                                            false,
                                            Store
                                        )
                                        }
                                        <NavDropdown title={
                                            <>
                                    <span className="material-icons">
                                                settings
                                            </span>
                                            </>
                                        } id="nav-dropdown" className={'float-end'}>
                                            <NavDropdown.Item onClick={(e) => AddedFunc3(EditSubTask,{id:ecData[ec].id,title:ecData[ec].title,responsibility:ecData[ec].responsibility,description:ecData[ec].description,due_to:ecData[ec].due_on,created_at:ecData[ec].created_at})}>
                                                {IntlMessageValue('','common.edit',Store)}
                                            </NavDropdown.Item>
                                            <NavDropdown.Item onClick={(e) => deleteSubtask(ecData[ec].id,CloseMember)}> {IntlMessageValue('','common.delete',Store)}</NavDropdown.Item>
                                        </NavDropdown>
                                    </th>
                                </tr>

                        </>
                    )
                }


                BuildSubTask.push(<>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>{IntlMessageValue('','common.title',Store)}</th>
                            <th>{IntlMessageValue('','common.ticket.responsibility',Store)}</th>
                            <th>{IntlMessageValue('','common.description',Store)}</th>
                            <th>{IntlMessageValue('','common.table.dueDate',Store)}</th>
                            <th>{IntlMessageValue('','common.date',Store)}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {SupportSubtaskHolder}
                        </tbody>
                    </Table>
                </>)
            }

        }



    }

    const deleteSubtask = (id:any,close:any) => {

        var raw = JSON.stringify({
            id: id
        })

        var requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        };

        fetch(ApiRoot() + 'yoptimize/delete/subtask/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result, close))
            .catch((error) => errorForm(error, close))

    }

    const editSubtaskCompleted = (data:any,state:any) => {

        let stateField = state as HTMLInputElement



        var raw = JSON.stringify({
            id: (data.id),
            complete: (stateField.checked === true ? 1 : 0),
            task_id: taskid,
            project_id:id,
            title: (data.title),
            responsibility:(data.responsibility),
            description:(data.description),
            member: localStorage.getItem('username')
        });

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+"yoptimize/edit/subtask/", requestOptions)
            .then(response => response.text())
            .then(result =>  SuccesDate())
            .catch(error => console.log('error', error))


    }

    const SuccesDate = () => {
        setFetchData(Date.now())
        notify_save()
    }


    const AddedFunc = (form?:any,footer?:any,mode?:any,modeID?:any) => {



        if(mode !== undefined && mode !== null) {
            let Dialogs = document.getElementById((modeID !== undefined && modeID !== null && modeID !== '' ? modeID :'addDialogInternalConnection')) as HTMLDivElement;
            Dialogs.classList.add("show");
            Dialogs.style.display = 'block';
        } else {
            let Dialog = document.getElementById('addDialogMember') as HTMLDivElement;
            Dialog.classList.add("show");
            Dialog.style.display = 'block';
            if(form !== undefined) {
                let newElement = form
                let temp = document.createElement('div')
                ReactDOM.render(newElement, temp)
                let ele = document.getElementById('injectModalTask') as HTMLDivElement
                if(ele !== null) {
                    ele.innerHTML = ''
                    ele.prepend(temp)
                }
            }
        }
    }

    const AddedFunc3 = (form:any,formParam:any) => {


            let Dialog = document.getElementById('addDialogMember') as HTMLDivElement;
            Dialog.classList.add("show");
            Dialog.style.display = 'block';
            if(form !== undefined) {
                let newElement = form(formParam)
                let temp = document.createElement('div')
                ReactDOM.render(newElement, temp)
                let ele = document.getElementById('injectModalTask') as HTMLDivElement
                if(ele !== null) {
                    ele.innerHTML = ''
                    ele.prepend(temp)
                }
            }
        }


    let EditComment = (comment:any) => (<>

            <div className="modal-dialog">
                <Form
                    id={'form001'}
                    className={'formControl'}
                    noValidate
                    onSubmit={(e) =>
                        editComment(e, CloseMember)
                    }
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title h4">{IntlMessageValue('','single.task.editComment',Store)}</div>
                            <button type="button" className="btn-close" aria-label="Close"
                                    onClick={() => CloseMember()}/>
                        </div>
                        <div className="modal-body" id={'FormBody'}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Control type="hidden" name={'id'} defaultValue={comment.cid} placeholder="name@example.com" />
                                <Form.Control type="text" name={'created_at'} defaultValue={comment.created} placeholder="name@example.com" />
                            </Form.Group>
                            <SunEditor
                                key={'text'}
                                name={'comment'}
                                defaultValue={comment.comment}
                                setOptions={{
                                    buttonList: SunEditorConfig(),
                                    attributesWhitelist: {
                                        div: 'id',
                                        p: 'id',
                                        h: 'id'
                                    }
                                }}
                                height={'350px'}
                            />
                        </div><br/>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary"
                                    onClick={() => CloseMember()}>{IntlMessageValue('','common.close',Store)}
                            </button>
                            <button type="submit" className="btn btn-primary float-end">{IntlMessageValue('','common.save',Store)}</button>
                        </div>
                    </div>
                </Form>
            </div>


        </>)

    let Comment = (<>

            <div className="modal-dialog">
                <Form
                    id={'form001'}
                    className={'formControl'}
                    noValidate
                    onSubmit={(e) =>
                        saveComment(e,CloseMember)
                    }
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <div
                                className="modal-title h4">{IntlMessageValue('', 'single.task.addComment', Store)}</div>
                            <button type="button" className="btn-close" aria-label="Close"
                                    onClick={() => CloseMember()}/>
                        </div>
                        <div className="modal-body" id={'FormBody'}>
                            <SunEditor
                                key={'text'}
                                name={'comment'}
                                setOptions={{
                                    buttonList: SunEditorConfig(),
                                    attributesWhitelist: {
                                        div: 'id',
                                        p: 'id',
                                        h: 'id'
                                    }
                                }}
                                height={'350px'}
                            />
                        </div>
                        <br/>
                        <div className="modal-body" id={'FormBody'}>
                            <Form.Group controlId="formFileMultiple" className="mb-3">
                                <Form.Label>{IntlMessageValue('', 'common.attach.document', Store)}</Form.Label>
                                <Form.Control id={'fileuploadAttachmentComment'} name={'file'} type="file" multiple/>
                            </Form.Group>
                            <input name={'url'} type={'hidden'} defaultValue={''} id={'AttachDocumentUrl'}/>
                            <input name={'name'} type={'hidden'} defaultValue={''} id={'AttachDocumentName'}/>
                            <input name={'type'} type={'hidden'} defaultValue={''} id={'AttachDocumentType'}/>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary"
                                    onClick={() => CloseMember()}>{IntlMessageValue('', 'common.close', Store)}
                            </button>
                            <button type="submit"
                                    className="btn btn-primary float-end">{IntlMessageValue('', 'common.save', Store)}</button>
                        </div>
                    </div>
                </Form>
            </div>


    </>)

    const editComment = (e: any, close: any, divid?: any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            id: formdata.get('id'),
            comment: formdata.get('comment'),
            created_at: formdata.get('created_at'),
            task_id: taskid,
            project_id: id,
            member: localStorage.getItem('username'),
            system: 1
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        FetchwithMattermostANDsuccessFormWithClose(
            {
                endpoint:'yoptimize/edit/comment/',
                requestOptions:requestOptions,
                successForm:successForm,
                errorForm:errorForm,
                close:close,
                preText: '## Kommentar wurde bearbeitet! :mega: \n',
                text:'[Projekt: ***'+(response !== true && typeof response !== "boolean" && response?.length > 0 ? response[0].project.title : '')+'***]('+window.location.origin+'/myprojects/task/all/projects/'+id+'/system) | [Ticket ***'+(response !== true && typeof response !== "boolean" && response?.length > 0 ? response[0].regard : '')+'***]('+window.location.origin+'/myprojects/task/single/'+id+'/'+taskid+')\n \n :adult: '+localStorage.getItem('username') +'\n \n Kommentar mit folgendem Inhalt wurde geändert: \n > '+formdata.get('comment')
            }
        )

    }

    const saveComment = (e:any,close:any,divid?:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            comment: formdata.get('comment'),
            task_id: taskid,
            project_id:id,
            member: localStorage.getItem('username'),
            system:1
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        FetchwithMattermostANDsuccessFormWithClose(
            {
                endpoint:'yoptimize/save/comment/',
                requestOptions:requestOptions,
                successForm:successForm,
                errorForm:errorForm,
                close:close,
                preText: '## Neuer Kommentar erstellt! :mega: \n',
                text:'[Projekt: ***'+(response !== true && typeof response !== "boolean" && response?.length > 0 ? response[0].project.title : '')+'***]('+window.location.origin+'/myprojects/task/all/projects/'+id+'/system) | [Ticket ***'+(response !== true && typeof response !== "boolean" && response?.length > 0 ? response[0].regard : '')+'***]('+window.location.origin+'/myprojects/task/single/'+id+'/'+taskid+')\n \n :adult: '+localStorage.getItem('username') +'\n \n Kommentar mit folgendem Inhalt wurde hinzugefügt: \n > '+formdata.get('comment')
            }
        )

        let formfile = (document.getElementById('fileuploadAttachmentComment') as HTMLInputElement).files

        if(formfile !== null) {
            for (let f = 0; f < formfile?.length; f++) {
                let formDatas = new FormData()
                formDatas.append('customer', process.env.REACT_APP_CDN_CLIENT as string)
                formDatas.append('context', 'pielers_element_text_image')
                // @ts-ignore
                formDatas.append(`file${0}`, formfile[f])
                var requestOptionss = {
                    method: 'POST',
                    body: formDatas
                }
                let link
                if (process.env.REACT_APP_MODE === 'LOCAL') {
                    link = process.env.REACT_APP_CDN_API_URL_LOCAL
                } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
                    link = process.env.REACT_APP_CDN_API_URL_DEVELOPMENT
                } else if (process.env.REACT_APP_MODE === 'LIVE') {
                    link = process.env.REACT_APP_CDN_API_URL_LIVE
                }

                fetch(link + 'upload/', requestOptionss)
                    .then((response) => response.text())
                    .then((result) => {

                        var formdata = new FormData(data)
                        var raw = JSON.stringify({
                            url: JSON.parse(result).cdn_url,
                            name: JSON.parse(result).name,
                            task_id: taskid,
                            project_id:id,
                            member: localStorage.getItem('username'),
                            type:JSON.parse(result).content_type
                        })

                        var requestOptions = {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization:
                                    'Bearer ' +
                                    JSON.parse(localStorage.getItem('token') as string)
                            },
                            body: raw
                        }

                        /*       fetch(ApiRoot()+'yoptimize/save/attachdocument/', requestOptions)
                                   .then((response) => response.text())
                                   .then((result) => successForm(result,close))
                                   .catch((error) => errorForm(error,close))*/
                        FetchwithMattermostANDsuccessFormWithClose(
                            {
                                endpoint:'yoptimize/save/attachdocument/',
                                requestOptions:requestOptions,
                                errorForm:errorForm,
                                successForm: successForm,
                                close:close,
                                preText: '## Attachment wurde hinzugefügt :label:\n',
                                text:'[Projekt: ***'+(response !== true && typeof response !== "boolean" && response?.length > 0 ? response[0].project.title : '')+'***]('+window.location.origin+'/myprojects/task/all/projects/'+id+'/system) | [Ticket ***'+(response !== true && typeof response !== "boolean" && response?.length > 0 ? response[0].regard : '')+'***]('+window.location.origin+'/myprojects/task/single/'+id+'/'+taskid+')\n \n:adult: '+localStorage.getItem('username') +' \n Attachment ***'+JSON.parse(result).name+'*** wurde hinzugefügt.'
                            }
                        )

                    })
                    .catch((e) => {
                        error(e)
                    })
            }
        }

    }
    const successForm = (result:any,close:any,mode?:any,modeID?:any,rewrite?:any) => {
        setFetchData(Date.now())
        notify_save()
        if(mode !== undefined && mode !== null) {
            if(modeID !== undefined && modeID !== null) {
                close(mode,modeID)
            }else{
                close(mode)
            }

        } else {
            close()
        }

        if(rewrite !== undefined && rewrite !== null) {
            navigate(result.url)
        }

    }

    const errorForm = (error:any,close:any,mode?:any,modeID?:any)=> {
        if(mode !== undefined && mode !== null) {
            if(modeID !== undefined && modeID !== null) {
                close(mode,modeID)
            }else{
                close(mode)
            }
        } else {
            close()
        }
    }

    let AttachDocument = (<>

        <div className="modal-dialog">
            <Form
                id={'form001'}
                className={'formControl'}
                noValidate
                onSubmit={(e) =>
                    sendAttachComment(e,CloseMember)
                }
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title h4">{IntlMessageValue('','common.attach.document',Store)}</div>
                        <button type="button" className="btn-close" aria-label="Close"
                                onClick={() => CloseMember()}/>
                    </div>
                    <div className="modal-body" id={'FormBody'}>
                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <Form.Label>{IntlMessageValue('','common.attach.document',Store)}</Form.Label>
                            <Form.Control id={'fileuploadAttachment'} name={'file'} type="file" multiple />
                        </Form.Group>
                        <input name={'url'}  type={'hidden'} defaultValue={''} id={'AttachDocumentUrl'} />
                        <input name={'name'} type={'hidden'} defaultValue={''} id={'AttachDocumentName'} />
                        <input name={'type'} type={'hidden'} defaultValue={''} id={'AttachDocumentType'} />
                    </div><br/>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary"
                                onClick={() => CloseMember()}>{IntlMessageValue('','common.close',Store)}
                        </button>
                        <button type="submit" id={'AttachDocumentSendButton'} className="btn btn-primary float-end" disabled={false}>
                            {IntlMessageValue('','common.save',Store)}
                        </button>
                    </div>
                </div>
            </Form>
        </div>


    </>)



    let sendAttachComment = (e:any,close:any) => {
        e.preventDefault()
        let data = e.currentTarget



        let formfile = (document.getElementById('fileuploadAttachment') as HTMLInputElement).files

        if(formfile !== null) {
            for (let f = 0; f < formfile?.length; f++) {
                let formDatas = new FormData()
         formDatas.append('customer', process.env.REACT_APP_CDN_CLIENT as string)
         formDatas.append('context', 'pielers_element_text_image')
         // @ts-ignore
         formDatas.append(`file${0}`, formfile[f])
         var requestOptions = {
             method: 'POST',
             body: formDatas
         }
         let link
         if (process.env.REACT_APP_MODE === 'LOCAL') {
             link = process.env.REACT_APP_CDN_API_URL_LOCAL
         } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
             link = process.env.REACT_APP_CDN_API_URL_DEVELOPMENT
         } else if (process.env.REACT_APP_MODE === 'LIVE') {
             link = process.env.REACT_APP_CDN_API_URL_LIVE
         }

         fetch(link + 'upload/', requestOptions)
             .then((response) => response.text())
             .then((result) => {

                     var formdata = new FormData(data)
                     var raw = JSON.stringify({
                         url: JSON.parse(result).cdn_url,
                         name: JSON.parse(result).name,
                         task_id: taskid,
                         project_id:id,
                         member: localStorage.getItem('username'),
                         type:JSON.parse(result).content_type
                     })

                     var requestOptions = {
                         method: 'POST',
                         headers: {
                             'Content-Type': 'application/json',
                             Authorization:
                                 'Bearer ' +
                                 JSON.parse(localStorage.getItem('token') as string)
                         },
                         body: raw
                     }

              /*       fetch(ApiRoot()+'yoptimize/save/attachdocument/', requestOptions)
                         .then((response) => response.text())
                         .then((result) => successForm(result,close))
                         .catch((error) => errorForm(error,close))*/
                 FetchwithMattermostANDsuccessFormWithClose(
                     {
                         endpoint:'yoptimize/save/attachdocument/',
                         requestOptions:requestOptions,
                         errorForm:errorForm,
                         successForm: successForm,
                         close:close,
                         preText: '## Attachment wurde hinzugefügt :label:\n',
                         text:'[Projekt: ***'+(response !== true && typeof response !== "boolean" && response?.length > 0 ? response[0].project.title : '')+'***]('+window.location.origin+'/myprojects/task/all/projects/'+id+'/system) | [Ticket ***'+(response !== true && typeof response !== "boolean" && response?.length > 0 ? response[0].regard : '')+'***]('+window.location.origin+'/myprojects/task/single/'+id+'/'+taskid+')\n \n:adult: '+localStorage.getItem('username') +' \n Attachment ***'+JSON.parse(result).name+'*** wurde hinzugefügt.'
                     }
                 )

             })
             .catch((e) => {
                 error(e)
             })
            }
        }
    }


    let addScreenshot = (<>

        <div className="modal-dialog">
            <Form
                id={'form001'}
                className={'formControl'}
                encType={"multipart/form-data"}
                noValidate
                onSubmit={(e) =>
                    sendScreenshot(e,CloseMember)
                }
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title h4">{IntlMessageValue('','single.task.addSreenshot',Store)}</div>
                        <button type="button" className="btn-close" aria-label="Close"
                                onClick={() => CloseMember()}/>
                    </div>
                    <div className="modal-body" id={'FormBody'} style={{height:'400px',overflow:"auto"}}>
                        <input name={'url'} type={'hidden'} id={'ScreenshotUrl'} defaultValue={''} />
                        <input name={'name'} type={'hidden'} id={'ScreenshotName'}  defaultValue={''}/>
                        <input name={'type'} type={'hidden'} id={'ScreenshotType'} defaultValue={''} />
                        <div className={'text-center'} dangerouslySetInnerHTML={{__html: IntlMessageValue('','single.task.copyCombi',Store)}} />
                        {/* @ts-ignore */}
                        <Gluejar onPaste={files => blobToFile(files[0],'Screenshot_aufgenommen_'+new Date().toDateString().toLocaleString().replace(',','').replaceAll(' ','_')+'.png')} errorHandler={err => console.error(err)}>
                            { /* @ts-ignore */
                                images =>
                                images.length > 0 &&
                                /* @ts-ignore */
                                images.map(image => <><img src={image} key={image} alt={`Pasted: ${image}`} style={{width:'100%'}} />


                                </>)
                            }
                        </Gluejar>

                    </div><br/>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary"
                                onClick={() => CloseMember()}>{IntlMessageValue('','common.close',Store)}
                        </button>
                        <button type="submit" id={'ScreenshotSendButton'} className="btn btn-primary float-end" disabled={true}>{IntlMessageValue('','common.save',Store)}</button>
                    </div>
                </div>
            </Form>
        </div>


    </>)

        const blobToFile = (url:any, fileName:string) => {
            const reader = new FileReader();
            var requestOptions = {
                method: 'GET',
            };

            fetch(url, requestOptions)
                .then(response => response.blob())
                .then(result => {
                    let formDatas = new FormData()
                    formDatas.append('customer', process.env.REACT_APP_CDN_CLIENT as string)
                    formDatas.append('context', 'pielers_element_text_image')
                    // @ts-ignore
                    formDatas.append(`file${0}`, result, fileName)
                    var requestOptions = {
                        method: 'POST',
                        body: formDatas
                    }

                    let link
                    if (process.env.REACT_APP_MODE === 'LOCAL') {
                        link = process.env.REACT_APP_CDN_API_URL_LOCAL
                    } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
                        link = process.env.REACT_APP_CDN_API_URL_DEVELOPMENT
                    } else if (process.env.REACT_APP_MODE === 'LIVE') {
                        link = process.env.REACT_APP_CDN_API_URL_LIVE
                    }

                    //let storeimage:any;

                    fetch(link + 'upload/', requestOptions)
                        .then((response) => response.text())
                        .then((result) => {
                            notify_bucket(Store)
                                let eleSourceURL = document.getElementById('ScreenshotUrl') as HTMLInputElement
                                eleSourceURL.value = JSON.parse(result).cdn_url
                                let eleSourceName = document.getElementById('ScreenshotName') as HTMLInputElement
                                eleSourceName.value = JSON.parse(result).name
                                let eleSourceType = document.getElementById('ScreenshotType') as HTMLInputElement
                                eleSourceType.value = JSON.parse(result).content_type
                                let eleSourceSendButton = document.getElementById('ScreenshotSendButton') as HTMLInputElement
                                eleSourceSendButton.removeAttribute('disabled')

                        })
                        .catch((e) => {
                            error(e)
                        })
                })
                .catch(error => console.log('error', error));

    }

    let sendScreenshot = (e:any,close:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            url: formdata.get('url'),
            name: formdata.get('name'),
            task_id: taskid,
            project_id:id,
            member: localStorage.getItem('username'),
            type:formdata.get('type')
        })


        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'yoptimize/save/attachdocument/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,close))
            .catch((error) => errorForm(error,close))
    }

    const deleteExternalConnections = (id:any,close:any) => {

        var raw = JSON.stringify({
            id: id
        })

        var requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        };

        fetch(ApiRoot() + 'yoptimize/delete/external_Connections/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result, close))
            .catch((error) => errorForm(error, close))

    }

    let  EditExternalConnections = (data:any) => (<>

        <div className="modal-dialog">
            <Form
                id={'form001'}
                className={'formControl'}
                onSubmit={(e) =>
                    sendExternalConnections(e,CloseMember)
                }
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title h4">{IntlMessageValue('','single.task.addExternalConnection',Store)}</div>
                        <button type="button" className="btn-close" aria-label="Close"
                                onClick={() => CloseMember()}/>
                    </div>
                    <div className="modal-body" id={'FormBody'}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>{IntlMessageValue('','common.url',Store)}</Form.Label>
                            <Form.Control name={'url'} type="text" defaultValue={data.url}  required={true}/>
                            <Form.Control name={'id'} type="hidden" defaultValue={data.id}  required={true}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>{IntlMessageValue('','common.title',Store)}</Form.Label>
                            <Form.Control name={'title'}  defaultValue={data.title}  type="text" required={true}/>
                        </Form.Group>
                        <Form.Label>{IntlMessageValue('','common.type',Store)}</Form.Label>
                        <Form.Select name={'type'} aria-label="Default select example" required={true} defaultValue={data.type}>
                            <option label={IntlMessageValue('','common.task.selectType',Store)} />
                            <option value="Weblink">Weblink</option>
                        </Form.Select>
                    </div><br/>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary"
                                onClick={() => CloseMember()}>{IntlMessageValue('','common.close',Store)}
                        </button>
                        <button type="submit" id={'AttachDocumentSendButton'} className="btn btn-primary float-end">{IntlMessageValue('','common.save',Store)}</button>
                    </div>
                </div>
            </Form>
        </div>


    </>)

    let ExternalConnections = (<>

        <div className="modal-dialog">
            <Form
                id={'form001'}
                className={'formControl'}
                onSubmit={(e) =>
                    sendExternalConnections(e,CloseMember)
                }
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title h4">{IntlMessageValue('','single.task.addExternalConnection',Store)}</div>
                        <button type="button" className="btn-close" aria-label="Close"
                                onClick={() => CloseMember()}/>
                    </div>
                    <div className="modal-body" id={'FormBody'}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>{IntlMessageValue('','common.url',Store)}</Form.Label>
                            <Form.Control name={'url'} type="text"  required={true}/>
                            <Form.Control name={'id'} type="hidden" defaultValue={'0'}  required={true}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>{IntlMessageValue('','common.title',Store)}</Form.Label>
                            <Form.Control name={'title'}  type="text" required={true}/>
                        </Form.Group>
                        <Form.Label>Type</Form.Label>
                        <Form.Select name={'type'} aria-label="Default select example" required={true}>
                            <option label={IntlMessageValue('','common.task.selectType',Store)} />
                            <option value="Weblink">Weblink</option>
                        </Form.Select>
                    </div><br/>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary"
                                onClick={() => CloseMember()}>{IntlMessageValue('','common.close',Store)}
                        </button>
                        <button type="submit" id={'AttachDocumentSendButton'} className="btn btn-primary float-end">{IntlMessageValue('','common.save',Store)}</button>
                    </div>
                </div>
            </Form>
        </div>


    </>)
    let sendExternalConnections = (e:any,close:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            id: formdata.get('id'),
            url: formdata.get('url'),
            title: formdata.get('title'),
            type:formdata.get('type'),
            task_id: taskid,
            project_id:id,
            dependency: 'Connected',
            creator: localStorage.getItem('username')
        })


        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'yoptimize/save/external_Connections/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,close))
            .catch((error) => errorForm(error,close))

    }


    const deleteInternalConnections = (id:any,taskid:any,urlid:any,title:any,myTitle:any,inProjectId:any,close:any) => {

        var raw = JSON.stringify({
            url: urlid,
            title: title,
            in_project: inProjectId,
            task_id: taskid,
            project_id: id,
        })

      var requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
          body:raw
        };

        fetch(ApiRoot() + 'yoptimize/delete/internal_Connections/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result, close))
            .catch((error) => errorForm(error, close))

        var raws = JSON.stringify({
            url: taskid,
            title: myTitle,
            in_project: id,
            task_id: urlid,
            project_id: inProjectId,
        })

        var requestOptionss = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body:raws
        };

        fetch(ApiRoot() + 'yoptimize/delete/internal_Connections/', requestOptionss)
            .then((response) => response.text())
            .then((result) => successForm(result, close))
            .catch((error) => errorForm(error, close))
    }

    let InternalConnections = () => {

        let FetchTaskHolder = [];

        if (FetchTask !== true && typeof FetchTask !== "boolean" && FetchTask?.length > 0) {
            for (let ft = 0; ft < FetchTask.length; ft++) {
                if(FetchTask[ft].delete !== '1') {
                    FetchTaskHolder.push(
                        <>
                            <option
                                value={FetchTask[ft].task_id + '|' + FetchTask[ft].regard + '|' + FetchTask[ft].project_id}>{FetchTask[ft].task_id}-{FetchTask[ft].regard}</option>
                        </>
                    )
                }

            }
        }



        let ProjectHolder = [];
        if (ALLProject !== true && typeof ALLProject !== "boolean" && ALLProject?.length > 0) {
            for (let ft = 0; ft < ALLProject.length; ft++) {
                if(ALLProject[ft].delete !== '1') {
                    ProjectHolder.push(
                        <>
                            <option
                                value={ALLProject[ft].id}>{ALLProject[ft].title}</option>
                        </>
                    )
                }
            }
        }


        return (<>
            <div className="offcanvas-body">
            <div className="modal-dialog">
                <Form
                    id={'form001'}
                    className={'formControl'}
                    onSubmit={(e) =>
                        sendInternalConnections(e, CloseMember,'addDialogInternalConnection')
                    }
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title h4">{IntlMessageValue('','single.task.addInternalConnection',Store)}</div>
                            <button type="button" className="btn-close" aria-label="Close"
                                    onClick={() => CloseMember(true,'addDialogInternalConnection')}/>
                        </div>
                        <div className="modal-body" id={'FormBody'}>
                            <Form.Select name={'InternalConnectionsInProject'} id={'InternalConnectionsInProject'}
                                         aria-label="Default select example" required={true}
                                         onChange={(e) => setFetchDataID(e.target.value)}>
                                <option label={IntlMessageValue('', 'common.project.select', Store)} value=""></option>
                                {ProjectHolder}
                            </Form.Select>
                            <br/>
                            <Form.Select name={'InternalConnectionsTaskId'} id={'InternalConnectionsTaskId'}
                                         aria-label="Default select example" required={true}>
                                <option label={IntlMessageValue('', 'common.task.select', Store)} value=""></option>
                                {FetchTaskHolder}
                            </Form.Select>
                            <br/>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>{IntlMessageValue('', 'common.ticket.responsibility', Store)}</Form.Label>
                                <Form.Select aria-label="Default select example"
                                             name={'InternalConnectionsResponsibility'}
                                             id={'InternalConnectionsResponsibility'} required={true}>
                                    <option label={IntlMessageValue('', 'common.responsibility.select', Store)}
                                            value=""></option>
                                    <option
                                        value="assign a user">{IntlMessageValue('', 'common.responsibility.notAssgin', Store)}</option>
                                    {/* @ts-ignore */
                                        UserHolder}
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <br/>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary"
                                    onClick={() => CloseMember(true,'addDialogInternalConnection')}>
                                {IntlMessageValue('','common.close',Store)}
                            </button>
                            <button type="submit" id={'AttachDocumentSendButton'}
                                    className="btn btn-primary float-end"> {IntlMessageValue('','common.save',Store)}
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
            </div>


        </>)
    }
    let sendInternalConnections = (e:any,close:any,Divid?:any) => {
        if (response !== true && typeof response !== "boolean" && response?.length > 0) {
            e.preventDefault()
            let data = e.currentTarget

            var formdata = new FormData(data)
            var raw = JSON.stringify({
                url: (formdata.get('InternalConnectionsTaskId') as string).split('|')[0],
                title: (formdata.get('InternalConnectionsTaskId') as string).split('|')[1],
                in_project: formdata.get('InternalConnectionsInProject'),
                task_id: taskid,
                project_id: id,
                responsibility: formdata.get('InternalConnectionsResponsibility'),
                member: localStorage.getItem('username')
            })

            var requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization:
                        'Bearer ' +
                        JSON.parse(localStorage.getItem('token') as string)
                },
                body: raw
            }

            fetch(ApiRoot() + 'yoptimize/save/internal_Connections/', requestOptions)
                .then((response) => response.text())
                .then((result) => successForm(result, close, true, Divid))
                .catch((error) => errorForm(error, close, true, Divid))

            var rawrevert = JSON.stringify({
                url: taskid,
                title: response[0].regard,
                task_id: (formdata.get('InternalConnectionsTaskId') as string).split('|')[0],
                in_project: id,
                project_id: formdata.get('InternalConnectionsInProject'),
                responsibility: formdata.get('InternalConnectionsResponsibility'),
                member: localStorage.getItem('username')
            })

            var requestOptionsrevert = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization:
                        'Bearer ' +
                        JSON.parse(localStorage.getItem('token') as string)
                },
                body: rawrevert
            }

            fetch(ApiRoot() + 'yoptimize/save/internal_Connections/', requestOptionsrevert)
                .then((response) => response.text())
                .then((result) => successForm(result, close, true))
                .catch((error) => errorForm(error, close, true))
        }

       let Projectid = document.getElementById('InternalConnectionsInProject') as HTMLSelectElement;
        if(Projectid !== null) {
            Projectid.value = ''
        }
       let TaskID = document.getElementById('InternalConnectionsTaskId') as HTMLSelectElement;
        if(TaskID !== null) {
            TaskID.value = ''
        }
       let Responsibility = document.getElementById('InternalConnectionsResponsibility') as HTMLSelectElement;
        if(Responsibility !== null) {
            Responsibility.value = ''
        }



    }

    const setterEditor = (id:any,id2:any) => {
        (document.getElementById(id) as HTMLDivElement).style.display = 'block';
        (document.getElementById(id2) as HTMLDivElement).style.display = 'none'
    }

    const setEditorContent = (value:any,textarea?:any) =>{
        setValue(value)
        if(textarea !== undefined) {
            (document.getElementById('descriptionTextarea') as HTMLTextAreaElement).value = value
        }
    }

    const [state, setState] = useState({
        background: '#fff',
    });

     const EditTask =() => {
         if (response !== true && typeof response !== "boolean" && response?.length > 0) {
             let UserHolder = [];

             if (AllUSer !== true && typeof AllUSer !== "boolean" && AllUSer?.length > 0) {
                 for (let us = 0; us < AllUSer?.length; us++) {
                     UserHolder.push(
                         <>
                             <option value={AllUSer[us].username}>{AllUSer[us].username}</option>
                         </>
                     )
                 }
             }
            return(
            <>
                <div className="offcanvas-body">
                <div className="modal-dialog" >

                    <Form
                        id={'form001'}
                        style={{padding: 0}}
                        className={'formControl'}
                        onSubmit={(e) =>
                            sendEditTask(e, CloseMember,'addDialogEditTask')
                        }
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title h4">{IntlMessageValue('','single.task.editTask',Store)}</div>
                                <button type="button" className="btn-close" aria-label="Close"
                                        onClick={() => CloseMember(true,'addDialogEditTask')}/>
                            </div>
                            <div className="modal-body" id={'FormBody'}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">{IntlMessageValue('','common.title',Store)}</InputGroup.Text>
                                    <Form.Control type="text" name={'title'} defaultValue={response[0].regard}
                                                  required={true}/>
                                </InputGroup>
                                <Button variant="outline-primary" onClick={()=>setterEditor('htmleditor','markdowneditor') }>HTML Editor</Button>
                                <Button variant="outline-primary" onClick={()=>setterEditor('markdowneditor','htmleditor') }>Markdown Editor</Button>

                                <div id={'htmleditor'} style={{display: 'block'}}>
                                    <SunEditor
                                        key={'test'}

                                        name={'description'}
                                        setContents={value}
                                        setOptions={{
                                            buttonList: SunEditorConfig(),
                                            attributesWhitelist: {
                                                div: 'id',
                                                p: 'id',
                                                h: 'id'
                                            }
                                        }}
                                        height={'373px'}
                                        onChange={(value) => setEditorContent(value)}
                                    />
                                </div>
                                <div id={'markdowneditor'} style={{display: 'none'}}
                                     data-color-mode="light">
                                    <MarkdownEditor
                                        value={(value !== ''&& value !== null && value !== undefined ? value.replaceAll('<p>','').replaceAll('</p>','') : '')}
                                        height={'450px'}
                                        visible={true}
                                        onChange={(value, viewUpdate) => setEditorContent(value,true)}

                                    />

                                </div>
                                <br/>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label><IntlMessage messageId="common.ticket.startOn" Store={Store} /></Form.Label>
                                            <Form.Control type="date" name={'start_on'} defaultValue={response[0].start_on} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label><IntlMessage messageId="common.ticket.dueOn" Store={Store} /></Form.Label>
                                            <Form.Control type="date" name={'due_on'} defaultValue={response[0].due_on} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                               {/*
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Start date</Form.Label>
                                <Form.Control type="datetime-local" name={'Start_date'}  />
                            </Form.Group>*/}
                                <br/>
                                <Row>
                                    <Col className={'col-4'}>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">{IntlMessageValue('','common.ticket.priority',Store)}</InputGroup.Text>
                                            <Form.Select aria-label="Default select example" name={'priority'}
                                                         required={true} defaultValue={response[0].priority}>
                                                <option label={IntlMessageValue('','common.priority.default',Store)} value=""></option>
                                                {PrioEditHolder}
                                            </Form.Select>
                                            <InputGroup.Text id="basic-addon1" style={{cursor:'pointer'}}><span
                                                className="material-icons float-end"
                                                onClick={() => switchdisplay('AgileTaskPriorities')}>
                                    info
                                    </span></InputGroup.Text>
                                        </InputGroup>

                                    </Col>
                                    <Col className={'col-6'}>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">{IntlMessageValue('','common.ticket.responsibility',Store)}</InputGroup.Text>
                                            <Form.Select aria-label="Default select example" name={'responsibility'}
                                                         required={true} defaultValue={response[0].responsibility}>
                                                <option label={IntlMessageValue('','common.responsibility.select',Store)} value=""></option>
                                                <option value="assign a user">{IntlMessageValue('','common.responsibility.notAssgin',Store)}</option>
                                                {UserHolder}
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                    <Col className={'col-2'}>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">{IntlMessageValue('','common.ticket.color',Store)}</InputGroup.Text>
                                            <Form.Control
                                                type="color"
                                                name={'color'}
                                                defaultValue={response[0].color}
                                                id={'saveSwimelanecolor'}
                                            />
                                        </InputGroup>

                                    </Col>
                                </Row>
                                <AddedColor Store={Store} state={state} cid={'saveSwimelanecolor'} color={ColorHolder} setState={setState} />
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => CloseMember(true,'addDialogEditTask')}>
                                    {IntlMessageValue('','common.close',Store)}
                                </button>
                                <button type="submit" id={'AttachDocumentSendButton'}
                                        className="btn btn-primary float-end">
                                    {IntlMessageValue('','common.save',Store)}
                                </button>
                            </div>

                        </div>
                    </Form>
                </div>
            </div>
            </>
        )
    }

    }
    let sendEditTask = (e:any,close:any,divid?:any) => {
            e.preventDefault()
            let data = e.currentTarget

            var formdata = new FormData(data)
            var raw = JSON.stringify({
                regard: formdata.get('title'),
                description: formdata.get('description'),
                responsibility: formdata.get('responsibility'),
                responsibilityID: SearchUserID(formdata.get('responsibility')),
                priority: formdata.get('priority'),
                due_on: formdata.get('due_on'),
                start_on: formdata.get('start_on'),
                lastupdatefrom: localStorage.getItem('username'),
                color:formdata.get('color')
            })

            var requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization:
                        'Bearer ' +
                        JSON.parse(localStorage.getItem('token') as string)
                },
                body: raw
            }
            setValue('');

        FetchwithMattermostANDsuccessFormWithClosedivIdandTrue(
            {
                endpoint:'yoptimize/project/'+id+'/edit/task/'+taskid+'/',
                requestOptions:requestOptions,
                successForm:successForm,
                errorForm:errorForm,
                close:close,
                divid:divid,
                preText: '## Ticket wurde bearbeitet! :rolled_up_newspaper:',
                text:'[Projekt: ***'+(response !== true && typeof response !== "boolean" && response?.length > 0 ? response[0].project.title : '')+'***]('+window.location.origin+'/myprojects/task/all/projects/'+id+'/system) | [Ticket: '+(response !== true && typeof response !== "boolean" && response?.length > 0 ? response[0].regard : '')+' ***'+taskid+'***]('+window.location.origin+'/myprojects/task/single/'+id+'/'+taskid+')\nwurde von :adult: '+localStorage.getItem('username') +' bearbeitet. \n \nInhalt :computer: : \n```json\n '+raw+' \n```'
            }
        )
        }

    let EditSubTask = (data:any) => (<>

        <div className="modal-dialog">
            <Form
                id={'form001'}
                className={'formControl'}
                onSubmit={(e) =>
                    sendSubTask(e,CloseMember)
                }
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title h4">{IntlMessageValue('','single.task.addSubtask',Store)}</div>
                        <button type="button" className="btn-close" aria-label="Close"
                                onClick={() => CloseMember()}/>
                    </div>
                    <div className="modal-body" id={'FormBody'}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>{IntlMessageValue('','common.title',Store)}</Form.Label>
                            <Form.Control name={'title'} defaultValue={data.title} type="text"  required={true}/>
                            <Form.Control name={'id'} defaultValue={data.id} type="hidden"  required={true}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>{IntlMessageValue('','common.description',Store)}</Form.Label>
                            <Form.Control as="textarea" rows={3} name={'description'} defaultValue={data.description} required={true}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>{IntlMessageValue('','common.table.dueDate',Store)}</Form.Label>
                            <Form.Control name={'due_to'} type="datetime-local" required={true} defaultValue={data.due_to}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>{IntlMessageValue('','common.ticket.responsibility',Store)}</Form.Label>
                            <Form.Select aria-label="Default select example" name={'responsibility'} defaultValue={data.responsibility}  required={true}>
                                <option label={IntlMessageValue('','common.responsibility.select',Store)} value="" />
                                <option value="assign a user">{IntlMessageValue('','common.responsibility.notAssgin',Store)}</option>
                                {UserHolder}
                            </Form.Select>
                        </Form.Group>
                    </div><br/>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary"
                                onClick={() => CloseMember()}>{IntlMessageValue('','common.close',Store)}
                        </button>
                        <button type="submit" id={'AttachDocumentSendButton'} className="btn btn-primary float-end">
                            {IntlMessageValue('','common.save',Store)}
                        </button>
                    </div>
                </div>
            </Form>
        </div>


    </>)

        let SubTask = (<>

            <div className="modal-dialog">
                <Form
                    id={'form001'}
                    className={'formControl'}
                    onSubmit={(e) =>
                        sendSubTask(e,CloseMember)
                    }
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title h4">{IntlMessageValue('','single.task.addSubtask',Store)}</div>
                            <button type="button" className="btn-close" aria-label="Close"
                                    onClick={() => CloseMember()}/>
                        </div>
                        <div className="modal-body" id={'FormBody'}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>{IntlMessageValue('','common.title',Store)}</Form.Label>
                                <Form.Control name={'title'} type="text"  required={true}/>
                                <Form.Control name={'id'} defaultValue={'0'} type="hidden"  required={true}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>{IntlMessageValue('','common.description',Store)}</Form.Label>
                                <Form.Control as="textarea" rows={3} name={'description'} required={true}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>{IntlMessageValue('','common.table.dueDate',Store)}</Form.Label>
                                <Form.Control name={'due_to'} type="datetime-local" required={true}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>{IntlMessageValue('','common.ticket.responsibility',Store)}</Form.Label>
                                <Form.Select aria-label="Default select example" name={'responsibility'}  required={true}>
                                    <option label={IntlMessageValue('','common.responsibility.select',Store)} value=""></option>
                                    <option value="assign a user">{IntlMessageValue('','common.responsibility.notAssgin',Store)}</option>
                                    {UserHolder}
                                </Form.Select>
                            </Form.Group>
                        </div><br/>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary"
                                    onClick={() => CloseMember()}>
                                {IntlMessageValue('','common.close',Store)}
                            </button>
                            <button type="submit" id={'AttachDocumentSendButton'} className="btn btn-primary float-end">
                                {IntlMessageValue('','common.save',Store)}
                            </button>
                        </div>
                    </div>
                </Form>
            </div>


        </>)
        let sendSubTask = (e:any,close:any) => {
            e.preventDefault()
            let data = e.currentTarget

            var formdata = new FormData(data)
            var raw = JSON.stringify({
                id:formdata.get('id'),
                title: formdata.get('title'),
                description: formdata.get('description'),
                task_id: taskid,
                project_id:id,
                responsibility: formdata.get('responsibility'),
                due_on: formdata.get('due_to'),
                member: localStorage.getItem('username')
            })

            var requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization:
                        'Bearer ' +
                        JSON.parse(localStorage.getItem('token') as string)
                },
                body: raw
            }

           fetch(ApiRoot()+'yoptimize/save/subtask/', requestOptions)
                .then((response) => response.text())
                .then((result) => successForm(result,close))
                .catch((error) => errorForm(error,close))

        }

    const CloseMember = (mode?:any,modeID?:any) => {
        if(mode !== undefined && mode !== null) {
            let Dialogs = document.getElementById((modeID !== undefined && modeID !== null && modeID !== '' ? modeID :'addDialogInternalConnection')) as HTMLDivElement;
            Dialogs.classList.remove("d-none");
            Dialogs.style.display = 'none';
        } else {
            let Dialog = document.getElementById('addDialogMember') as HTMLDivElement;
            Dialog.classList.remove("d-none");
            Dialog.style.display = 'none';
        }

    }
        /* @ts-ignore */
        let ProjectHolder = [];
        if (ALLProject !== true && typeof ALLProject !== "boolean" && ALLProject?.length > 0) {
            for (let ft = 0; ft < ALLProject.length; ft++) {
                    if(ALLProject[ft].delete !== '1') {
                        ProjectHolder.push(
                            <>
                                <option
                                    value={ALLProject[ft].id}>{ALLProject[ft].title}</option>
                            </>
                        )
                    }

            }
        }
    const SetterLabel = (e:any,tid:any,cid:any) => {

        let Color = document.getElementById(cid) as HTMLInputElement;
        let ColorPre = document.getElementById(e.target.value) as HTMLOptionElement;
        if(Color !== null && ColorPre !== null) {
            Color.value = ColorPre.dataset.color as string
        }
    }

    const Labels = ResponseAllLabels(fetchData)
    let AllLabelsHoldder= [];
    if (typeof Labels !== "boolean" && Labels?.length > 0) {
        for (let c = 0; c < Labels.length; c++) {
            AllLabelsHoldder.push(
                <>
                    <option id={Labels[c].name} value={Labels[c].name} data-color={Labels[c].color}/>
                </>
            )
        }
    }

    let Label = (<>

        <div className="modal-dialog">
            <Form
                id={'form001'}
                className={'formControl'}
                onSubmit={(e) =>
                    sendLabel(e,CloseMember)
                }
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title h4"><IntlMessage messageId="single.task.addLabel.headline" Store={Store} /></div>
                        <button type="button" className="btn-close" aria-label="Close"
                                onClick={() => CloseMember()}/>
                    </div>
                    <div className="modal-body" id={'FormBody'}>
                        <Row>
                            <Col className={'col-10'}><Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label><IntlMessage messageId="common.title" Store={Store} /></Form.Label>
                                {/*<Form.Control name={'name'} id={'LabelID'} type="text"  required={true}/>*/}
                                <input className="form-control" list="datalistOptions"
                                       placeholder={IntlMessageValue('','common.typeToSearch',Store)} name={'name'} id={'LabelID'} required={true}
                                       onChange={(e)=>SetterLabel(e,'LabelID','LabelColor')} autoComplete="off"/>
                                <datalist id="datalistOptions" >
                                    {AllLabelsHoldder}
                                </datalist>
                            </Form.Group></Col>
                            <Col className={'col-2'}><Form.Label htmlFor="exampleColorInput">
                                <IntlMessage messageId="common.ticket.color" Store={Store} />
                            </Form.Label>
                                <Form.Control
                                    required={true}
                                    type="color"
                                    name={'color'}
                                    id={'LabelColor'}
                                /></Col>
                        </Row>
                    </div><br/>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary"
                                onClick={() => CloseMember()}><IntlMessage messageId="common.close" Store={Store} />
                        </button>
                        <button type="submit" id={'AttachDocumentSendButton'} className="btn btn-primary float-end">
                            <IntlMessage messageId="common.save" Store={Store} />
                        </button>
                    </div>
                </div>
            </Form>
        </div>


    </>)
    let sendLabel = (e:any,close:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            name: formdata.get('name'),
            color: formdata.get('color'),
            task_id: taskid,
            project_id:id,
            member: localStorage.getItem('username')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        FetchwithMattermostANDsuccessFormWithClose(
            {
                endpoint:'yoptimize/save/label/',
                requestOptions:requestOptions,
                successForm:successForm,
                errorForm:errorForm,
                close:close,
                preText: '## Label wurde erstellt  :label: \n',
                text:'[Projekt: ***'+(response !== true && typeof response !== "boolean" && response?.length > 0 ? response[0].project.title : '')+'***]('+window.location.origin+'/myprojects/task/all/projects/'+id+'/system) | [Ticket ***'+(response !== true && typeof response !== "boolean" && response?.length > 0 ? response[0].regard : '')+'***]('+window.location.origin+'/myprojects/task/single/'+id+'/'+taskid+')\n \n :adult: '+localStorage.getItem('username') +' \n \n :bookmark: Label *'+formdata.get('name')+'* mit der Farbe `'+formdata.get('color')+'` erstellt!'
            }
        )

    }

    const setStartSwim = (id:any) => {
        setSwimlaneID(id)
        setFetchData(Date.now())
    }

    const DuplicateTask = () => {

            let SwimLaneHolder = [];
            let localSwimLanes = JSON.parse(localStorage.getItem('swimlanes') as string)

                for (let us = 0; us < localSwimLanes?.length; us++) {
                    SwimLaneHolder.push(
                        <>
                            <option value={localSwimLanes[us].id}>{localSwimLanes[us].name}</option>
                        </>
                    )
                }

if(response !== true && typeof response !== "boolean" && response?.length > 0 && ALLProject !== true && typeof ALLProject !== "boolean" && ALLProject?.length > 0 ) {
    if(LoadDuplicate === false) {
        setStartSwim(response[0].project_id)
        setLoadDuplicate(true)
    }
    if(SwimLaneHolder?.length > 0) {
        return (
            <>
                <div className="offcanvas-body">
                <div className="modal-dialog">
                    <Form
                        id={'form001'}
                        className={'formControl'}
                        onSubmit={(e) =>
                            sendDuplicateTask(e, CloseMember,'addDialogDuplicateTask')
                        }
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <div
                                    className="modal-title h4">{IntlMessageValue('', 'single.task.duplicateToAnotherProject', Store)}</div>
                                <button type="button" className="btn-close" aria-label="Close"
                                        onClick={() => CloseMember(true, 'addDialogDuplicateTask')}/>
                            </div>
                            <br/>
                            <div className="modal-body" id={'FormBody'}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text
                                        id="basic-addon1">{IntlMessageValue('de', 'common.title', Store)}</InputGroup.Text>
                                    <Form.Control name={'regard'}
                                                  defaultValue={'Kopie vom ' + (response?.length > 0 ? response[0].regard : '')}/>

                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text
                                        id="basic-addon1">{IntlMessageValue('', 'common.ticket.project', Store)}</InputGroup.Text>
                                    <Form.Select name={'DuplicateTaskProjectID'} aria-label="Default select example"
                                                 required={true} onChange={(e) => setSwim(e)}
                                                 defaultValue={response[0].project_id}>
                                        <option label={IntlMessageValue('', 'common.project.select', Store)}
                                                value=""></option>
                                        { /* @ts-ignore */
                                            ProjectHolder}
                                    </Form.Select>
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text
                                        id="basic-addon1">{IntlMessageValue('', 'common.ticket.swimlane', Store)}</InputGroup.Text>
                                    <Form.Select name={'DuplicateTaskSwimlaneID'} aria-label="Default select example"
                                                 required={true} defaultValue={response[0].swimlane_id}>
                                        <option label={IntlMessageValue('', 'common.swimlane.select', Store)}
                                                value=""></option>
                                        {SwimLaneHolder}
                                    </Form.Select>
                                </InputGroup>
                                <hr/>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Kommentare übernehmen</InputGroup.Text>
                                    <Form.Select name={'addedCommment'} aria-label="Default select example"
                                                 required={true}>
                                        <option value="0">{IntlMessageValue('', 'common.no', Store)}</option>
                                        <option value="1">{IntlMessageValue('', 'common.yes', Store)}</option>
                                    </Form.Select>
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Anhänge übernehmen</InputGroup.Text>
                                    <Form.Select name={'addedAttachment'} aria-label="Default select example"
                                                 required={true}>
                                        <option value="0">{IntlMessageValue('', 'common.no', Store)}</option>
                                        <option value="1">{IntlMessageValue('', 'common.yes', Store)}</option>
                                    </Form.Select>
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Unteraufgaben übernehmen</InputGroup.Text>
                                    <Form.Select name={'addedSubtask'} aria-label="Default select example"
                                                 required={true}>
                                        <option value="0">{IntlMessageValue('', 'common.no', Store)}</option>
                                        <option value="1">{IntlMessageValue('', 'common.yes', Store)}</option>
                                    </Form.Select>
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Externe Verbindungen übernehmen</InputGroup.Text>
                                    <Form.Select name={'addedExternal'} aria-label="Default select example"
                                                 required={true}>
                                        <option value="0">{IntlMessageValue('', 'common.no', Store)}</option>
                                        <option value="1">{IntlMessageValue('', 'common.yes', Store)}</option>
                                    </Form.Select>
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Interne Verbindungen übernehmen</InputGroup.Text>
                                    <Form.Select name={'addedInternal'} aria-label="Default select example"
                                                 required={true}>
                                        <option value="0">{IntlMessageValue('', 'common.no', Store)}</option>
                                        <option value="1">{IntlMessageValue('', 'common.yes', Store)}</option>
                                    </Form.Select>
                                </InputGroup>
                                <hr/>
                            </div>
                            <br/>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => CloseMember(true, 'addDialogDuplicateTask')}>{IntlMessageValue('', 'common.close', Store)}
                                </button>
                                <button type="submit" id={'AttachDocumentSendButton'}
                                        className="btn btn-primary float-end">{IntlMessageValue('', 'common.save', Store)}
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
                </div>
            </>)
    }
} else {
    return
}
    }

    let sendDuplicateTask = (e: any, close: any, divid?: any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            task_id: taskid,
            project_id: id,
            regard: formdata.get('regard'),
            addedCommment: formdata.get('addedCommment'),
            addedAttachment:formdata.get('addedAttachment'),
            addedSubtask:formdata.get('addedSubtask'),
            addedExternal:formdata.get('addedExternal'),
            addedInternal:formdata.get('addedInternal')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                    Authorization:
                        'Bearer ' +
                        JSON.parse(localStorage.getItem('token') as string)
                },
                body: raw
            }

            fetch(ApiRoot()+'yoptimize/copy/newproject/'+formdata.get('DuplicateTaskProjectID')+'/project/'+id+'/task/'+taskid+'/swimlane/'+formdata.get('DuplicateTaskSwimlaneID')+'/', requestOptions)
                .then((response) => response.text())
                .then((result) => successForm(result,close,true,divid))
                .catch((error) => errorForm(error,close,true,divid))

        }

        const MoveTask  = () => {

            let SwimLaneHolder = [];
            let localSwimLanes = JSON.parse(localStorage.getItem('swimlanes') as string)

                for (let us = 0; us < localSwimLanes?.length; us++) {
                    SwimLaneHolder.push(
                        <>
                            <option value={localSwimLanes[us].id}>{localSwimLanes[us].name}</option>
                        </>
                    )
                }



            return (
                <>
                    <div className="offcanvas-body">
                    <div className="modal-dialog">
                        <Form
                            id={'form001'}
                            className={'formControl'}
                            onSubmit={(e) =>
                                sendMoveTask(e, CloseMember,'addDialogMoveTask')
                            }
                        >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-title h4">{IntlMessageValue('','single.task.moveToAnotherProject',Store)}</div>
                                    <button type="button" className="btn-close" aria-label="Close"
                                            onClick={() => CloseMember(true,'addDialogMoveTask')}/>
                                </div><br/>
                                <div className="modal-body" id={'FormBody'}>
                                    <Form.Select name={'DuplicateTaskProjectID'} aria-label="Default select example"
                                                 required={true} onChange={(e) => setSwim(e)}>
                                        <option label={IntlMessageValue('','common.project.select',Store)} value=""></option>
                                        { /* @ts-ignore */
                                            ProjectHolder}
                                    </Form.Select>
                                </div><br/>
                                <div className="modal-body" id={'FormBody'}>
                                    <Form.Select name={'DuplicateTaskSwimlaneID'} aria-label="Default select example"
                                                 required={true}>
                                        <option label={IntlMessageValue('','common.swimlane.select',Store)} value=""></option>
                                        {SwimLaneHolder}
                                    </Form.Select>
                                </div><br/>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary"
                                            onClick={() => CloseMember(true,'addDialogMoveTask')}>{IntlMessageValue('','common.close',Store)}
                                    </button>
                                    <button type="submit" id={'AttachDocumentSendButton'}
                                            className="btn btn-primary float-end">{IntlMessageValue('','common.save',Store)}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </div>
                    </div>
                </>
            )
        }
        let sendMoveTask = (e:any,close:any,divid?:any) => {
            e.preventDefault()
            let data = e.currentTarget

            var formdata = new FormData(data)
            var raw = JSON.stringify({
                task_id: taskid,
                project_id:id
            })

            var requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization:
                        'Bearer ' +
                        JSON.parse(localStorage.getItem('token') as string)
                },
                body: raw
            }

            fetch(ApiRoot()+'yoptimize/move/newproject/'+formdata.get('DuplicateTaskProjectID')+'/project/'+id+'/task/'+taskid+'/swimlane/'+formdata.get('DuplicateTaskSwimlaneID')+'/', requestOptions)
                .then((response) => response.json())
                .then((result) => successForm(result,close,true,divid,true))
                .catch((error) => errorForm(error,close,true,divid))

        }




    const CloseTask = () => {


        var raw = ''

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'yoptimize/project/'+id+'/task/'+taskid+'/close/', requestOptions)
            .then((response) => response.text())
            .then((result) => msgAlert(result))
            .catch((error) => console.log('error', error))
    }

    const msgAlert = (result:any) => {
        let State = document.getElementById('state_select') as HTMLSelectElement
            State.value = '6'
        setFetchData(Date.now())
        handleCloseCloseTask()
        notify_save()
    }

        const setResponsibilityTask = () => {


            var raw = JSON.stringify({
                responsibility: localStorage.getItem('username'),
                responsibilityID: localStorage.getItem('userid')
            })

            var requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization:
                        'Bearer ' +
                        JSON.parse(localStorage.getItem('token') as string)
                },
                body: raw
            }

            fetch(ApiRoot()+'yoptimize/project/'+id+'/task/'+taskid+'/responsibility/', requestOptions)
                .then((response) => response.text())
                .then((result) => SuccesDate())
                .catch((error) => console.log('error', error))
        }




        useEffect(() => {
            let editorText = (document.getElementById('htmleditor') as HTMLDivElement)
            if(editorText !== null) {
               let text = (editorText.children[0] as HTMLTextAreaElement)
                if(text !== null) {
                    text.id='descriptionTextarea'
                }
            }
            document.addEventListener('keyup', function (event) {
                if ( event.keyCode == 27 )   {
                    closeEsc()
                }
            })



        })


    const switchState = (value:any) => {
        var raw = JSON.stringify(
            {
                task_id: taskid,
                project_id:id,
                status_id:value,
                lastupdatefrom: localStorage.getItem('username'),
            })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'state/byTask/yoptimize/', requestOptions)
            .then((response) => response.text())
            .then((result) => SuccesDate())
            .catch((error) => console.log('error', error))

        FetchwithMattermostOnlysetFetchData(
            {
                endpoint:'state/byTask/yoptimize/',
                requestOptions:requestOptions,
                setFetchData:setFetchData,
                preText: '## Status geändert :traffic_light:',
                text:'[Projekt: ***'+(response !== true && typeof response !== "boolean" && response?.length > 0 ? response[0].project.title : '')+'***]('+window.location.origin+'/myprojects/task/all/projects/'+id+'/system) | [Ticket ***'+(response !== true && typeof response !== "boolean" && response?.length > 0 ? response[0].regard : '')+'***]('+window.location.origin+'/myprojects/task/single/'+id+'/'+taskid+') \n \n :adult: '+localStorage.getItem('username')+' \n \n Status vom Ticket wurde geändert. Neuer Status: ***'+ value + '***'
            }
        )
    }

    const LockedOrder = () => {
        SingleTaskFetch({id:id,taskid:taskid,lock:"1",lockby:localStorage.getItem('username'),url:location.pathname,mode:'add',setFetchData:setFetchData})
    }

    let accordionOpen = [];
    accordionOpen.push('Description')
    if(BuildSubTask.length > 0) {accordionOpen.push('Subtask')}
    if(BuildInternalConnections.length > 0) {accordionOpen.push('ic')}
    if(BuildExternalConnections.length > 0) {accordionOpen.push('ec')}
    if(BuildAttachtment.length > 0) {accordionOpen.push('Attachments')}
    if(BuildComments.length > 0) {accordionOpen.push('Comments')}
    if (response !== true && typeof response !== "boolean" && response?.length > 0) {
        if(response[0].lock === '0' || response[0].lock === null) {
            SingleTaskFetch({id:id,taskid:taskid,lock:"1",lockby:localStorage.getItem('username'),url:location.pathname,mode:'add'})
        }
        return (
            <>
                <div className={'ticketsystem ml-3'} data-color-mode="light">
                    {response[0].lock === "1" && response[0].lockby !== localStorage.getItem('username') ?
                    <LockScreen orderFunc={LockedOrder}
                                header={IntlMessageValue('de', 'lock.SingleTask.header', Store)}
                                onlyShowButtonLabel={IntlMessageValue('de', 'lock.SingleTask.onlyShowButtonLabel', Store)}
                                onlyShow={setShowActions}
                                chatButtonLabel={IntlMessageValue('de', 'lock.SingleTask.chatButton', Store)}
                                orderButtonLabel={IntlMessageValue('de', 'lock.SingleTask.orderButton', Store)}
                                backButtonLabel={IntlMessageValue('de', 'lock.SingleTask.backButton', Store)}
                                text={IntlMessageValue('de', 'lock.SingleTask.text', Store).replace('[TaskID]',response[0].task_id).replace('[TaskRegards]',response[0].regard).replace('[LockBy]',response[0].lockby)} />
                    : <></>}
                    {nosearch === true ? <Search navigate={navigate} /> : <></>}
                    <Row>
                        <Col xs={2} md={2} xl={2} xxl={2}>
                            <h3>  <Link className={'btn btn-primary'} to={'/myprojects/task/all/projects/'+id+'/'+response[0].project.title} >
                    <span className="material-icons" style={{verticalAlign: 'middle', marginBottom: '7px'}}>
                        arrow_back_ios
                    </span>
                                Task {response[0].task_id}
                            </Link></h3></Col>
                        <Col><h3>{response[0].regard}  {refresh(setFetchData, IntlMessageValue('de', 'common.reloadPage', Store))}</h3></Col>
                    </Row>
                    <Row>

                        <Col xs={2} md={2} xl={2} xxl={2}>
                            <h4 onClick={(e) => collapse(e, 'summary')}>
                                <IntlMessage messageId="single.task.summary.headline" Store={Store} />
                                <span id={'summary_arrow'} className="material-icons" style={{verticalAlign: 'bottom'}}>
                            expand_less
                        </span>
                            </h4>
                            <div id={'summary'} className={'d-block'}>
                                <Navbar className="bg-body-transparent" style={{cursor:'pointer'}}>
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px'}} onClick={()=>switchdisplay('summaryAccordion',['Last_activitaet'])}>
                                 <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                summarize
                            </span><IntlMessage messageId="single.task.summary" Store={Store} /></Navbar.Brand>
                                    </Container>
                                </Navbar>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={()=>switchdisplay('Last_activitaet',['summaryAccordion'])}>
                                 <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                palette
                            </span><IntlMessage messageId="single.task.lastActivitities" Store={Store} /></Navbar.Brand>
                                    </Container>
                                </Navbar>
                              {/*  <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand href="#home" style={{fontSize: '16px'}}>
                                 <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                compare_arrows
                            </span><IntlMessage messageId="single.task.transitions" Store={Store} /></Navbar.Brand>
                                    </Container>
                                </Navbar>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand href="#home" style={{fontSize: '16px'}}>
                                 <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                equalizer
                            </span><IntlMessage messageId="single.task.analyse" Store={Store} /></Navbar.Brand>
                                    </Container>
                                </Navbar>*/}
                                <br/>
                            </div>
                            <h4 onClick={(e) => collapse(e, 'actions')} className={(showActions === true ? 'd-block' : 'd-none')}><IntlMessage messageId="single.task.actions" Store={Store} />
                                <span id={'actions_arrow'} className="material-icons" style={{verticalAlign: 'bottom'}}>
                            expand_less
                        </span>
                            </h4>
                            <div id={'actions'} className={(showActions === true ? 'd-block' : 'd-none')}>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => AddedFunc('','',true,'addDialogEditTask')}>
                            <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                edit_note
                            </span>
                                            <IntlMessage messageId="single.task.editTask" Store={Store} />
                                        </Navbar.Brand>
                                    </Container>
                                </Navbar>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => AddedFunc(Label)}>
                                  <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                new_label
                            </span>
                                            <IntlMessage messageId="single.task.createLabel" Store={Store} /></Navbar.Brand>
                                    </Container>
                                </Navbar>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => AddedFunc(SubTask)}>
                                  <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                add
                            </span>
                                            <IntlMessage messageId="single.task.addSubtask" Store={Store} /></Navbar.Brand>
                                    </Container>
                                </Navbar>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => AddedFunc('','',true,'addDialogInternalConnection')}>
                                   <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                fork_right
                            </span>
                                            <IntlMessage messageId="single.task.addInternalConnection" Store={Store} /></Navbar.Brand>
                                    </Container>
                                </Navbar>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => AddedFunc(ExternalConnections)}>
                                   <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                ios_share
                            </span>
                                            <IntlMessage messageId="single.task.addExternalConnection" Store={Store} /></Navbar.Brand>
                                    </Container>
                                </Navbar>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => AddedFunc(Comment)}>
                                   <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                chat
                            </span>
                                            <IntlMessage messageId="single.task.addComment" Store={Store} /></Navbar.Brand>
                                    </Container>
                                </Navbar>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => AddedFunc(AttachDocument)}>
                                  <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                add_to_drive
                            </span>
                                            <IntlMessage messageId="single.task.attachDocument" Store={Store} /></Navbar.Brand>
                                    </Container>
                                </Navbar>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => AddedFunc(addScreenshot)}>
                                <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                photo_camera
                            </span>
                                            <IntlMessage messageId="single.task.addSreenshot" Store={Store} /></Navbar.Brand>
                                    </Container>
                                </Navbar>
                                {process.env.REACT_APP_MODE_TICKET_ID !== undefined && process.env.REACT_APP_MODE_TICKET_ID !== null
                                    && process.env.REACT_APP_MODE_TICKET_NAME !== undefined && process.env.REACT_APP_MODE_TICKET_NAME !== null ?
                                    <></>
                                :
                                <>
                                    <Navbar className="bg-body-transparent">
                                        <Container>
                                            <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => AddedFunc('','',true,'addDialogDuplicateTask')}>
                                <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                content_copy
                            </span><IntlMessage messageId="single.task.duplicateToAnotherProject" Store={Store} /></Navbar.Brand>
                                        </Container>
                                    </Navbar>
                                    <Navbar className="bg-body-transparent">
                                        <Container>
                                            <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => AddedFunc('','',true,'addDialogMoveTask')}>
                                <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                difference
                            </span><IntlMessage messageId="single.task.moveToAnotherProject" Store={Store} /></Navbar.Brand>
                                        </Container>
                                    </Navbar>
                                </>
                                }

                                {/*<Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => AddedFunc()}>
                                <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                send
                            </span>Send via email</Navbar.Brand>
                                    </Container>
                                </Navbar>*/}
                               {/* <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => AddedFunc()}>
                                <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                control_camera
                            </span>Move position</Navbar.Brand>
                                    </Container>
                                </Navbar>*/}

                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => handleShowCloseTask()}>
                                <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                delete
                            </span><IntlMessage messageId="single.task.close" Store={Store} /></Navbar.Brand>
                                    </Container>
                                </Navbar>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => savearchiv(e,id,taskid,1,setFetchData)}>
                                <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                archive
                            </span><IntlMessage messageId="single.task.archived" Store={Store} /></Navbar.Brand>
                                    </Container>
                                </Navbar>

                            </div>
                        </Col>
                        <Col xs={10} md={10} xl={10} xxl={10}>
                            <Row>
                                <Col className={'col-6'}>
                                    <Card>
                                        <Card.Body>
                                            <Row>
                                                <Col className={'col-3'}>Status<br/>
                                                    {process.env.REACT_APP_MODE_TICKET_ID !== undefined && process.env.REACT_APP_MODE_TICKET_ID !== null
                                                    && process.env.REACT_APP_MODE_TICKET_NAME !== undefined && process.env.REACT_APP_MODE_TICKET_NAME !== null

                                                        ?
                                                        <>
                                                            {response[0].status.state as string}
                                                        </>
                                                        :
                                                        (showActions === true ? <>
                                                            <Form.Select aria-label="Default select example" id={'state_select'}
                                                                         onChange={(e) => switchState(e.target.value)}
                                                                         defaultValue={response[0].status.id as string}>
                                                                <option label={'Status wählen'}></option>
                                                                {StateHolder}
                                                            </Form.Select>
                                                        </> : response[0].status.state as string)

                                                    }

                                                </Col>
                                                <Col className={'col-3'}><IntlMessage messageId="common.ticket.column" Store={Store} /><br/><strong>{response[0].column?.name}</strong></Col>
                                                <Col
                                                    className={'col-3'}><IntlMessage messageId="common.ticket.swimlane" Store={Store} /><br/><strong>{response[0].swimlane.name}</strong></Col>
                                                <Col className={'col-3'}><IntlMessage messageId="common.ticket.position" Store={Store} /><br/><strong>{response[0].position}</strong></Col>
                                            </Row>
                                            <br/>
                                            <Row>
                                                <Col className={'col-3'}><IntlMessage messageId="common.ticket.priority" Store={Store} /><br/><strong>P{response[0].priority}</strong> <span
                                                    className="material-icons float-end"
                                                    onClick={() => switchdisplay('AgileTaskPriorities')}>
                                    info
                                    </span></Col>
                                                <Col className={'col-3'}><IntlMessage messageId="common.ticket.creator" Store={Store} /><br/><strong>{response[0].creator}</strong></Col>
                                                <Col className={'col-6'}><IntlMessage messageId="common.ticket.project" Store={Store} /><br/><strong>{response[0].project.title}</strong></Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className={'col-4'}>
                                    <Card>
                                        <Card.Body>
                                            <Row>
                                                <Col>
                                                    <IntlMessage messageId="common.ticket.startOn" Store={Store} /><br/><strong>
                                                    {(response[0].start_on !== null && response[0].start_on !== undefined  ?
                                                        dateFormat(
                                                            response[0].start_on.replace('T',' '),
                                                            localStorage.getItem('locale'),
                                                            'us',
                                                            false,
                                                            false,
                                                            Store
                                                        ) :
                                                        response[0].start_on)}
                                                </strong>
                                                </Col>
                                                <Col>
                                                    <IntlMessage messageId="common.ticket.dueOn" Store={Store} /><br/><strong>
                                                    {(response[0].due_on !== null ?
                                                        dateFormat(
                                                            response[0].due_on.replace('T',' '),
                                                            localStorage.getItem('locale'),
                                                            'us',
                                                            false,
                                                            false,
                                                            Store
                                                        ):
                                                        response[0].due_on)}
                                                </strong>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <IntlMessage messageId="single.task.modified" Store={Store} /><br/><strong>
                                                    {(response[0].updated_at !== null ?
                                                            dateFormat(
                                                            response[0].updated_at.replace('T',' '),
                                                            localStorage.getItem('locale'),
                                                            'us',
                                                            true,
                                                                false,
                                                            Store
                                                        ) :
                                                    response[0].updated_at)}
                                                </strong>
                                                </Col>
                                                <Col>
                                                    <IntlMessage messageId="single.task.postponed" Store={Store} /><br/><strong>
                                                    {(response[0].postpaned_at !== null ?
                                                        dateFormat(
                                                            response[0].postpaned_at.replace('T',' '),
                                                            localStorage.getItem('locale'),
                                                            'us',
                                                            true,
                                                            false,
                                                            Store
                                                        ) :
                                                        response[0].postpaned_at)}
                                                </strong>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <IntlMessage messageId="single.task.created" Store={Store} /><br/><strong>
                                                    {(response[0].created_at !== null ?

                                                        dateFormat(
                                                            response[0].created_at,
                                                            localStorage.getItem('locale'),
                                                            'us',
                                                            true,
                                                            false,
                                                            Store
                                                        ):
                                                        response[0].created_at)}
                                                </strong>

                                                </Col>
                                                <Col>

                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className={'col-2'}>
                                    <Card>
                                        <Card.Body>
                                            <IntlMessage messageId="common.ticket.responsibility" Store={Store}/>
                                            <br/>
                                            <Avatar style={{float: 'left'}}
                                                    src={UserAvatar(AllUSer,response[0].responsibilityID)}>

                                            </Avatar> {response[0].responsibility}
                                            <br/><br/>{(showActions === true ? <>
                                            <span className="material-icons pl-3"
                                                  onClick={() => setResponsibilityTask()}
                                                  style={{verticalAlign: 'middle', fontSize: '30px', color: '#2EB0D9'}}>
                                    person_add_alt
                                    </span>

                                              <span style={{color: '#2EB0D9', cursor: 'pointer'}}
                                                    onClick={() => setResponsibilityTask()}>
                                            <IntlMessage messageId="single.task.assignToMe" Store={Store}/>
                                        </span>
                                        </> : '')}

                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                            <Col className={'pt-3'}>
                                <Stack direction="horizontal" gap={2}>
                                    {StackBadgeHolder}
                                </Stack>
                                </Col>
                            </Row>
                            <Row  id={'summaryAccordion'} className={'mt-3'} style={{display:'block'}}>
                               {/* <Accordion className={'bg-transparent'} style={{background: 'none'}} alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Description</Accordion.Header>
                                        <Accordion.Body >
                                            <div dangerouslySetInnerHTML={{__html: simpleMarkdown(response[0].description)}} />

                                            <MDEditor.Markdown
                                                source={'\n\r'+response[0].description} />
                                            </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1" aria-expanded={(BuildSubTask.length !== 0 ? 'true' : 'false')} >
                                        <Accordion.Header>Subtask</Accordion.Header>
                                        <Accordion.Body>
                                            {BuildSubTask}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Internal connections</Accordion.Header>
                                        <Accordion.Body>
                                            {BuildInternalConnections}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>External connections</Accordion.Header>
                                        <Accordion.Body>
                                            {BuildExternalConnections}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4" >
                                        <Accordion.Header>Attachments</Accordion.Header>
                                        <Accordion.Body className={'pb-3'}>
                                            {BuildAttachtment}
                                            <br style={{clear: 'both'}}/>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>Comments</Accordion.Header>
                                        <Accordion.Body>
                                            {BuildComments}
                                            <Button variant="primary" className={'float-end'} onClick={(e) => AddedFunc(Comment)}>New Comment</Button>
                                            <br style={{clear: 'both'}}/>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>*/}
                                <ReactAccordion allowMultipleExpanded allowZeroExpanded preExpanded={accordionOpen} style={{border:0}}>
                                    <ReactAccordionItem uuid="Description">
                                        <ReactAccordionItemHeading>
                                            <ReactAccordionItemButton style={{background: 'white',border: '1px solid #ddd'}}>
                                                <IntlMessage messageId="common.description" Store={Store} />
                                            </ReactAccordionItemButton>
                                        </ReactAccordionItemHeading>
                                        <ReactAccordionItemPanel style={{background:'white',borderLeft: '1px solid #ddd',borderRight: '1px solid #ddd'}}>

                                                <MDEditor.Markdown source={'\n\r'+(response[0].description !== null && response[0].description != undefined ? response[0].description : '')} />

                                        </ReactAccordionItemPanel>
                                    </ReactAccordionItem>
                                    <ReactAccordionItem uuid="Subtask">
                                        <ReactAccordionItemHeading>
                                            <ReactAccordionItemButton style={{background: 'white',border: '1px solid #ddd',borderTop:0}}>
                                                <IntlMessage messageId="single.tasks.subtask" Store={Store} />
                                            </ReactAccordionItemButton>
                                        </ReactAccordionItemHeading>
                                        <ReactAccordionItemPanel style={{background:'white',borderLeft: '1px solid #ddd',borderRight: '1px solid #ddd'}}>
                                            {BuildSubTask}
                                        </ReactAccordionItemPanel>
                                    </ReactAccordionItem>
                                    <ReactAccordionItem uuid="ic">
                                        <ReactAccordionItemHeading>
                                            <ReactAccordionItemButton style={{background: 'white',border: '1px solid #ddd',borderTop:0}}>
                                                <IntlMessage messageId="single.task.internal_connections" Store={Store} />
                                            </ReactAccordionItemButton>
                                        </ReactAccordionItemHeading>
                                        <ReactAccordionItemPanel style={{background:'white',borderLeft: '1px solid #ddd',borderRight: '1px solid #ddd'}}>
                                            {BuildInternalConnections}
                                        </ReactAccordionItemPanel>
                                    </ReactAccordionItem>
                                    <ReactAccordionItem uuid="ec">
                                        <ReactAccordionItemHeading>
                                            <ReactAccordionItemButton style={{background: 'white',border: '1px solid #ddd',borderTop:0}}>
                                                <IntlMessage messageId="single.task.external_connections" Store={Store} />
                                            </ReactAccordionItemButton>
                                        </ReactAccordionItemHeading>
                                        <ReactAccordionItemPanel style={{background:'white',borderLeft: '1px solid #ddd',borderRight: '1px solid #ddd'}}>
                                            {BuildExternalConnections}
                                        </ReactAccordionItemPanel>
                                    </ReactAccordionItem>
                                    <ReactAccordionItem uuid="Attachments">
                                        <ReactAccordionItemHeading>
                                            <ReactAccordionItemButton style={{background: 'white',border: '1px solid #ddd',borderTop:0}}>
                                                <IntlMessage messageId="single.task.attachments" Store={Store} />
                                            </ReactAccordionItemButton>
                                        </ReactAccordionItemHeading>
                                        <ReactAccordionItemPanel style={{background:'white',borderLeft: '1px solid #ddd',borderRight: '1px solid #ddd'}}>
                                            {BuildAttachtment}
                                            <br style={{clear: 'both'}}/>
                                        </ReactAccordionItemPanel>
                                    </ReactAccordionItem>
                                    <ReactAccordionItem uuid="Comments">
                                        <ReactAccordionItemHeading>
                                            <ReactAccordionItemButton style={{background: 'white',border: '1px solid #ddd',borderTop:0}}>
                                                <IntlMessage messageId="single.task.comments" Store={Store} />
                                            </ReactAccordionItemButton>
                                        </ReactAccordionItemHeading>
                                        <ReactAccordionItemPanel style={{background:'white',borderLeft: '1px solid #ddd',borderRight: '1px solid #ddd'}}>
                                            {BuildComments}
                                            {(showActions === true ? <>
                                                <Button variant="primary" className={'float-end'} onClick={(e) => AddedFunc(Comment)}>
                                                    {IntlMessageValue('','new.comment',Store)}
                                                </Button>
                                            </> : '')}

                                            <br style={{clear: 'both'}}/>
                                        </ReactAccordionItemPanel>
                                    </ReactAccordionItem>
                                </ReactAccordion>
                            </Row>
                            <Row id={'Last_activitaet'} className={'mt-5'} style={{paddingLeft:'15px',paddingRight:'15px',display:'none'}}>
                                <Card>

                                        <Card.Body>
                                            <section >
                                            <ul className="timeline">
                                                {BuildLastActivities}
                                            </ul>
                                        </section>
                                        </Card.Body>

                                </Card>
                            </Row>
                        </Col>
                    </Row>

                </div>
                {/*<div role="dialog" aria-modal="true" className="fade modal" id={'addDialogMember'}>
                    <div id={'injectModalTask'}></div>
                </div>*/}

                <div role="dialog" aria-modal="true" className="offcanvas offcanvas-end"
                     id={'addDialogInternalConnection'} style={{overflowY:'auto',minWidth:'400px',top: '60px',right: '10px',width: '40%'}}>
                    {InternalConnections()}
                </div>
                {process.env.REACT_APP_MODE_TICKET_ID !== undefined && process.env.REACT_APP_MODE_TICKET_ID !== null
                && process.env.REACT_APP_MODE_TICKET_NAME !== undefined && process.env.REACT_APP_MODE_TICKET_NAME !== null ?
                    <></> :<>
                <div role="dialog" aria-modal="true" className="offcanvas offcanvas-end"
                     id={'addDialogDuplicateTask'} style={{overflowY:'auto',minWidth:'400px',top: '60px',right: '10px',width: '40%'}}>
                    {DuplicateTask()}
                </div>
                <div role="dialog" aria-modal="true" className="offcanvas offcanvas-end"
                     id={'addDialogMoveTask'} style={{overflowY:'auto',minWidth:'400px',top: '60px',right: '10px',width: '40%'}}>
                    {MoveTask()}
                </div>
                    </>}
                <div role="dialog" aria-modal="true" className="offcanvas offcanvas-end"
                     id={'addDialogEditTask'} style={{overflowY:'auto',minWidth:'400px',top: '60px',right: '10px',width: '40%'}}>
                    {EditTask()}
                </div>

               {/* <div role="dialog" aria-modal="true" className="offcanvas offcanvas-end"
                     id={'addEditComment'} style={{overflowY:'auto',minWidth:'400px',top: '60px',right: '10px',width: '40%'}}>
                    <div className="offcanvas-body">
                        {EditComment()}
                    </div>
                </div>*/}

                <Modal show={showCloseTask} onHide={handleCloseCloseTask}>
                    <Modal.Header closeButton>
                        <Modal.Title>{IntlMessageValue('','single.task.modal.closeTask.headline',Store)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{IntlMessageValue('','single.task.modal.closeTask.content',Store)}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseCloseTask}>
                            {IntlMessageValue('','common.close',Store)}
                        </Button>
                        <Button variant="primary float-end" onClick={()=>CloseTask()}>
                            {IntlMessageValue('','single.task.modal.closeTask.button',Store)}
                        </Button>
                    </Modal.Footer>
                </Modal>



                <div role="dialog" aria-modal="true" className="offcanvas offcanvas-end"
                     id="addDialogMember" style={{overflowY:'auto',minWidth:'400px',top: '60px',right: '10px',width: '40%'}}>

                    <div className="offcanvas-body">
                        <div id="injectModalTask"></div>
                    </div>
                </div>
                <div id="AgileTaskPriorities" className="table-list tooltip-agile-row"
                     style={{display: 'none',right:'75%',top:'25%',zIndex:'9999'}}>
                    <div className="table-list-header" style={{color:'white',background:'#666666',height:'24px',}}><strong style={{verticalAlign: 'middle'}}>Prioritäten</strong>
                        <span className="material-icons float-end" style={{cursor:'pointer'}} onClick={() => switchdisplay('AgileTaskPriorities')}>
                                close
                            </span>

                    </div>

                    {PrioHolder}

                </div>

            </>
        )
    } else {
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }

}

export default SingleTask;


export const refresh = (setFetchData:any,langKey?:any) => {

    return (
        <>
            <Button variant="secondary" className={'float-end ml-3'} style={{position:'relative',right:'5px',zIndex:'96'}} onClick={(e) => setFetchData(Date.now())}>
                <span className="material-icons" style={{verticalAlign:'bottom'}}>
                    autorenew
                </span>
                {langKey !== undefined ? langKey : 'Reload Page'}

            </Button>

        </>

    )
}

export const switchdisplay = (id:any,closeAnother?:any,displayState?:any,isMoveElement:boolean=false) => {

    if(closeAnother !==undefined && closeAnother !== null) {
        let ele = document.getElementById(id) as HTMLDivElement
        if(ele !== null) {
                ele.style.display = 'block'
        }
        for(let close=0;close < closeAnother.length;close++) {
            console.log(closeAnother[close])
            let eletmp = document.getElementById(closeAnother[close]) as HTMLDivElement
            console.log(eletmp)
            if(eletmp !== null) {
                eletmp.style.display = 'none'
            }
        }
    } else {
        let ele = document.getElementById(id) as HTMLDivElement
        if(ele !== null) {
            /*console.log(ele.style.display)*/
            if(ele.style.display === 'none') {
                ele.style.display = (displayState !== undefined && displayState !== null ? displayState : 'block')
            } else if(ele.style.display === (displayState !== undefined && displayState !== null ? displayState : 'block')) {
                ele.style.display = 'none'
            }
            if(isMoveElement === true) {
                localStorage.setItem('moveInformationdisplay',ele.style.display)
            }
        }
    }



}


export const simpleMarkdown = (mdText:any) => {
    // first, handle syntax for code-block
    mdText = mdText.replaceAll(/\r\n/g, '\n')
    mdText = mdText.replaceAll(/\n~~~ *(.*?)\n([\s\S]*?)\n~~~/g, '<pre><code title="$1">$2</code></pre>' )
    mdText = mdText.replaceAll(/\n``` *(.*?)\n([\s\S]*?)\n```/g, '<pre><code title="$1">$2</code></pre>' )

    // split by "pre>", skip for code-block and process normal text
    var mdHTML = ''
    var mdCode = mdText.split( 'pre>')

    for (var i=0; i<mdCode.length; i++) {
        if ( mdCode[i].substr(-2) == '</' ) {
            mdHTML += '<pre>' + mdCode[i] + 'pre>'
        } else {
            mdHTML += mdCode[i].replace(/(.*)<$/, '$1')
                .replaceAll(/##### (.*?)\s*#*$/gm, '<h5>$1</h5>')
                .replaceAll(/#### (.*?)\s*#*$/gm, '<h4 id="$1">$1</h4>')
                .replaceAll(/### (.*?)\s*#*$/gm, '<h3 id="$1">$1</h3>')
                .replaceAll(/## (.*?)\s*#*$/gm, '<h2 id="$1">$1</h2>')
                .replaceAll(/# (.*?)\s*#*$/gm, '<h1 id="$1">$1</h1>')
                .replaceAll(/^-{3,}|^\_{3,}|^\*{3,}/gm, '<hr/>')
                .replaceAll(/``(.*?)``/gm, '<code>$1</code>' )
                .replaceAll(/`(.*?)`/gm, '<code>$1</code>' )
                .replaceAll(/^\>> (.*$)/gm, '<blockquote><blockquote>$1</blockquote></blockquote>')
                .replaceAll(/^\> (.*$)/gm, '<blockquote>$1</blockquote>')
                .replaceAll(/<\/blockquote\>\n<blockquote\>/g, '\n<br>' )
                .replaceAll(/<\/blockquote\>\n<br\><blockquote\>/g, '\n<br>' )
                .replaceAll(/!\[(.*?)\]\((.*?) "(.*?)"\)/gm, '<img alt="$1" src="$2" $3 />')
                .replaceAll(/!\[(.*?)\]\((.*?)\)/gm, '<img alt="$1" src="$2" />')
                .replaceAll(/\[(.*?)\]\((.*?) "(.*?)"\)/gm, '<a href="$2" title="$3">$1</a>')
                .replaceAll(/<http(.*?)\>/gm, '<a href="http$1">http$1</a>')
                .replaceAll(/\[(.*?)\]\(\)/gm, '<a href="$1">$1</a>')
                .replaceAll(/\[(.*?)\]\((.*?)\)/gm, '<a href="$2">$1</a>')
                .replaceAll(/^[\*|+|-][ |.](.*)/gm, '<ul><li>$1</li></ul>' ).replace(/<\/ul\>\n<ul\>/g, '\n' )
                .replaceAll(/^\d[ |.](.*)/gm, '<ol><li>$1</li></ol>' ).replace(/<\/ol\>\n<ol\>/g, '\n' )
                .replaceAll(/\*\*\*(.*)\*\*\*/gm, '<b><em>$1</em></b>')
                .replaceAll(/\*\*(.*)\*\*/gm, '<b>$1</b>')
                .replaceAll(/\*([\w \d]*)\*/gm, '<em>$1</em>')
                .replaceAll(/___(.*)___/gm, '<b><em>$1</em></b>')
                .replaceAll(/__(.*)__/gm, '<u>$1</u>')
                .replaceAll(/_([\w \d]*)_/gm, '<em>$1</em>')
                .replaceAll(/~~(.*)~~/gm, '<del>$1</del>')
                .replaceAll(/\^\^(.*)\^\^/gm, '<ins>$1</ins>')
                .replaceAll(/ +\n/g, '\n<br/>')
                .replaceAll(/\n\s*\n/g, '\n<p>\n')
                .replaceAll(/^ {4,10}(.*)/gm, '<pre><code>$1</code></pre>' )
                .replaceAll(/^\t(.*)/gm, '<pre><code>$1</code></pre>' )
                .replaceAll(/<\/code\><\/pre\>\n<pre\><code\>/g, '\n' )
                .replaceAll(/\\([`_\\\*\+\-\.\(\)\[\]\{\}])/gm, '$1' )
        }
    }

    return mdHTML.trim()
}

export const closeEsc = () => {

    let map = [
        'addDialogInternalConnection',
        'addDialogDuplicateTask',
        'addDialogMoveTask',
        'addDialogEditTask',
        'addDialogMember',
        'addDialogSwimelanes'
    ]

    for(let m = 0;m<map.length;m++){
        let tmp = document.getElementById(map[m]) as HTMLDivElement;
        if(tmp !== null){
            tmp.style.display = 'none';
        }
    }

}


export const DeleteAttachment = (url:any,aid:any,name:any,response:any,setFetchData:any,id:any,taskid:any) => {



    let sendUrl = url.replace(process.env.REACT_APP_CDN_URL+'/','')

    if(sendUrl.charAt(0) == "/") {

        sendUrl = sendUrl.slice(1)
    }

    var raw = JSON.stringify({
        "path": sendUrl
    });



    var requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization:
                'Bearer ' +
                JSON.parse(localStorage.getItem('token') as string)
        },
        body: raw,
    };

   fetch(process.env.REACT_APP_CDN_URL+"/api/delete/test/", requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));

    var requestOptionss = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization:
                'Bearer ' +
                JSON.parse(localStorage.getItem('token') as string)
        },
    };

    FetchwithMattermostOnlysetFetchData(
        {
            endpoint:'yoptimize/delete/attachdocument/'+aid+'/',
            requestOptions:requestOptionss,
            setFetchData:setFetchData,
            preText: '## Attachment wurde gelöscht :label:\n',
            text:'[Projekt: ***'+(response !== true && typeof response !== "boolean" && response?.length > 0 ? response[0].project.title : '')+'***]('+window.location.origin+'/myprojects/task/all/projects/'+id+'/system) | [Ticket ***'+(response !== true && typeof response !== "boolean" && response?.length > 0 ? response[0].regard : '')+'***]('+window.location.origin+'/myprojects/task/single/'+id+'/'+taskid+')\n \n:adult: '+localStorage.getItem('username') +' \n Attachment ***'+name+'*** wurde gelöscht.'
        }
    )
}

export const collapse = (e:any,id:any,arrowShow:any=true,noneShow:any = [],withLabel:any=false,setFetchData?:any) => {

    if(arrowShow === true) {
        let arrow = document.getElementById(id + '_arrow') as HTMLSpanElement

        let Collapse = document.getElementById(id) as HTMLDivElement;
        if (Collapse.classList.contains("d-none") === true) {
            Collapse.classList.remove("d-none");
            Collapse.classList.add("d-block");
            arrow.innerHTML = 'expand_less'
        } else {
            Collapse.classList.add("d-none");
            Collapse.classList.remove("d-block");
            arrow.innerHTML = 'expand_more'
        }
    } else {
        let Collapse = document.getElementById(id) as HTMLDivElement;
        if (Collapse.classList.contains("d-none") === true) {
            Collapse.classList.remove("d-none");
            Collapse.classList.add("d-block");
            if(withLabel === true) {
                let CollapseLabel = document.getElementById(id+'_label') as HTMLDivElement;
                CollapseLabel.classList.add("bg-primary");
                CollapseLabel.classList.remove("bg-primary-subtle");
                setFetchData(Date.now())
            }
            for(let ns=0;ns<noneShow.length;ns++) {
                let CollapseClose = document.getElementById(noneShow[ns]) as HTMLDivElement;
                CollapseClose.classList.add("d-none");
                CollapseClose.classList.remove("d-block");
                if(withLabel === true) {
                    let CollapseLabel = document.getElementById(noneShow[ns]+'_label') as HTMLDivElement;
                    CollapseLabel.classList.add("bg-primary-subtle");
                    CollapseLabel.classList.remove("bg-primary");
                    setFetchData(Date.now())
                }
            }
        }
    }

}
