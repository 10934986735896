/**
 * @typedef SpinnerJSXProps
 * @prop {string} messageId ID des Language Key
 * @prop {string} display Falls der Display Status vorab anders gesetzt werden soll
 * @prop {string} class Falls zusätzliche Klassen benötigt werden
 * @author Marcel Ugurcu
 * @version 0.0.1
 *
 */
import { Spinner } from 'react-bootstrap'
import React from 'react'

interface SpinnerJSXProps {
    text?:string
    display?: string
    className?: string
    animation?: 'border' | 'grow';
    size?: 'sm';
    variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light' | string;
}
/**
 * SpinnerJSX
 * @component
 * @example
 * <SpinnerJSX messageId={'common.loading'} />
 * @param {SpinnerJSXProps} props Übergabe werte zum Füllen des Spinners
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
 const UniSpinner = (props:SpinnerJSXProps) => {
    return (
        <>
            <div
                className={'scrollbar-overlay spinner ' + props.className}
                style={{ display: props.display }}
            >
                <Spinner animation={props.animation !== undefined ? props.animation : "border"} size={props.size} variant={props.variant} role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                <p>
                    {props.text}
                </p>
            </div>
        </>
    )
}

export default UniSpinner;
