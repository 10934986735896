import InputGroup from 'react-bootstrap/InputGroup';
import InfoButton from "../../InfoButton";
import React from "react";

interface UniInputGroupTextProps {
    /** Set Label text on Element */
    text: string,
    /** Set custom Classes on Element */
    class?: string,
    /** Set id on Element in DOM */
    id?: string,
    InfoButton?: {
        display: boolean
        text: any
    }
}

/**
 * Add custom label on Input inner InputGroup with <strong><a href="https://react-bootstrap.netlify.app/" target="_blank">React Bootstrap</a></strong> Module <a href="https://react-bootstrap.netlify.app/docs/forms/input-group" target="_blank">InputGroup.Text</a>.<br/><br/>
 *  <strong>Code Example:</strong><br/>
 * ```tsx
 * <UniForm validated={validated} Submit={handleSubmit}>
 *    <UniForm.InputGroup>
 *       <UniForm.InputGroupText text={'Your Text'}>
 *       <UniForm.FormControl type={'text'} name={'name'} placeholder={'placeholder'}  />
 *    </UniForm.InputGroup>
 * </UniForm>
 * ```
 * @param props
 * @version 0.0.1
 * @author [Marcel Ugurcu](https://wum-solution.de)
 * @constructor
 */
const UniInputGroupText = (props: UniInputGroupTextProps) => {

    let Class: string = props.class === undefined ? 'mb-3' : props.class

    let ID: string = props.id === undefined ? '' : props.id

    return (
        <>
            <InputGroup.Text id={ID} className={Class}>
                {props.InfoButton !== undefined && props.InfoButton.display === true
                    ? <><InfoButton.Wrapper>
                        <span>{props.text}</span><InfoButton
                        text={props.InfoButton.text}
                        icon="info"
                    />
                    </InfoButton.Wrapper></>
                    : props.text
                }
            </InputGroup.Text>

        </>
    )
}

export default UniInputGroupText;
