import CompanyDE from "../settings/company/localization/locales/de_DE.json"
import ProfilDE from "../settings/profil/localization/locales/de_DE.json"

import CompanyEN from "../settings/company/localization/locales/en_US.json"
import ProfilEN from "../settings/profil/localization/locales/en_US.json"

export const SettingsLanguageDE = {
    ...CompanyDE,
    ...ProfilDE
}

export const SettingsLanguageEN = {
    ...CompanyEN,
    ...ProfilEN
}
