import Form from "react-bootstrap/Form";
import React, {useRef, useState} from "react";
import {ResponseSingleTickets} from "../API/response/ticketsystem2";
import {useSelector} from "react-redux";
import {Link, useLocation, useParams} from "react-router-dom";
import {SpinnerJSX} from "../../ticketsystem/modules/components/Spinner";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../css/ticketsystem2.css'
import {ApiRoot} from "../../../@WUM/core/RestFullApi/api";
import SunEditor from "suneditor-react";
import {refresh} from "../../ticketsystem/modules/new/Pages/SingleTask";
import InputGroup from "react-bootstrap/InputGroup";
import {DynamikOffCanvas, IntlMessage, IntlMessageValue, dateFormat, error, ApiURL} from "../../../@WUM/core/Elements/index";
import {init2} from "../../../@WUM/core/form-serilizer/seriliaze/Formserialize";
import {getToken, setNavi} from "../../../@WUM/core/Function/index";
import MultiprogressBar from "./components/MultiprogressBar";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Badge from 'react-bootstrap/Badge';
import {ResponseAllTResponsibility} from "../../ticketsystem/API/response/ticket";
import {SingleTaskFetch} from "../../../@WUM/core/Elements/CheckRoute/Fetch/SingleTaskFetch";
import {SingleTicketFetch} from "../../../@WUM/core/Elements/CheckRoute/Fetch/SingleTicketFetch";
import {LockScreen} from "../../../@WUM/core/Elements";
import {CryptDecrypt} from "../../../@WUM/core/Elements/crypt/Crypt";
import {ResponseDeparmtent} from "../../settings/organisation/API/response/support_software";
import {FilterResponse} from "../../ticketsystem/modules/new/Pages/MyProjects";
import UpgradeInfoBox from "../../../@WUM/core/upgrade";
const TicketNew = (props:{value:any}) =>{
    setNavi({
        route:'/ticket/ticketsystem/'
    })

    const SunEditorConfig = () => {
        return [
            // Default
            ['italic','bold','outdent', 'indent','align', 'list','link']
        ]
    }
    const location = useLocation();
    const { id } = useParams<{ id: string }>()
    const Store = useSelector((state: any) => state)
    const [fetchData, setFetchData] = useState(0);
    const [activeButton, setactiveButton] = useState('discussion');
    let state;
    let PrioritaetState;
    let BuildLastActivities = [];
    let AllUSer = ResponseAllTResponsibility(fetchData)
    let UserHolder = [];
    if (AllUSer !== true && typeof AllUSer !== "boolean" && AllUSer?.length > 0) {
        for (let us = 0; us < AllUSer?.length; us++) {
            UserHolder.push(
                <>
                    <option value={AllUSer[us].username}>{AllUSer[us].username}</option>
                </>
            )
        }
    }
    let DepartmentHoldder: any= [];
    const Department = ResponseDeparmtent('1');
    let DepartmentNumber = 0;
    if (Department !== null && Department !== undefined && typeof Department !== "boolean" && Department.length !== 0) {
        for (let c = 0; c < Department.length; c++) {

            DepartmentNumber++;
            DepartmentHoldder.push(
                <>
                    <option value={Department[c].id}>{
                        IntlMessageValue('de',  Department[c].name, Store)
                    }</option>
                </>
            )
        }
    }
    const SearchDepartment = (id:any) => {
        if (Department !== null && Department !== undefined && typeof Department !== "boolean" && Department.length !== 0) {
            for (let c = 0; c < Department.length; c++) {
                if(id === Department[c].id){
                    return IntlMessageValue('de',  Department[c].name, Store)
                }
            }

        }
    }
    const [value, setValue] = useState('');
    const [valueComment, setValueComment] = useState('');
    const [valueTaskField, setValueTaskField] = useState('');
    const response = ResponseSingleTickets(id,fetchData,setValue);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    if (typeof response !== "boolean" && response?.length > 0) {
        if(response[0].last_activities !== undefined && response[0].last_activities !== null) {

            if(localStorage.getItem('userRights') !== null && localStorage.getItem('userRights')!== undefined && JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string).ticketsystemTicketLog === 0) {
                BuildLastActivities.push(
                    <>
                        <li className="timeline-item mb-5" style={{padding: '0px'}}>
                            <UpgradeInfoBox Store={Store} />
                        </li>
                    </>
                )

            } else {

                for (let la = response[0].last_activities.length - 1; la >= 0; la--) {
                    let LAData = response[0].last_activities[la]
                    BuildLastActivities.push(
                        <>
                            <li className="timeline-item mb-5" style={{padding: '0px'}}>
                                <h5 className="fw-bold m-0">
                                    <span className={'float-start'} style={{paddingTop:'5px',paddingLeft:'5px'}}><small>{LAData.member}</small></span>
                                </h5>
                                <br style={{clear:'both'}}/>
                                <p className="text-muted mb-2 fw-bold" style={{paddingLeft:'5px',borderRadius:'10px',border:'1px solid #E5E5E5'}}>
                                    <span dangerouslySetInnerHTML={{__html: LAData.regard}}/>
                                    <br/><small>{
                                    dateFormat(
                                        LAData.created_at,
                                        localStorage.getItem('locale'),
                                        'us',
                                        true,
                                        false,
                                        Store
                                    )
                                }</small></p>
                                <p className="text-muted" dangerouslySetInnerHTML={{__html: LAData.content}} style={{padding:'5px',borderRadius:'10px',border:'1px solid #E5E5E5'}} />
                            </li>
                        </>
                    )
                }
            }
        }

        let CommentHolder = [];
        if(response[0].ticket_comment !== undefined && response[0].ticket_comment !== null) {
            for (let c = response[0].ticket_comment.length - 1; c >= 0; c--) {
                let CData = response[0].ticket_comment[c]
                if(CData.system == null || CData.system == '0') {
                    CommentHolder.push(
                        <>
                            <div className={'m-0 p-0 ms-5 mt-3 '} style={{width:'95%'}}>
                                <Card style={{borderColor:'#662d91'}}>
                                    <Card.Body className={'p-0'}>
                                        <Card.Header style={{background:'#662d91',color:'white'}}><Row>
                                            <Col>

                                            </Col>
                                            <Col>
                                                {
                                                    dateFormat(
                                                        CData.created_at,
                                                        localStorage.getItem('locale'),
                                                        'us',
                                                        true,
                                                        false,
                                                        Store
                                                    )}
                                            </Col>
                                            <Col className={'text-right'}>
                                                <strong>{CData.member}</strong>
                                            </Col>
                                        </Row></Card.Header>
                                        <Card.Text className={'p-3'}>
                                            <div dangerouslySetInnerHTML={{__html: CData.comment}}/>
                                        </Card.Text></Card.Body>
                                </Card>
                            </div>
                        </>
                    )
                } else if(CData.system == '1') {
                    CommentHolder.push(
                        <>
                            <div className={'m-0 p-0 mt-3 me-5'} style={{width:'95%'}}>
                                <Card>
                                    <Card.Body className={'p-0'}>
                                        <Card.Header><Row>
                                            <Col>
                                                <strong>{CData.member}</strong>
                                            </Col>
                                            <Col>
                                                {
                                                    dateFormat(
                                                        CData.created_at,
                                                        localStorage.getItem('locale'),
                                                        'us',
                                                        true,
                                                        false,
                                                        Store
                                                    )}
                                            </Col>
                                            <Col>

                                            </Col>
                                        </Row></Card.Header>
                                        <Card.Text className={'p-3'}>
                                            <div dangerouslySetInnerHTML={{__html: CData.comment}}/>
                                        </Card.Text></Card.Body>
                                </Card>
                            </div>
                        </>
                    )
                }

            }
        }

        let AttachmentHolder = [];
        if(response[0].attachment !== undefined && response[0].attachment !== null) {
            for (let a = response[0].attachment.length - 1; a >= 0; a--) {
                let AData = response[0].attachment[a]
                AttachmentHolder.push(
                    <>
                        <Card className={'mb-3'}>
                            <Card.Body>
                                <Card.Title>{AData.member} <small style={{fontSize:'12px'}}>{AData.created_at}</small></Card.Title>
                                <Card.Text>
                                    <a href={AData.url} target={'_blank'}>
                                        <span className="material-icons " style={{verticalAlign:'bottom'}}>
                                            attachment
                                        </span>
                                        {AData.name}
                                    </a>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </>
                )
            }
        }
        let TaskHolder = [];

        const displaySwitcher = (id:any,anotherid:any) => {

            let first = document.getElementById(id) as HTMLDivElement;
            setactiveButton(id)

            if(first !== null) {
                first.style.display = 'block';
                for(let x=0;x<anotherid.length;x++) {
                    let second = document.getElementById(anotherid[x]) as HTMLDivElement;
                    if(second !== null) {
                        second.style.display = 'none';
                    }

                }

            }

        }

        const setStateTask = (state:any,tuid:any) => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "ticket_id": id,
                "id":tuid,
                "lastupdatefrom": localStorage.getItem('username'),
                "state": state
            });


            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
            };
            setValueComment('')
            setValueTaskField('')
            fetch(ApiRoot()+"yoptimize/state/underTask/", requestOptions)
                .then(response => response.text())
                .then(result => setFetchData(Date.now()))
                .catch(error => console.log('error', error));
        }


        if(response[0].ticket_undertask !== undefined && response[0].ticket_undertask !== null) {
            for (let tu = 0; tu < response[0].ticket_undertask.length; tu++) {
                let TUData = response[0].ticket_undertask[tu]
                TaskHolder.push(
                    <>
                        {TUData.state === '1' ?
                            <>
                                <p  style={{cursor: 'pointer'}} onClick={()=>setStateTask(0,TUData.id)}>

                                        <span className="material-icons float-start "
                                              style={{verticalAlign: 'bottom', color: 'green'}}>
                                check_circle
                                </span>


                                    <span className={'float-start'} style={{boxSizing: 'unset', paddingLeft: '10px'}} dangerouslySetInnerHTML={{__html: TUData.content}} />
                                    <br style={{clear: 'both'}}/>
                                </p>
                                <br/>
                            </>
                            :
                            <>
                                <p style={{cursor: 'pointer'}} onClick={()=>setStateTask(1,TUData.id)}>
                                <span className="material-icons float-start "
                                      style={{verticalAlign: 'bottom', color: 'gray'}}>
                                check_circle
                                </span>


                                    <span className={'float-start'} style={{boxSizing: 'unset', paddingLeft: '10px'}} dangerouslySetInnerHTML={{__html: TUData.content}} />
                                    <br style={{clear: 'both'}}/>
                                </p>
                                <br/>
                            </>
                        }
                    </>
                )
            }
        }

        if(response[0].ticket_prioritaeten.id === 1) {
            PrioritaetState = {variants:['success','secondary','secondary','secondary','secondary'],title:[IntlMessageValue('de', 'inbox.priority.Lowest', Store),IntlMessageValue('de', 'inbox.priority.Low', Store),IntlMessageValue('de', 'inbox.priority.Medium', Store),IntlMessageValue('de', 'inbox.priority.High', Store),IntlMessageValue('de', 'inbox.priority.Urgent', Store)]};
        } else if(response[0].ticket_prioritaeten.id === 2) {
            PrioritaetState = {variants:['success','success','secondary','secondary','secondary'],title:[IntlMessageValue('de', 'inbox.priority.Lowest', Store),IntlMessageValue('de', 'inbox.priority.Low', Store),IntlMessageValue('de', 'inbox.priority.Medium', Store),IntlMessageValue('de', 'inbox.priority.High', Store),IntlMessageValue('de', 'inbox.priority.Urgent', Store)]};
        }else if(response[0].ticket_prioritaeten.id === 3) {
            PrioritaetState = {variants:['success','success','warning','secondary','secondary'],title:[IntlMessageValue('de', 'inbox.priority.Lowest', Store),IntlMessageValue('de', 'inbox.priority.Low', Store),IntlMessageValue('de', 'inbox.priority.Medium', Store),IntlMessageValue('de', 'inbox.priority.High', Store),IntlMessageValue('de', 'inbox.priority.Urgent', Store)]};
        }else if(response[0].ticket_prioritaeten.id === 4) {
            PrioritaetState = {variants:['success','success','warning','danger','secondary'],title:[IntlMessageValue('de', 'inbox.priority.Lowest', Store),IntlMessageValue('de', 'inbox.priority.Low', Store),IntlMessageValue('de', 'inbox.priority.Medium', Store),IntlMessageValue('de', 'inbox.priority.High', Store),IntlMessageValue('de', 'inbox.priority.Urgent', Store)]};
        } else if(response[0].ticket_prioritaeten.id === 5) {
            PrioritaetState = {variants:['success','success','warning','danger','danger'],title:[IntlMessageValue('de', 'inbox.priority.Lowest', Store),IntlMessageValue('de', 'inbox.priority.Low', Store),IntlMessageValue('de', 'inbox.priority.Medium', Store),IntlMessageValue('de', 'inbox.priority.High', Store),IntlMessageValue('de', 'inbox.priority.Urgent', Store)]};
        }  else {
            PrioritaetState = {variants:['secondary','secondary','secondary','secondary','secondary'],title:[IntlMessageValue('de', 'inbox.priority.Lowest', Store),IntlMessageValue('de', 'inbox.priority.Low', Store),IntlMessageValue('de', 'inbox.priority.Medium', Store),IntlMessageValue('de', 'inbox.priority.High', Store),IntlMessageValue('de', 'inbox.priority.Urgent', Store)]};
        }

        if(response[0].status.id === 1) {
            state = {variants:['danger','secondary','secondary','secondary'],title:[IntlMessageValue('de', 'inbox.status.Open', Store),IntlMessageValue('de', 'inbox.status.In Progress', Store),IntlMessageValue('de', 'inbox.status.Pending', Store),IntlMessageValue('de', 'inbox.status.Resolve', Store)]};
        } else if(response[0].status.id === 2) {
            state = {variants:['danger','warning','secondary','secondary'],title:[IntlMessageValue('de', 'inbox.status.Open', Store),IntlMessageValue('de', 'inbox.status.In Progress', Store),IntlMessageValue('de', 'inbox.status.Pending', Store),IntlMessageValue('de', 'inbox.status.Resolve', Store)]};
        }else if(response[0].status.id === 3) {
            state = {variants:['danger','warning','warning','secondary'],title:[IntlMessageValue('de', 'inbox.status.Open', Store),IntlMessageValue('de', 'inbox.status.In Progress', Store),IntlMessageValue('de', 'inbox.status.Pending', Store),IntlMessageValue('de', 'inbox.status.Resolve', Store)]};
        }/*else if(response[0].status.id === 4) {
            state = {variants:['danger','warning','warning','warning','secondary'],title:['Open','In Progress','Pending','Overdue','Resolve']};
        }*/else if(response[0].status.id === 5) {
            state = {variants:['danger','warning','warning','success'],title:[IntlMessageValue('de', 'inbox.status.Open', Store),IntlMessageValue('de', 'inbox.status.In Progress', Store),IntlMessageValue('de', 'inbox.status.Pending', Store),IntlMessageValue('de', 'inbox.status.Resolve', Store)]};
        } else {
            state = {variants:['danger','secondary','secondary','secondary'],title:[IntlMessageValue('de', 'inbox.status.Open', Store),IntlMessageValue('de', 'inbox.status.In Progress', Store),IntlMessageValue('de', 'inbox.status.Pending', Store),IntlMessageValue('de', 'inbox.status.Resolve', Store)]};
        }

        const saveState = (e:any) => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "id": id,
                "status_id": e.target.value,
                "lastupdatefrom": localStorage.getItem('username')
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
            };

            fetch(ApiRoot()+"yoptimize/save/status/ticket/", requestOptions)
                .then(response => response.text())
                .then(result => setFetchData(Date.now()))
                .catch(error => console.log('error', error));
        }

        const savePrio = (e:any) => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "id": id,
                "priority": e.target.value,
                "lastupdatefrom": localStorage.getItem('username')
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
            };

            fetch(ApiRoot()+"yoptimize/save/prioritaet/ticket/", requestOptions)
                .then(response => response.text())
                .then(result => setFetchData(Date.now()))
                .catch(error => console.log('error', error));
        }

        const saveComment = () => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "ticket_id": id,
                "comment": (document.getElementsByName('CommentField')[0] as HTMLTextAreaElement).value,
                "member": localStorage.getItem('username')
            });


            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
            };
            setValueComment('')
            setValueTaskField('')
            fetch(ApiRoot()+"yoptimize/save/ticket/comment/", requestOptions)
                .then(response => response.text())
                .then(result => setFetchData(Date.now()))
                .catch(error => console.log('error', error));
        }

        let PrioEditHolder:any = []
        const setterEditor = (id:any,id2:any) => {
            (document.getElementById(id) as HTMLDivElement).style.display = 'block';
            (document.getElementById(id2) as HTMLDivElement).style.display = 'none'
        }


        const setEditorContent = (value:any,textarea?:any) =>{
            setValue(value)
            if(textarea !== undefined) {
                (document.getElementById('descriptionTextarea') as HTMLTextAreaElement).value = value
            }
        }

        const CloseDynmaikModal = () => {
            handleClose();
        }

        const successForm = (result:any) => {
            setFetchData(Date.now())
            setValue('')
            handleClose()
        }

        const errorForm = (error:any)=> {
            handleClose()
        }

        const saveResponsibility = (e:any,dataset:any) => {
            const form = e.currentTarget
            e.preventDefault()
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer "+ getToken());

            var raw = JSON.stringify(
                {
                    "id": id,
                    "regard": dataset.regard,
                    "status_id": dataset.status_id,
                    "description": dataset.description,
                    "due_on": dataset.due_on,
                    "did": dataset.did,
                    "priority": dataset.priority,
                    "instanz": dataset.instanz,
                    "responsibility": e.target.value,
                    "support_type_id":dataset.support_type,
                    "lastupdatefrom": localStorage.getItem('username')
                }
            );

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
            };

            setValue('');
            fetch(ApiURL() +"yoptimize/save/ticket/", requestOptions)
                .then(response => response.text())
                .then(result => {successForm(result)})
                .catch(error => errorForm(error));
        }

        const saveDepartment = (e:any,dataset:any) => {
            const form = e.currentTarget
            e.preventDefault()
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer "+ getToken());

            var raw = JSON.stringify(
                {
                    "id": id,
                    "regard": dataset.regard,
                    "status_id": dataset.status_id,
                    "description": dataset.description,
                    "due_on": dataset.due_on,
                    "did": e.target.value,
                    "priority": dataset.priority,
                    "instanz": dataset.instanz,
                    "responsibility": dataset.responsibility,
                    "support_type_id":dataset.support_type,
                    "lastupdatefrom": localStorage.getItem('username')
                }
            );

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
            };

            setValue('');
            fetch(ApiURL() +"yoptimize/save/ticket/", requestOptions)
                .then(response => response.text())
                .then(result => {successForm(result)})
                .catch(error => errorForm(error));
        }

        const saveSupportType = (e:any,dataset:any) => {
            const form = e.currentTarget
            e.preventDefault()
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer "+ getToken());

            var raw = JSON.stringify(
                {
                    "id": id,
                    "regard": dataset.regard,
                    "status_id": dataset.status_id,
                    "description": dataset.description,
                    "due_on": dataset.due_on,
                    "did": dataset.did,
                    "priority": dataset.priority,
                    "instanz": dataset.instanz,
                    "responsibility": dataset.responsibility,
                    "support_type_id":e.target.value,
                    "lastupdatefrom": localStorage.getItem('username')
                }
            );

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
            };

            setValue('');
            fetch(ApiURL() +"yoptimize/save/ticket/", requestOptions)
                .then(response => response.text())
                .then(result => {successForm(result)})
                .catch(error => errorForm(error));
        }

        const handlesubmit = (e:any) => {
            const form = e.currentTarget
            e.preventDefault()
            let Data = init2(form)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer "+ getToken());

            var raw = JSON.stringify(
                {
                    "id": id,
                    "regard": Data.regard,
                    "status_id": Data.status_id,
                    "description": Data.description,
                    "due_on": Data.due_on,
                    "priority": Data.priority,
                    "did": Data.did,
                    "instanz": Data.instanz,
                    "responsibility": Data.responsibility,
                    "support_type_id":Data.support_type,
                    "lastupdatefrom": localStorage.getItem('username')
                }
            );

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
            };

            setValue('');
            fetch(ApiURL() +"yoptimize/save/ticket/", requestOptions)
                .then(response => response.text())
                .then(result => {successForm(result)})
                .catch(error => errorForm(error));
        };

        const saveUnderTask = () => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "ticket_id": id,
                "content": (document.getElementsByName('TaskField')[0] as HTMLTextAreaElement).value,
                "member": localStorage.getItem('username'),
                "state": "0"
            });


            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
            };
            setValueComment('')
            setValueTaskField('')
            fetch(ApiRoot()+"yoptimize/state/underTask/", requestOptions)
                .then(response => response.text())
                .then(result => setFetchData(Date.now()))
                .catch(error => console.log('error', error));
        }

        let sendAttachComment = () => {

            let formfile = (document.getElementById('fileuploadAttachment') as HTMLInputElement).files

            if(formfile !== null) {
                for (let f = 0; f < formfile?.length; f++) {
                    let formDatas = new FormData()
                    formDatas.append('customer', process.env.REACT_APP_CDN_CLIENT as string)
                    formDatas.append('context', 'pielers_element_text_image')
                    // @ts-ignore
                    formDatas.append(`file${0}`, formfile[f])
                    var requestOptions = {
                        method: 'POST',
                        body: formDatas
                    }
                    let link
                    if (process.env.REACT_APP_MODE === 'LOCAL') {
                        link = process.env.REACT_APP_CDN_API_URL_LOCAL
                    } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
                        link = process.env.REACT_APP_CDN_API_URL_DEVELOPMENT
                    } else if (process.env.REACT_APP_MODE === 'LIVE') {
                        link = process.env.REACT_APP_CDN_API_URL_LIVE
                    }

                    fetch(link + 'upload/', requestOptions)
                        .then((response) => response.text())
                        .then((result) => {

                            var raw = JSON.stringify({
                                url: JSON.parse(result).cdn_url,
                                name: JSON.parse(result).name,
                                ticket_id: id,
                                member: localStorage.getItem('username'),
                                type:JSON.parse(result).content_type
                            })

                            var requestOptions = {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization:
                                        'Bearer ' +
                                        JSON.parse(localStorage.getItem('token') as string)
                                },
                                body: raw
                            }

                            fetch(ApiRoot()+'yoptimize/ticket/save/attachdocument/', requestOptions)
                                .then((response) => response.text())
                                .then((result) => successForm(result))
                                .catch((error) => errorForm(error))

                        })
                        .catch((e) => {
                            error(e)
                        })
                }
            }
        }

        const LockedOrder = () => {
            SingleTicketFetch({ticketid:id,lock:"1",lockby:localStorage.getItem('username'),url:location.pathname,mode:'add',setFetchData:setFetchData})
        }

        if(response[0].lock === '0' || response[0].lock === null) {
            SingleTicketFetch({ticketid:id,lock:"1",lockby:localStorage.getItem('username'),url:location.pathname,mode:'add'})
        }
        return(
            <>
                <div className={'ticketsystem'} style={{paddingLeft: '25px',paddingRight: '25px'}}>
                    {response[0].lock === "1" && response[0].lockby !== localStorage.getItem('username') ?
                        <LockScreen orderFunc={LockedOrder}
                                    header={IntlMessageValue('de', 'lock.SingleTask.header', Store)}
                                    chatButtonLabel={IntlMessageValue('de', 'lock.SingleTask.chatButton', Store)}
                                    orderButtonLabel={IntlMessageValue('de', 'lock.SingleTask.orderButton', Store)}
                                    backButtonLabel={IntlMessageValue('de', 'lock.SingleTask.backButton', Store)}
                                    text={IntlMessageValue('de', 'lock.SingleTask.text', Store).replace('[TaskID]',response[0].id).replace('[TaskRegards]',response[0].regard).replace('[LockBy]',response[0].lockby)} />
                        : <></>}
                    <h3 className={'m-2 p-0 mb-3'}>
                        <Link className={'btn btn-info float-start mr-2'}
                              to={'/ticket/ticketsystem/1'}
                              style={{background: 'white', borderColor: '#23AAE1', color: '#23AAE1'}}>
                            Back
                        </Link>
                        <IntlMessage messageId="ticket.headline" Store={Store} />
                        {refresh(setFetchData)}
                        <DynamikOffCanvas
                            openButtonLabel={<><span className="material-icons pr-2" style={{verticalAlign:'bottom'}}>
                                edit_calendar
                            </span>
                                <IntlMessage messageId="ticket.button.edittask" Store={Store} /></>}
                            saveButtonLabel={IntlMessageValue('de', 'common.save', Store)}
                            closeButtonLabel={IntlMessageValue('de', 'common.close', Store)}
                            title={'Create new ticket'}
                            ClassOpenButton={'float-end'}
                            ClassSaveButton={'float-end'}
                            body={<>

                                <div className="modal-dialog" style={{marginRight: '-23px'}}>

                                </div>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Title</InputGroup.Text>
                                    <Form.Control name={'regard'} type="title" defaultValue={(response[0].regard !== null ? response[0].regard :'')} />
                                </InputGroup>
                                <Row>
                                    <Col className={'col-6'}>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">{IntlMessageValue('de', 'common.status', Store)}</InputGroup.Text>
                                            <Form.Select aria-label="Default select example" name={'status_id'}
                                                         defaultValue={(response[0].status_id !== null ? response[0].status.id : '1')}>
                                                <option
                                                    value="1">{IntlMessageValue('de', 'inbox.status.Open', Store)}</option>
                                                <option
                                                    value="2">{IntlMessageValue('de', 'inbox.status.In Progress', Store)}</option>
                                                <option
                                                    value="3">{IntlMessageValue('de', 'inbox.status.Pending', Store)}</option>
                                                <option
                                                    value="5">{IntlMessageValue('de', 'inbox.status.Resolve', Store)}</option>
                                            </Form.Select>
                                        </InputGroup>

                                    </Col>
                                    <Col className={'col-6'}>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">{IntlMessageValue('de', 'inbox.Support-Typ', Store)}</InputGroup.Text>
                                            <Form.Select aria-label="Default select example"
                                                         name={'support_type'}
                                                         defaultValue={(response[0].support_type_id !== null ? response[0].support_type_id : '2')}
                                                         required={true}>
                                                <option
                                                    label={IntlMessageValue('de', 'inbox.Support-Typ.select', Store)}
                                                    value=""></option>
                                                <option value="2"><IntlMessage messageId={"t2.Bug"} Store={Store}/>
                                                </option>
                                                <option value="4"><IntlMessage messageId={"t2.Fehlende Funktion"}
                                                                               Store={Store}/></option>
                                                <option value="6"><IntlMessage messageId={"t2.Verbesserung"}
                                                                               Store={Store}/>
                                                </option>
                                                <option value="7"><IntlMessage messageId={"t2.Individuelle Anpassung"}
                                                                               Store={Store}/></option>
                                                <option value="8"><IntlMessage messageId={"t2.Anwenderfrage"}
                                                                               Store={Store}/></option>
                                                <option value="9"><IntlMessage messageId={"t2.Dokumentationsanfrage"}
                                                                               Store={Store}/></option>
                                                <option value="10"><IntlMessage messageId={"t2.Hardwarefrage"}
                                                                                Store={Store}/></option>
                                                <option value="11"><IntlMessage messageId={"t2.Sicherheitsprobleme"}
                                                                                Store={Store}/></option>
                                                <option value="12"><IntlMessage messageId={"t2.Leistungsprobleme"}
                                                                                Store={Store}/></option>
                                                <option value="13"><IntlMessage messageId={"t2.Kompatibilität"}
                                                                                Store={Store}/></option>
                                                <option value="14"><IntlMessage messageId={"t2.Berechtigungsprobleme"}
                                                                                Store={Store}/></option>
                                                <option value="15"><IntlMessage messageId={"t2.Feedback"}
                                                                                Store={Store}/></option>
                                                <option value="16"><IntlMessage messageId={"t2.Produktvorschläge"}
                                                                                Store={Store}/></option>
                                                <option value="17"><IntlMessage
                                                    messageId={"t2.source.Allgemeine Anfrage"}
                                                    Store={Store}/></option>
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                <Col className={'col-6'}>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1"><IntlMessage
                                                messageId="common.ticket.dueOn"
                                                Store={Store}/></InputGroup.Text>

                                            <Form.Control type="date" name={'due_on'}
                                                          defaultValue={(response[0].due_on !== null ? response[0].due_on : '')}/>

                                        </InputGroup>
                                    </Col>

                                    <Col className={'col-6'}>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text
                                                id="basic-addon1">{IntlMessageValue('de', 'common.priority', Store)}</InputGroup.Text>
                                            <Form.Select aria-label="Default select example" name={'priority'}
                                                         defaultValue={(response[0].priority !== null ? response[0].priority : '1')}
                                                         required={true}>
                                                <option label={IntlMessageValue('de', 'inbox.priority.select', Store)}
                                                        value=""></option>
                                                <option
                                                    value="1">{IntlMessageValue('de', 'inbox.priority.Lowest', Store)}</option>
                                                <option
                                                    value="2">{IntlMessageValue('de', 'inbox.priority.Low', Store)}</option>
                                                <option
                                                    value="3">{IntlMessageValue('de', 'inbox.priority.Medium', Store)}</option>
                                                <option
                                                    value="4">{IntlMessageValue('de', 'inbox.priority.High', Store)}</option>
                                                <option
                                                    value="5">{IntlMessageValue('de', 'inbox.priority.Urgent', Store)}</option>
                                            </Form.Select>
                                        </InputGroup>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={'col-6'}>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">{IntlMessageValue('de', 't2.responsibility', Store)}</InputGroup.Text>
                                            <Form.Select aria-label="Default select example" name={'responsibility'}
                                                         defaultValue={(response[0].responsibility !== null ? response[0].responsibility : '')}
                                                         required={true}>
                                                <option label="Responsibility select" value=""></option>
                                                {UserHolder}
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                    <Col className={'col-6'}>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">Herkunft</InputGroup.Text>
                                            <Form.Select aria-label="Default select example" name={'instanz'} defaultValue={(response[0].instanz !== null ? response[0].instanz :'')}
                                                         required={true} disabled={true}>
                                                <option label={response[0].instanz} value=""></option>
                                                <option value="t2_telephone"><IntlMessage messageId="t2_telephone" Store={Store} /></option>
                                                <option value="t2_email"><IntlMessage messageId="t2_email" Store={Store} /></option>
                                                <option value="t2_website"><IntlMessage messageId="t2_website" Store={Store} /></option>
                                                <option value="t2_other"><IntlMessage messageId="t2_other" Store={Store} /></option>
                                            </Form.Select>
                                        </InputGroup>

                                    </Col>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text
                                            id="basic-addon1">{IntlMessageValue('de', 'common.department', Store)}</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" name={'did'} defaultValue={(response[0].did !== null ? response[0].did :'')} >
                                            <option label={IntlMessageValue('de', 'common.filter.all', Store)}/>
                                            {DepartmentHoldder}
                                            <option value={'null'}>Nicht zugewiesen</option>
                                        </Form.Select>
                                    </InputGroup>
                                </Row>
                                <div id={'htmleditor'} style={{display: 'block'}}>
                                    <SunEditor
                                        key={'test'}

                                        name={'description'}
                                        setContents={value}
                                        setOptions={{
                                            buttonList: SunEditorConfig(),
                                            attributesWhitelist: {
                                                div: 'id',
                                                p: 'id',
                                                h: 'id'
                                            }
                                        }}
                                        height={'450px'}
                                        onChange={(value) => setEditorContent(value)}
                                    />
                                </div>
                                <br/>

                                <Form.Control name={'creator'} type="hidden"
                                              defaultValue={localStorage.getItem('username') as string}/>
                            </>}
                            handlesubmit={handlesubmit}
                            handleShow={handleShow}
                            show={show}
                            id={'TaskNew'}
                            setShow={setShow}
                            handleClose={CloseDynmaikModal}
                        />
                    </h3>

                    <Card>
                        <Card.Body className={'p-0'}>
                            <Row style={{minHeight: '80vh'}}>
                                <Col  style={{padding:'20px',paddingLeft:'30px'}}>
                                    <h3 className={'mt-2 mb-3'}>{response[0].regard}


                                    </h3>
                                    <div className={'mb-3'}>
                                        {(response[0].did !== null ? <><Badge bg="secondary"
                                                                              className={'float-end'} style={{fontSize:'16px',fontWeight:'normal'}}>
                                                {SearchDepartment(response[0].did)}
                                            </Badge></>
                                            :<></>)}
                                        {(response[0].support_type_id !== null ? <Badge bg="secondary"
                                                                                        className={'float-end mr-3'} style={{fontSize:'16px',fontWeight:'normal'}}>
                                            {(response[0].support_type_id !== null ?
                                                IntlMessageValue('de', 't2.'+response[0].ticket_type.type, Store)
                                                : '')}</Badge> : <></>)}

                                        {(response[0].status.id === 4 ?
                                            <Badge bg="danger" className={'float-end mr-3'} style={{fontSize:'16px',fontWeight:'normal'}}>Overdue</Badge> : <></>)}


                                        <span className="text-center mr-3 badge btn-primary"
                                              style={{fontSize: '16px', fontWeight: 'normal',float:'right'}}><IntlMessage
                                            messageId="ticket.responsibility"
                                            Store={Store}/> {response[0].responsibility}
                                    </span>
                                        <br style={{clear:'both'}} />
                                    </div>
                                    <Row style={{marginLeft:'0px'}}>
                                        <div className={'m-0 p-0'} >
                                            <Card>

                                                <Card.Body className={'p-0'}
                                                           style={{backgroundColor: 'rgb(229, 229, 229)'}}>
                                                    <Card.Header><Row>
                                                        <Col>
                                                            <strong><IntlMessage messageId="ticket.button.report"
                                                                                 Store={Store}/></strong>
                                                        </Col>
                                                        <Col>
                                                            {
                                                                dateFormat(
                                                                    response[0].created_at,
                                                                    localStorage.getItem('locale'),
                                                                    'us',
                                                                    true,
                                                                    false,
                                                                    Store
                                                                )
                                                            }
                                                        </Col>
                                                        <Col>
                                                            <SingleExpander id={'firstResponse'} className={'float-end'} />
                                                        </Col>
                                                    </Row></Card.Header>
                                                    <Card.Text className={'p-3'} id={'firstResponse'} style={{maxHeight:'250px',overflow:'hidden'}}>
                                                        <div
                                                            dangerouslySetInnerHTML={{__html: response[0].description}}/>
                                                    </Card.Text></Card.Body>
                                            </Card>
                                        </div>
                                    </Row>
                                    <Row id={'Communication'} style={{marginLeft:'0px'}}>

                                        {CommentHolder}
                                    </Row><Row>
                                    <Col>
                                        <p dangerouslySetInnerHTML={{__html: IntlMessageValue('de', 'ticket.write.answer', Store)}}/>
                                        <SunEditor
                                            key={'test'}
                                            name={'CommentField'}
                                            setContents={valueComment}
                                            placeholder={IntlMessageValue('de', 'ticket.comment.placeholder', Store)}
                                            setOptions={{
                                                buttonList: SunEditorConfig(),
                                                        attributesWhitelist: {
                                                            div: 'id',
                                                            p: 'id',
                                                            h: 'id'
                                                        }
                                                    }}
                                                    height={'150px'}
                                                    onChange={(value) => setValueComment(value)}
                                                />
                                                <Button variant="success" className={'float-end mt-3'} style={{width:'50px'}}
                                                        onClick={() => saveComment()}>
                                                <span className="material-icons">
                                                    send
                                                </span>
                                                </Button>
                                    </Col>
                                    </Row>
                                </Col>
                                <Col xxxl={3} xxl={3} xl={3} lg={6} md={6} sm={6} xs={12} xxs={12}  style={{position: 'relative'}}>
                                    <Card style={{height: '100%', background: '#E5E5E5'}}>
                                        <Card.Body>
                                            <ButtonGroup aria-label="Basic example">
                                                <Button
                                                    variant={(activeButton === 'discussion' ? 'primary' : 'secondary')}
                                                    onClick={() => displaySwitcher('discussion', ['task', 'attachment', 'timeline'])}>Info</Button>
                                                <Button variant={(activeButton === 'task' ? 'primary' : 'secondary')}
                                                        onClick={() => displaySwitcher('task', ['discussion', 'attachment', 'timeline'])}>{IntlMessageValue('de', 'ticket.ToDo', Store)}</Button>
                                                <Button
                                                    variant={(activeButton === 'attachment' ? 'primary' : 'secondary')}
                                                    onClick={() => displaySwitcher('attachment', ['discussion', 'task', 'timeline'])}>{IntlMessageValue('de', 'ticket.Attachment', Store)}</Button>
                                                <Button
                                                    variant={(activeButton === 'timeline' ? 'primary' : 'secondary')}
                                                    onClick={() => displaySwitcher('timeline', ['discussion', 'task', 'attachment'])}>{IntlMessageValue('de', 'ticket.Log', Store)}</Button>
                                            </ButtonGroup>

                                            <hr className={'mb-2'}/>
                                            <div id={'discussion'} style={{display: 'block'}}>

                                                <Row className="text-center">
                                                    <Col>
                                                        <Row className={'pb-0'}><Col><IntlMessage
                                                            messageId="ticket.button.ticketnumber" Store={Store}/></Col></Row>
                                                        <Row><Col
                                                            className={'fw-bolder pb-0'}>#{response[0].id}</Col></Row>
                                                    </Col>
                                                    <Col>
                                                        <Row className={'pb-0'}><Col><IntlMessage
                                                            messageId="ticket.button.dateissue"
                                                            Store={Store}/></Col></Row>
                                                        <Row><Col className={'fw-bolder pb-0'}>{
                                                            dateFormat(
                                                                response[0].created_at,
                                                                localStorage.getItem('locale'),
                                                                'us',
                                                                true,
                                                                'onlyDate',
                                                                Store
                                                            )
                                                        }</Col></Row>
                                                    </Col>
                                                </Row><Row className="text-center">

                                                <Col>
                                                    <Row className={'pb-0'}><Col><IntlMessage
                                                        messageId="ticket.button.issueby" Store={Store}/></Col></Row>
                                                    <Row><Col
                                                        className={'fw-bolder pb-0'}>{response[0].creator}</Col></Row>
                                                </Col>
                                                <Col>
                                                    <Row className={'pb-0'}><Col><IntlMessage
                                                        messageId="ticket.button.duedate" Store={Store}/></Col></Row>
                                                    <Row><Col
                                                        className={'fw-bolder pb-0'}>
                                                        { (response[0].due_on !== null  ?
                                                            dateFormat(
                                                                response[0].due_on,
                                                                localStorage.getItem('locale'),
                                                                'us',
                                                                true,
                                                                'onlyDate',
                                                                Store
                                                            ) : <></>)
                                                        }</Col></Row>
                                                </Col>
                                            </Row>
                                                <Row>
                                                    <Col className={'text-center'}>
                                                        <Row className={'pb-0'}><Col><IntlMessage messageId="t2.source"
                                                                                                  Store={Store}/></Col></Row>
                                                        <Row><Col
                                                            className={'fw-bolder pb-0'}>
                                                            <IntlMessage messageId={response[0].instanz} Store={Store} />
                                                            </Col></Row>
                                                    </Col>
                                                </Row>
                                                <hr/>
                                                <h3>Quick-Edit</h3>
                                                <Row>
                                                    <Col><InputGroup className="mb-2">
                                                        <InputGroup.Text
                                                            id="basic-addon1">{IntlMessageValue('de', 't2.responsibility', Store)}</InputGroup.Text>
                                                        <Form.Select aria-label="Default select example"
                                                                     name={'responsibility'}
                                                                     defaultValue={(response[0].responsibility !== null ? response[0].responsibility : '')}
                                                                     onChange={(e) => saveResponsibility(e, response[0])}
                                                                     required={true}>
                                                            <option label="Responsibility select" value=""></option>
                                                            {UserHolder}
                                                        </Form.Select>
                                                    </InputGroup>

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <InputGroup className="mb-3">
                                                            <InputGroup.Text
                                                                id="basic-addon1">{IntlMessageValue('de', 'common.department', Store)}</InputGroup.Text>
                                                            <Form.Select aria-label="Default select example" name={'did'}
                                                                         onChange={(e) => saveDepartment(e, response[0])}
                                                                         defaultValue={(response[0].did !== null ? response[0].did : '')}>
                                                                <option label={IntlMessageValue('de', 'common.filter.all', Store)}/>
                                                                {DepartmentHoldder}
                                                                <option value={'null'}>Nicht zugewiesen</option>
                                                            </Form.Select>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                <Col>
                                                    <Row className={'pb-3'}><Col><IntlMessage
                                                        messageId="ticket.button.status" Store={Store}/></Col></Row>
                                                    <Row><Col>
                                                        <Form.Select aria-label="Default select example"
                                                                     onChange={(e) => saveState(e)}
                                                                     defaultValue={(response[0].status_id !== null ? response[0].status.id : '1')}>
                                                            <option
                                                                value="1">{IntlMessageValue('de', 'inbox.status.Open', Store)}</option>
                                                            <option
                                                                value="2">{IntlMessageValue('de', 'inbox.status.In Progress', Store)}</option>
                                                            <option
                                                                value="3">{IntlMessageValue('de', 'inbox.status.Pending', Store)}</option>
                                                            <option
                                                                value="5">{IntlMessageValue('de', 'inbox.status.Resolve', Store)}</option>
                                                        </Form.Select>
                                                    </Col></Row>
                                                </Col>

                                                <Col>
                                                    <Row className={'pb-3'}><Col><IntlMessage
                                                        messageId="ticket.button.prio"
                                                        Store={Store}/></Col></Row>
                                                    <Row><Col>
                                                        <Form.Select aria-label="Default select example"
                                                                     onChange={(e) => savePrio(e)}
                                                                     defaultValue={(response[0].priority !== null ? response[0].ticket_prioritaeten.id : '1')}>
                                                            <option
                                                                value="1">{IntlMessageValue('de', 'inbox.priority.Lowest', Store)}</option>
                                                            <option
                                                                value="2">{IntlMessageValue('de', 'inbox.priority.Low', Store)}</option>
                                                            <option
                                                                value="3">{IntlMessageValue('de', 'inbox.priority.Medium', Store)}</option>
                                                            <option
                                                                value="4">{IntlMessageValue('de', 'inbox.priority.High', Store)}</option>
                                                            <option
                                                                value="5">{IntlMessageValue('de', 'inbox.priority.Urgent', Store)}</option>
                                                        </Form.Select>
                                                    </Col></Row>
                                                </Col>
                                            </Row><Row>
                                                <Col className={'col-12 mt-3'}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text id="basic-addon1">{IntlMessageValue('de', 'inbox.Support-Typ', Store)}</InputGroup.Text>
                                                        <Form.Select aria-label="Default select example"
                                                                     name={'support_type'}
                                                                     onChange={(e) => saveSupportType(e, response[0])}
                                                                     defaultValue={(response[0].support_type_id !== null ? response[0].support_type_id : '2')}
                                                                     required={true}>
                                                            <option
                                                                label={IntlMessageValue('de', 'inbox.Support-Typ.select', Store)}
                                                                value=""></option>
                                                            <option value="2"><IntlMessage messageId={"t2.Bug"}
                                                                                           Store={Store}/></option>
                                                            <option value="4"><IntlMessage
                                                                messageId={"t2.Fehlende Funktion"}
                                                                Store={Store}/></option>
                                                            <option value="6"><IntlMessage messageId={"t2.Verbesserung"}
                                                                                           Store={Store}/>
                                                            </option>
                                                            <option value="7"><IntlMessage
                                                                messageId={"t2.Individuelle Anpassung"}
                                                                Store={Store}/></option>
                                                            <option value="8"><IntlMessage
                                                                messageId={"t2.Anwenderfrage"}
                                                                Store={Store}/></option>
                                                            <option value="9"><IntlMessage
                                                                messageId={"t2.Dokumentationsanfrage"}
                                                                Store={Store}/></option>
                                                            <option value="10"><IntlMessage
                                                                messageId={"t2.Hardwarefrage"}
                                                                Store={Store}/></option>
                                                            <option value="11"><IntlMessage
                                                                messageId={"t2.Sicherheitsprobleme"}
                                                                Store={Store}/></option>
                                                            <option value="12"><IntlMessage
                                                                messageId={"t2.Leistungsprobleme"}
                                                                Store={Store}/></option>
                                                            <option value="13"><IntlMessage
                                                                messageId={"t2.Kompatibilität"}
                                                                Store={Store}/></option>
                                                            <option value="14"><IntlMessage
                                                                messageId={"t2.Berechtigungsprobleme"}
                                                                Store={Store}/></option>
                                                            <option value="15"><IntlMessage messageId={"t2.Feedback"}
                                                                                            Store={Store}/></option>
                                                            <option value="16"><IntlMessage
                                                                messageId={"t2.Produktvorschläge"}
                                                                Store={Store}/></option>
                                                            <option value="17"><IntlMessage
                                                                messageId={"t2.source.Allgemeine Anfrage"}
                                                                Store={Store}/></option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                                <hr/>
                                                <Row className={'m-0 p-0'}>
                                                    <Col className={'col-6 m-0 p-0'}
                                                         style={{borderRight: '1px solid gray'}}>
                                                        <Row className={'m-2 p-0'} style={{
                                                            margin: '15px',
                                                            paddingTop: '20px',
                                                            marginLeft: '0px'
                                                        }}>
                                                            <Col className={'col-12 LabelMulti p-0'}>
                                                                <p>Status:</p>
                                                                <MultiprogressBar end={4} data={state}
                                                                                  Mode={'horizontal'}/>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col className={'col-6 m-0 p-0'}>
                                                        <Row className={'m-2 p-0'} style={{
                                                            margin: '15px',
                                                            paddingTop: '20px',
                                                            marginLeft: '0px'
                                                        }}>
                                                            <Col className={'col-12 LabelMulti p-0'}>
                                                                <p>{IntlMessageValue('de', 'common.priority', Store)}:</p>
                                                                <MultiprogressBar end={5} data={PrioritaetState}
                                                                                  Mode={'horizontal'}/>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <hr/>
                                            </div>
                                            <div id={'task'} style={{display: 'none'}}>
                                                <div style={{
                                                    position: 'absolute',
                                                    top: '75px',
                                                    paddingLeft: '10px',
                                                    width: '90%',
                                                    height: '55%',
                                                    overflowX: 'hidden',
                                                    overflowY: 'auto',
                                                    marginBottom: '10px'}}>
                                                    {TaskHolder}
                                                </div>
                                                <div style={{position:'absolute',bottom:'5px',width:'90%'}}>
                                                    <SunEditor
                                                        key={'test'}

                                                        name={'TaskField'}
                                                        setContents={valueTaskField}
                                                        placeholder={IntlMessageValue('de', 'ticket.task.placeholder', Store)}
                                                        setOptions={{
                                                            buttonList: SunEditorConfig(),
                                                            attributesWhitelist: {
                                                                div: 'id',
                                                                p: 'id',
                                                                h: 'id'
                                                            }
                                                        }}
                                                        height={'150px'}
                                                        onChange={(value) => setValueTaskField(value)}
                                                    />
                                                    <Button variant="success" className={'float-end mt-3'}
                                                            onClick={() => saveUnderTask()}>
                                                <span className="material-icons">
                                                    send
                                                </span>
                                                    </Button>
                                                </div>
                                            </div>
                                            <div id={'attachment'} style={{display: 'none'}}>
                                                {AttachmentHolder}
                                                <div style={{position: 'absolute', bottom: '5px', width: '90%'}}>
                                                    <Form.Group controlId="formFileMultiple" className="mb-3">
                                                        <Form.Control id={'fileuploadAttachment'} name={'file'} type="file"
                                                                      multiple/>
                                                    </Form.Group>
                                                    <Button variant="success" className={'float-end mt-3'}
                                                            onClick={() => sendAttachComment()}>
                                                <span className="material-icons">
                                                    send
                                                </span>
                                                    </Button>
                                                </div>

                                            </div>
                                            <div id={'timeline'} style={{display: 'none'}}>
                                                <div style={{
                                                    position: 'absolute',
                                                    top: '75px',
                                                    paddingLeft: '10px',
                                                    width: '90%',
                                                    height: '85%',
                                                    overflowX: 'hidden',
                                                    overflowY: 'auto',
                                                    marginBottom: '10px'
                                                }}>

                                                    <ul className="timeline">
                                                        {BuildLastActivities}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                </div>
            </>
        )
    } else {
        return(
            <>
                <SpinnerJSX messageId={'common.loading'} />
            </>
        )
    }
}

export default TicketNew;


export const SingleExpander = (props:{id:string,className?:string}) => {

    const more = useRef<HTMLDivElement>(null);
    const less = useRef<HTMLDivElement>(null);

    const makeExpand = (id:string,mode:string) => {
        let Element = document.getElementById(id) as HTMLElement;

        if(mode === 'less') {
            Element.style.maxHeight = '250px';
            Element.style.overflow = 'hidden';

            let Less = less.current as HTMLSpanElement;
            Less.style.display = 'none';

            let More = more.current as HTMLSpanElement;
            More.style.display = 'inline-block';

        } else if(mode === 'more') {
            Element.style.maxHeight = 'none';
            Element.style.overflow = 'unset'
            let More = more.current as HTMLSpanElement;
            More.style.display = 'none';

            let Less = less.current as HTMLSpanElement;
            Less.style.display = 'inline-block';


        }
    }

    return (<>
            <div className={props.className}>
        <span className="material-symbols-outlined" ref={more} onClick={()=>makeExpand(props.id,'more')} style={{display:'inline-block'}}>
        add
        </span>

        <span className="material-symbols-outlined" ref={less} onClick={()=>makeExpand(props.id,'less')} style={{display:'none'}}>
    remove
    </span>
            </div>
        </>
)


}