import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../css/dashboard.css'
import { useSelector } from 'react-redux'
import {IntlMessageValue} from '../../../../../@WUM/core/Elements/index'

import {
    Taskmanager1er,
    MyTaskWidget1er,
    MyTaskWidget2er,
    MyTaskWidget2er2er,
    MyTaskWidget4er,
    Taskmanager2er,
    Taskmanager2er2er,
    Taskmanager4er,
    ProjectStatus1er,
    TaskStatus1er,
    ProjectStatus2er,
    TaskStatus2er,
    ProjectStatus2er2,
    TaskStatus2er2,
    TaskStatus4er, ProjectStatus4er
} from "../Widgets/Widgets";

import Accordion from 'react-bootstrap/Accordion';
import {
    ResponseWidgetDashboard,
    ResponseWidgetLastActivitat,
    ResponseWidgetMTW
} from "../../API/response/activity_recording";
import React, {useState} from "react";
import {getToken} from "../../../../../@WUM/core/Function/index";
import {ApiURL} from "../../../../../@WUM/core/component/const/ApiURL";
import Button from 'react-bootstrap/Button';
import {ResponseDashboardProject} from "../../../../ticketsystem/API/response/ticket";
import Card from "react-bootstrap/Card";
import DraggableDivGrid from "../../../../../@WUM/core/Elements/DragAbleDivGrid";
import {CryptDecrypt} from "../../../../../@WUM/core/Elements/crypt/Crypt";
import UpgradeInfoBox from "../../../../../@WUM/core/upgrade";

const DashbaordOverview = (props: { value: any }) => {
    const Store = useSelector((state: any) => state)
    const [fetchData, setFetchData] = useState(0);

    const [count, setCount] = useState(0)

    const onDropID = (id:any,x:any,y:any,w:any,h:any) => {

                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", "Bearer "+ getToken());
                let item ={
                    id:id,
                    w: w,
                    h: h,
                    x: x,
                    y: y,
                }
                var raw = JSON.stringify(item);


                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                };

              fetch(ApiURL() +"widget/dashboard/save/", requestOptions)
                    .then(response => response.text())
                    .then(result => {setFetchData(Date.now())})
                    .catch(error => console.log(error));

    };

    const onDelete = (id:any) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+ getToken());
        var raw = '';

        //widget/dashboard/save/
        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            body: raw,
        };

        fetch(ApiURL() +"widget/dashboard/delete/"+id+'/', requestOptions)
            .then(response => response.text())
            .then(result => {setFetchData(Date.now())})
            .catch(error => console.log(error));

    };


    const dragstart_handler = (id:string) => {


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+ getToken());
        let item ={
            i: id.split('|')[0]+Date.now(),
            w: id.split('|')[1],
            h: id.split('|')[2],
            x: 0,
            y: 0,
            type:id.split('|')[0],
            user:localStorage.getItem('userid')
        }
        var raw = JSON.stringify(item);


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        fetch(ApiURL() +"widget/dashboard/save/", requestOptions)
            .then(response => response.text())
            .then(result => {setFetchData(Date.now())})
            .catch(error => console.log(error));
    }



    const showElement = (id:any) => {

        let tmp = document.getElementById(id) as HTMLDivElement;
        if(tmp !== null) {
            if(tmp.style.display === 'none') {
                tmp.style.display = 'block'
            } else {
                tmp.style.display = 'none'
            }
        }

    }

    let DashbaordStateTaskManager = ResponseDashboardProject()
    let WidgetMTW = ResponseWidgetMTW(localStorage.getItem('username')?.replaceAll(' ','_'),fetchData)
    let WidgetDashboard = ResponseWidgetDashboard(localStorage.getItem('userid'),fetchData,setCount);
    let WidgetLastActivitat = ResponseWidgetLastActivitat(fetchData);

    if (WidgetLastActivitat !== true && typeof WidgetLastActivitat !== "boolean" && WidgetLastActivitat?.length > 0
        && WidgetDashboard !== true && typeof WidgetDashboard !== "boolean" && WidgetDashboard?.length > 0
        && DashbaordStateTaskManager !== true && typeof DashbaordStateTaskManager !== "boolean" && DashbaordStateTaskManager?.length > 0 ) {

        let WidgetMap = [
            {name: 'Taskmanager1er',element:<Taskmanager1er Store={Store} data={WidgetLastActivitat} /> },
            {name: 'Taskmanager2er',element:<Taskmanager2er  Store={Store} data={WidgetLastActivitat} />},
            {name: 'Taskmanager4er',element:<Taskmanager4er  Store={Store} data={WidgetLastActivitat} />},
            {name: 'Taskmanager2er2er',element:<Taskmanager2er2er  Store={Store} data={WidgetLastActivitat} />},
            {name: 'MyTaskWidget1er',element:<MyTaskWidget1er  Store={Store} data={WidgetMTW} />},
            {name: 'MyTaskWidget2er',element:<MyTaskWidget2er  Store={Store} data={WidgetMTW} />},
            {name: 'MyTaskWidget4er',element:<MyTaskWidget4er  Store={Store} data={WidgetMTW} />},
            {name: 'MyTaskWidget2er2er',element:<MyTaskWidget2er2er  Store={Store} data={WidgetMTW} />},
            {name: 'ProjectStatus1er',element:<ProjectStatus1er  Store={Store} data={DashbaordStateTaskManager} />},
            {name: 'TaskStatus1er',element:<TaskStatus1er  Store={Store} data={DashbaordStateTaskManager} />},
            {name: 'ProjectStatus2er',element:<ProjectStatus2er  Store={Store} data={DashbaordStateTaskManager} />},
            {name: 'TaskStatus2er',element:<TaskStatus2er  Store={Store} data={DashbaordStateTaskManager} />},
            {name: 'ProjectStatus2er2',element:<ProjectStatus2er2  Store={Store} data={DashbaordStateTaskManager} />},
            {name: 'TaskStatus2er2',element:<TaskStatus2er2  Store={Store} data={DashbaordStateTaskManager} />},
            {name: 'TaskStatus4er',element:<TaskStatus4er  Store={Store} data={DashbaordStateTaskManager} />},
            {name: 'ProjectStatus4er',element:<ProjectStatus4er  Store={Store} data={DashbaordStateTaskManager} />},
        ]

        let WidgetHolder = [];

            for(let w=0;w<WidgetDashboard.length;w++) {
                let DataW = WidgetDashboard[w];
                let ElementHolder = [];
                let Header = '';
                for(let el=0;el<WidgetMap.length;el++) {
                    if(DataW.type === WidgetMap[el].name) {
                        ElementHolder.push(
                            WidgetMap[el].element
                        )
                        Header = WidgetMap[el].name
                    }
                }
                WidgetHolder.push(
                    <DraggableDivGrid content={ElementHolder} id={WidgetDashboard[w].id} onDropID={onDropID} Header={IntlMessageValue('de', Header, Store)} onDelete={onDelete}
                                      className={'p-0'} style={{border: 0}} y={ DataW.y} x={DataW.x} w={parseInt(DataW.w)} h={parseInt(DataW.h)}/>
                )
            }


        return (
            <>
                <div className={'ticketsystem'}>
                    {/*<Row className={'DashbaordRow text-center'}>{dashboard}</Row>*/}

                    <Button variant="primary" className={'addButtonDashbaord'}
                            onClick={(e) => showElement('addDashbaord')}>
                    <span className="material-icons" style={{verticalAlign: 'middle'}}>
                        add_circle
                    </span>
                    </Button>

                        {WidgetHolder}



                    <div id={'addDashbaord'} className={'addDashbaord'} style={{display: 'none', overflow: 'scroll'}}>
                        <Button variant="primary" style={{position: 'absolute', top: '6px'}}
                                className={'addButtonDashbaordNew'} onClick={(e) => showElement('addDashbaord')}>
                    <span className="material-icons" style={{verticalAlign: 'middle'}}>
                        cancel
                    </span> Schließen
                        </Button>

                        <Accordion defaultActiveKey="0" alwaysOpen style={{marginTop: '50px'}}>
                            {count >= (
                                localStorage.getItem('userRights') !== null && localStorage.getItem('userRights') !== undefined && JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string).taskmanagermModulWidgetet)
                            && count >= (localStorage.getItem('userRights') !== null && localStorage.getItem('userRights') !== undefined && JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string).ticketsystemModulWidget)
                                ? <>
                                    <UpgradeInfoBox Store={Store} />
                                </> :
                                <>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>1x1</Accordion.Header>
                                        <Accordion.Body>
                                            <Row>
                                                <Col>
                                                    <div
                                                        className="droppable-element"
                                                        draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('Taskmanager1er|1|1')}
                                            >
                                                <h4>{IntlMessageValue('de', 'Taskmanager1er', Store)}</h4>
                                                <img src={'/assets/widget/Taskmanager1er.png'}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('MyTaskWidget1er|1|1')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'MyTaskWidget1er', Store)}</h4>
                                                <img src={'/assets/widget/MyTaskWidget1er.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('ProjectStatus1er|1|1')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'ProjectStatus1er', Store)}</h4>
                                                <img src={'/assets/widget/ProjectStatus1er.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('TaskStatus1er|1|1')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'TaskStatus1er', Store)}</h4>
                                                <img src={'/assets/widget/TaskStatus1er.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>1x2</Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('Taskmanager2er2er|1|2')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'Taskmanager2er2er', Store)}</h4>
                                                <img src={'/assets/widget/Taskmanager2er2er.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('MyTaskWidget2er2er|1|2')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'MyTaskWidget2er2er', Store)}</h4>
                                                <img src={'/assets/widget/MyTaskWidget2er2er.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>2x1</Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('Taskmanager2er|2|1')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'Taskmanager2er', Store)}</h4>
                                                <img src={'/assets/widget/Taskmanager2er.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('MyTaskWidget2er|2|1')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'MyTaskWidget2er', Store)}</h4>
                                                <img src={'/assets/widget/MyTaskWidget2er.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('ProjectStatus2er|2|1')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'ProjectStatus2er', Store)}</h4>
                                                <img src={'/assets/widget/ProjectStatus2er.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('ProjectStatus2er2|2|1')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'ProjectStatus2er2', Store)}</h4>
                                                <img src={'/assets/widget/ProjectStatus2er2.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('TaskStatus2er|2|1')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'TaskStatus2er', Store)}</h4>
                                                <img src={'/assets/widget/TaskStatus2er.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('TaskStatus2er2|2|1')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'TaskStatus2er2', Store)}</h4>
                                                <img src={'/assets/widget/TaskStatus2er2.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>2x2</Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('Taskmanager4er|2|2')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'Taskmanager4er', Store)}</h4>
                                                <img src={'/assets/widget/Taskmanager4er.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('MyTaskWidget4er|2|2')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'MyTaskWidget4er', Store)}</h4>
                                                <img src={'/assets/widget/MyTaskWidget4er.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('ProjectStatus4er|2|2')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'ProjectStatus4er', Store)}</h4>
                                                <img src={'/assets/widget/ProjectStatus4er.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('TaskStatus4er|2|2')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'TaskStatus4er', Store)}</h4>
                                                <img src={'/assets/widget/TaskStatus4er.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Accordion.Body>

                            </Accordion.Item>
    </>}
                        </Accordion>
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className={'ticketsystem'}>

                    <Button variant="primary" className={'addButtonDashbaord'}
                            onClick={(e) => showElement('addDashbaord')}>
                    <span className="material-icons" style={{verticalAlign: 'middle'}}>
                        add_circle
                    </span>
                    </Button>
                    <DraggableDivGrid y={0} x={0} w={2} h={2}
                                      style={{border: 0}} content={<>
                        <Card style={{background: 'none'}}>
                            <Card.Body className={'p-3 text-center'}>
                                <p style={{fontWeight: 'bold'}}
                                   dangerouslySetInnerHTML={{__html: IntlMessageValue('de', 'welcome.message.header', Store) + ' ' + localStorage.getItem('username')}}/>
                                <p dangerouslySetInnerHTML={{__html: IntlMessageValue('de', 'welcome.messsage.content', Store)}}/>
                            </Card.Body>
                        </Card>
                    </>} className={'p-0'}/>
                    <div id={'addDashbaord'} className={'addDashbaord'} style={{display: 'none', overflow: 'scroll'}}>
                        <Button variant="primary" style={{position: 'absolute', top: '6px'}}
                                className={'addButtonDashbaordNew'} onClick={(e) => showElement('addDashbaord')}>
                    <span className="material-icons" style={{verticalAlign: 'middle'}}>
                        cancel
                    </span> Schließen
                        </Button>

                        <Accordion defaultActiveKey="0" alwaysOpen style={{marginTop: '50px'}}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>1x1</Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('Taskmanager1er|1|1')}
                                            >
                                                <h4>{IntlMessageValue('de', 'Taskmanager1er', Store)}</h4>
                                                <img src={'/assets/widget/Taskmanager1er.png'}
                                                     />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('MyTaskWidget1er|1|1')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'MyTaskWidget1er', Store)}</h4>
                                                <img src={'/assets/widget/MyTaskWidget1er.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('ProjectStatus1er|1|1')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'ProjectStatus1er', Store)}</h4>
                                                <img src={'/assets/widget/ProjectStatus1er.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('TaskStatus1er|1|1')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'TaskStatus1er', Store)}</h4>
                                                <img src={'/assets/widget/TaskStatus1er.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>1x2</Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('Taskmanager2er2er|1|2')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'Taskmanager2er2er', Store)}</h4>
                                                <img src={'/assets/widget/Taskmanager2er2er.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('MyTaskWidget2er2er|1|2')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'MyTaskWidget2er2er', Store)}</h4>
                                                <img src={'/assets/widget/MyTaskWidget2er2er.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>2x1</Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('Taskmanager2er|2|1')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'Taskmanager2er', Store)}</h4>
                                                <img src={'/assets/widget/Taskmanager2er.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('MyTaskWidget2er|2|1')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'MyTaskWidget2er', Store)}</h4>
                                                <img src={'/assets/widget/MyTaskWidget2er.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('ProjectStatus2er|2|1')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'ProjectStatus2er', Store)}</h4>
                                                <img src={'/assets/widget/ProjectStatus2er.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('ProjectStatus2er2|2|1')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'ProjectStatus2er2', Store)}</h4>
                                                <img src={'/assets/widget/ProjectStatus2er2.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('TaskStatus2er|2|1')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'TaskStatus2er', Store)}</h4>
                                                <img src={'/assets/widget/TaskStatus2er.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('TaskStatus2er2|2|1')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'TaskStatus2er2', Store)}</h4>
                                                <img src={'/assets/widget/TaskStatus2er2.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>2x2</Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('Taskmanager4er|2|2')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'Taskmanager4er', Store)}</h4>
                                                <img src={'/assets/widget/Taskmanager4er.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('MyTaskWidget4er|2|2')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'MyTaskWidget4er', Store)}</h4>
                                                <img src={'/assets/widget/MyTaskWidget4er.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('ProjectStatus4er|2|2')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'ProjectStatus4er', Store)}</h4>
                                                <img src={'/assets/widget/ProjectStatus4er.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div
                                                className="droppable-element"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => dragstart_handler('TaskStatus4er|2|2')}
                                            >
                                                <h4 >{IntlMessageValue('de', 'TaskStatus4er', Store)}</h4>
                                                <img src={'/assets/widget/TaskStatus4er.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
            </>
        )
    }
}

export default DashbaordOverview
