import { LanguageSwitcherValue } from '../components/LanguageSwitcher'
import { isEmptyArray } from 'formik'
import HelperOverviewthProps from '../../Interface/HelperOverviewth.interface'

export const HelperOverviewth = (props: HelperOverviewthProps) => {
    if (props.row !== undefined && !isEmptyArray(props.row)) {
        let OptionSwimlane = []

        for (let x = 0; x < props.row.row.length; x++) {
            OptionSwimlane.push(
                <>
                    <th className="backalgin">
                        {LanguageSwitcherValue(
                            'board.' + props.row.row[x].name + ''
                        )}
                    </th>
                </>
            )
        }
        return <>{OptionSwimlane}</>
    } else {
        return <></>
    }
}
