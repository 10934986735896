import {JSXElementConstructor, ReactElement, ReactNode, ReactPortal} from "react";
import Modal from 'react-bootstrap/Modal';
import React from 'react'
interface UniModalBodyProps{
    /** Allows it to have children and have them reflected. */
        elements: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined
}

export const UniModalBody = (props:UniModalBodyProps) => {

    return(
        <>
            <Modal.Body>
                {props.elements}
            </Modal.Body>
        </>
    )
}
