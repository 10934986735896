import {Form} from "react-bootstrap";
import React, {ChangeEvent, useEffect, useRef} from "react";
import CountryAll from "./country.json";
import FederalStateGerman from "./federalStatesGerman.json"

interface CountryProps{
    defaultValue:string
    name:string
    className:string
    mode: 'federalState' | 'country'
    id?:string
    /** Combine Element with Onchange Function and Event */
    onChange?: (event: ChangeEvent) => void
}

const Country = (props:CountryProps) => {

    const SelectFileRef = useRef( null );

    let setCountry = CountryAll

    let setFederalState = FederalStateGerman;

    let Data = (props.mode === 'federalState' ? setFederalState : (props.mode === 'country' ? setCountry : setCountry))

    useEffect(() => {
        if(SelectFileRef.current !== null) {
        const element = (SelectFileRef.current as HTMLSelectElement)
            if(element !== null) {
                const optionNodes = Array.from(element.children);
                const comparator = new Intl.Collator('de').compare;
                // @ts-ignore
                optionNodes.sort((a, b) => comparator(a.textContent, b.textContent));
                // @ts-ignore
                optionNodes.forEach((option) => element.appendChild(option));
            }
        }
    })

    return(
        <Form.Select  ref={SelectFileRef} id={props.id} name={props.name} className={props.className} defaultValue={props.defaultValue} onChange={props.onChange}>
            {Data.map((route:any, index:any) =>
                <option value={route.key  as string}>{route.value}</option>
            )}
        </Form.Select>

    )
}

export default Country;


export const enabledFederalState = (props:{e?:any,value?:any,id:any}) => {
    let check = (props.e !== undefined ? props.e.target.value : props.value)
    if(check !== 'DE') {
        let FederalState = document.getElementById(props.id) as HTMLDivElement;
        if(FederalState !== null) {
            FederalState.style.display = 'none';
        }
    } else {
        let FederalState = document.getElementById(props.id) as HTMLDivElement;
        if(FederalState !== null) {
            FederalState.style.display = 'flex';
        }
    }
}
