import React from 'react'
import Form from 'react-bootstrap/Form'
import legende from './helper/legende'
import buildOption from './helper/buildOption'
import { Row } from 'react-bootstrap'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import $ from 'jquery'
import ReactDOM from 'react-dom'
import deleteFetchButton from './helper/deleteFetch'
import deleteTaxonButton from './helper/deleteTaxon'
import { ValidateMessage, IntlMessage, IntlMessageValue } from '../../Elements/index'

function Fetchselect(data: any) {
    let rows = JSON.parse(localStorage.getItem(data.local) as string)

    if (rows === undefined || rows === null) {
        return
    }
    let i = data.onClickBeginn !== undefined ? data.onClickBeginn : 0
    const required =
        data.required !== undefined && data.required !== null ? true : false

    const validmessage = (
        <ValidateMessage valid={data.valid} Store={data.Store} />
    )

    const row = []
    if (data.nonetype !== undefined) {
        row.push(buildOption({ value: '', label: '' }, '', 0))
    } else {
        row.push(buildOption({ value: 0, label: '' }, '', 0))
    }
    if (data.beginn !== 'true') {
        for (let e = 1; e < rows[0].length; e++) {
            row.push(
                buildOption(
                    {
                        value: rows[0][e][data.valueState],
                        label: rows[0][e][data.labelState]
                    },
                    '',
                    e
                )
            )
        }
    } else {
        if (data.start !== undefined) {
            for (let e = data.start; e < rows.length; e++) {
                row.push(
                    buildOption(
                        {
                            value: rows[e][data.valueState],
                            label:
                                data.uppercase !== undefined
                                    ? rows[e][data.labelState].toUpperCase()
                                    : rows[e][data.labelState]
                        },
                        data.selected,
                        e
                    )
                )
            }
        } else {
            for (let e = 0; e < rows[0].length; e++) {
                row.push(
                    buildOption(
                        {
                            value: rows[0][e][data.valueState],
                            label:
                                data.uppercase !== undefined
                                    ? rows[0][e][data.labelState].toUpperCase()
                                    : rows[0][e][data.labelState]
                        },
                        '',
                        e
                    )
                )
            }
        }
    }
    // This function is triggered when the select changes
    const selectChanges = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value
        // rows[]

        let rowProducts = []
        for (let x = 0; x < rows.length; x++) {
            if (rows[x].id === parseInt(value)) {
                rowProducts.push(rows[x])
            }
        }

        let rowElement = []
        for (let x = 0; x < rows[0].length; x++) {
            if (rows[0][x].id === parseInt(value)) {
                rowElement.push(rows[0][x])
            }
        }

        let rowTaxon = []

        for (let x = 0; x < rows.length; x++) {
            if (rows[x].translatable_id === parseInt(value)) {
                rowTaxon.push(rows[x])
            }
        }

        let rowTax = []

        for (let x = 0; x < rows.length; x++) {
            if (rows[x].id === parseInt(value)) {
                rowTax.push(rows[x])
            }
        }
        let rowRoles = []

        for (let x = 0; x < rows.length; x++) {
            if (rows[x].id === parseInt(value)) {
                rowRoles.push(rows[x])
            }
        }

        let rowShipping = []

        for (let x = 0; x < rows.length; x++) {
            if (rows[x].id === parseInt(value)) {
                rowShipping.push(rows[x])
            }
        }

        if (data.type === 'taxon') {
            let newElement = createTaxon({ item: rowTaxon[0] }, i, data.Store)
            let temp = document.createElement('div')
            ReactDOM.render(newElement, temp)
            let ele = document.getElementById(data.onClick_id)
            if (ele !== null) {
                ele.appendChild(temp)
            }

            i++
        } else if (data.type === 'formluar') {
        } else if (data.type === 'taxonMerchant') {
            let newElement = createTaxonMerchant(
                { item: rowTaxon[0] },
                i,
                data.Store
            )
            let temp = document.createElement('div')
            ReactDOM.render(newElement, temp)
            let ele = document.getElementById(data.onClick_id)
            if (ele !== null) {
                ele.appendChild(temp)
            }

            i++
        } else if (data.type === 'Products') {
            let newElement = createElement(
                { item: rowProducts[0] },
                i,
                data.appendid,
                data.idforward
            )
            let temp = document.createElement('div')
            ReactDOM.render(newElement, temp)
            let ele = document.getElementById(data.onClick_id)
            if (ele !== null) {
                ele.appendChild(temp)
            }

            i++
        } else if (data.type === 'Shipping') {
            let newElement = createShipping(
                { item: rowShipping[0] },
                20,
                data.Store
            )
            let temps = document.createElement('div')
            ReactDOM.render(newElement, temps)
            let ele = document.getElementById(data.onClick_id)
            let clear = document.getElementById('shipping')
            if (ele !== null) {
                if (clear !== null) {
                    clear.innerHTML = ''
                }
                ele.innerHTML = ''
                ele.appendChild(temps)
            }
        } else if (data.type === 'Tax') {
        } else if (data.type === 'Roles') {
        } else if (data.type === 'author') {
        } else {
            let newElement = createElement(
                { index: 5, item: rowElement[0] },
                5,
                data.appendid,
                data.idforward
            )
            $(newElement).appendTo($('.append'))
        }
    }
    if (data.type === 'taxon') {
        return (
            <>
                <Form.Group
                    controlId={'select' + data.label}
                    key={'select' + data.label}
                    className="mb-3"
                >
                    <Form.Label>{data.label}</Form.Label>
                    <Form.Select
                        name={data.name}
                        aria-label="Floating label select example"
                        defaultValue={data.selected}
                        onChange={selectChanges}
                        required={required}
                    >
                        {row}
                    </Form.Select>
                    {validmessage}
                </Form.Group>
                {legende(data)}
            </>
        )
    } else if (data.type === 'taxonMerchant') {
        return (
            <>
                <Form.Group
                    controlId={'select' + data.label}
                    key={'select' + data.label}
                    className="mb-3"
                >
                    <Form.Label>{data.label}</Form.Label>
                    <Form.Select
                        name={data.name}
                        aria-label="Floating label select example"
                        defaultValue={data.selected}
                        onChange={selectChanges}
                        required={required}
                    >
                        {row}
                    </Form.Select>
                    {validmessage}
                </Form.Group>
                {legende(data)}
            </>
        )
    } else if (data.type === 'author') {
        return (
            <>
                <Form.Group
                    controlId={'select' + data.label}
                    key={'select' + data.label}
                    className="mb-3"
                >
                    <Form.Label>{data.label}</Form.Label>
                    <Form.Select
                        name={data.name}
                        aria-label="Floating label select example"
                        defaultValue={data.selected}
                        onChange={selectChanges}
                        required={required}
                    >
                        {row}
                    </Form.Select>
                    {validmessage}
                </Form.Group>
                {legende(data)}
            </>
        )
    } else if (data.type === 'Shipping') {
        return (
            <>
                <Form.Group
                    controlId={'select' + data.label}
                    key={'select' + data.label}
                    className="mb-3"
                >
                    <Form.Label>{data.label}</Form.Label>
                    <Form.Select
                        name={data.name}
                        aria-label="Floating label select example"
                        defaultValue={data.selected}
                        onChange={selectChanges}
                        required={required}
                    >
                        {row}
                    </Form.Select>
                    {validmessage}
                </Form.Group>
                {legende(data)}
            </>
        )
    } else if (data.type === 'Tax') {
        return (
            <>
                <Form.Group
                    controlId={'select' + data.label}
                    key={'select' + data.label}
                    className="mb-3"
                >
                    <Form.Label>{data.label}</Form.Label>
                    <Form.Select
                        name={data.name}
                        aria-label="Floating label select example"
                        defaultValue={data.selected}
                        required={required}
                    >
                        {row}
                    </Form.Select>
                    {validmessage}
                </Form.Group>
                {legende(data)}
            </>
        )
    } else if (data.type === 'Roles') {
        return (
            <>
                <Form.Group
                    controlId={'select' + data.label}
                    key={'select' + data.label}
                    className="mb-3"
                >
                    <Form.Label>{data.label}</Form.Label>
                    <Form.Select
                        name={data.name}
                        aria-label="Floating label select example"
                        defaultValue={data.selected}
                        required={required}
                    >
                        {row}
                    </Form.Select>
                    {validmessage}
                </Form.Group>
                {legende(data)}
            </>
        )
    } else if (data.onClickType === 'true') {
        const selectChangesdisabled = (groupDisbled: any, name: any) => {
            let sendElement = document.getElementsByName(
                name
            )[0] as HTMLSelectElement

            if (sendElement.value !== '0') {
                for (let x = 0; x < groupDisbled.length; x++) {
                    let element = document.getElementsByName(
                        groupDisbled[x]
                    )[0] as HTMLSelectElement
                    element.disabled = true
                }
            } else {
                for (let x = 0; x < groupDisbled.length; x++) {
                    let element = document.getElementsByName(
                        groupDisbled[x]
                    )[0] as HTMLSelectElement
                    element.disabled = false
                }
            }
        }

        return (
            <>
                <Form.Group
                    controlId={'select' + data.label}
                    key={'select' + data.label}
                    className="mb-3"
                >
                    <Form.Label>{data.label}</Form.Label>
                    <Form.Select
                        name={data.name !== undefined ? data.name : 'parent_id'}
                        aria-label="Floating label select example"
                        defaultValue={data.selected}
                        onChange={() =>
                            selectChangesdisabled(data.groupDisbled, data.name)
                        }
                        required={required}
                    >
                        {row}
                    </Form.Select>
                    {validmessage}
                </Form.Group>
                {legende(data)}
            </>
        )
    } else if (data.onClick === 'true') {
        return (
            <>
                <Form.Group
                    controlId={'select' + data.label}
                    key={'select' + data.label}
                    className="mb-3"
                >
                    <Form.Label>{data.label}</Form.Label>
                    <Form.Select
                        name={data.name !== undefined ? data.name : 'parent_id'}
                        aria-label="Floating label select example"
                        defaultValue={data.selected}
                        onChange={selectChanges}
                        required={required}
                    >
                        {row}
                    </Form.Select>
                    {validmessage}
                </Form.Group>
                {legende(data)}
            </>
        )
    } else {
        let id = data.id !== undefined ? data.id : 'select' + data.label
        return (
            <>
                <Form.Group
                    controlId={id}
                    key={'select' + data.label}
                    className="mb-3"
                >
                    <Form.Label>{data.label}</Form.Label>
                    <Form.Select
                        name={data.name !== undefined ? data.name : 'parent_id'}
                        aria-label="Floating label select example"
                        defaultValue={data.selected}
                        disabled={
                            data.disabled !== undefined &&
                            data.disabled !== 'false'
                                ? data.disabled
                                : ''
                        }
                        required={required}
                    >
                        {row}
                    </Form.Select>
                    {validmessage}
                </Form.Group>
                {legende(data)}
            </>
        )
    }
}
export default Fetchselect

const createElement = (data: any, x: any, appendid: any, idforward: any) => {
    let addId = idforward.length + x
    if (idforward.length === undefined) {
        addId = 0 + x
    }
    return (
        <>
            <Row>
                <InputGroup
                    className="mb-3"
                    id={appendid + '[Products]' + addId}
                >
                    <InputGroup.Text>Produkt</InputGroup.Text>
                    <FormControl
                        name={
                            appendid + '[products][' + addId + '][product_id]'
                        }
                        type={'hidden'}
                        aria-label="id"
                        value={data.item.id}
                    />
                    <InputGroup.Text>Code:</InputGroup.Text>
                    <FormControl
                        name={appendid + '[products][' + addId + '][code]'}
                        type={'text'}
                        disabled={true}
                        aria-label="code"
                        value={data.item.code}
                    />
                    <InputGroup.Text>Name:</InputGroup.Text>
                    <FormControl
                        name={appendid + '[products][' + addId + '][name]'}
                        aria-label="name"
                        value={data.item.name}
                        readOnly={true}
                    />
                    {deleteFetchButton(appendid + '[products]' + addId)}
                </InputGroup>
            </Row>
        </>
    )
}

const createShipping = (data: any, i: any, Store: any) => {
    data.legende = (
        <IntlMessage Store={Store} messageId="shipping.price_format.legend" />
    )
    const validate = 'einen gültigen Wert'
    return (
        <Row id={'shippingprice'}>
            <InputGroup className="mb-3 append" id={'Shipping' + data.item.id}>
                <InputGroup.Text>
                    <IntlMessage Store={Store} messageId="common.method" />
                </InputGroup.Text>
                <FormControl
                    name={'Shipping[' + data.item.id + '][id]'}
                    type={'hidden'}
                    aria-label="id"
                    value={data.item.id}
                />
                <FormControl
                    name={'Shipping[' + data.item.id + '][name]'}
                    aria-label="name"
                    value={data.item.name}
                    readOnly={true}
                />
                <InputGroup.Text>
                    <IntlMessage
                        Store={Store}
                        messageId="common.shipping_price"
                    />
                </InputGroup.Text>
                <FormControl
                    id={'shippingpriceInput'}
                    name={'Shipping[' + data.item.id + '][price]'}
                    defaultValue={'0,00'}
                    type={'text'}
                    aria-label="price"
                    placeholder={IntlMessageValue(
                        'de',
                        'shipping.placeholder.price',
                        Store
                    )}
                    required={true}
                />
                <InputGroup.Text>€</InputGroup.Text>
                {deleteFetchButton('Shipping' + data.item.id)}
                <Form.Control.Feedback type="invalid">
                    <IntlMessage
                        Store={Store}
                        messageId="notification.invalid_value"
                        preperator={'replace'}
                        replace={validate}
                        param={'[valid]'}
                    />
                </Form.Control.Feedback>
            </InputGroup>
            {legende(data)}
        </Row>
    )
}

const createTaxon = (data: any, i: any, Store: any) => {
    return (
        <Row>
            <InputGroup className="mb-3 append" id={'taxon99' + i}>
                <InputGroup.Text>
                    <IntlMessage Store={Store} messageId="common.category" />
                </InputGroup.Text>
                <FormControl
                    name={'Artikelkategorie[99' + i + '][id]'}
                    type={'hidden'}
                    aria-label="id"
                    value={data.item.translatable_id}
                />
                <FormControl
                    name={'Artikelkategorie[99' + i + '][name]'}
                    aria-label="name"
                    value={data.item.name}
                    readOnly={true}
                />
                {deleteFetchButton('taxon99' + i)}
            </InputGroup>
        </Row>
    )
}

const createTaxonMerchant = (data: any, i: any, Store: any) => {
    return (
        <Row>
            <InputGroup className="mb-3 append" id={'taxonMerchant' + i}>
                <InputGroup.Text>
                    <IntlMessage
                        Store={Store}
                        messageId="common.provider_category"
                    />
                </InputGroup.Text>
                <FormControl
                    name={'taxons[' + i + '][id]'}
                    className={'taxon'}
                    title={IntlMessageValue('de', 'merchant.taxon.id', Store)}
                    type={'hidden'}
                    aria-label="id"
                    value={data.item.translatable_id}
                />
                <FormControl
                    name={'taxons[' + i + '][name]'}
                    className={'taxon'}
                    title={IntlMessageValue('de', 'merchant.taxon.name', Store)}
                    type={'text'}
                    aria-label="name"
                    value={data.item.name}
                    readOnly={true}
                />
                <FormControl
                    name={'taxons[' + i + '][order]'}
                    className={'taxon'}
                    title={IntlMessageValue(
                        'de',
                        'merchant.taxon.order',
                        Store
                    )}
                    type={'text'}
                    aria-label="name"
                    value={data.item.order}
                    placeholder={IntlMessageValue(
                        'de',
                        'provider.category.placeholder',
                        Store
                    )}
                />
                {deleteTaxonButton('taxonMerchant' + i)}
            </InputGroup>
        </Row>
    )
}
