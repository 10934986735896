import {SingletaskActionType} from "../../Actions/ActionType";

const initialState = {
    loading: false,
    comment: [{comment:''}]
}

type action = {
    type: string
    comment?: any
}

const SingleTasksReducer = (state = initialState, action: action) => {
    switch (action.type) {
        case SingletaskActionType.Singletask_INIT:
            return {
                ...state,
                comment: action.comment,
                loading: true
            }
        default:
            return state
    }
}

export default SingleTasksReducer
