import Table from "react-bootstrap/Table";
import React, {useEffect, useState} from "react";
import {
    ResponseAllCPS, ResponseAllPrio,
    ResponseAllProject, ResponseAllTaskSwimelanes,
    ResponseAllTResponsibility,
    ResponsemyTasks, ResponseSingleProject
} from "../../../API/response/ticket";
import {useNavigate} from "react-router-dom";
import { dateFormat, IntlMessage, IntlMessageValue, DynamikOffCanvas, error, ApiURL } from "../../../../../@WUM/core/Elements/index";
import {useSelector} from "react-redux";
import {closeEsc, collapse, refresh, switchdisplay} from "./SingleTask";
import {setNavi, getToken} from "../../../../../@WUM/core/Function/index";
import Dropdown from 'react-bootstrap/Dropdown';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu-v2";
import {ApiRoot} from "../../../../../@WUM/core/RestFullApi/api";
import {notify_save} from "../../../../../@WUM/core/component/const/notification.service";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import {AddedColor, InformationTicket, MoveElement, savedelete} from "./MyTask";
import InputGroup from "react-bootstrap/InputGroup";
import SunEditor from "suneditor-react";
import SunEditorConfig from "../../components/SuneditorConfig";
import MarkdownEditor from "@uiw/react-markdown-editor";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SendMessage, {FetchwithMattermostANDsuccessFormWithClosedivIdandTrue} from "../components/Mattermost";
import PielersForm from "../../../../../@WUM/core/form-serilizer";
import {init2} from "../../../../../@WUM/core/form-serilizer/seriliaze/Formserialize";
import {Calendar, momentLocalizer} from "react-big-calendar";
import CreateGrantDiagramm from "../components/Grant";
import moment from "moment";
import {Task} from "gantt-task-react";
const MytaskUser = (props:{value:any}) => {
    setNavi({
        route:'/MytaskUser/overview/'
    })
    const [OverdueHolderSortData, setOverdueHolderSortData] = useState([]);
    const [DuothisWeekHolderSortData, setDuothisWeekHolderSortData] = useState([]);
    const [DuelaterHolderSortData, setDuelaterHolderSortData] = useState([]);
    const [NoDueDateHolderSortData, setNoDueDateHolderSortData] = useState([]);
    const [fetchData, setFetchData] = useState(0);
    const [ctc,setCtc] = useState('');
    const [showEdit,setShowEdit] = useState('');
    const [showDuplicate,setShowDuplicate] = useState('');
    const [swimlaneID, setSwimlaneID] = useState('0');
    const response = ResponsemyTasks(localStorage.getItem('username')?.replaceAll(' ','_'),fetchData);
    const navigate = useNavigate();
    let Swimelanes = ResponseAllTaskSwimelanes(swimlaneID,fetchData)
    const singleProject = ResponseSingleProject(swimlaneID);
    const Configuration = {
        tableHeader: [
            'ID',
            'Task',
            'Duo Date',
            'Project',
            'State',
        ],
        tableHeaderType: ['string','string','string','string','string'],
        tableContent: ['task_id','regard','due_on','project.title','status_id']
    }
    let AllUSer = ResponseAllTResponsibility(fetchData)
    let UserHolder = [];

    if (AllUSer !== true && typeof AllUSer !== "boolean" && AllUSer?.length > 0) {
        for (let us = 0; us < AllUSer?.length; us++) {
            UserHolder.push(
                <>
                    <option value={AllUSer[us].username}>{AllUSer[us].username}</option>
                </>
            )
        }
    }
    const AllCPS = ResponseAllCPS(fetchData);
    let ColorHolder:any = [];
    if (typeof AllCPS !== "boolean" && AllCPS?.length > 0) {
        for (let c = 0; c < AllCPS.length; c++) {
            ColorHolder.push({color:AllCPS[c].color,title:AllCPS[c].title})
        }
    }
    const [state, setState] = useState({
        background: '#fff',
    });
    const [value, setValue] = useState("");
    const setEditorContent = (value:any,textarea?:any) =>{
        setValue(value)
        if(textarea !== undefined) {
            (document.getElementById('descriptionTextarea') as HTMLTextAreaElement).value = value
        }
    }
    const setterEditor = (id:any,id2:any) => {
        (document.getElementById(id) as HTMLDivElement).style.display = 'block';
        (document.getElementById(id2) as HTMLDivElement).style.display = 'none'
    }
    let OverdueHolder = [];
    let DuothisWeekHolder = [];
    let DuelaterHolder = [];
    let NoDueDateHolder = [];
    const Store = useSelector((state: any) => state)
    const setStartSwim = (id:any) => {
        setSwimlaneID(id)
    }
    const triggerCMenu = (data:any) => {
        setStartSwim(data.project_id)
        let M1 = document.getElementById('m2') as HTMLLinkElement;
        if(M1 !== null) {
            M1.href ='/myprojects/task/single/'+data.project_id+'/'+data.task_id
        }
        let id = document.getElementById('Projectid') as HTMLInputElement
        if(id !== null) {
            id.value = data.project_id
        }
        let taskid = document.getElementById('taskiD') as HTMLInputElement
        if(taskid !== null) {
            taskid.value = data.task_id
        }
        let saveSwimelanecolor = document.getElementById('saveSwimelanecolor') as HTMLInputElement
        if(saveSwimelanecolor !== null) {
            saveSwimelanecolor.value = data.color
        }
        let taskTitle = document.getElementById('taskTitle') as HTMLInputElement
        if(taskTitle !== null) {
            taskTitle.value = data.regard
        }
        let regardDuplicate = document.getElementById('regardDuplicate') as HTMLInputElement
        if(regardDuplicate !== null) {
            regardDuplicate.value = 'Kopie vom ' + data.regard
        }
        let DuplicateTaskProjectID = document.getElementById('DuplicateTaskProjectID') as HTMLSelectElement
        if(DuplicateTaskProjectID !== null) {
            DuplicateTaskProjectID.value = data.project_id
        }

        let DuplicateTaskSwimlaneID = document.getElementById('DuplicateTaskSwimlaneID') as HTMLSelectElement
        if(DuplicateTaskSwimlaneID !== null) {
            DuplicateTaskSwimlaneID.value = data.swimlane_id
        }
        let taskDueOn = document.getElementById('taskDueOn') as HTMLInputElement
        if(taskDueOn !== null) {
            taskDueOn.value = data.due_on
        }
        let TaskResponsibility = document.getElementById('TaskResponsibility') as HTMLInputElement
        if(TaskResponsibility !== null) {
            TaskResponsibility.value = data.responsibility
        }

        let taskPriority = document.getElementById('taskPriority') as HTMLInputElement
        if(taskPriority !== null) {
            taskPriority.value = data.priority
        }
        let Project_title = document.getElementById('Project_title') as HTMLInputElement
        if(Project_title !== null) {
            Project_title.value = data.project.title
        }
        setValue(data.description)
        setShowEdit('')
        setCtc('/myprojects/task/single/'+data.project_id+'/'+data.task_id)

    }

    const CopytoClipboard = () => {

        navigator.clipboard.writeText(ctc).then(() => {
            console.log("Text in die Zwischenablage kopiert");

        },() => {
            console.error('Fehler beim Koperen');
        });

    }

    const [showCloseTask, setShowCloseTask] = useState(false);
    const CloseMember = (mode?:any,modeID?:any) => {
        if(mode !== undefined && mode !== null) {
            let Dialogs = document.getElementById((modeID !== undefined && modeID !== null && modeID !== '' ? modeID :'addDialogInternalConnection')) as HTMLDivElement;
            Dialogs.classList.remove("d-none");
            Dialogs.style.display = 'none';
        } else {
            let Dialog = document.getElementById('addDialogMember') as HTMLDivElement;
            Dialog.classList.remove("d-none");
            Dialog.style.display = 'none';
        }

    }
    const handleCloseCloseTask = () => setShowCloseTask(false);
    const handleShowCloseTask = () => setShowCloseTask(true);
    if (response !== true && typeof response !== "boolean" && response !== undefined) {
        let DataoverDue = (OverdueHolderSortData.length === 0 ? response.alltoverduestasks : OverdueHolderSortData);
        for(let t=0;  t < DataoverDue.length;t++) {
            let data = DataoverDue[t]
            OverdueHolder.push(
                <tr style={{cursor:'pointer'}} onContextMenu={(e)=>triggerCMenu(data)} onDoubleClick={()=> navigate('/myprojects/task/single/'+data.project_id+'/'+data.task_id)}>
                    <td>{data.task_id}</td>
                    <td>{data.regard}</td>
                    <td style={{color:'red'}}>{
                        dateFormat(
                            data.due_on,
                            localStorage.getItem('locale'),
                            'us',
                            false,
                            false,
                            Store
                        )
                        }</td>
                    <td>{data.project.title}</td>
                    <td>{data.status.state}</td>
                </tr>
            )
        }
        let DataDuothisWeek = (DuothisWeekHolderSortData.length === 0 ? response.alltLast7Ddaystasks : DuothisWeekHolderSortData);
        for(let t6=0; t6 < DataDuothisWeek.length;t6++) {
            let data = DataDuothisWeek[t6]
            DuothisWeekHolder.push(
                <tr style={{cursor:'pointer'}}  onContextMenu={(e)=>triggerCMenu(data)} onDoubleClick={()=> navigate('/myprojects/task/single/'+data.project_id+'/'+data.task_id)}>
                    <td>{data.task_id}</td>
                    <td>{data.regard}</td>
                    <td>{
                        dateFormat(
                            data.due_on,
                            localStorage.getItem('locale'),
                            'us',
                            false,
                            false,
                            Store
                        )
                    }</td>
                    <td>{data.project.title}</td>
                    <td>{data.status.state}</td>
                </tr>
            )
        }
        let DataDuelater = (DuelaterHolderSortData.length === 0 ? response.alltLastover7Ddaystasks : DuelaterHolderSortData);
        for(let t7=0; t7 < DataDuelater.length;t7++) {
            let data = DataDuelater[t7]
            DuelaterHolder.push(
                <tr style={{cursor:'pointer'}}
                    onContextMenu={(e)=>triggerCMenu(data)}
                    onDoubleClick={()=> navigate('/myprojects/task/single/'+data.project_id+'/'+data.task_id)} >
                    <td>{data.task_id}</td>
                    <td>{data.regard}</td>
                    <td>{
                        dateFormat(
                            data.due_on,
                            localStorage.getItem('locale'),
                            'us',
                            false,
                            false,
                            Store
                        )
                    }</td>
                    <td>{data.project.title}</td>
                    <td>{data.status.state}</td>
                </tr>
            )
        }
            let Datas = (NoDueDateHolderSortData.length === 0 ? response.alltLastoverNoneDdaystasks : NoDueDateHolderSortData);
            for (let tn = 0; tn < Datas.length; tn++) {
                let data = Datas[tn]
                NoDueDateHolder.push(
                    <tr style={{cursor:'pointer'}}  onContextMenu={(e)=>triggerCMenu(data)} onDoubleClick={()=> navigate('/myprojects/task/single/'+data.project_id+'/'+data.task_id)}>
                        <td>{data.task_id}</td>
                        <td>{data.regard}</td>
                        <td>{
                            dateFormat(
                                data.due_on,
                                localStorage.getItem('locale'),
                                'us',
                                false,
                                false,
                                Store
                            )
                        }</td>
                        <td>{data.project.title}</td>
                        <td>{data.status.state}</td>

                    </tr>
                )
            }

    }
    const setterSwimelane = (id:any,expandid:any,closeAnother?:any,displayState?:any) => {
        switchdisplay(id,closeAnother,displayState)
        let swimelane = document.getElementById(id) as HTMLDivElement;
        if(swimelane !== null) {
            if (swimelane.style.display === 'none') {
                let Icon = document.getElementById(expandid) as HTMLSpanElement;
                if (Icon !== null) {
                    Icon.innerHTML = 'expand_more'
                }
            } else {
                let Icon = document.getElementById(expandid) as HTMLSpanElement;
                if (Icon !== null) {
                    Icon.innerHTML = 'expand_less'
                }
            }
        }
    }

    const CloseTask = () => {

        let id = (document.getElementById('Projectid') as HTMLInputElement)?.value
        let taskid = (document.getElementById('taskiD') as HTMLInputElement)?.value
        var raw = ''

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'yoptimize/project/'+id+'/task/'+taskid+'/close/', requestOptions)
            .then((response) => response.text())
            .then((result) => msgAlert(result))
            .catch((error) => console.log('error', error))
    }

    const msgAlert = (result:any) => {
        setFetchData(Date.now())
        handleCloseCloseTask()
        notify_save()
    }
    let sendEditTask = (e:any,id:any,taskid:any,Project_title:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            regard: formdata.get('title'),
            description: formdata.get('description'),
            responsibility: formdata.get('responsibility'),
            priority: formdata.get('priority'),
            due_on: formdata.get('due_on'),
            lastupdatefrom: localStorage.getItem('username'),
            color:formdata.get('color')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }
        setValue('');
        fetch(ApiRoot()+'yoptimize/project/'+id+'/edit/task/'+taskid+'/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,id,taskid,Project_title,formdata.get('title'),raw))
            .catch((error) => console.log('error', error))

    }

    const successFormNew = (result:any) => {
        setFetchData(Date.now())
        handleClose()
    }

    const successForm = (result:any,id:any,taskid:any,project_title:any,task_regard:any,raw:any) => {
        setFetchData(Date.now())
        notify_save()
        setShowEdit('')
        SendMessage(
            '[Projekt: ***'+project_title+'***]('+window.location.origin+'/myprojects/task/all/projects/'+id+'/system) | [Ticket: '+task_regard+' ***'+taskid+'***]('+window.location.origin+'/myprojects/task/single/'+id+'/'+taskid+')\nwurde von :adult: '+localStorage.getItem('username') +' bearbeitet. \n \nInhalt :computer: : \n```json\n '+raw+' \n```',
            '## Ticket wurde bearbeitet! :rolled_up_newspaper:'
        )


    }


    useEffect(() => {

        document.addEventListener('keyup', function (event) {
            if ( event.keyCode == 27 )   {
                closeEsc()
            }
        })
    })
    useEffect(() => {
        MoveElement()
    })
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const CloseDynmaikModal = () => {
        handleClose();
    }

    const handlesubmit = (e:any) => {
        const form = e.currentTarget
        e.preventDefault()
        let Data = init2(form)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+ getToken());

        var raw = JSON.stringify(
            {
                "regard": Data.regard,
                "swimlane_id": Data.swimlane_id,
                "project_id": Data.project_id,
                "support_type_id": Data.support_type_id,
                "status_id": Data.status_id,
                "task_id": Data.task_id,
                "member": Data.member,
                "description": Data.description,
                "due_on": Data.due_on,
                "priority": Data.priority,
                "responsibility": Data.responsibility,
                "color": Data.color,
                "column_id": Data.column_id,
                "creator": Data.creator
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        setValue('');
        fetch(ApiURL() +"yoptimize/new/task/", requestOptions)
            .then(response => response.text())
            .then(result => {
                let formfile = (document.getElementById('fileuploadAttachment') as HTMLInputElement).files
                if(formfile !== null &&  formfile?.length > 0) {

                    for (let f = 0; f < formfile?.length; f++) {
                        let formDatas = new FormData()
                        formDatas.append('customer', process.env.REACT_APP_CDN_CLIENT as string)
                        formDatas.append('context', 'pielers_element_text_image')
                        // @ts-ignore
                        formDatas.append(`file${0}`, formfile[f])
                        var requestOptions = {
                            method: 'POST',
                            body: formDatas
                        }
                        let link
                        if (process.env.REACT_APP_MODE === 'LOCAL') {
                            link = process.env.REACT_APP_CDN_API_URL_LOCAL
                        } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
                            link = process.env.REACT_APP_CDN_API_URL_DEVELOPMENT
                        } else if (process.env.REACT_APP_MODE === 'LIVE') {
                            link = process.env.REACT_APP_CDN_API_URL_LIVE
                        }

                        fetch(link + 'upload/', requestOptions)
                            .then((response) => response.text())
                            .then((results) => {

                                var raws = JSON.stringify({
                                    url: JSON.parse(results).cdn_url,
                                    name: JSON.parse(results).name,
                                    task_id: JSON.parse(result).task_id,
                                    project_id: Data.project_id,
                                    member: localStorage.getItem('username'),
                                    type: JSON.parse(results).content_type
                                })

                                var requestOptionss = {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        Authorization:
                                            'Bearer ' +
                                            JSON.parse(localStorage.getItem('token') as string)
                                    },
                                    body: raws
                                }
                                fetch(ApiRoot() + 'yoptimize/save/attachdocument/', requestOptionss)
                                    .then((response) => response.text())
                                    .then((result) => {
                                        successFormNew(result)
                                        SendMessage(
                                            '[Projekt: ***' + (singleProject !== true && typeof singleProject !== "boolean" && singleProject?.length > 0 ? singleProject[0].title : '') + '***](' + window.location.origin + '/myprojects/task/all/projects/' + Data.project_id + '/system) \n \n :adult: ' + localStorage.getItem('username') + '\n \n Ticket mit Inhalt :computer:: \n```json\n ' + JSON.stringify(Data) + ' hinzugefügt \n```',
                                            '## Neues Ticket erstellt! :rolled_up_newspaper: \n')
                                    })
                                    .catch((e) => {
                                        error(e)
                                    })

                            })
                            .catch(error => errorForm(error));
                    }} else {
                    successFormNew(result)
                    SendMessage(
                        '[Projekt: ***' + (singleProject !== true && typeof singleProject !== "boolean" && singleProject?.length > 0 ? singleProject[0].title : '') + '***](' + window.location.origin + '/myprojects/task/all/projects/' + Data.project_id + '/system) \n \n :adult: ' + localStorage.getItem('username') + '\n \n Ticket mit Inhalt :computer:: \n```json\n ' + JSON.stringify(Data) + ' hinzugefügt \n```',
                        '## Neues Ticket erstellt! :rolled_up_newspaper: \n')
                }
            })
            .catch(error => errorForm(error));


    };

    const errorForm = (error:any)=> {
        handleClose()
    }

    const FormModal = [
        {
            formType: 'input',
            label: IntlMessageValue('de', 'common.title', Store),
            required: true,
            type: 'text',
            name: 'regard',
            id: "newCalenderTitle",
            value: ''
        },
        {
            formType: 'input',
            label: 'support_type_id',
            type: 'hidden',
            name: 'support_type_id',
            id: "newCalenderTitle",
            value: '4'
        },
        {
            formType: 'input',
            label: 'status_id',
            type: 'hidden',
            name: 'status_id',
            id: "newCalenderTitle",
            value: '2'
        },
        {
            formType: 'input',
            label: 'task_id',
            type: 'hidden',
            name: 'task_id',
            id: "newCalenderTitle",
            value: '0'
        },
        {
            formType: 'input',
            label: 'member',
            type: 'hidden',
            name: 'member',
            id: "newCalenderTitle",
            value: localStorage.getItem('username')
        }
    ]



    const setSwim = (e:any) => {
        setSwimlaneID(e.target.value)
        setFetchData(Date.now())
    }

    const ALLProject = ResponseAllProject(fetchData);
    /* @ts-ignore */
    let ProjectHolder = [];
    if (ALLProject !== true && typeof ALLProject !== "boolean" && ALLProject?.length > 0) {
        for (let ft = 0; ft < ALLProject.length; ft++) {

            ProjectHolder.push(
                <>
                    <option
                        value={ALLProject[ft].id}>{ALLProject[ft].title}</option>
                </>
            )
        }
    }

    let SwimLaneHolder = [];
    let localSwimLanes = JSON.parse(localStorage.getItem('swimlanes') as string)

    for (let us = 0; us < localSwimLanes?.length; us++) {
        SwimLaneHolder.push(
            <>
                <option value={localSwimLanes[us].id}>{localSwimLanes[us].name}</option>
            </>
        )
    }
    const [calender, setCalender] = useState(0);
    moment.locale("de")
    moment.updateLocale("de", {
        week: {
            dow: 1,
            doy: 4,
        },
    });
    const localizer = momentLocalizer(moment) // or globalizeLocalizer

    let EventHolder = [];
    let GrandDataHolder = [];
    if(response !== true && typeof response !== "boolean" && response !== undefined ) {
        for(let re=0;re<response.alltLastoverNoneDdaystasks.length;re++) {
            let taskData = response.alltLastoverNoneDdaystasks[re]
            GrandDataHolder.push(taskData)
            EventHolder.push(
                {
                    "title": taskData.regard,
                    "start": new Date(taskData.created_at),
                    "end":   new Date((taskData.due_on === null ? taskData.created_at : taskData.due_on)),
                    "allday": true,
                    "link":"/myprojects/task/single/"+taskData.project_id+"/"+taskData.task_id
                }
            )
        }
        for(let re=0;re<response.alltoverduestasks.length;re++) {
            let taskData = response.alltoverduestasks[re]
            GrandDataHolder.push(taskData)
            EventHolder.push(
                {
                    "title": taskData.regard,
                    "start": new Date(taskData.created_at),
                    "end":   new Date((taskData.due_on === null ? taskData.created_at : taskData.due_on)),
                    "allday": true,
                    "link":"/myprojects/task/single/"+taskData.project_id+"/"+taskData.task_id
                }
            )
        }
        for(let re=0;re<response.alltLast7Ddaystasks.length;re++) {
            let taskData = response.alltLast7Ddaystasks[re]
            GrandDataHolder.push(taskData)
            EventHolder.push(
                {
                    "title": taskData.regard,
                    "start": new Date(taskData.created_at),
                    "end":   new Date((taskData.due_on === null ? taskData.created_at : taskData.due_on)),
                    "allday": true,
                    "link":"/myprojects/task/single/"+taskData.project_id+"/"+taskData.task_id
                }
            )
        }
        for(let re=0;re<response.alltLastover7Ddaystasks.length;re++) {
            let taskData = response.alltLastover7Ddaystasks[re]
            GrandDataHolder.push(taskData)
            EventHolder.push(
                {
                    "title": taskData.regard,
                    "start": new Date(taskData.created_at),
                    "end":   new Date((taskData.due_on === null ? taskData.created_at : taskData.due_on)),
                    "allday": true,
                    "link":"/myprojects/task/single/"+taskData.project_id+"/"+taskData.task_id
                }
            )
        }
    }

    const messages = {
        allDay: 'Alle Tage',
        previous: '<',
        next: '>',
        today: 'Heute',
        month: 'Monat',
        week: 'Woche',
        day: 'Tag',
        agenda: 'Agenda',
        date: 'Datum',
        time: 'Zeit',
        event: 'Event'
    }
    const CalenderNavigate = (e:any) => {
        navigate(e.link)
    }

    const GrantClick = (task: Task) => {

    };
    const Prio = ResponseAllPrio(fetchData)
    let PrioHolder = [];
    let PrioEditHolder:any = []
    let regex = /[A-Z]/ig;
    if (typeof Prio !== "boolean" && Prio?.length > 0) {
        for (let c = 0; c <Prio.length; c++) {

            PrioEditHolder.push(
                <option value={Prio[c].name.replace(regex,'')}>{Prio[c].name}</option>
            )

            PrioHolder.push(
                <>
                    <div className="agile-row table-border-left" style={{background: Prio[c].color}}>
                        <div className="table-list-details agile-table-list-title">
                            <strong>{Prio[c].name}</strong>
                        </div>
                        <div className="table-list-details agile-table-list-details p-0 m-0 text-center">
                            <ul className="p-0 m-0">
                                <li className="">{Prio[c].underline}</li>
                            </ul>
                        </div>
                    </div>
                </>
            )
        }
    }
    let DataNone = response !== true && typeof response !== "boolean" && response !== undefined && NoDueDateHolderSortData.length === 0 ? response.alltLastoverNoneDdaystasks : NoDueDateHolderSortData
    let DataOverdue = response !== true && typeof response !== "boolean" && response !== undefined && OverdueHolderSortData.length === 0 ? response.alltoverduestasks : OverdueHolderSortData
    let DataDuothisWeek = response !== true && typeof response !== "boolean" && response !== undefined && DuothisWeekHolderSortData.length === 0 ? response.alltLast7Ddaystasks : DuothisWeekHolderSortData;
    let DataDuelater = response !== true && typeof response !== "boolean" && response !== undefined && DuelaterHolderSortData.length === 0 ? response.alltLastover7Ddaystasks : DuelaterHolderSortData;
    const DuplicateTask = () => {

        let SwimLaneHolder = [];
        let localSwimLanes = JSON.parse(localStorage.getItem('swimlanes') as string)

        for (let us = 0; us < localSwimLanes?.length; us++) {
            SwimLaneHolder.push(
                <>
                    <option value={localSwimLanes[us].id}>{localSwimLanes[us].name}</option>
                </>
            )
        }
        if(ALLProject !== true && typeof ALLProject !== "boolean" && ALLProject?.length > 0 && SwimLaneHolder?.length > 0) {
            return (
                <>
                    <div className="offcanvas-body">
                        <div className="modal-dialog">
                            <Form
                                id={'form001'}
                                className={'formControl'}
                                onSubmit={(e) =>
                                    sendDuplicateTask(e, CloseMember, 'addDialogDuplicateTask')
                                }
                            >
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <div
                                            className="modal-title h4">{IntlMessageValue('', 'single.task.duplicateToAnotherProject', Store)}</div>
                                        <button type="button" className="btn-close" aria-label="Close"
                                                onClick={() => CloseMember(true, 'addDialogDuplicateTask')}/>
                                    </div>
                                    <br/>
                                    <div className="modal-body" id={'FormBody'}>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text
                                                id="basic-addon1">{IntlMessageValue('de', 'common.title', Store)}</InputGroup.Text>
                                            <Form.Control name={'regard'} id={'regardDuplicate'}/>

                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text
                                                id="basic-addon1">{IntlMessageValue('', 'common.ticket.project', Store)}</InputGroup.Text>
                                            <Form.Select name={'DuplicateTaskProjectID'}
                                                         id={'DuplicateTaskProjectID'}
                                                         aria-label="Default select example"
                                                         required={true} onChange={(e) => setSwim(e)}>
                                                <option label={IntlMessageValue('', 'common.project.select', Store)}
                                                        value=""></option>
                                                { /* @ts-ignore */
                                                    ProjectHolder}
                                            </Form.Select>
                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text
                                                id="basic-addon1">{IntlMessageValue('', 'common.ticket.swimlane', Store)}</InputGroup.Text>
                                            <Form.Select name={'DuplicateTaskSwimlaneID'}
                                                         id={'DuplicateTaskSwimlaneID'}
                                                         aria-label="Default select example"
                                                         required={true}>
                                                <option label={IntlMessageValue('', 'common.swimlane.select', Store)}
                                                        value=""></option>
                                                {SwimLaneHolder}
                                            </Form.Select>
                                        </InputGroup>
                                        <hr/>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">Kommentare übernehmen</InputGroup.Text>
                                            <Form.Select name={'addedCommment'} aria-label="Default select example"
                                                         required={true}>
                                                <option value="0">{IntlMessageValue('', 'common.no', Store)}</option>
                                                <option value="1">{IntlMessageValue('', 'common.yes', Store)}</option>
                                            </Form.Select>
                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">Anhänge übernehmen</InputGroup.Text>
                                            <Form.Select name={'addedAttachment'} aria-label="Default select example"
                                                         required={true}>
                                                <option value="0">{IntlMessageValue('', 'common.no', Store)}</option>
                                                <option value="1">{IntlMessageValue('', 'common.yes', Store)}</option>
                                            </Form.Select>
                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">Unteraufgaben
                                                übernehmen</InputGroup.Text>
                                            <Form.Select name={'addedSubtask'} aria-label="Default select example"
                                                         required={true}>
                                                <option value="0">{IntlMessageValue('', 'common.no', Store)}</option>
                                                <option value="1">{IntlMessageValue('', 'common.yes', Store)}</option>
                                            </Form.Select>
                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">Externe Verbindungen
                                                übernehmen</InputGroup.Text>
                                            <Form.Select name={'addedExternal'} aria-label="Default select example"
                                                         required={true}>
                                                <option value="0">{IntlMessageValue('', 'common.no', Store)}</option>
                                                <option value="1">{IntlMessageValue('', 'common.yes', Store)}</option>
                                            </Form.Select>
                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">Interne Verbindungen
                                                übernehmen</InputGroup.Text>
                                            <Form.Select name={'addedInternal'} aria-label="Default select example"
                                                         required={true}>
                                                <option value="0">{IntlMessageValue('', 'common.no', Store)}</option>
                                                <option value="1">{IntlMessageValue('', 'common.yes', Store)}</option>
                                            </Form.Select>
                                        </InputGroup>
                                        <hr/>
                                    </div>
                                    <br/>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary"
                                                onClick={() => setShowDuplicate('')}>{IntlMessageValue('', 'common.close', Store)}
                                        </button>
                                        <button type="submit" id={'AttachDocumentSendButton'}
                                                className="btn btn-primary float-end">{IntlMessageValue('', 'common.save', Store)}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </>)
        }else{
            return;
        }

    }

    let sendDuplicateTask = (e: any, close: any, divid?: any) => {
        e.preventDefault()
        let data = e.currentTarget

        let taskID = (document.getElementById('taskiD') as HTMLInputElement)?.value;
        let ProjectID = (document.getElementById('Projectid') as HTMLInputElement)?.value

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            task_id: taskID,
            project_id: ProjectID,
            regard: formdata.get('regard'),
            addedCommment: formdata.get('addedCommment'),
            addedAttachment:formdata.get('addedAttachment'),
            addedSubtask:formdata.get('addedSubtask'),
            addedExternal:formdata.get('addedExternal'),
            addedInternal:formdata.get('addedInternal')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }



       fetch(ApiRoot()+'yoptimize/copy/newproject/'+formdata.get('DuplicateTaskProjectID')+'/project/'+ProjectID+'/task/'+taskID+'/swimlane/'+formdata.get('DuplicateTaskSwimlaneID')+'/', requestOptions)
            .then((response) => response.text())
            .then((result) => successFormDuplicate(result))
            .catch((error) => errorFormDuplicate(error))

    }
    const successFormDuplicate = (result:any) => {
        setFetchData(Date.now())
        notify_save()
        setShowDuplicate('')

    }

    const errorFormDuplicate = (error:any)=> {
        setShowDuplicate('')
    }
    return (
        <>
            <div className={'ticketsystem'} style={{paddingLeft: '25px'}}>

                <h3 id={'top'} className={'float-start'}>{IntlMessageValue('de', 'common.my.tasks', Store)}</h3>
                {refresh(setFetchData, IntlMessageValue('de', 'common.reloadPage', Store))}

                <DynamikOffCanvas
                    openButtonLabel={<><span className="material-icons" style={{verticalAlign: 'bottom'}}>
                    add
                    </span>
                        {IntlMessageValue('de', 'mytask.button.createTasks', Store)}</>}
                    saveButtonLabel={IntlMessageValue('de', 'common.save', Store)}
                    closeButtonLabel={IntlMessageValue('de', 'common.close', Store)}
                    title={IntlMessageValue('de', 'mytask.headline.offcanvas', Store)}
                    ClassOpenButton={'float-end'}
                    ClassSaveButton={'float-end'}
                    body={<>

                        <div className="modal-dialog" style={{marginRight: '-23px'}}>

                        </div>
                        {PielersForm(FormModal)}
                        <div className="modal-body" id={'FormBody'}>
                            <Form.Select name={'project_id'} aria-label="Default select example"
                                         required={true} onChange={(e) => setSwim(e)}>
                                <option label={IntlMessageValue('de', 'common.project.select', Store)}
                                        value=""></option>
                                { /* @ts-ignore */
                                    ProjectHolder}
                            </Form.Select>
                        </div>
                        <br/>
                        <div className="modal-body" id={'FormBody'}>
                            <Form.Select name={'swimlane_id'} aria-label="Default select example"
                                         required={true}>
                                <option label={IntlMessageValue('de', 'common.swimlane.select', Store)}
                                        value=""></option>
                                {SwimLaneHolder}
                            </Form.Select>
                        </div>
                        <br/>

                        <Button variant="outline-primary"
                                onClick={() => setterEditor('htmleditor', 'markdowneditor')}>HTML Editor</Button>
                        <Button variant="outline-primary"
                                onClick={() => setterEditor('markdowneditor', 'htmleditor')}>Markdown
                            Editor</Button>

                        <div id={'htmleditor'} style={{display: 'block'}}>
                            <SunEditor
                                key={'test'}

                                name={'description'}
                                setContents={''}
                                setOptions={{
                                    buttonList: SunEditorConfig(),
                                    attributesWhitelist: {
                                        div: 'id',
                                        p: 'id',
                                        h: 'id'
                                    }
                                }}
                                height={'450px'}
                                onChange={(value) => setEditorContent(value)}
                            />
                        </div>
                        <div id={'markdowneditor'} style={{display: 'none'}} data-color-mode="light">
                            <MarkdownEditor
                                value={''}
                                height={'450px'}
                                visible={true}
                                onChange={(value, viewUpdate) => setEditorContent(value, true)}

                            />

                        </div>
                        <br/>
                        <Row>
                            <Col className={'col-6'}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label><IntlMessage messageId="common.ticket.dueOn"
                                                             Store={Store}/></Form.Label>
                                    <Form.Control type="date" name={'due_on'}/>
                                </Form.Group>
                            </Col>
                            <Col className={'col-6'}>
                                <Form.Group controlId="formFileMultiple" className="mb-3">
                                    <Form.Label>{IntlMessageValue('de', 'common.attach.document', Store)}</Form.Label>
                                    <Form.Control id={'fileuploadAttachment'} name={'file'} type="file" multiple/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={'col-4'}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">
                                        {IntlMessageValue('de', 'common.ticket.priority', Store)}</InputGroup.Text>
                                    <Form.Select aria-label="Default select example" name={'priority'}
                                                 required={true}>
                                        <option label={IntlMessageValue('de', 'common.priority.default', Store)}
                                                value=""></option>
                                        {PrioEditHolder}
                                    </Form.Select>
                                    <InputGroup.Text id="basic-addon1" style={{cursor: 'pointer'}}><span
                                        className="material-icons float-end"
                                        onClick={() => switchdisplay('MoveElement', null, null, true)}>
                                    info
                                    </span></InputGroup.Text>
                                </InputGroup>

                            </Col>
                            <Col className={'col-6'}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text
                                        id="basic-addon1">{IntlMessageValue('de', 'common.ticket.responsibility', Store)}</InputGroup.Text>
                                    <Form.Select aria-label="Default select example" name={'responsibility'}
                                                 required={true}>
                                        <option label={IntlMessageValue('de', 'common.responsibility.select', Store)}
                                                value=""></option>
                                        <option
                                            value="assign a user">{IntlMessageValue('de', 'common.responsibility.notAssgin', Store)}</option>
                                        {UserHolder}
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                            <Col className={'col-2'}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text
                                        id="basic-addon1">{IntlMessageValue('de', 'common.ticket.color', Store)}</InputGroup.Text>
                                    <Form.Control
                                        type="color"
                                        name={'color'}
                                        defaultValue={'#ffffff'}
                                        id={'saveSwimelanecolor1'}
                                    />
                                </InputGroup>

                            </Col>
                        </Row>
                        <AddedColor Store={Store} state={state} cid={'saveSwimelanecolor1'} color={ColorHolder}
                                    setState={setState}/>
                        <Form.Control name={'column_id'} type="hidden"
                                      defaultValue={(2)}/>
                        <Form.Control name={'creator'} type="hidden"
                                      defaultValue={localStorage.getItem('username') as string}/>
                    </>}
                    handlesubmit={handlesubmit}
                    handleShow={handleShow}
                    show={show}
                    id={'TaskNew'}
                    setShow={setShow}
                    handleClose={CloseDynmaikModal}
                />
                <Button variant="secondary" className={'float-end ml-3'}
                        style={{position: 'relative', right: '5px', zIndex: '96', background: '#29AAE1'}}
                        onClick={(e) => (calender === 0 ? setCalender(1) : setCalender(0))}>
                <span className="material-icons" style={{verticalAlign: 'bottom'}}>
                    calendar_month
                </span>
                    Kalender/Grant {(calender === 0 ?
                    <>
                    <span className="material-icons" style={{verticalAlign: 'bottom'}}>
                            visibility
                    </span>
                    </> : <>
                    <span className="material-icons" style={{verticalAlign: 'bottom'}}>
                            visibility_off
                    </span>
                    </>)}
                </Button>
                <br style={{clear: 'both'}}/>
                <div id={'overview'} style={{display: (calender === 1 ? 'none' : 'block')}}>
                    <div className={'mb-4 ml-3'}>
                    </div>
                    <div className={'mb-4'}>
                        <div className="app">
                        </div>

                        <div className="swimelane swimlanemyTask ml-3">
                    <span className="material-icons float-start" style={{cursor: 'pointer'}}
                          id={'expand_swimelane_overdue'}
                          onClick={() => setterSwimelane('swimelane_overdue', 'expand_swimelane_overdue', null, 'block')}>
                                    expand_less
                                </span>

                            <p className={'m-0'} style={{cursor: 'pointer'}}
                               onClick={() => setterSwimelane('swimelane_overdue', 'expand_swimelane_overdue', null, 'block')}>
                                {IntlMessageValue('de', 'mytask.swimlane.overdue', Store)}
                            </p>
                        </div>
                        <div className={'ml-3'} id={'swimelane_overdue'} style={{display: 'block'}}>
                            {OverdueHolder.length === 0 ? <>
                                    <h3>{IntlMessageValue('de', 'mytask.notask.available', Store)}</h3></> :
                                <>
                                    <ContextMenuTrigger id="same_unique_identifier">
                                        <Table striped bordered hover>
                                            <thead>
                                            <TableHead Configuration={Configuration} Store={Store}
                                                       setFetchData={setFetchData} data={DataOverdue}
                                                       setSortData={setOverdueHolderSortData}/>
                                            </thead>
                                            <tbody id={'TimeHolder'} className={'TimeHolder'}>

                                            {OverdueHolder}

                                            </tbody>
                                        </Table>
                                    </ContextMenuTrigger>
                                </>
                            }
                        </div>
                    </div>
                    <div className={'mb-4'}>
                        <div className="swimelane swimlanemyTask ml-3">
                    <span className="material-icons float-start" style={{cursor: 'pointer'}}
                          id={'expand_swimelane_due_this_week'}
                          onClick={() => setterSwimelane('swimelane_due_this_week', 'expand_swimelane_due_this_week', null, 'block')}>
                                    expand_less
                                </span>

                            <p className={'m-0'} style={{cursor: 'pointer'}}
                               onClick={() => setterSwimelane('swimelane_due_this_week', 'expand_swimelane_due_this_week', null, 'block')}>
                                {IntlMessageValue('de', 'mytask.swimlane.duenext7days', Store)}
                            </p>
                        </div>
                        <div className={'ml-3'} id={'swimelane_due_this_week'} style={{display: 'block'}}>
                            {DuothisWeekHolder.length === 0 ? <>
                                    <h3>{IntlMessageValue('de', 'mytask.notask.available', Store)}</h3></> :
                                <ContextMenuTrigger id="same_unique_identifier">
                                    <Table striped bordered hover>
                                        <thead>
                                        <TableHead Configuration={Configuration} Store={Store}
                                                   setFetchData={setFetchData} data={DataDuothisWeek}
                                                   setSortData={setDuothisWeekHolderSortData}/>
                                        </thead>
                                        <tbody id={'TimeHolder'} className={'TimeHolder'}>
                                        {DuothisWeekHolder}
                                        </tbody>
                                    </Table>
                                </ContextMenuTrigger>
                            }
                        </div>
                    </div>
                    <div className={'mb-4'}>
                        <div className="swimelane swimlanemyTask ml-3">
                    <span className="material-icons float-start" style={{cursor: 'pointer'}}
                          id={'expand_swimelane_due_later'}
                          onClick={() => setterSwimelane('swimelane_due_later', 'expand_swimelane_due_later', null, 'block')}>
                                    expand_less
                                </span>

                            <p className={'m-0'} style={{cursor: 'pointer'}}
                               onClick={() => setterSwimelane('swimelane_due_later', 'expand_swimelane_due_later', null, 'block')}>
                                {IntlMessageValue('de', 'mytask.swimlane.duelater', Store)}
                            </p>
                        </div>
                        <div className={'ml-3'} id={'swimelane_due_later'}
                             style={{display: 'block', position: "relative"}}>
                            {DuelaterHolder.length === 0 ? <>
                                    <h3>{IntlMessageValue('de', 'mytask.notask.available', Store)}</h3></> :
                                <ContextMenuTrigger id="same_unique_identifier">
                                    <Table striped bordered hover>
                                        <thead>
                                        <TableHead Configuration={Configuration} Store={Store}
                                                   setFetchData={setFetchData} data={DataDuelater}
                                                   setSortData={setDuelaterHolderSortData}/>
                                        </thead>
                                        <tbody id={'TimeHolder'} className={'TimeHolder'}>
                                        {DuelaterHolder}
                                        </tbody>
                                    </Table>
                                </ContextMenuTrigger>
                            }
                        </div>
                    </div>
                    <div className={'mb-4'}>
                        <div className="swimelane swimlanemyTask ml-3">
                    <span className="material-icons float-start" style={{cursor: 'pointer'}}
                          id={'expand_swimelane_no_due_date'}
                          onClick={() => setterSwimelane('swimelane_no_due_date', 'expand_swimelane_no_due_date', null, 'block')}>
                                    expand_less
                                </span>

                            <p className={'m-0'} style={{cursor: 'pointer'}}
                               onClick={() => setterSwimelane('swimelane_no_due_date', 'expand_swimelane_no_due_date', null, 'block')}>
                                {IntlMessageValue('de', 'mytask.swimlane.noduedate', Store)}
                            </p>
                        </div>
                        <div className={'ml-3'} id={'swimelane_no_due_date'} style={{display: 'block'}}>
                            {NoDueDateHolder.length === 0 ? <>
                                    <h3>{IntlMessageValue('de', 'mytask.notask.available', Store)}</h3></> :
                                <ContextMenuTrigger id="same_unique_identifier">
                                    <Table striped bordered hover>
                                        <thead>
                                        <TableHead Configuration={Configuration} Store={Store}
                                                   setFetchData={setFetchData} data={DataNone}
                                                   setSortData={setNoDueDateHolderSortData}/>
                                        </thead>

                                        <tbody id={'TimeHolder'} className={'TimeHolder'}>
                                        {NoDueDateHolder}
                                        </tbody>
                                    </Table>
                                </ContextMenuTrigger>
                            }
                        </div>
                    </div>

                    {/* @ts-ignore */}
                    <ContextMenu id="same_unique_identifier">
                        {/* @ts-ignore */}
                        <MenuItem data={{foo: "bar"}} onClick={() => setShowEdit('show')}>
                     <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                        toggle_on
                                    </span>
                            <span id={'copyToC'}
                                  className={'pl-2'}>{IntlMessageValue('de', 'common.detail.open', Store)}</span>
                        </MenuItem>
                        {/* @ts-ignore */}
                        <MenuItem data={{foo: "bar"}}>
                            <a href={''} id={'m2'} className={"MenuItemahref"} target={'_blank'}
                               style={{textDecoration: 'none'}}>
                                    <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                        open_in_new
                                    </span>
                                <span className={'pl-2'}>{IntlMessageValue('de', 'common.open.new.tab', Store)}</span>
                            </a>
                        </MenuItem>
                        {/* @ts-ignore */}
                        <MenuItem data={{foo: "bar"}} onClick={() => CopytoClipboard()}>
                     <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                        link
                                    </span>
                            <span id={'copyToC'}
                                  className={'pl-2'}>{IntlMessageValue('de', 'common.copy.task.link', Store)}</span>
                        </MenuItem>
                        {/* @ts-ignore */}
                        <MenuItem data={{foo: "bar"}} onClick={() => setShowDuplicate('show')}>
                     <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                        content_copy
                                    </span>
                            <span id={'copyToC'} className={'pl-2'}>{IntlMessageValue('de', 'single.task.duplicateToAnotherProject', Store)}</span>
                        </MenuItem>
                        {/* @ts-ignore */}
                        <MenuItem divider/>
                        {/* @ts-ignore */}
                        <MenuItem data={{foo: "bar"}} onClick={(e) => handleShowCloseTask()}>
                                <span className="material-icons" style={{verticalAlign: 'bottom'}}>
                                    check_circle_outline
                                </span>
                            <span className={'pl-2'}>{IntlMessageValue('de', 'common.mark.as.completed', Store)}</span>
                            <Form.Control type="hidden" name={'id'} id={'Projectid'}/>
                            <Form.Control type="hidden" name={'taskiD'} id={'taskiD'}/>
                            <Form.Control type="hidden" name={'Project_title'} id={'Project_title'}/>
                        </MenuItem>
                        {/* @ts-ignore */}
                        <MenuItem divider/>
                        {/* @ts-ignore */}
                        <MenuItem data={{foo: "bar"}}
                                  onClick={(e) => savedelete(e, (document.getElementById('Projectid') as HTMLInputElement)?.value, (document.getElementById('taskiD') as HTMLInputElement)?.value, '1', setFetchData)}>
                                <span className="material-icons" style={{verticalAlign: 'bottom', color: 'red'}}>
                                    delete
                                </span>
                            <span className={'pl-2'}
                                  style={{color: 'red'}}><strong>{IntlMessageValue('de', 'common.task.deleted', Store)}</strong></span>
                        </MenuItem>
                    </ContextMenu>
                </div>
                <div id="CalenderElement" style={{display: (calender === 0 ? 'none' : 'block')}}>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="calender_label" className={'bg-primary'}
                                         onClick={(e: any) => collapse(e, 'calender', false, ['GrantDia'], true, setFetchData)}>
                                <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                    calendar_month
                                </span>
                        </InputGroup.Text>
                        <InputGroup.Text id="GrantDia_label" className={'bg-primary-subtle'}
                                         onClick={(e: any) => collapse(e, 'GrantDia', false, ['calender'], true, setFetchData)}>
                                 <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                    insights
                                </span>
                        </InputGroup.Text>
                    </InputGroup>
                    <div id={'calender'} className={'d-block'}>
                        <Calendar
                            messages={messages}
                            localizer={localizer}
                            /*startAccessor="start"
                            endAccessor="end" */
                            popup={true} defaultView={'week'}
                            events={EventHolder}
                            onDoubleClickEvent={(e: any) => CalenderNavigate(e)}
                            style={{
                                minHeight: 'calc(85vh)',
                                maxHeight: 'calc(100vh)',
                                minWidth: '100%',
                                maxWidth: "1000px",
                                margin: " 0 auto",
                                background: '#EFEFEF'
                            }}
                        />
                    </div>
                    <CreateGrantDiagramm ressource={GrandDataHolder} id={'GrantDia'} GrantClick={GrantClick}
                                         setFetchData={setFetchData} className={'d-none'}/>
                </div>
            </div>
            <Modal show={showCloseTask} onHide={handleCloseCloseTask}>
                <Modal.Header closeButton>
                    <Modal.Title>Close task</Modal.Title>
                </Modal.Header>
                <Modal.Body>Should this task really be closed?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCloseTask}>
                        Close
                    </Button>
                    <Button variant="primary float-end" onClick={() => CloseTask()}>
                        Yes i´m sure
                    </Button>
                </Modal.Footer>
            </Modal>
            <div role="dialog" aria-modal="true" className={"offcanvas offcanvas-end " + showEdit}
                 id={'addDialogEditTask'}
                 style={{overflowY: 'auto', minWidth: '400px', top: '60px', right: '10px', width: '40%'}}>
                <div className="offcanvas-body">
                    <div className="modal-dialog">
                        <Form
                            id={'form001'}
                            style={{padding: 0}}
                            className={'formControl'}
                            onSubmit={(e) =>
                                sendEditTask(e, (document.getElementById('Projectid') as HTMLInputElement)?.value, (document.getElementById('taskiD') as HTMLInputElement)?.value, (document.getElementById('Project_title') as HTMLInputElement)?.value)
                            }
                        >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-title h4">Edit task</div>
                                </div>
                                <div className="modal-body" id={'FormBody'}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text
                                            id="basic-addon1">{IntlMessageValue('de', 'common.ticket.title', Store)}</InputGroup.Text>
                                        <Form.Control type="text" name={'title'} id={'taskTitle'}
                                                      required={true}/>
                                    </InputGroup>
                                    <Button variant="outline-primary"
                                            onClick={() => setterEditor('htmleditor', 'markdowneditor')}>HTML
                                        Editor</Button>
                                    <Button variant="outline-primary"
                                            onClick={() => setterEditor('markdowneditor', 'htmleditor')}>Markdown
                                        Editor</Button>

                                    <div id={'htmleditor'} style={{display: 'block'}}>
                                        <SunEditor
                                            key={'test'}

                                            name={'description'}
                                            setContents={value}
                                            setOptions={{
                                                buttonList: SunEditorConfig(),
                                                attributesWhitelist: {
                                                    div: 'id',
                                                    p: 'id',
                                                    h: 'id'
                                                }
                                            }}
                                            height={'373px'}
                                            onChange={(value) => setEditorContent(value)}
                                        />
                                    </div>
                                    <div id={'markdowneditor'} style={{display: 'none'}}
                                         data-color-mode="light">
                                        <MarkdownEditor
                                            value={value.replaceAll('<p>', '').replaceAll('</p>', '')}
                                            height={'450px'}
                                            visible={true}
                                            onChange={(value, viewUpdate) => setEditorContent(value, true)}

                                        />

                                    </div>
                                    <br/>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label><IntlMessage messageId="common.ticket.dueOn"
                                                                 Store={Store}/></Form.Label>
                                        <Form.Control type="date" name={'due_on'} id={'taskDueOn'}/>
                                    </Form.Group>
                                    <br/>
                                    <Row>
                                        <Col className={'col-4'}>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text
                                                    id="basic-addon1">{IntlMessageValue('de', 'common.ticket.priority', Store)}</InputGroup.Text>
                                                <Form.Select aria-label="Default select example" name={'priority'}
                                                             id={'taskPriority'}
                                                             required={true}>
                                                    <option
                                                        label={IntlMessageValue('de', 'common.priority.default', Store)}
                                                        value=""></option>
                                                    {PrioEditHolder}
                                                </Form.Select>
                                                <InputGroup.Text id="basic-addon1" style={{cursor: 'pointer'}}><span
                                                    className="material-icons float-end"
                                                    onClick={() => switchdisplay('MoveElement', null, null, true)}>
                                    info
                                    </span></InputGroup.Text>
                                            </InputGroup>

                                        </Col>
                                        <Col className={'col-6'}>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text
                                                    id="basic-addon1">{IntlMessageValue('de', 'common.ticket.responsibility', Store)}</InputGroup.Text>
                                                <Form.Select aria-label="Default select example" name={'responsibility'}
                                                             required={true} id={'TaskResponsibility'}>
                                                    <option
                                                        label={IntlMessageValue('de', 'common.responsibility.select', Store)}
                                                        value=""></option>
                                                    <option
                                                        value="assign a user">{IntlMessageValue('de', 'common.responsibility.notAssgin', Store)}</option>
                                                    {UserHolder}
                                                </Form.Select>
                                            </InputGroup>
                                        </Col>
                                        <Col className={'col-2'}>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text
                                                    id="basic-addon1">{IntlMessageValue('de', 'common.ticket.color', Store)}</InputGroup.Text>
                                                <Form.Control
                                                    type="color"
                                                    name={'color'}
                                                    id={'saveSwimelanecolor'}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <AddedColor Store={Store} state={state} cid={'saveSwimelanecolor'}
                                                color={ColorHolder} setState={setState}/>
                                    <button type="button" className="btn btn-secondary"
                                            onClick={() => setShowEdit('')}>{IntlMessageValue('de', 'common.close', Store)}
                                    </button>
                                    <button type="submit" id={'AttachDocumentSendButton'}
                                            className="btn btn-primary float-end">{IntlMessageValue('de', 'common.save', Store)}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>


            </div>
            <div role="dialog" aria-modal="true" className={"offcanvas offcanvas-end " + showDuplicate}
                 id={'addDialogDuplicateTask'}
                 style={{overflowY: 'auto', minWidth: '400px', top: '60px', right: '10px', width: '40%'}}>
                {DuplicateTask()}
            </div>
            <InformationTicket AllCPS={[]} Prios={Prio}/>
        </>
    )
}

export default MytaskUser;


export const TableHead = (props: {
    data: any,
    setFetchData: any,
    Configuration: any,
    setSortData: any,
    Store: any
}) => {
    return (
        <>
            <tr>
                <th style={{width: '10%'}}>
                    <TableSortIcons id={0} label={'ID'} setFetchData={props.setFetchData} DataNone={props.data} Configuration={props.Configuration} setSortData={props.setSortData} />
                </th>
                <th style={{width:'30%'}}>
                    <TableSortIcons id={1} label={IntlMessageValue('de','common.table.task',props.Store)} setFetchData={props.setFetchData} DataNone={props.data} Configuration={props.Configuration} setSortData={props.setSortData} />
                </th>
                <th style={{width:'20%'}}>  <TableSortIcons id={2} label={IntlMessageValue('de','common.table.dueDate',props.Store)} setFetchData={props.setFetchData} DataNone={props.data} Configuration={props.Configuration} setSortData={props.setSortData} />
                </th>
                <th style={{width:'30%'}}>  <TableSortIcons id={3} label={IntlMessageValue('de','common.table.project',props.Store)} setFetchData={props.setFetchData} DataNone={props.data} Configuration={props.Configuration} setSortData={props.setSortData} />
                </th>
                <th style={{width:'10%'}}> <TableSortIcons id={4} label={IntlMessageValue('de','common.table.state',props.Store)} setFetchData={props.setFetchData} DataNone={props.data} Configuration={props.Configuration} setSortData={props.setSortData} />
                </th>
            </tr>
        </>

    )

}

export const TableHeadShort = (props:{data:any,setFetchData:any,Configuration:any,setSortData:any,Store:any}) => {
    return(
        <>
            <tr>
                <th style={{width:'15%'}}>
                    <TableSortIcons id={0} label={'ID'} setFetchData={props.setFetchData} DataNone={props.data} Configuration={props.Configuration} setSortData={props.setSortData} />
                </th>
                <th style={{width:'65%'}}>
                    <TableSortIcons id={1} label={IntlMessageValue('de','common.table.task',props.Store)} setFetchData={props.setFetchData} DataNone={props.data} Configuration={props.Configuration} setSortData={props.setSortData} />
                </th>
                <th style={{width:'20%'}}>  <TableSortIcons id={2} label={IntlMessageValue('de','common.table.dueDate',props.Store)} setFetchData={props.setFetchData} DataNone={props.data} Configuration={props.Configuration} setSortData={props.setSortData} />
                </th>
            </tr>
        </>

    )

}

export const TableSortIcons = (props:{label:any,id:any,DataNone:any,Configuration:any,setSortData:any,setFetchData:any}) => {

    return(
        <>
            {props.label}
        <span className="material-icons" style={{verticalAlign:'middle'}}
              onClick={()=>Sort(props.DataNone,'asc',props.Configuration.tableHeaderType[props.id],props.Configuration.tableContent[props.id],props.setSortData,props.setFetchData)}>
                    expand_less
                    </span>
            <span className="material-icons" style={{verticalAlign:'middle'}}
                  onClick={()=>Sort(props.DataNone,'desc',props.Configuration.tableHeaderType[props.id],props.Configuration.tableContent[props.id],props.setSortData,props.setFetchData)}>
                    expand_more
                    </span>
        </>
    )
}

const Sort = (Datas:any,sortmode:any,sorttype:any,part:any,setter:any,responseSetter:any) => {
    let Sortback;
    let Data =  Datas
    if(sortmode === 'asc') {
        Sortback = Data.sort(function(a:any, b:any) {
            switch (sorttype) {
                case 'string':
                    if(part.includes('.') === true) {
                        return (a[part.split('.')[0]][part.split('.')[1]] > b[part.split('.')[0]][part.split('.')[1]]) ? 1 : ((a[part.split('.')[0]][part.split('.')[1]] < b[part.split('.')[0]][part.split('.')[1]]) ? -1 : 0)
                    } else {
                        return (a[part] > b[part]) ? 1 : ((a[part] < b[part]) ? -1 : 0)
                    }

                case 'date':
                    if(part.includes('.') === true) {
                        return new Date(a[part.split('.')[0]][part.split('.')[1]]).getTime() - new Date(b[part.split('.')[0]][part.split('.')[1]]).getTime()
                    } else {
                        return new Date(a[part]).getTime() - new Date(b[part]).getTime()
                    }

                case 'integer':
                    if(part.includes('.') === true) {
                        return  a[part.split('.')[0]][part.split('.')[1]] - b[part.split('.')[0]][part.split('.')[1]]
                    } else {
                        return  a[part] - b[part]
                    }

            }
        });
    } else if(sortmode === 'desc') {
        Sortback = Data.sort(function(a:any, b:any) {
            switch (sorttype) {
                case 'string':
                    if(part.includes('.') === true) {
                        return (b[part.split('.')[0]][part.split('.')[1]] > a[part.split('.')[0]][part.split('.')[1]]) ? 1 : ((b[part.split('.')[0]][part.split('.')[1]] < a[part.split('.')[0]][part.split('.')[1]]) ? -1 : 0)
                    } else {
                        return (b[part] > a[part]) ? 1 : ((b[part] < a[part]) ? -1 : 0)
                    }

                case 'date':
                    if(part.includes('.') === true) {
                        return new Date(b[part.split('.')[0]][part.split('.')[1]]).getTime() - new Date(a[part.split('.')[0]][part.split('.')[1]]).getTime()
                    } else {
                        return new Date(b[part.split('.')[0]][part.split('.')[1]]).getTime() - new Date(a[part.split('.')[0]][part.split('.')[1]]).getTime()
                    }

                case 'integer':
                    if(part.includes('.') === true) {
                        return  b[part.split('.')[0]][part.split('.')[1]] - a[part.split('.')[0]][part.split('.')[1]]
                    }else{
                        return  b[part] - a[part]
                    }

            }
        });
    }
    setter(Sortback)
    responseSetter(Date.now())
}


