import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import {
    ResponseAllCPS,
    ResponseAllLabels,
    ResponseAllPrio,
    ResponseAllTemplate,
    ResponseAllNotifictions,
    ResponseAllTrigger
} from "../../../API/response/ticket";
import React, {useState} from "react";
import {ApiRoot} from "../../../../../@WUM/core/RestFullApi/api";
import {notify_save, notifyProvision, setNavi} from "../../../../../@WUM/core/Function/index";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import {AddedColor, savearchiv} from "./MyTask";
import ReactDOM from "react-dom";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import {collapse} from "./SingleTask";
import Card from 'react-bootstrap/Card';
import {IntlMessage, IntlMessageValue} from "../../../../../@WUM/core/Elements/index";
import {useSelector} from "react-redux";
import {UniForm} from "../../../../../@WUM/core/Elements";
import SunEditorConfig from "../../components/SuneditorConfig";
import SunEditor from "suneditor-react";
import MIcon from "../../../../../@WUM/core/Elements/MIcon";
import DraggableDiv from "../../../../../@WUM/core/Elements/DragAbleDiv";
const CPS = (value:any) => {
    const [fetchData, setFetchData] = useState(0);
    const Store = useSelector((state: any) => state)
    const AllCPS = ResponseAllCPS(fetchData);
    setNavi({
        route:'/cps/overview'
    })
    let ColorHolder:any = [];
    if (typeof AllCPS !== "boolean" && AllCPS?.length > 0) {
        for (let c = 0; c < AllCPS.length; c++) {
            ColorHolder.push({color:AllCPS[c].color,title:AllCPS[c].title})
        }
    }

    const [state, setState] = useState({
        background: '#fff',
    });

    let AllCPSHoldder= [];

    const AllTrigger = ResponseAllTrigger()
    let TriggerHolder: any  = [];
    if (typeof AllTrigger !== "boolean" && AllTrigger?.length > 0) {
        for (let us = 0; us < AllTrigger.length; us++) {
            TriggerHolder.push(
                <>
                    <option value={AllTrigger[us].id}>{AllTrigger[us].name}</option>
                </>
            )
        }
    }



    if (typeof AllCPS !== "boolean" && AllCPS?.length > 0) {
        for (let c = 0; c < AllCPS.length; c++) {

            AllCPSHoldder.push(
                <>
                    <tr>
                        <td style={{background: AllCPS[c].color, width: '20px'}}></td>
                        <td style={{width: '150px'}}>{AllCPS[c].title}</td>
                        <td style={{width: '40px'}}><Button variant="primary" onClick={()=>AddedFunc3(EditCPS,{id:AllCPS[c].id,title:AllCPS[c].title,color:AllCPS[c].color})}>{IntlMessageValue('','cps.table.edit',Store)}</Button></td>
                        <td style={{width: '40px'}}><Button variant="danger" onClick={()=>deleteCPS(AllCPS[c].id)}>{IntlMessageValue('','cps.table.delete',Store)}</Button></td>

                    </tr>
                </>
            )
        }
    }
    const Prio = ResponseAllPrio(fetchData)
    let AllPrioHoldder= [];
    if (typeof Prio !== "boolean" && Prio?.length > 0) {
        for (let c = 0; c < Prio.length; c++) {
            AllPrioHoldder.push(
                <>
                    <tr>
                        <td style={{background: Prio[c].color, width: '20px'}}></td>
                        <td style={{width: '150px'}}>{Prio[c].name}</td>
                        <td style={{width: '150px'}}>{Prio[c].underline}</td>
                        <td style={{width: '40px'}}><Button variant="primary" onClick={()=>AddedFunc3(EditPrio,{id:Prio[c].id,name:Prio[c].name,color:Prio[c].color,underline:Prio[c].underline})}>{IntlMessageValue('','cps.table.edit',Store)}</Button></td>
                        <td style={{width: '40px'}}><Button variant="danger" onClick={()=>deletePrio(Prio[c].id)}>{IntlMessageValue('','cps.table.delete',Store)}</Button></td>
                    </tr>
                </>
            )
        }
    }

    const Template = ResponseAllTemplate(fetchData)
    let AllTemplateHoldder= [];
    if (typeof Template !== "boolean" && Template?.length > 0) {
        for (let c = 0; c < Template.length; c++) {
            AllTemplateHoldder.push(
                <>
                    <tr>
                        <td style={{width: '150px'}}>{Template[c].name}</td>
                        <td style={{width: '40px'}}><Button variant="primary" onClick={()=>AddedFunc3(EditTemplate,{id:Template[c].id,name:Template[c].name,content:Template[c].content})}>{IntlMessageValue('','cps.table.edit',Store)}</Button></td>
                        <td style={{width: '40px'}}><Button variant="danger" onClick={()=>deleteTemplate(Template[c].id)}>{IntlMessageValue('','cps.table.delete',Store)}</Button></td>
                    </tr>
                </>
            )
        }
    }
    const Notification = ResponseAllNotifictions(fetchData)

    let AllNotificationHoldder= [];

    let NotificationNumber = 0;
    if (typeof Notification !== "boolean" && Notification?.length > 0) {
        for (let c = 0; c < Notification.length; c++) {

            NotificationNumber++;
            AllNotificationHoldder.push(
                <>
                    <tr>
                        <td style={{width: '200px'}}>{Notification[c].name}</td>
                        <td style={{width: '250px'}}>{Notification[c].subject}</td>
                        <td style={{width: '50px'}}>{(Notification[c].state === "0" || Notification[c].state === null ? 'inaktiv' : 'aktiv')}</td>
                        <td style={{width: '50px'}}>{Notification[c].type}</td>
                       {/* <td style={{width: '50px',}} className={'text-center'}>
                            {(Notification[c].to_staff === "0" || Notification[c].to_staff === null ?
                                <MIcon children={'radio_button_unchecked'} variant={'fill'}/> :
                                <MIcon children={'radio_button_checked'} variant={'fill'}/>)}
                        </td>
                        <td style={{width: '50px'}} className={'text-center'}>
                            {(Notification[c].to_customer === "0" || Notification[c].to_customer === null ?
                                <MIcon children={'radio_button_unchecked'} variant={'fill'}/> :
                                <MIcon children={'radio_button_checked'} variant={'fill'}/>)}
                        </td>
                        <td style={{width: '50px'}} className={'text-center'}>
                            {(Notification[c].to_admin === "0" || Notification[c].to_admin === null ?
                                <MIcon children={'radio_button_unchecked'} variant={'fill'}/> :
                                <MIcon children={'radio_button_checked'} variant={'fill'}/>)}
                        </td>*/}
                        <td style={{width: '40px'}} className={'text-center'}>
                            <Button variant="primary" onClick={() => AddedFunc3(EditNotifications, {
                                id: Notification[c].id,
                                name: Notification[c].name,
                                content: Notification[c].content,
                                to_staff: Notification[c].to_staff,
                                to_customer: Notification[c].to_customer,
                                to_admin: Notification[c].to_admin,
                                subject: Notification[c].subject,
                                state: Notification[c].state,
                                type:Notification[c].type
                            })}>
                                {IntlMessageValue('', 'cps.table.edit', Store)}
                            </Button>
                        </td>
                        <td style={{width: '40px'}} className={'text-center'}>
                            <Button variant="danger"
                                    onClick={() => deleteNotifications(Notification[c].id)}>{IntlMessageValue('', 'cps.table.delete', Store)}</Button>
                        </td>
                    </tr>
                </>
            )
        }
    }
    const Labels = ResponseAllLabels(fetchData)
    let AllLabelsHoldder= [];
    if (typeof Labels !== "boolean" && Labels?.length > 0) {
        for (let c = 0; c < Labels.length; c++) {
            AllLabelsHoldder.push(
                <>
                    <tr>
                        <td style={{background: Labels[c].color, width: '20px'}}></td>
                        <td style={{width: '150px'}}>{Labels[c].name}</td>
                        <td style={{width: '40px'}}><Button variant="primary" onClick={()=>AddedFunc3(EditLabel,{id:Labels[c].id,name:Labels[c].name,color:Labels[c].color})}>{IntlMessageValue('','cps.table.edit',Store)}</Button></td>
                        <td style={{width: '40px'}}><Button variant="danger" onClick={()=>deleteLabel(Labels[c].id)}>{IntlMessageValue('','cps.table.delete',Store)}</Button></td>
                    </tr>
                </>
            )
        }
    }

    const CloseMember = (indiId?:any,indclear?:any) => {
        let Dialog = document.getElementById((indiId !== undefined && indiId !== null ? indiId : 'addDialogdynamik')) as HTMLDivElement;
        Dialog.style.display = 'none';

        if(indclear !== null && indclear !== undefined) {
            for(let i =0;i<indclear.length;i++) {
                let tmp = document.getElementById(indclear[i].id) as HTMLInputElement;

                if(tmp !== null) {
                    tmp.value = indclear[i].value;
                }
            }
        }

        let Task = document.getElementById('TaskId') as HTMLInputElement;

        if(Task !== null) {
            Task.value = '';
        }

        let Project = document.getElementById('ProjectId') as HTMLInputElement;

        if(Project !== null){
            Project.value = ''
        }

        let Drag = document.getElementsByClassName('drag')[0] as HTMLDivElement
        Drag.style.display = 'none'

    }

    const AddedFunc3 = (form:any,formParam:any) => {

        let Dialog = document.getElementById('addDialogdynamik') as HTMLDivElement;
        Dialog.classList.add("show");
        Dialog.style.display = 'block';
        if(form !== undefined) {
            let newElement = form(formParam)
            let temp = document.createElement('div')
            ReactDOM.render(newElement, temp)
            let ele = document.getElementById('injectModalTask') as HTMLDivElement
            if(ele !== null) {
                ele.innerHTML = ''
                ele.prepend(temp)
            }
        }


    }

    const EditCPS = (data:any) => {
        return(<>

                <div className="modal-dialog">
                    <Form
                        id={'form001'}
                        className={'formControl'}
                        noValidate
                        onSubmit={(e) =>
                            editCPS(e,CloseMember,'addDialogdynamik')
                        }
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title h4">{IntlMessageValue('','cps.button.new.cardBackground',Store)}</div>
                                <button type="button" className="btn-close" aria-label="Close"
                                        onClick={() => CloseMember()}/>
                            </div>
                            <div className="modal-body" id={'FormBody'}>
                                <Row>
                                    <Col className={'col-10'}> <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>{IntlMessageValue('','cps.table.name',Store)}</Form.Label>
                                        <Form.Control type="text" id={'editSwimelanename'} name={'title'} required={true}
                                                      defaultValue={data.title}/>
                                    </Form.Group></Col>
                                    <Col className={'col-2'}> <Form.Label htmlFor="exampleColorInput">{IntlMessageValue('','cps.table.color',Store)}</Form.Label>
                                        <Form.Control
                                            type="color"
                                            name={'color'}
                                            id={'editSwimelanecolor'}
                                            defaultValue={data.color}
                                        /></Col>
                                </Row>
                                <input name={'id'} type="hidden" id="id" className="form-control" defaultValue={data.id} />
                                <AddedColor state={state} Store={Store} cid={'editSwimelanecolor'} color={ColorHolder} setState={setState} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => CloseMember()}>Schließen
                                </button>
                                <button type="submit" id={'AttachDocumentSendButton'} className="btn btn-primary float-end" >Speichern</button>
                            </div>


                        </div>
                    </Form>
                </div>
            </>
        )}

    const editCPS = (e:any,CloseMember:any,CloseMemberID:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            id: formdata.get('id'),
            title: formdata.get('title'),
            color: formdata.get('color')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'ColorPaletteStandard/create/yoptimize/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,CloseMember))
            .catch((error) => errorForm(error,CloseMember))
    }

    const deleteCPS = (Lid:any) => {

        var requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
        };

        fetch(ApiRoot()+'ColorPaletteStandard/delete/yoptimize/'+Lid+'/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,CloseMember))
            .catch((error) => errorForm(error,CloseMember))

    }

    const EditPrio = (data:any) => {
        return(<>

                <div className="modal-dialog">
                    <Form
                        id={'form001'}
                        className={'formControl'}
                        noValidate
                        onSubmit={(e) =>
                            editPrio(e,CloseMember,'addDialogdynamik')
                        }
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title h4">{IntlMessageValue('','cps.button.new.priority',Store)}</div>
                                <button type="button" className="btn-close" aria-label="Close"
                                        onClick={() => CloseMember()}/>
                            </div>
                            <div className="modal-body" id={'FormBody'}>
                                <Row>
                                    <Col className={'col-10'}> <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>{IntlMessageValue('','cps.table.name',Store)}</Form.Label>
                                        <Form.Control type="text" id={'editSwimelanename'} name={'name'} required={true}
                                                      defaultValue={data.name}/>
                                    </Form.Group></Col>
                                    <Col className={'col-2'}> <Form.Label htmlFor="exampleColorInput">{IntlMessageValue('','cps.table.color',Store)}</Form.Label>
                                        <Form.Control
                                            type="color"
                                            name={'color'}
                                            id={'editSwimelanecolor'}
                                            defaultValue={data.color}
                                        /></Col>
                                </Row>
                                <input name={'id'} type="hidden" id="id" className="form-control" defaultValue={data.id} />
                                <AddedColor state={state} cid={'editSwimelanecolor'} Store={Store} color={ColorHolder} setState={setState} />
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>{IntlMessageValue('','cps.table.description',Store)}</Form.Label>
                                    <Form.Control as="textarea" rows={3} name={'underline'} defaultValue={data.underline}/>
                                </Form.Group>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => CloseMember()}>Schließen
                                </button>
                                <button type="submit" id={'AttachDocumentSendButton'} className="btn btn-primary float-end" >Speichern</button>
                            </div>


                        </div>
                    </Form>
                </div>
            </>
        )}

    const editPrio = (e:any,CloseMember:any,CloseMemberID:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            id: formdata.get('id'),
            name: formdata.get('name'),
            underline: formdata.get('underline'),
            color: formdata.get('color')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'prioritaeten/create/yoptimize/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,CloseMember))
            .catch((error) => errorForm(error,CloseMember))
    }

    const deletePrio = (Lid:any) => {

        var requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
        };

        fetch(ApiRoot()+'prioritaeten/delete/yoptimize/'+Lid+'/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,CloseMember))
            .catch((error) => errorForm(error,CloseMember))

    }

    const EditLabel = (data:any) => {
        return(<>

                <div className="modal-dialog">
                    <Form
                        id={'form001'}
                        className={'formControl'}
                        noValidate
                        onSubmit={(e) =>
                            editLabel(e,CloseMember,'addDialogdynamik')
                        }
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title h4">Label</div>
                                <button type="button" className="btn-close" aria-label="Close"
                                        onClick={() => CloseMember()}/>
                            </div>
                            <div className="modal-body" id={'FormBody'}>
                                <Row>
                                    <Col className={'col-10'}> <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>{IntlMessageValue('','cps.table.name',Store)}</Form.Label>
                                        <Form.Control type="text" id={'editSwimelanename'} name={'name'} required={true}
                                                      defaultValue={data.name}/>
                                    </Form.Group></Col>
                                    <Col className={'col-2'}> <Form.Label htmlFor="exampleColorInput">{IntlMessageValue('','cps.table.color',Store)}</Form.Label>
                                        <Form.Control
                                            type="color"
                                            name={'color'}
                                            id={'editSwimelanecolor'}
                                            defaultValue={data.color}
                                        /></Col>
                                </Row>
                                <input name={'id'} type="hidden" id="id" className="form-control" defaultValue={data.id} />
                                <AddedColor state={state} cid={'editSwimelanecolor'} Store={Store} color={ColorHolder} setState={setState} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => CloseMember()}>Schließen
                                </button>
                                <button type="submit" id={'AttachDocumentSendButton'} className="btn btn-primary float-end" >Speichern</button>
                            </div>


                        </div>
                    </Form>
                </div>
            </>
        )}

    const editLabel = (e:any,CloseMember:any,CloseMemberID:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            id: formdata.get('id'),
            name: formdata.get('name'),
            underline: formdata.get('underline'),
            color: formdata.get('color')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'label/create/yoptimize/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,CloseMember))
            .catch((error) => errorForm(error,CloseMember))
    }

    const deleteLabel = (Lid:any) => {

        var requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
        };

        fetch(ApiRoot()+'label/delete/yoptimize/'+Lid+'/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,CloseMember))
            .catch((error) => errorForm(error,CloseMember))

    }

    const EditTemplate = (data:any) => {
        return(<>

                <div className="modal-dialog">
                    <Form
                        id={'form001'}
                        className={'formControl'}
                        noValidate
                        onSubmit={(e) =>
                            editTemplate(e,CloseMember,'addDialogdynamik')
                        }
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title h4">Template</div>
                                <button type="button" className="btn-close" aria-label="Close"
                                        onClick={() => CloseMember()}/>
                            </div>
                            <div className="modal-body pt-3" id={'FormBody'}>
                                <UniForm.InputGroup>
                                    <UniForm.InputGroupText text={IntlMessageValue('de', 'common.name', Store)}/>
                                    <UniForm.FormControl name={'name'} defaultValue={data.name} required={true}
                                                         type={'text'}/>
                                </UniForm.InputGroup>

                                <UniForm.Label text={'Inhalt'} InfoButton={{ display: true,text: '' }} />
                                <SunEditor
                                    key={'test'}

                                    name={'content'}
                                    setContents={data.content}
                                    setOptions={{
                                        buttonList: SunEditorConfig(),
                                        attributesWhitelist: {
                                            div: 'id|class',
                                            p: 'id|class',
                                            h: 'id|class'
                                        }
                                    }}
                                    height={'450px'}
                                />

                                <input name={'id'} type="hidden" id="id" className="form-control" defaultValue={data.id} />
                            </div>
                            <div className="modal-footer mt-3">
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => CloseMember()}>Schließen
                                </button>
                                <button type="submit" id={'AttachDocumentSendButton'} className="btn btn-primary float-end" >Speichern</button>
                            </div>


                        </div>
                    </Form>
                </div>
            </>
        )}

    const editTemplate = (e:any,CloseMember:any,CloseMemberID:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            id: formdata.get('id'),
            name: formdata.get('name'),
            content: formdata.get('content')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'template/task/create/yoptimize/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,CloseMember))
            .catch((error) => errorForm(error,CloseMember))
    }

    const deleteTemplate = (Lid:any) => {

        var requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
        };

        fetch(ApiRoot()+'template/task/delete/yoptimize/'+Lid+'/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,CloseMember))
            .catch((error) => errorForm(error,CloseMember))

    }
    const dataArray = [
        "[Vorname des Nutzers]",
        "[Name der Aufgabe]",
        "[Name des Kommentierenden]",
        "[Inhalt des Kommentars]",
        "[Neuer Inhalt des Kommentars]",
        "[Link zur Aufgabe]",
        "[Name des Verantwortlichen]",
        "[Priorität der Aufgabe]",
        "[Fälligkeitsdatum der Aufgabe]",
        "[Name des neuen Verantwortlichen]",
        "[Alter Status]",
        "[Neuer Status]",
        "[Name der gelöschten Aufgabe]",
        "[Name der wiederhergestellten Aufgabe]",
        "[Liste der wiederhergestellten Aufgaben]",
        "[Name der archivierten Aufgabe]",
        "[Name des neuen Labels]",
        "[Name des entfernten Labels]",
        "[Link zur bearbeiteten externen Verknüpfung]",
        "[Link zur externen Verknüpfung]",
        "[Link zur gelöschten externen Verknüpfung]",
        "[Link zur internen Verknüpfung]",
        "[Link zur gelöschten internen Verknüpfung]",
        "[Name der neuen Unteraufgabe]",
        "[Name der Unteraufgabe]",
        "[Name der entfernten Unteraufgabe]",
    ];
    const EditNotifications = (data:any) => {

        const DisplayDrag = () => {
            let Drag = document.getElementsByClassName('drag')[0] as HTMLDivElement
            if(Drag.style.display === 'block') {
                Drag.style.display = 'none'
            } else {
                Drag.style.display = 'block'
            }

        }

        return(<>

                <div className="modal-dialog">
                    <Form
                        id={'form001'}
                        className={'formControl'}
                        noValidate
                        onSubmit={(e) =>
                            editNotifications(e,CloseMember,'addDialogdynamik')
                        }
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title h4">Template</div>
                                <button type="button" className="btn-close" aria-label="Close"
                                        onClick={() => CloseMember()}/>
                            </div>
                            <div className="modal-body pt-3" id={'FormBody'}>
                                <UniForm.InputGroup>
                                    <UniForm.InputGroupText text={IntlMessageValue('de', 'common.name', Store)}/>
                                    <UniForm.FormControl name={'name'} defaultValue={data.name} required={true}
                                                         type={'text'}/>
                                </UniForm.InputGroup>
                                <UniForm.InputGroup>
                                    <UniForm.InputGroupText
                                        text={IntlMessageValue('de', 'common.ticket.subject', Store)}/>
                                    <UniForm.FormControl name={'subject'} defaultValue={data.subject} required={true}
                                                         type={'text'}/>
                                </UniForm.InputGroup>

                                <UniForm.Label text={'Inhalt'} InfoButton={{display: true, text: ''}}/>
                                <Button className={'ml-3 mb-2'} onClick={DisplayDrag}>ShortCode</Button>
                                <SunEditor
                                    key={'test'}

                                    name={'content'}
                                    setContents={data.content}
                                    setOptions={{
                                        buttonList: SunEditorConfig(),
                                        attributesWhitelist: {
                                            div: 'id|class',
                                            p: 'id|class',
                                            h: 'id|class'
                                        }
                                    }}
                                    height={'450px'}
                                />
                               {/* <UniForm.InputGroup class={'mt-3 mb-3 Checkbolder'}>
                                    <UniForm.InputGroupText class={'mb-0'} text={IntlMessageValue('de', 'recipient', Store)}/>
                                    <Form.Check
                                        inline
                                        style={{paddingTop:'10px',marginLeft:' 5px',marginRight:'0px',paddingRight:'10px',fontWeight:"bolder",background:'#dee2e6',borderRight:'2px solid white'}}

                                        label={IntlMessageValue('de', 'common.to_staff', Store)}
                                        defaultChecked={data.to_staff  === "1" ? true : false}
                                        name={'to_staff'}
                                        type={'checkbox'}
                                        id={'to_staff'}
                                    />
                                    <Form.Check
                                        inline
                                        style={{paddingTop:'10px',marginLeft:' 5px',marginRight:'0px',paddingRight:'10px',fontWeight:"bolder",background:'#dee2e6',borderRight:'2px solid white'}}

                                        label={IntlMessageValue('de', 'common.to_customer', Store)}
                                        name={'to_customer'}
                                        defaultChecked={data.to_customer === "1" ? true : false }
                                        type={'checkbox'}
                                        id={'to_customer'}
                                    />
                                    <Form.Check
                                        inline
                                        style={{paddingTop:'10px',marginLeft:' 5px',marginRight:'0px',paddingRight:'10px',fontWeight:"bolder",background:'#dee2e6',borderRight:'2px solid white'}}
                                        label={IntlMessageValue('de', 'common.to_admin', Store)}
                                        name={'to_admin'}
                                        defaultChecked={data.to_admin  === "1" ? true : false}
                                        type={'checkbox'}
                                        id={'to_admin'}
                                    />
                                </UniForm.InputGroup>*/}
                                <UniForm.InputGroup class={'mt-3 mb-3 Checkbolder'}>
                                    <UniForm.InputGroupText class={'mb-0'} text={IntlMessageValue('de', 'common.type', Store)}/>

                                    <Form.Select aria-label="Default select example" name={'type'}
                                                 defaultValue={data.type}>
                                        <option label={IntlMessageValue('de', 'notification.placeholder', Store)}
                                                value=""></option>
                                        {TriggerHolder}
                                    </Form.Select>
                                </UniForm.InputGroup>
                                <UniForm.InputGroup class={'mt-3 mb-3 Checkbolder'}>
                                <UniForm.InputGroupText class={'mb-0'} text={IntlMessageValue('de', 'common.status', Store)}/>
                                <Form.Select aria-label="Default select example" name={'state'} defaultValue={data.state}>
                                    <option value="1">{IntlMessageValue('de', 'common.activated', Store)}</option>
                                    <option value="0">{IntlMessageValue('de', 'common.deactivated', Store)}</option>
                                </Form.Select>
                                </UniForm.InputGroup>
                                <input name={'id'} type="hidden" id="id" className="form-control"
                                       defaultValue={data.id}/>
                            </div>
                            <div className="modal-footer mt-3">
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => CloseMember()}>Schließen
                                </button>
                                <button type="submit" id={'AttachDocumentSendButton'}
                                        className="btn btn-primary float-end">Speichern
                                </button>
                            </div>


                        </div>
                    </Form>
                </div>
            </>
        )}

    const editNotifications = (e:any,CloseMember:any,CloseMemberID:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            id: formdata.get('id'),
            name: formdata.get('name'),
            content: formdata.get('content'),
           /* to_staff:formdata.get('to_staff'),
            to_customer:formdata.get('to_customer'),
            to_admin:formdata.get('to_admin'),*/
            subject:formdata.get('subject'),
            state:formdata.get('state'),
            type:formdata.get('type')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'notifications/task/create/yoptimize/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,CloseMember))
            .catch((error) => errorForm(error,CloseMember))
    }

    const deleteNotifications = (Lid:any) => {

        var requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
        };

        fetch(ApiRoot()+'notifications/task/delete/yoptimize/'+Lid+'/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,CloseMember))
            .catch((error) => errorForm(error,CloseMember))

    }

    const successForm = (result:any,close:any,mode?:any,indiclear?:any) => {
        setFetchData(Date.now())
        notify_save()
        if(mode !== undefined && mode !== null) {
            close(mode,indiclear)
        } else {
            close()
        }

    }

    const errorForm = (error:any,close:any,mode?:any)=> {
        if(mode !== undefined && mode !== null) {
            close(mode)
        } else {
            close()
        }
    }

    const collapseGeneralSettings = (e:any,id:any,arrowShow:any=true,noneShow:any = [],menuid:any,noneShowmenu:any = []) => {

            let Collapse = document.getElementById(id) as HTMLDivElement;
            if (Collapse.classList.contains("d-none") === true) {
                Collapse.classList.remove("d-none");
                Collapse.classList.add("d-block");
                let CollapseInfo = document.getElementById(id+'_info') as HTMLDivElement;
                CollapseInfo.classList.remove("d-none");
                CollapseInfo.classList.add("d-block");

                for(let ns=0;ns<noneShow.length;ns++) {
                    let CollapseClose = document.getElementById(noneShow[ns]) as HTMLDivElement;
                    CollapseClose.classList.add("d-none");
                    CollapseClose.classList.remove("d-block");
                    let CollapseInfo = document.getElementById(noneShow[ns]+'_info') as HTMLDivElement;
                    CollapseInfo.classList.add("d-none");
                    CollapseInfo.classList.remove("d-block");

                }
            }
        let CollapseMenu = document.getElementById(menuid) as HTMLDivElement;
        CollapseMenu.classList.add("d-flex");
        CollapseMenu.classList.remove("d-none");
        for (let ns = 0; ns < noneShowmenu.length; ns++) {
            let CollapseMenusec = document.getElementById(noneShowmenu[ns]) as HTMLDivElement;
            CollapseMenusec.classList.add("d-none");
            CollapseMenusec.classList.remove("d-flex");
        }
        }


    return (
        <>
            <div className={'ticketsystem'} style={{paddingLeft: '25px'}}>
            <Row id={'cpsmenu'} className={'d-flex'}>
                    <Col xs={2} md={2} xl={2} xxl={2}>
                        <h3 id={'headline'}>{IntlMessageValue('','cps.headline',Store)}</h3>
                        <h4 onClick={(e) => collapse(e, 'actions')}>{IntlMessageValue('','cps.actions',Store)}
                            <span id={'actions_arrow'} className="material-icons" style={{verticalAlign: 'bottom'}}>
                            expand_less
                        </span>
                        </h4>
                        <div id={'actions'} className={'d-block'}>
                        <Navbar className="bg-body-transparent">
                            <Container>
                                <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}}  onClick={(e) => collapseGeneralSettings(e, 'cps',false,['Priority','Label','Template'],'cpsmenu',['Notifications'])} >
                            <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                palette
                            </span>
                                    {IntlMessageValue('','common.cps.navi.cps',Store)}
                                </Navbar.Brand>
                            </Container>
                        </Navbar>
                        <Navbar className="bg-body-transparent">
                            <Container>
                                <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => collapseGeneralSettings(e, 'Priority',false,['cps','Label','Template'],'cpsmenu',['Notifications'])} >
                                  <span className="material-icons" style={{verticalAlign: 'middle'}} >
                                low_priority
                            </span>
                                    {IntlMessageValue('','cps.nav.task.priority',Store)}</Navbar.Brand>
                            </Container>
                        </Navbar>
                        <Navbar className="bg-body-transparent">
                            <Container>
                                <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => collapseGeneralSettings(e, 'Label',false,['cps','Priority','Template'],'cpsmenu',['Notifications'])} >
                                  <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                label
                            </span>
                                    {IntlMessageValue('','cps.nav.task.label',Store)}</Navbar.Brand>
                            </Container>
                        </Navbar>
                            <Navbar className="bg-body-transparent">
                                <Container>
                                    <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => collapseGeneralSettings(e, 'Template',false,['cps','Priority','Label'],'cpsmenu',['Notifications'])} >
                                  <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                description
                            </span>
                                        Template</Navbar.Brand>
                                </Container>
                            </Navbar>
                            <Navbar className="bg-body-transparent">
                                <Container>
                                    <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}}  onClick={(e) => collapseGeneralSettings(e, 'notificationss',false,[''],'Notifications',['cpsmenu'])} >
                                        <MIcon variant={'fill'} className={'mr-3'} style={{verticalAlign: 'middle'}}>
                                            schedule_send
                                        </MIcon>
                                        {IntlMessageValue('', 'common.ticket.notification', Store)}
                                    </Navbar.Brand>
                                </Container>
                            </Navbar>
                    </div>
                </Col>
                <Col xs={4} md={4} xl={4} xxl={4}>
                    <Row style={{marginTop: '2em', marginLeft: '-12px', marginRight: '-12px'}} className={'d-block'} id={'cps'}>
                        <Col style={{width:'max-content'}}>
                            <div style={{width:'450px',paddingRight:'0px',marginBottom:'10px'}}>
                                <Button variant="success" className={'float-end'}
                                        onClick={()=>AddedFunc3(EditCPS,{id:0,title:'',color:'#ffffff'})}> {IntlMessageValue('','cps.button.new.cardBackground',Store)}</Button>
                                <br style={{clear:'both'}}/>
                            </div>

                            <Table striped bordered hover style={{width:'450px'}}>
                                <thead>
                                <tr>
                                    <th style={{width:'20px'}}>{IntlMessageValue('','cps.table.color',Store)}</th>
                                    <th style={{width:'150px'}}>{IntlMessageValue('','cps.table.title',Store)}</th>
                                    <th style={{width:'40px'}}>{IntlMessageValue('','cps.table.edit',Store)}</th>
                                    <th style={{width:'40px'}}>{IntlMessageValue('','cps.table.delete',Store)}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {AllCPSHoldder}
                                </tbody>
                            </Table>
                        </Col>
                    </Row><Row style={{marginTop: '2em', marginLeft: '-12px', marginRight: '-12px'}} className={'d-none'} id={'Priority'}>
                        <Col style={{width:'max-content'}}>
                            <div style={{width:'450px',paddingRight:'0px',marginBottom:'10px'}}>
                                <Button variant="success" className={'float-end'}
                                        onClick={()=>AddedFunc3(EditPrio,{id:0,name:'',color:'#ffffff'})}>{IntlMessageValue('','cps.button.new.priority',Store)}</Button>
                                <br style={{clear:'both'}}/>
                            </div>
                            <Table striped bordered hover style={{width:'450px'}}>
                                <thead>
                                <tr>
                                    <th style={{width:'20px'}}>{IntlMessageValue('','cps.table.color',Store)}</th>
                                    <th style={{width:'50px'}}>{IntlMessageValue('','cps.table.title',Store)}</th>
                                    <th style={{width:'350px'}}>{IntlMessageValue('','cps.table.description',Store)}</th>
                                    <th style={{width:'40px'}}>{IntlMessageValue('','cps.table.edit',Store)}</th>
                                    <th style={{width:'40px'}}>{IntlMessageValue('','cps.table.delete',Store)}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {AllPrioHoldder}
                                </tbody>
                            </Table>
                        </Col>
                </Row><Row style={{marginTop: '2em', marginLeft: '-12px', marginRight: '-12px'}} className={'d-none'}  id={'Label'}>
                        <Col style={{width:'max-content'}}>
                            <div style={{width:'450px',paddingRight:'0px',marginBottom:'10px'}}>
                                <Button variant="success" className={'float-end'}
                                        onClick={()=>AddedFunc3(EditLabel,{id:0,name:'',color:'#ffffff',underline:''})}>{IntlMessageValue('','cps.button.new.taskLabel',Store)}</Button>
                                <br style={{clear:'both'}}/>
                            </div>
                            <Table striped bordered hover style={{width:'450px'}}>
                                <thead>
                                <tr>
                                    <th style={{width:'20px'}}>{IntlMessageValue('','cps.table.color',Store)}</th>
                                    <th style={{width:'50px'}}>{IntlMessageValue('','cps.table.name',Store)}</th>
                                    <th style={{width:'40px'}}>{IntlMessageValue('','cps.table.edit',Store)}</th>
                                    <th style={{width:'40px'}}>{IntlMessageValue('','cps.table.delete',Store)}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {AllLabelsHoldder}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '2em', marginLeft: '-12px', marginRight: '-12px'}} className={'d-none'}  id={'Template'}>
                        <Col style={{width:'max-content'}}>
                            <div style={{width:'450px',paddingRight:'0px',marginBottom:'10px'}}>
                                <Button variant="success" className={'float-end'}
                                        onClick={()=>AddedFunc3(EditTemplate,{id:0,name:'',content:''})}>{IntlMessageValue('','cps.button.new.taskTemplate',Store)}</Button>
                                <br style={{clear:'both'}}/>
                            </div>
                            <Table striped bordered hover style={{width:'450px'}}>
                                <thead>
                                <tr>
                                    <th style={{width: '50px'}}>{IntlMessageValue('', 'common.name', Store)}</th>
                                    <th style={{width: '40px'}}>{IntlMessageValue('', 'cps.table.edit', Store)}</th>
                                    <th style={{width: '40px'}}>{IntlMessageValue('', 'cps.table.delete', Store)}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {AllTemplateHoldder}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Col>
                <Col xs={6} md={6} xl={6} xxl={6}>
                    <Row style={{marginTop: '2em', marginLeft: '-12px', marginRight: '-12px',width: '500px', float: 'right'}} className={'d-block'} id={'cps'}>
                        <Col style={{width:'max-content'}}>
                            <Card id={'cps_info'} className={'d-block'}>
                                <Card.Body>
                                    <div dangerouslySetInnerHTML={{__html: IntlMessageValue('','cps.info',Store)}} />
                                   </Card.Body>
                            </Card>
                            <Card id={'Priority_info'} className={'d-none'}>
                                <Card.Body>
                                    <div dangerouslySetInnerHTML={{__html: IntlMessageValue('','cps.prio',Store)}} />
                                </Card.Body>
                            </Card>
                            <Card id={'Label_info'} className={'d-none'}>
                                <Card.Body>
                                    <div dangerouslySetInnerHTML={{__html: IntlMessageValue('','cps.label',Store)}} />
                                </Card.Body>
                            </Card>
                            <Card id={'Template_info'} className={'d-none'}>
                                <Card.Body>
                                    <div dangerouslySetInnerHTML={{__html: IntlMessageValue('','cps.template',Store)}} />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>


                <Row id={'Notifications'} className={'d-none'}>
                    <Col xs={2} md={2} xl={2} xxl={2}>
                        <h3 id={'headline'}>{IntlMessageValue('','cps.headline',Store)}</h3>
                        <h4 onClick={(e) => collapse(e, 'actions')}>{IntlMessageValue('','cps.actions',Store)}
                            <span id={'actions_arrow'} className="material-icons" style={{verticalAlign: 'bottom'}}>
                            expand_less
                        </span>
                        </h4>
                        <div id={'actions'} className={'d-block'}>
                            <Navbar className="bg-body-transparent">
                                <Container>
                                    <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}}  onClick={(e) => collapseGeneralSettings(e, 'cps',false,['Priority','Label','Template'],'cpsmenu',['Notifications'])} >
                            <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                palette
                            </span>
                                        {IntlMessageValue('','common.cps.navi.cps',Store)}
                                    </Navbar.Brand>
                                </Container>
                            </Navbar>
                            <Navbar className="bg-body-transparent">
                                <Container>
                                    <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => collapseGeneralSettings(e, 'Priority',false,['cps','Label','Template'],'cpsmenu',['Notifications'])} >
                                  <span className="material-icons" style={{verticalAlign: 'middle'}} >
                                low_priority
                            </span>
                                        {IntlMessageValue('','cps.nav.task.priority',Store)}</Navbar.Brand>
                                </Container>
                            </Navbar>
                            <Navbar className="bg-body-transparent">
                                <Container>
                                    <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => collapseGeneralSettings(e, 'Label',false,['cps','Priority','Template'],'cpsmenu',['Notifications'])} >
                                  <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                label
                            </span>
                                        {IntlMessageValue('','cps.nav.task.label',Store)}</Navbar.Brand>
                                </Container>
                            </Navbar>
                            <Navbar className="bg-body-transparent">
                                <Container>
                                    <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => collapseGeneralSettings(e, 'Template',false,['cps','Priority','Label'],'cpsmenu',['Notifications'])} >
                                  <span className="material-icons" style={{verticalAlign: 'middle'}}>
                                description
                            </span>
                                        Template</Navbar.Brand>
                                </Container>
                            </Navbar>
                            <Navbar className="bg-body-transparent">
                                <Container>
                                    <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}}  onClick={(e) => collapseGeneralSettings(e, 'notificationss',false,['cps','Priority','Label','Template'],'Notifications',['cpsmenu'])} >
                                        <MIcon variant={'fill'} className={'mr-3'} style={{verticalAlign: 'middle'}}>
                                            schedule_send
                                        </MIcon>
                                        {IntlMessageValue('', 'common.ticket.notification', Store)}
                                    </Navbar.Brand>
                                </Container>
                            </Navbar>
                        </div>
                    </Col>
                    <Col xs={9} md={9} xl={9} xxl={9} className={'col'}>
                        <DraggableDiv style={{border:0,display:"none"}}  className={'drag'} content={
                            <>
                                <h3>ShortCode</h3>
                                <div >
                                    {dataArray.map((route, index) =>
                                        <p className={'p-0 m-0'}>{route}</p>
                                    )}
                                </div>
                            </>
                        }  />
                        <Row style={{marginTop: '2em', marginLeft: '-12px', marginRight: '-12px'}} className={'d-block'}  id={'notificationss'}>
                            <Card >
                                <Card.Body>
                                    <div style={{width:'100%',paddingRight:'0px',marginBottom:'10px'}}>
                                        <h3 className={'float-start'}>{IntlMessageValue('', 'common.ticket.notification', Store)} ({NotificationNumber})</h3>
                                        <Button variant="success" className={'float-end'}
                                                onClick={()=>AddedFunc3(EditNotifications,{id:0,name:'',content:'',to_staff:0,to_customer:0,to_admin:0,subject:'',state:1})}>{IntlMessageValue('','common.ticket.notificationAdd',Store)}</Button>
                                        <br style={{clear:'both'}}/>
                                    </div>
                                    <Table striped bordered hover style={{width:'100%'}}>
                                        <thead>
                                        <tr>
                                            <th style={{width: '150px'}}>{IntlMessageValue('', 'common.name', Store)}</th>
                                            <th style={{width: '40px'}}>{IntlMessageValue('', 'common.ticket.subject', Store)}</th>
                                            <th style={{width: '40px'}}>{IntlMessageValue('', 'common.status', Store)}</th>
                                            <th style={{width: '10px'}}>{IntlMessageValue('', 'common.type', Store)}</th>
                                           {/* <th style={{width: '50px'}}
                                                className={'text-center'}>{IntlMessageValue('', 'common.to_staff', Store)}</th>
                                            <th style={{width: '40px'}}
                                                className={'text-center'}>{IntlMessageValue('', 'common.to_customer', Store)}</th>
                                            <th style={{width: '40px'}}
                                                className={'text-center'}>{IntlMessageValue('', 'common.to_admin', Store)}</th>*/}
                                            <th style={{width: '40px'}}></th>
                                            <th style={{width: '40px'}}></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {AllNotificationHoldder}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Row>
                    </Col>
                </Row>

                <div id={'Notifications_info'} className={'d-none'}> </div>



            </div>
            <div role="dialog" aria-modal="true" className="offcanvas offcanvas-end"
                 id="addDialogdynamik"
                 style={{overflowY: 'auto', minWidth: '400px', top: '60px', right: '10px', width: '40%'}}>

                <div className="offcanvas-body">
                    <div id="injectModalTask"></div>
                </div>
            </div>
        </>
    )
}

export default CPS;
