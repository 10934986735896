import {setNavi} from "../../../@WUM/core/Function/index";
import '../css/ticketsystem2.css'
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import {Chart} from "react-google-charts";
import LineChart from "react-google-charts";
import AreaChart from "react-google-charts";
import ColumnChart from "react-google-charts";
import {ResponseDashbaordTask} from "../API/response/ticketsystem2";
import {Link, useNavigate} from "react-router-dom";
import {colorSwitcher} from "../../ticketsystem/modules/new/Pages/MyTask";
import {IntlMessageValue} from "../../../@WUM/core/component/const/IntlMessage";
import Table from 'react-bootstrap/Table';
import {dateFormat, IntlMessage} from "../../../@WUM/core/Elements";
const Reports = (value:any) => {

    setNavi({
        route:'/report/activityrecording/'
    })

    const Store = useSelector((state: any) => state)
    const Tasks = ResponseDashbaordTask(0)
    const data = [];
    const navigate = useNavigate();
    if (Tasks !== true && typeof Tasks !== "boolean" && Tasks !== undefined) {
        data.push(["Task", "Hours per Day"]);
        data.push([IntlMessageValue('de', 'inbox.priority.Lowest', Store), Tasks.EmployeOverciew[0].prio.lowest]);
        data.push([IntlMessageValue('de', 'inbox.priority.Low', Store), Tasks.EmployeOverciew[0].prio.low]);
        data.push([IntlMessageValue('de', 'inbox.priority.Medium', Store), Tasks.EmployeOverciew[0].prio.medium]);
        data.push([IntlMessageValue('de', 'inbox.priority.High', Store), Tasks.EmployeOverciew[0].prio.hight]);
        data.push([IntlMessageValue('de', 'inbox.priority.Urgent', Store), Tasks.EmployeOverciew[0].prio.urgent]);
    }



    let Piedata = [ ];



    if (Tasks !== true && typeof Tasks !== "boolean"  && Tasks !== undefined) {
        Piedata.push(["Task", "Hours per Day"]);
        Piedata.push([IntlMessageValue('de', 'inbox.status.Open', Store), Tasks.EmployeOverciew[0].state.open]);
        Piedata.push([IntlMessageValue('de', 'inbox.status.In Progress', Store), Tasks.EmployeOverciew[0].state.inProgress]);
        Piedata.push([IntlMessageValue('de', 'inbox.status.Pending', Store), Tasks.EmployeOverciew[0].state.pending]);
        Piedata.push([IntlMessageValue('de', 'inbox.status.Resolve', Store), Tasks.EmployeOverciew[0].state.close]);
    }

    let Piesdata = [ ];



    if (Tasks !== true && typeof Tasks !== "boolean"  && Tasks !== undefined) {
        Piesdata.push(["Task", "Hours per Day"]);
        Piesdata.push([IntlMessageValue('de', 't2.Bug', Store), Tasks.EmployeOverciew[0].type.bug]);
        Piesdata.push([IntlMessageValue('de', 't2.Fehlende Funktion', Store), Tasks.EmployeOverciew[0].type.missing_function]);
        Piesdata.push([IntlMessageValue('de', 't2.Verbesserung', Store), Tasks.EmployeOverciew[0].type.improvement]);
        Piesdata.push([IntlMessageValue('de', 't2.Individuelle Anpassung', Store), Tasks.EmployeOverciew[0].type.individual_customization]);
    }
    let colorset = [
        {color:'#2EA02E'},
        {color:'#2EA02E'},
        {color:'#2EA02E'},
        {color:'#ff8800'},
        {color:'#B71234'},
        {color:'#B71234'}
    ]

    let colorsetState = [
        {color:'#2EA02E'},
        {color:'#001ca4'},
        {color:'#fcfc40'},
        {color:'#ff8800'},
        {color:'#f82c00'},
        {color:'#14b814'}
    ]


    let chartDatas = [];
    chartDatas.push(["Tag", "Tickets erstellt", "Tickets gelöst"]);

    let chartDataArea = [];
    chartDataArea.push(["Tag", "Erste Reaktionszeit", "Durchschnittliche Reaktionszeit"])

    let chartDataColumn = [];
    chartDataColumn.push(["Tag", "Ticket complete time",'Average'])


    if (Tasks !== true && typeof Tasks !== "boolean"  && Tasks !== undefined) {

        chartDatas = [
            ...chartDatas,
            ...Tasks.Tickets_History
        ]

        chartDataArea = [
            ...chartDataArea,
            ...Tasks.Reaction_Time
        ]
        chartDataColumn = [
            ...chartDataColumn,
            ...Tasks.Complete_Time
        ]

    }



    const optionsColumn = {
        title: "Vollständige Auflösungszeit",
        legend: { position: 'bottom', alignment: 'center' },
        chartArea: {width: "80%", height: "60%"},
        series: {
            0: { color: "green" }, // Vollständige Auflösungszeit
            1: { color: "red" }, // Reaktionszeit
        },
    };




    const optionss = {
        title: "Tickets History - Last 30 Days",
        legend: { position: 'bottom', alignment: 'center' },
        chartArea: {width: "80%", height: "60%"},
        series: {
            0: { color: "#3f51b5" }, // Tickets erstellt
            1: { color: "green" }, // Tickets gelöst
        },
    };

    const optionsArea = {
        title: "First Response Time - Last 30 Days",
        hAxis: {
            title: "Datum",
        },
        vAxis: {
            title: "Reaktionszeit in Minuten",
        },
        legend: { position: 'bottom', alignment: 'center' },
        chartArea: {width: "80%", height: "60%"},
        series: {
            0: { color: "#3f51b5" }, // Erste Reaktionszeit
            1: { color: "red" }, // Durchschnittliche Reaktionszeit
        },
    };

    if (Tasks !== true && typeof Tasks !== "boolean"  && Tasks !== undefined) {

        let RecentHolder = [];

        for(let re=0;re<Tasks.RecentTask.length;re++) {
            let Data = Tasks.RecentTask[re]
            RecentHolder.push(
                <>
                    <tr onDoubleClick={() => navigate('/ticket/single/ticketsystem/' + Data.id)} >
                        <td><p className={'tableHeight'}>{Data.plugin}</p></td>
                        <td><p className={'tableHeight'}>{Data.regard}</p></td>
                        <td> <span className="badge " style={{
                            background: (Data.priority !== null ? colorset[Data.priority].color : colorset[0].color),
                            color: (colorSwitcher((Data.priority !== null ? colorset[Data.priority].color : colorset[0].color), false) ? 'black' : 'white')
                        }}>
                           {(Data.priority !== null ? IntlMessageValue('de', 'inbox.priority.' + Data.ticket_prioritaeten.name, Store) : 'Low')}
                        </span></td>
                        <td>
                            <span className="badge rounded-pill" style={{
                                background: (Data.status_id !== null ? colorsetState[Data.status_id].color : colorsetState[0].color),
                                color: (colorSwitcher((Data.status_id !== null ? colorsetState[Data.status_id].color : colorsetState[0].color), false) ? 'black' : 'white')
                            }}>
                            {IntlMessageValue('de', 'inbox.status.' + Data.status.state, Store)}
                        </span></td>
                        <td>{(Data.support_type_id !== null ? <><IntlMessage messageId={"dashboard.t3." + Data.ticket_type.type}
                                                                             Store={Store}/></> : '')}</td>
                        <td>{
                            dateFormat(
                                Data.created_at,
                                localStorage.getItem('locale'),
                                'us',
                                true,
                                'onlyDate',
                                Store
                            )
                        }</td>
                    </tr>

                </>
            )
        }

        return (<>

            <div className={'ticketsystem'} style={{paddingLeft: '25px'}}>
                <h3 className={'mb-3'}>Reports & Statistic</h3>


                <Row style={{marginTop: '2em', marginLeft: '0px', marginRight: '0px'}}>
                    <Col className={'col-6 bg-transparent p-0 '}>
                        <div className={' p-3 mb-3 rounded border bg-white'} style={{height: '100%'}}>
                            <h3><IntlMessage messageId={'dashboard.last6.tickets'} Store={Store}/></h3>
                            <Row className={'p-3'}>

                                <Table hover>
                                    <tbody>
                                    {RecentHolder}
                                    </tbody>
                                </Table>
                            </Row>
                        </div>
                    </Col>

                    <Col className={'col-6 bg-transparent p-0'}>
                        <div className={'card p-3 ml-3'} style={{height: '100%'}}>

                            <AreaChart chartType={'AreaChart'}
                                       data={chartDataArea}
                                       options={optionsArea}
                                       height={400}
                                       width="100%"
                            />
                        </div>
                    </Col>
                </Row>
                <Row style={{marginTop: '2em', marginLeft: '0px', marginRight: '0px'}}>
                    <Col className={'col-6 bg-transparent p-0'} style={{height: '100%'}}>
                        <div className={'card p-3'}>

                            <LineChart chartType={'LineChart'}
                                       data={chartDatas}
                                       options={optionss}
                                       height={400}
                                       width="100%"
                            />
                        </div>
                    </Col>
                    <Col className={'col-6 bg-transparent p-0'}>
                        <div className={'card p-3 ml-3'} style={{height: '100%'}}>

                            <ColumnChart chartType={'AreaChart'}
                                         data={chartDataColumn}
                                         options={optionsColumn}
                                         height={400}
                                         width="100%"
                            />
                        </div>
                    </Col>
                </Row>

            </div>
        </>)

    } else {
        return (
            <></>
        )
    }
}
export default Reports;
