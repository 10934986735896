import { useEffect, useState } from 'react'
import { error } from '../../../Elements/index'
import { notification } from '../request/notification'
import { notificationApiType } from '../request/notificationApi.interface.ts'

export const ResponseAllNotification = (limit: any, searchValue: any) => {
    const [posts, setPosts] = useState<notificationApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await notification
                .getPostsALL(limit, searchValue)
                .then((data) => {
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [limit, searchValue])

    return isError ? posts : posts
}


export const ResponseAllNotificationUnread = (limit:any) => {
    const [posts, setPosts] = useState<notificationApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await notification
                .getPostsALLUnread(limit)
                .then((data) => {
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [limit])

    return isError ? posts : posts
}



export const ResponseNotificationDetail = (id: any) => {
    const [posts, setPosts] = useState<notificationApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await notification
                .getAPost(id)
                .then((data) => {
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? posts : posts
}
