import React, { Suspense, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Sidebar from '../sidebar/sidebar'
import Site404 from './404'
import ErrorBoundary from '../../core/routing/ErrorBoundary'
import ReactFlagsSelect from 'react-flags-select'
import Avatar from '@material-ui/core/Avatar'
import { IntlMessageValue } from '../component/const/IntlMessage'
import * as actions from '../../templates/default/localization/store/Actions/Locale/LocaleAction'
import { AppDispatch } from '../../../store'
import { useDispatch, useSelector } from 'react-redux'
import HelpDesk from '../../helpdesk/modules'
import SecureCheck from '../RoleSecury'
import { ResponseGetModuls } from './Api/response/dynamik'
import Ribbon, {closeModalRibbon} from "../ribbon";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link} from "react-router-dom";
import GetRoute from "../RestFullApi/local/getRoute";
import {ResponseGetRibbon} from "../ribbon/API/response/ribbons";
import Favourite from "../Favo/modules/Favourite";
import {CheckRoute} from "../Elements";

interface RoutingProps {
    config: { roles: string[]; path: string; elements: JSX.Element }[]
    setValue: any
    value: any
}

const Routing = (props: RoutingProps) => {
    const dispatch: AppDispatch = useDispatch()
    let Store = useSelector((state: any) => state)
    let route = props.config
    if (
        process.env.REACT_APP_DB_MODULS !== undefined && process.env.REACT_APP_DB_MODULS === 'true'
    ) {
        ResponseGetModuls(dispatch)
    }




    const [selected, setSelected] = useState(localStorage.getItem('locale'))
    const [favi, setFavi] = useState(JSON.parse(localStorage.getItem('Favo') === 'undefined' ? '[{}]' : localStorage.getItem('Favo') as string ))
    const [jsonFavo,setJasonFavo]  = useState()


    let sidebar
    const setter = (code: React.SetStateAction<string>) => {
        setSelected(code as string)
        props.setValue(code)
        localStorage.setItem('locale', code as string)
        dispatch(actions.getLocale(code as string) as any)
    }

    if (process.env.REACT_APP_SIDEBAR === undefined) {
        sidebar = (
            <>
                <Sidebar setValue={props.setValue} value={props.value} />
            </>
        )
    } else {
        sidebar = <></>
    }

    let avatar
    let username

    if (
        Store.user !== undefined &&
        Store.user.username !== null &&
        Store.user.username !== undefined
    ) {
        if (Store.user.username.includes(' ')) {
            avatar =
                Store.user.username.split(' ')[0].charAt(0).toUpperCase() +
                '' +
                Store.user.username.split(' ')[1].charAt(0).toUpperCase()
        } else {
            avatar = Store.user.username.split(' ')[0].charAt(0).toUpperCase()
        }
        username = Store.user.username
    } else {
        username = 'Demo User'
        avatar = 'DU'
    }
    let Ribbons;
    let Header;
    let Footers;
    let themeHead;
    let notifi =
        (process.env.REACT_APP_WITH_NOTIFICATION !== undefined ? <>
            <Link to={'/notification/0'}>
        <span className="material-icons notificationIcon">
            notifications
            </span>
            </Link>
        </> : <></>)

    if (process.env.REACT_APP_SIDEBAR === undefined && process.env.REACT_APP_RIBBON === undefined) {
        sidebar = (
            <>
                <Sidebar setValue={props.setValue} value={props.value} />
            </>
        )
        Ribbons =  <></>
        Header = <>

            <div className={'theme-avatar'}>
                {notifi}
                <Avatar style={{ float: 'left' }} src={'data:image/png;base64,'+localStorage.getItem('avatar') as string}>
                    {avatar}
                </Avatar>{' '}
                <p
                    className={'avatarName'}
                    style={{ paddingTop: '0px' }}
                >
                    {username}
                    <br />{' '}
                    <a href={'/Logout'}>
                        {IntlMessageValue(
                            'de',
                            'logout',
                            Store
                        )}
                    </a>
                </p>
            </div>
            <div className={'theme-flags'}>
                <ReactFlagsSelect
                    selected={selected as string}
                    onSelect={(code) => setter(code)}
                    countries={['US', 'DE']}
                    customLabels={{
                        US: 'EN-US',
                        DE: 'DE'
                    }}
                    placeholder="Select Language"
                />
            </div>
        </>

        themeHead = <>
            <div className={'theme-header'}>
                {Header}
                {Ribbons}
            </div>
            <div
                className={"ContentWrapper"}
                style={{
                    paddingLeft: '5px',
                    paddingRight: '15px',
                    marginTop: '70px',
                    background: 'white',
                    marginBottom: '70px'
                }}
            >
                <CheckRoute>
                    <Routes>
                        {route.map((route, index) =>
                            SecureCheck(route.roles) ? (

                                <Route
                                    key={index}
                                    path={route.path}
                                    element={route.elements}
                                />

                            ) : (
                                <></>
                            )
                        )}
                        <Route path="*" element={<Site404 />} />
                   </Routes>
                </CheckRoute>
                {process.env.REACT_APP_FAVICON ?<></>:
                <Favourite name={'start'}
                           key={'start'} button={'button'}
                           placement={'start'}
                           newFavo={true} /> }
            </div>
        </>
        Footers =
            <>
                <div className={'theme-footer'}>
                    <p style={{ float: 'right' }}>
                        {process.env
                            .REACT_APP_FOOTER_COPYRIGHT !==
                        undefined
                            ? process.env
                                .REACT_APP_FOOTER_COPYRIGHT
                            : '©2023 WUM Solution UG '}
                    </p>
                </div>
            </>
    } else {
        if (ResponseGetModuls(dispatch) !== undefined && process.env.REACT_APP_RIBBON_DYNAMIK !== undefined ) {
            const Favos = ResponseGetRibbon('favos','ribbon',jsonFavo);
            Ribbons = <Ribbon setValue={props.setValue} value={props.value} setFavi={setFavi} setJasonFavo={setJasonFavo} Favos={Favos} elemets={(<>


            </>)}/>
        } else if(process.env.REACT_APP_RIBBON_DYNAMIK === undefined) {
            const Favos = ResponseGetRibbon('favos','ribbon',jsonFavo);
            Ribbons = <Ribbon setValue={props.setValue} value={props.value} setFavi={setFavi} setJasonFavo={setJasonFavo} Favos={Favos} elemets={(<>


            </>)}/>
        }
            sidebar = <></>
            Header = <>
                <Row style={{
                    paddingLeft: '10px',
                    background: 'rgba(19, 94, 126, 0.8)',
                    padding: '5px',
                    minHeight: '40px'
                }}>
                    <Col className={'col-1'}> <img
                        src={'/UniKlein.png.png'}
                        height={'30'}
                        alt={'Logo'}
                        onClick={() => ShowDialogRibbon()}
                        style={{height: '30px'}}
                    />
                    </Col>
                    <Col className={'col-8'} id={'favoLink'}>
                        {buildFavoIcon(favi)}
                    </Col>
                    <Col className={'col-3'}>
                        {notifi}
                        <a href={'/Logout'} style={{float: 'right'}}>
                            <span className="material-icons" aria-hidden="true" style={{
                                fontSize: '20px',
                                verticalAlign: 'bottom',
                                marginRight: '5px',
                                color: 'white'
                            }} title='Logout'>logout</span>
                        </a>
                        <ReactFlagsSelect
                            selected={selected as string}
                            onSelect={(code) => setter(code)}
                            countries={['US', 'DE']}
                            customLabels={{
                                US: 'EN-US',
                                DE: 'DE'
                            }}
                            id={'flagsRibbon'}
                            placeholder="Select Language"
                        />
                        <div className={'theme-avatar'}>

                            <p
                                className={'avatarName'}
                                style={{paddingTop: '0px', color: 'white'}}
                            >
                                {username}
                            </p>
                        </div>
                    </Col>
                </Row>
            </>
            themeHead = <>
                <div className={'theme-header'} style={{
                    height: 'unset',
                    paddingBottom: '0px',
                    paddingRight: 0,
                    paddingLeft: '5px',
                    paddingTop: '5px',
                    boxShadow: 'unset'
                }}>
                    {Header}
                    {Ribbons}
                </div>
                <div
                    className={"ContentWrapper"}
                    style={{
                        paddingLeft: '5px',
                        paddingRight: '15px',
                        marginTop: '50px',
                        background: 'white',
                        marginBottom: '70px'
                    }}
                >
                    <CheckRoute>
                        <Routes>
                            {route.map((route, index) =>
                                SecureCheck(route.roles) ? (

                                    <Route
                                        key={index}
                                        path={route.path}
                                        element={route.elements}
                                    />

                                ) : (
                                    <></>
                                )
                            )}
                            <Route path="*" element={<Site404/>}/>
                        </Routes>
                    </CheckRoute>
                </div>
            </>
            Footers =
                <>

                </>
        }
    if (
        process.env.REACT_APP_SIDEBAR !== undefined &&
        process.env.REACT_APP_AUTH !== undefined
    ) {
        return (
            <div className={'flex'}>
                <BrowserRouter>
                    {/* @ts-ignore */}
                    <ErrorBoundary>
                        <Suspense fallback={<div>Loading...</div>}>
                            <div id={'wrapperContent'}>
                                <CheckRoute>
                                    <Routes>
                                    {route.map((route, index) =>
                                        SecureCheck(route.roles) ? (

                                                <Route
                                                key={index}
                                                path={route.path}
                                                element={route.elements}
                                                />

                                        ) : (
                                            <></>
                                        )
                                    )}
                                        <Route path="*" element={<Site404 />} />
                                    </Routes>
                                </CheckRoute>
                            </div>
                        </Suspense>
                    </ErrorBoundary>
                </BrowserRouter>
            </div>
        )
    } else {
        return (
            <div className={'flex'}>
                <BrowserRouter>
                    <div id={'sidebarCol'}>{sidebar}</div>
                    {/* @ts-ignore */}
                    <ErrorBoundary>
                        <Suspense fallback={<div>Loading...</div>}>
                            <div id={'wrapperContent'}>
                                {themeHead}
                                {process.env.REACT_APP_HELPDESK !== undefined &&
                                process.env.REACT_APP_HELPDESK === 'true' ? (
                                    <HelpDesk />
                                ) : (
                                    <></>
                                )}
                                {Footers}
                            </div>
                        </Suspense>
                    </ErrorBoundary>
                </BrowserRouter>
            </div>
        )
    }
}

export default Routing

export const ShowDialogRibbon = () => {
    let eles = document.getElementById('dialogRibbon') as HTMLDivElement
    eles.style.display = 'block'
}

export const buildFavoIcon = (favi:any) => {
    let Favo = [];

    if (
        localStorage.getItem('Favo') !== null && JSON.parse(
            localStorage.getItem('Favo') as string
        ).length > 0
    ) {
        let Favos = JSON.parse(
            localStorage.getItem('Favo') as string
        )

        for(let x=0;x<Favos.length;x++) {
            Favo.push(<>
                <Link
                    key={x}
                    to={Favos[x].route}
                    className={'text-white text-decoration-none'}
                    onClick={() =>
                        closeModalRibbon('dialogRibbon', 'imgHolder')
                    }
                >
            <span className="material-icons" aria-hidden="true"
                  style={{
                      fontSize: '30px',
                      verticalAlign: 'bottom',
                      marginRight: '5px',
                      padding:'5px',
                      color: 'rgba(19, 94, 126, 0.8)',
                      background: 'white',
                      borderRadius: '15px'
                  }}
                  title={Favos[x].name}>{Favos[x].icon}</span>
                </Link>
                </>
            )
        }
    }


    return(
        <>
            {Favo}
        </>
    )
}
