import {
    CreateTaskCardDashbaord
} from "../../../../ticketsystem/modules/new/components/CreateProjectCard";
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useNavigate} from "react-router-dom";
import Table from "react-bootstrap/Table";
import {dateFormat, IntlMessage, IntlMessageValue} from "../../../../../@WUM/core/Elements/index";
import {Chart} from "react-google-charts";
import Card from 'react-bootstrap/Card';
import CreateBlock from "../../../../ticketsystem/modules/new/components/CreateBlock";
import Button from 'react-bootstrap/Button';



export const Taskmanager1er = (props:{data:any,Store:any}) =>  (
        <>
            <Card style={{background:'none'}}>
                <Card.Body className={'pt-2 pb-0'}>
                    {BuildTask(props.data, props.Store, 0,'col-12')}
            </Card.Body>
        </Card>
        </>
    )

export const Taskmanager2er = (props:{data:any,Store:any}) => (
    <>
    <Card style={{background:'none'}}>
        <Card.Body className={'pt-2 pb-0'}>
            {BuildTask(props.data,props.Store,1,'col-6')}
        </Card.Body>
    </Card>
    </>
)

export const Taskmanager4er = (props:{data:any,Store:any}) => (
    <>
    <Card style={{background:'none'}}>
        <Card.Body className={'pt-2 pb-0'}>
            {BuildTask(props.data,props.Store,3,'col-6')}
        </Card.Body>
    </Card>
    </>
)

export const Taskmanager2er2er = (props:{data:any,Store:any}) => (
    <>
    <Card style={{background:'none'}}>
        <Card.Body className={'pt-2 pb-0'}>
            {BuildTask(props.data,props.Store,1,'col-12')}
        </Card.Body>
    </Card>
    </>
)

export const MyTaskWidget1er = (props:{data:any,Store:any}) => {

    const navigate = useNavigate();

    let DataHolder = [];
    if (props.data !== true && typeof props.data !== "boolean" && props.data !== undefined && props.data.length > 0) {
        let DataoverDue = props.data ;
            for (let t = 0; t < 4; t++) {
                let data = DataoverDue[t]
                DataHolder.push(
                    <tr style={{cursor: 'pointer'}}
                        onDoubleClick={() => navigate('/myprojects/task/single/' + data.project_id + '/' + data.task_id)}>
                        <td style={{whiteSpace: 'nowrap'}}>{data.task_id}</td>
                        <td style={{whiteSpace: 'nowrap'}}>{data.regard.substring(0, 15)+'...'}</td>
                        <td style={{color: 'red',whiteSpace: 'nowrap'}}>{
                            dateFormat(
                                data.due_on,
                                localStorage.getItem('locale'),
                                'us',
                                false,
                                false,
                                props.Store
                            )
                        }</td>
                    </tr>
                )
            }
    }
    return(
        <>
        <Card style={{background:'none'}}>
            <Card.Body className={'pt-0 pb-0 pl-0 pr-0'}>
                <Table striped bordered hover className={'mb-0 Header'}>
                        <thead>
                        <tr>
                            <th style={{width: '20%'}}>
                                ID
                            </th>
                            <th style={{width: '65%'}}>
                                <IntlMessage  messageId="common.ticket.subject" Store={props.Store}/>
                            </th>
                            <th>
                                <IntlMessage  messageId="common.ticket.dueOn" Store={props.Store}/>
                            </th>
                        </tr>
                        </thead>
                        <tbody id={'TimeHolder'} className={'TimeHolder'}>

                        {DataHolder}

                        </tbody>
                    </Table>
            </Card.Body>
        </Card>
        </>
    )
}

export const MyTaskWidget2er = (props: { data: any, Store: any }) => {

    const navigate = useNavigate();

    let DataHolder = [];
    if (props.data !== true && typeof props.data !== "boolean" && props.data !== undefined && props.data.length > 0) {
        let DataoverDue = props.data;
            for (let t = 0; t < 4; t++) {
                let data = DataoverDue[t]
                DataHolder.push(
                    <tr style={{cursor: 'pointer'}}
                        onDoubleClick={() => navigate('/myprojects/task/single/' + data.project_id + '/' + data.task_id)}>
                        <td style={{whiteSpace: 'nowrap'}}>{data.task_id}</td>
                        <td style={{whiteSpace: 'nowrap'}}>{data.regard.substring(0, 24)}</td>
                        <td style={{color: 'red', whiteSpace: 'nowrap'}}>{
                            dateFormat(
                                data.due_on,
                                localStorage.getItem('locale'),
                                'us',
                                false,
                                false,
                                props.Store
                            )
                        }</td>
                        <td style={{whiteSpace: 'nowrap'}}>{data.project.title}</td>
                        <td style={{whiteSpace: 'nowrap'}}>{data.status.state}</td>
                    </tr>
                )
            }
    }
    return (
        <>
        <Card style={{background:'none'}}>
            <Card.Body className={'pt-0 pb-0 pl-0 pr-0'}>
                <Table striped bordered hover className={'mb-0 Header'}>
                <thead>
                <tr>
                    <th style={{width: '10%'}}>
                        ID
                    </th>
                    <th style={{width: '30%'}}>
                        <IntlMessage  messageId="common.ticket.subject" Store={props.Store}/>
                    </th>
                    <th style={{width: '20%'}}>
                        <IntlMessage  messageId="common.ticket.dueOn" Store={props.Store}/>
                    </th>
                    <th style={{width: '30%'}}>
                        <IntlMessage  messageId="common.project" Store={props.Store}/>
                    </th>
                    <th style={{width: '10%'}}>
                        <IntlMessage  messageId="ticket.button.status" Store={props.Store}/>
                    </th>
                </tr>
                </thead>
                <tbody id={'TimeHolder'} className={'TimeHolder'}>

                {DataHolder}

                </tbody>
            </Table>
            </Card.Body>
        </Card>
        </>
    )
}

export const MyTaskWidget4er = (props: { data: any, Store: any }) => {
    const navigate = useNavigate();

    let DataHolder = [];
    if (props.data !== true && typeof props.data !== "boolean" && props.data !== undefined && props.data.length > 0) {
        let DataoverDue =  props.data ;
            for (let t = 0; t < 10; t++) {
                let data = DataoverDue[t]
                DataHolder.push(
                    <tr style={{cursor: 'pointer'}}
                        onDoubleClick={() => navigate('/myprojects/task/single/' + data.project_id + '/' + data.task_id)}>
                        <td style={{whiteSpace: 'nowrap'}}>{(data !== undefined && data.task_id !== undefined ? data.task_id : '')}</td>
                        <td style={{whiteSpace: 'nowrap'}}>{(data !== undefined && data.regard !== undefined ? data.regard.substring(0, 24) : '')}</td>
                        <td style={{
                            color: 'red',
                            whiteSpace: 'nowrap'
                        }}>{(data !== undefined && data.due_on !== undefined ?
                            dateFormat(
                                data.due_on,
                                localStorage.getItem('locale'),
                                'us',
                                false,
                                false,
                                props.Store
                            )
                            : '')
                        }
                        </td>
                        <td style={{whiteSpace: 'nowrap'}}>{(data !== undefined && data.project !== undefined ? data.project.title : '')}</td>
                        <td style={{whiteSpace: 'nowrap'}}>{(data !== undefined && data.status !== undefined ? data.status.state : '')}</td>
                    </tr>
                )
            }
    }
    return (
        <>
        <Card style={{background:'none'}}>
            <Card.Body className={'pt-0 pb-0 pl-0 pr-0'}>
                <Table striped bordered hover className={'mb-0'}>
                    <thead>
                    <tr>
                        <th style={{width: '10%'}}>
                            ID
                        </th>
                        <th style={{width: '30%'}}>
                            <IntlMessage  messageId="common.ticket.subject" Store={props.Store}/>
                        </th>
                        <th style={{width: '20%'}}>
                            <IntlMessage  messageId="common.ticket.dueOn" Store={props.Store}/>
                        </th>
                        <th style={{width: '30%'}}>
                            <IntlMessage  messageId="common.project" Store={props.Store}/>
                        </th>
                        <th style={{width: '10%'}}>
                            <IntlMessage  messageId="ticket.button.status" Store={props.Store}/>
                        </th>
                    </tr>
                    </thead>
                    <tbody id={'TimeHolder'} className={'TimeHolder'}>

                    {DataHolder}

                    </tbody>
                </Table>
            </Card.Body>
        </Card>
        </>
    )
}

export const MyTaskWidget2er2er = (props:{data:any,Store:any}) => {

    const navigate = useNavigate();

    let DataHolder = [];
    if (props.data !== true && typeof props.data !== "boolean" && props.data !== undefined  && props.data.length > 0) {
        let DataoverDue = props.data ;
            for (let t = 0; t < 9; t++) {
                let data = DataoverDue[t]
                DataHolder.push(
                    <tr style={{cursor: 'pointer'}}
                        onDoubleClick={() => navigate('/myprojects/task/single/' + data.project_id + '/' + data.task_id)}>
                        <td style={{whiteSpace: 'nowrap'}}>{data.task_id}</td>
                        <td style={{whiteSpace: 'nowrap'}}>{data.regard.substring(0, 15) + '...'}</td>
                        <td style={{color: 'red', whiteSpace: 'nowrap'}}>{
                            dateFormat(
                                data.due_on,
                                localStorage.getItem('locale'),
                                'us',
                                false,
                                false,
                                props.Store
                            )
                        }</td>
                    </tr>
                )
            }
    }
    return(
        <>
            <Card style={{background:'none'}}>
                <Card.Body className={'pt-0 pb-0 pl-0 pr-0'}>
                <Table striped bordered hover className={'mb-0 Header'}>
                    <thead>
                    <tr>
                        <th style={{width: '20%'}}>
                            ID
                        </th>
                        <th style={{width: '65%'}}>
                            <IntlMessage  messageId="common.ticket.subject" Store={props.Store}/>
                        </th>
                        <th>
                            <IntlMessage  messageId="common.ticket.dueOn" Store={props.Store}/>
                        </th>
                    </tr>
                    </thead>
                    <tbody id={'TimeHolder'} className={'TimeHolder'}>

                    {DataHolder}

                    </tbody>
                </Table>
            </Card.Body>
        </Card>
        </>
    )
}

export const ProjectStatus1er = (props:{data:any,Store:any}) => {


    let Piedata = [ ];
    Piedata.push(["Task", "Hours per Day"]);
    let slices= [];
    for(let cx =0;cx<props.data[0].AllColumnProject.length;cx++) {
        let tmpData = [IntlMessageValue('de', 'pie.project.'+props.data[0].AllColumnProject[cx].name, props.Store), props.data[0].ProjectColoumn[props.data[0].AllColumnProject[cx].name]]
        Piedata.push(tmpData);
        slices.push({color: props.data[0].AllColumnProject[cx].color}) ;
    }
    const Pieoptions = {

        pieHole: 0.6,
        chartArea: {width: "100%", height: "100%"},
        is3D: false,
        slices: slices,
        backgroundColor:'transparent',
        legend: {position: 'none'}
    };
    const totalPie = (
        <>
            <div className={'totalPie'} style={{marginTop:'20%'}}>
                <h4>Total<br/>{props.data[0].TotalActiveProjects}</h4>
            </div>
        </>
    )
    return (
        <>
        <Card style={{background:'none'}}>
            <Card.Body className={'pt-0 pb-0'}>
                <Row>
                    <Col>
                        {totalPie}
                        <Chart
                            chartType="PieChart"
                            width="100%"
                            height="190px"
                            data={Piedata}
                            options={Pieoptions}
                        /></Col>

                </Row>
            </Card.Body>
        </Card>
        </>
    )
}

export const ProjectStatus2er = (props:{data:any,Store:any}) => {


    let Piedata = [ ];
    Piedata.push(["Task", "Hours per Day"]);
    let legendenholder = [];
    let slices= [];
    for(let cx =0;cx<props.data[0].AllColumnProject.length;cx++) {
        let tmpData = [IntlMessageValue('de', 'pie.project.'+props.data[0].AllColumnProject[cx].name, props.Store), props.data[0].ProjectColoumn[props.data[0].AllColumnProject[cx].name]]
        Piedata.push(tmpData);
        legendenholder.push(
            <>
                <Col className={'col-6'} style={{borderBottom: '1px solid '+props.data[0].AllColumnProject[cx].color, marginBottom: '10px'}}>
                    <div style={{
                        borderLeft: '5px solid '+props.data[0].AllColumnProject[cx].color,
                        borderRadius: '0px',
                        paddingLeft: '10px',
                        borderBottomLeftRadius: '5px',
                        borderTopLeftRadius: '5px'
                    }}>
                        <p className={'m-0'}>{IntlMessageValue('de', 'pie.project.'+props.data[0].AllColumnProject[cx].name, props.Store)}</p>
                        <p className={'float-start m-0'}><strong>{props.data[0].ProjectColoumn[props.data[0].AllColumnProject[cx].name]}</strong></p><p className={'float-end m-0'}>
                        <strong>{((100 / (props.data[0].TotalActiveProjects as number) ) * (props.data[0].ProjectColoumn[props.data[0].AllColumnProject[cx].name] as number)).toFixed(1)}%</strong></p>
                        <br style={{clear: 'both', height: '1px'}}/>
                    </div>
                    <br/>
                </Col>
            </>
        )
        slices.push({color: props.data[0].AllColumnProject[cx].color}) ;
    }
    const Pieoptions = {

        pieHole: 0.6,
        chartArea: {width: "100%", height: "95%"},
        is3D: false,
        backgroundColor:'transparent',
        legend: {position: 'none'}
    };
    const totalPie = (
        <>
            <div className={'totalPie'} style={{marginTop:'22%',marginLeft: '37%'}}>
                <h4>Total<br/>{props.data[0].TotalActiveProjects}</h4>
            </div>
        </>
    )

    const Legende = (
        <>
            <Row className={'legende mt-3'} style={{height: '150px',overflow: 'hidden'}}>
                {legendenholder}

            </Row>

        </>
    )

    return (
        <>
        <Card style={{background:'none'}}>
            <Card.Body className={'pt-0 pb-0'}>
                <Row>
                    <Col className={'col-5'}>
                        {totalPie}
                        <Chart
                            chartType="PieChart"
                            width="100%"
                            height="190px"
                            data={Piedata}
                            options={Pieoptions}
                        /></Col>
                    <Col className={'pt-2'}>{Legende}</Col>
                </Row>
            </Card.Body>
        </Card>
        </>
    )
}

export const ProjectStatus2er2 = (props:{data:any,Store:any}) =>{


    return(<>
            <Card style={{background:'none'}}>
                <Card.Body className={'pt-1 pb-1 pl-0 pr-0'}>
                    <Row>
                        <Col className={'pl-0'}>
                            <CreateBlock title={IntlMessageValue('de', 'dashboard.lastproject30pastdays', props.Store)} icon={'format_list_numbered'} number={props.data[0].last30daysProject}/>
                        </Col>
                        <Col className={'pr-0'}>
                            <CreateBlock title={IntlMessageValue('de', 'dashboard.archivedProjects', props.Store)} icon={'history'} number={props.data[0].ArchivedProject}/>
                        </Col>
                    </Row>
                    <Row className={'pt-2'}>
                        <Col className={'pl-0'}>
                            <CreateBlock title={IntlMessageValue('de', 'dashboard.deletedProjects', props.Store)} icon={'format_list_numbered'} number={props.data[0].DeletedProject}/>
                        </Col>
                        <Col className={'pr-0'}>
                            <CreateBlock title={IntlMessageValue('de', 'dashboard.numberofTasks', props.Store)} icon={'format_list_numbered'} number={props.data[0].totalTask}/>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}

export const ProjectStatus4er = (props:{data:any,Store:any}) =>{
    let Piedata = [ ];
    Piedata.push(["Task", "Hours per Day"]);
    let legendenholder = [];
    let slices= [];
    for(let cx =0;cx<props.data[0].AllColumnProject.length;cx++) {
        let tmpData = [IntlMessageValue('de', 'pie.project.'+props.data[0].AllColumnProject[cx].name,props.Store), props.data[0].ProjectColoumn[props.data[0].AllColumnProject[cx].name]]
        Piedata.push(tmpData);
        legendenholder.push(
            <>
                <Col className={'col-6'} style={{borderBottom: '1px solid '+props.data[0].AllColumnProject[cx].color, marginBottom: '10px'}}>
                    <div style={{
                        borderLeft: '5px solid '+props.data[0].AllColumnProject[cx].color,
                        borderRadius: '0px',
                        paddingLeft: '10px',
                        borderBottomLeftRadius: '5px',
                        borderTopLeftRadius: '5px'
                    }}>
                        <p className={'m-0'}>{IntlMessageValue('de', 'pie.project.'+props.data[0].AllColumnProject[cx].name,props.Store)}</p>
                        <p className={'float-start m-0'}><strong>{props.data[0].ProjectColoumn[props.data[0].AllColumnProject[cx].name]}</strong></p><p className={'float-end m-0'}>
                        <strong>{((100 / (props.data[0].TotalActiveProjects as number) ) * (props.data[0].ProjectColoumn[props.data[0].AllColumnProject[cx].name] as number)).toFixed(1)}%</strong></p>
                        <br style={{clear: 'both', height: '1px'}}/>
                    </div>
                    <br/>
                </Col>
            </>
        )
        slices.push({color: props.data[0].AllColumnProject[cx].color}) ;
    }
    const Pieoptions = {

        pieHole: 0.6,
        chartArea: {width: "100%", height: "95%"},
        is3D: false,
        backgroundColor:'transparent',
        legend: {position: 'none'}
    };
    const totalPie = (
        <>
            <div className={'totalPie'} style={{marginTop:'22%',marginLeft: '37%'}}>
                <h4>Total<br/>{props.data[0].TotalActiveProjects}</h4>
            </div>
        </>
    )

    const Legende = (
        <>
            <Row className={'legende mt-3 mr-3'} style={{height: '150px',overflow: 'hidden'}}>
                {legendenholder}

            </Row>

        </>
    )

    return(<>
            <Card style={{background:'none'}}>
                <Card.Body className={'pt-1 pb-1 pl-0 pr-0'}>
                    <Row>
                        <Col className={'pl-0'}>
                            <CreateBlock title={IntlMessageValue('de', 'dashboard.lastproject30pastdays', props.Store)} icon={'format_list_numbered'} number={props.data[0].last30daysProject}/>
                        </Col>
                        <Col className={'pr-0'}>
                            <CreateBlock title={IntlMessageValue('de', 'dashboard.archivedProjects', props.Store)} icon={'history'} number={props.data[0].ArchivedProject}/>
                        </Col>
                    </Row>
                    <Row className={'pt-2'}>
                        <Col className={'pl-0'}>
                            <CreateBlock title={IntlMessageValue('de', 'dashboard.deletedProjects', props.Store)} icon={'format_list_numbered'} number={props.data[0].DeletedProject}/>
                        </Col>
                        <Col className={'pr-0'}>
                            <CreateBlock title={IntlMessageValue('de', 'dashboard.numberofTasks', props.Store)} icon={'format_list_numbered'} number={props.data[0].totalTask}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={'col-5'}>
                            {totalPie}
                            <Chart
                                chartType="PieChart"
                                width="100%"
                                height="190px"
                                data={Piedata}
                                options={Pieoptions}
                            /></Col>
                        <Col className={'pt-2'}>{Legende}</Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}

export const TaskStatus1er = (props:{data:any,Store:any}) => {



    let PiedataTask = [ ];
    PiedataTask.push(["Task", "Hours per Day"]);
    let slicesTask = [];
    for(let cx =0;cx<props.data[0].AllColumnTask.length;cx++) {
        let tmpData = [IntlMessageValue('de', 'pie.task.' + props.data[0].AllColumnTask[cx].name,props.Store), props.data[0].TaskColumn[props.data[0].AllColumnTask[cx].name]]
        PiedataTask.push(tmpData);
        slicesTask.push({color: props.data[0].AllColumnTask[cx].color}) ;
    }
    const Pieoptions = {

        pieHole: 0.6,
        chartArea: {width: "100%", height: "100%"},
        is3D: false,
        backgroundColor:'transparent',
        slices: slicesTask,
        legend: {position: 'none'}
    };
    const totalPie = (
        <>
            <div className={'totalPie'} style={{marginTop:'20%'}}>
                <h4>Total<br/>{props.data[0].totalTask}</h4>
            </div>
        </>
    )
    return (
        <>
        <Card style={{background:'none'}}>
            <Card.Body className={'pt-0 pb-0'}>
                <Row>
                    <Col>
                        {totalPie}
                        <Chart
                            chartType="PieChart"
                            width="100%"
                            height="190px"
                            data={PiedataTask}
                            options={Pieoptions}
                        /></Col>

                </Row>
            </Card.Body>
        </Card>
        </>
    )
}

export const TaskStatus2er = (props:{data:any,Store:any}) => {


    let PiedataTask = [ ];
    PiedataTask.push(["Task", "Hours per Day"]);
    let slicesTask = [];
    let legendenholderTask = [];
    for(let cx =0;cx<props.data[0].AllColumnTask.length;cx++) {
        let tmpData = [IntlMessageValue('de', 'pie.task.' + props.data[0].AllColumnTask[cx].name,props.Store), props.data[0].TaskColumn[props.data[0].AllColumnTask[cx].name]]
        PiedataTask.push(tmpData);
        legendenholderTask.push(
            <>
                <Col className={'col-6'} style={{borderBottom: '1px solid '+props.data[0].AllColumnTask[cx].color, marginBottom: '10px'}}>
                    <div style={{
                        borderLeft: '5px solid '+props.data[0].AllColumnTask[cx].color,
                        borderRadius: '0px',
                        paddingLeft: '10px',
                        borderBottomLeftRadius: '5px',
                        borderTopLeftRadius: '5px'
                    }}>
                        <p className={'m-0'}>{IntlMessageValue('de', 'pie.task.' + props.data[0].AllColumnTask[cx].name,props.Store)}</p>
                        <p className={'float-start m-0'}><strong>{props.data[0].TaskColumn[props.data[0].AllColumnTask[cx].name]}</strong></p><p className={'float-end m-0'}>
                        <strong>{((100 / (props.data[0].totalTask as number) ) * (props.data[0].TaskColumn[props.data[0].AllColumnTask[cx].name] as number)).toFixed(1)}%</strong></p>
                        <br style={{clear: 'both', height: '1px'}}/>
                    </div>
                    <br/>
                </Col>
            </>
        )
        slicesTask.push({color: props.data[0].AllColumnTask[cx].color}) ;
    }
    const Pieoptions = {

        pieHole: 0.6,
        chartArea: {width: "100%", height: "80%"},
        is3D: false,
        backgroundColor:'transparent',
        slices: slicesTask,
        legend: {position: 'none'}
    };
    const totalPie = (
        <>
            <div className={'totalPie'} style={{marginTop:'22%',marginLeft: '37%'}}>
                <h4>Total<br/>{props.data[0].totalTask}</h4>
            </div>
        </>
    )

    const LegendeTask = (
        <>
            <Row className={'legende mt-3'} style={{height: '150px',overflow: 'hidden'}}>

                {legendenholderTask}
            </Row>

        </>
    )

    return (
        <>
        <Card style={{background:'none'}}>
            <Card.Body className={'pt-0 pb-0'}>
                <Row>
                    <Col className={'col-5'}>
                        {totalPie}
                        <Chart
                            chartType="PieChart"
                            width="100%"
                            height="100px"
                            data={PiedataTask}
                            options={Pieoptions}
                        /></Col>
                    <Col className={'pt-2'}> {LegendeTask}</Col>
                </Row>
            </Card.Body>
        </Card>
        </>
    )
}

export const TaskStatus2er2 = (props:{data:any,Store:any}) =>{


    return(<>
            <Card style={{background:'none'}}>
                <Card.Body className={'pt-1 pb-1 pl-0 pr-0'}>
                    <Row>
                        <Col className={'pl-0'}>
                            <CreateBlock title={IntlMessageValue('de', 'dashboard.newtaskpast30days', props.Store)} border={"1px solid #23AAE1"} icon={'format_list_numbered'} number={props.data[0].last30daysTasks}/>
                        </Col>
                        <Col className={'pr-0'}>
                            <CreateBlock title={IntlMessageValue('de', 'pie.task.Open Task', props.Store)} icon={'history'} number={props.data[0].TaskColumn["Open Project"]}/>
                        </Col>
                    </Row>
                    <Row className={'pt-2'}>
                        <Col className={'pl-0'}>
                            <CreateBlock title={IntlMessageValue('de', 'dashboard.archivedTasks', props.Store)} icon={'format_list_numbered'} number={props.data[0].TaskColumn.Completed}/>
                        </Col>
                        <Col className={'pr-0'}>
                            <CreateBlock title={IntlMessageValue('de', 'dashboard.deletedTasks', props.Store)} icon={'format_list_numbered'} number={props.data[0].DeletedTask}/>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}

export const TaskStatus4er = (props:{data:any,Store:any}) =>{
    let PiedataTask = [ ];
    PiedataTask.push(["Task", "Hours per Day"]);
    let slicesTask = [];
    let legendenholderTask = [];
    for(let cx =0;cx<props.data[0].AllColumnTask.length;cx++) {
        let tmpData = [IntlMessageValue('de', 'pie.task.' + props.data[0].AllColumnTask[cx].name,props.Store), props.data[0].TaskColumn[props.data[0].AllColumnTask[cx].name]]
        PiedataTask.push(tmpData);
        legendenholderTask.push(
            <>
                <Col className={'col-6'} style={{borderBottom: '1px solid '+props.data[0].AllColumnTask[cx].color, marginBottom: '10px'}}>
                    <div style={{
                        borderLeft: '5px solid '+props.data[0].AllColumnTask[cx].color,
                        borderRadius: '0px',
                        paddingLeft: '10px',
                        borderBottomLeftRadius: '5px',
                        borderTopLeftRadius: '5px'
                    }}>
                        <p className={'m-0'}>{IntlMessageValue('de', 'pie.task.' + props.data[0].AllColumnTask[cx].name,props.Store)}</p>
                        <p className={'float-start m-0'}><strong>{props.data[0].TaskColumn[props.data[0].AllColumnTask[cx].name]}</strong></p><p className={'float-end m-0'}>
                        <strong>{((100 / (props.data[0].totalTask as number) ) * (props.data[0].TaskColumn[props.data[0].AllColumnTask[cx].name] as number)).toFixed(1)}%</strong></p>
                        <br style={{clear: 'both', height: '1px'}}/>
                    </div>
                    <br/>
                </Col>
            </>
        )
        slicesTask.push({color: props.data[0].AllColumnTask[cx].color}) ;
    }
    const Pieoptions = {

        pieHole: 0.6,
        chartArea: {width: "100%", height: "95%"},
        is3D: false,
        backgroundColor:'transparent',
        slices: slicesTask,
        legend: {position: 'none'}
    };
    const totalPie = (
        <>
            <div className={'totalPie'} style={{marginTop:'22%',marginLeft: '37%'}}>
                <h4>Total<br/>{props.data[0].totalTask}</h4>
            </div>
        </>
    )

    const LegendeTask = (
        <>
            <Row className={'legende mt-3 mr-3'} style={{height: '150px',overflow: 'hidden'}}>

                {legendenholderTask}
            </Row>

        </>
    )


    return(<>
            <Card style={{background:'none'}}>
                <Card.Body className={'pt-1 pb-1 pl-0 pr-0'}>
                    <Row>
                        <Col className={'pl-0'}>
                            <CreateBlock title={IntlMessageValue('de', 'dashboard.newtaskpast30days', props.Store)} border={"1px solid #23AAE1"} icon={'format_list_numbered'} number={props.data[0].last30daysTasks}/>
                        </Col>
                        <Col className={'pr-0'}>
                            <CreateBlock title={IntlMessageValue('de', 'pie.task.Open Task', props.Store)} icon={'history'} number={props.data[0].TaskColumn["Open Project"]}/>
                        </Col>
                    </Row>
                    <Row className={'pt-2'}>
                        <Col className={'pl-0'}>
                            <CreateBlock title={IntlMessageValue('de', 'dashboard.archivedTasks', props.Store)} icon={'format_list_numbered'} number={props.data[0].TaskColumn.Completed}/>
                        </Col>
                        <Col className={'pr-0'}>
                            <CreateBlock title={IntlMessageValue('de', 'dashboard.deletedTasks', props.Store)} icon={'format_list_numbered'} number={props.data[0].DeletedTask}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={'col-5'}>
                            {totalPie}
                            <Chart
                                chartType="PieChart"
                                width="100%"
                                height="190px"
                                data={PiedataTask}
                                options={Pieoptions}
                            /></Col>
                        <Col className={'pt-2'}> {LegendeTask}</Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}

const BuildTask = (Tasks: any, Store: any, cell: any, col: any) => {
    let buildDashboardCardTask = [];
    if (Tasks !== true && typeof Tasks !== "boolean" && Tasks?.length > 0 && Tasks !== undefined) {

        for (let x = 0; x <= cell; x++) {
            buildDashboardCardTask.push(<>
                <Col className={col}> <CreateTaskCardDashbaord Store={Store} ProjectId={Tasks[x].project_id} priorität={Tasks[x].priority}
                                position={(Tasks[x].position !== null ? Tasks[x].position : x)}
                                comment={Tasks[x].comment} ClassN={'col-6'} ColPadding={'0px 12px '}
                                id={Tasks[x].task_id} avatarID={1}
                                title={Tasks[x].regard} now={100} variant={'success'}
                                status={'Open'} daysColor={'248, 98, 27'} daysLeft={2} task={1}
                                allTask={1} archiv={Tasks[x].archiv}
                                label={Tasks[x].support_label} delete={Tasks[x].delete}
                                teamMember={true} date={Tasks[x].created_at} text={Tasks[x].instanz}
                                background={(Tasks[x].instanz !== undefined && Tasks[x].instanz !== null && Tasks[x].instanz !== '' ? '#e5f1fd' : (Tasks[x].color !== undefined && Tasks[x].color !== null ? Tasks[x].color : 'white'))}/>
               </Col></>)

        }
    }
    return (<><Row>{buildDashboardCardTask}</Row></>)
}
