import React from 'react'
import { galeriApiType } from '../interface/galeryApi.interface'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import ReactDOM from 'react-dom'
import Button from 'react-bootstrap/Button'
import { checkLogin } from '../../../templates/default/Auth/service/AuthService'
import { apiRoot } from '../../RestFullApi/ApiHook'
import { notify_save } from './../../Function/index'
import Badge from 'react-bootstrap/Badge'
import {IntlMessage} from './../../Elements/index'
const Gallery = (
    append: any,
    Imgsrc: any,
    namespace: any,
    Store: any,
    mode: any,
    jsonID: any = null,
    appendJson: any = null,
    appendJsonField: any = null,
    withSave: any = undefined,
    video: any = 'false'
) => {
    let eleNamespace = document.getElementById('namespace') as HTMLInputElement
    eleNamespace.value = namespace

    let eleImgsrc = document.getElementById('Imgsrc') as HTMLInputElement
    eleImgsrc.value = Imgsrc

    let eleImgappend = document.getElementById('append') as HTMLInputElement
    eleImgappend.value = append

    let eleGaleriemode = document.getElementById(
        'Galeriemode'
    ) as HTMLInputElement
    eleGaleriemode.value = mode

    const tag = (tag: any, id: any) => {
        let ele = document.getElementById(id) as HTMLDivElement
        if (ele.classList.contains('active')) {
            ;[].forEach.call(
                document.querySelectorAll('.ga-item'),
                function (el: HTMLDivElement) {
                    let element = el as HTMLDivElement
                    element.style.display = 'block'
                }
            )
            ele.classList.remove('active')
        } else {
            ;[].forEach.call(
                document.querySelectorAll('.ga-item'),
                function (el: HTMLDivElement) {
                    let element = el as HTMLDivElement

                    if (element.classList.contains('active')) {
                    } else {
                        element.style.display = 'none'
                    }
                }
            )
            ;[].forEach.call(
                document.querySelectorAll('.' + tag),
                function (el: HTMLDivElement) {
                    let element = el as HTMLDivElement
                    element.style.display = 'block'
                }
            )

            ele.classList.add('active')
        }
    }

    const save = (galerie: any) => {
        var myHeaders = new Headers()
        myHeaders.append(
            'Authorization',
            'Bearer ' + localStorage.getItem('token')
        )
        myHeaders.append('Content-Type', 'application/json')

        var raw = JSON.stringify({
            id: galerie.id,
            name: galerie.name,
            description:
                (document.getElementById('description') as HTMLInputElement)
                    .value !== ''
                    ? (
                          document.getElementById(
                              'description'
                          ) as HTMLInputElement
                      ).value
                    : ' ',
            enabled: galerie.enabled,
            width: galerie.width,
            height: galerie.height,
            length: galerie.length,
            content_type: galerie.content_type,
            content_size: galerie.content_size,
            copyright:
                (document.getElementById('copyright') as HTMLInputElement)
                    .value !== ''
                    ? (document.getElementById('copyright') as HTMLInputElement)
                          .value
                    : ' ',
            author_name:
                (document.getElementById('author_name') as HTMLInputElement)
                    .value !== ''
                    ? (
                          document.getElementById(
                              'author_name'
                          ) as HTMLInputElement
                      ).value
                    : ' ',
            context:
                (document.getElementById('context') as HTMLInputElement)
                    .value !== ''
                    ? (document.getElementById('context') as HTMLInputElement)
                          .value
                    : ' ',
            update_at: galerie.update_at,
            token: galerie.token,
            cdn_url: galerie.cdn_url
        })

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        }

        fetch(apiRoot + 'v2/gallery/' + galerie.id + '/', requestOptions)
            .then((response) => response.text())
            .then((result) => {
                notify_save(Store)
            })
            .catch((error) => console.log('error', error))
    }

    const addedIMG = (galerie: any) => {
        var myHeaders = new Headers()
        myHeaders.append(
            'Authorization',
            'Bearer ' + localStorage.getItem('token')
        )
        myHeaders.append('Content-Type', 'application/json')

        var raw = JSON.stringify({
            id: galerie.id,
            name: galerie.name,
            description:
                (document.getElementById('description') as HTMLInputElement)
                    .value !== ''
                    ? (
                          document.getElementById(
                              'description'
                          ) as HTMLInputElement
                      ).value
                    : ' ',
            enabled: galerie.enabled,
            width: galerie.width,
            height: galerie.height,
            length: galerie.length,
            content_type: galerie.content_type,
            content_size: galerie.content_size,
            copyright:
                (document.getElementById('copyright') as HTMLInputElement)
                    .value !== ''
                    ? (document.getElementById('copyright') as HTMLInputElement)
                          .value
                    : ' ',
            author_name:
                (document.getElementById('author_name') as HTMLInputElement)
                    .value !== ''
                    ? (
                          document.getElementById(
                              'author_name'
                          ) as HTMLInputElement
                      ).value
                    : ' ',
            context:
                (document.getElementById('context') as HTMLInputElement)
                    .value !== ''
                    ? (document.getElementById('context') as HTMLInputElement)
                          .value
                    : ' ',
            update_at: galerie.update_at,
            token: galerie.token,
            cdn_url: galerie.cdn_url
        })

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        }

        fetch(apiRoot + 'v2/gallery/' + galerie.id + '/', requestOptions)
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.log('error', error))

        let eleappend = document.getElementById(append) as HTMLInputElement
        eleappend.value = mode !== true ? galerie.id : galerie.cdn_url

        /*
        Ansatz Json befüllen
         */

        if (jsonID !== undefined && jsonID !== null) {
            let JsonP = JSON.parse(
                (document.getElementById(jsonID) as HTMLInputElement).value
            )
            //console.log(JsonP);
            JsonP.item[appendJson][appendJsonField] =
                mode !== true ? galerie.id : galerie.cdn_url
            ;(document.getElementById(jsonID) as HTMLInputElement).value =
                JSON.stringify(JsonP)

            //console.log(JsonP);
        }

        let elesrc = document.getElementById(Imgsrc) as HTMLImageElement
        elesrc.src = galerie.cdn_url

        let eleLabel = document.getElementById(namespace) as HTMLLabelElement
        eleLabel.innerHTML = galerie.name

        let eletags = document.getElementById('tags') as HTMLDivElement
        eletags.innerHTML = ''

        let dialog = document.getElementById('dialog') as HTMLDivElement
        dialog.style.display = 'none'

        let imgHolder = document.getElementById('imgHolder') as HTMLDivElement
        imgHolder.innerHTML = ''

        let date = document.getElementById('ga-img-date') as HTMLSpanElement
        date.innerHTML = ''

        let name = document.getElementById('ga-img-name') as HTMLSpanElement
        name.innerHTML = ''

        let cdn_url = document.getElementById('ga-img-url') as HTMLSpanElement
        cdn_url.innerHTML = ''

        let content_type = document.getElementById(
            'ga-img-type'
        ) as HTMLSpanElement
        content_type.innerHTML = ''

        let content_size = document.getElementById(
            'ga-img-content_size'
        ) as HTMLSpanElement
        content_size.innerHTML = ''

        let width = document.getElementById('ga-img-width') as HTMLSpanElement
        width.innerHTML = ''

        let height = document.getElementById('ga-img-height') as HTMLSpanElement
        height.innerHTML = ''

        let eleIMG = document.getElementById('placeIMG') as HTMLImageElement
        eleIMG.src = ''

        let options = document.getElementById('ga-options') as HTMLDivElement
        options.style.display = 'none'
        let optionHolder = document.getElementById(
            'optionHolder'
        ) as HTMLDivElement
        optionHolder.innerHTML = ''
    }

    const setImage = (event: { detail: any }, galerie: any) => {
        let eles = document.getElementById('ga-options') as HTMLDivElement
        eles.style.display = 'block'
        showOptions(galerie)
    }

    let img: any = []
    const showOptions = (galerie: any) => {
        var myHeaders = new Headers()
        myHeaders.append(
            'Authorization',
            'Bearer ' + localStorage.getItem('token')
        )

        var requestOptions = {
            method: 'GET',
            headers: myHeaders
        }

        fetch(apiRoot + 'v2/gallery/' + galerie.id + '/', requestOptions)
            .then((response) => response.text())
            .then((result) => {
                checkLogin(JSON.parse(result))
                galerie = JSON.parse(result)

                let eleIMG = document.getElementById(
                    'placeIMG'
                ) as HTMLImageElement
                let eleplaceVideo = document.getElementById(
                    'placeVideo'
                ) as HTMLImageElement
                if (
                    galerie.content_type === 'video/webm' ||
                    galerie.content_type === 'video/mp4'
                ) {
                    eleplaceVideo.style.display = 'block'
                    eleIMG.style.display = 'none'
                    eleplaceVideo.src =
                        galerie.cdn_url !== undefined
                            ? process.env.REACT_APP_MODE === 'LOCAL'
                                ? galerie.cdn_url.replace(
                                      process.env.REACT_APP_CDN_URL,
                                      process.env.REACT_APP_CDN_URL_LOCAL
                                  )
                                : galerie.cdn_url
                            : ''
                } else {
                    eleIMG.style.display = 'block'
                    if (galerie.content_type === 'application/pdf') {
                        eleIMG.src = '/assets/images/img.png'
                    } else {
                        eleIMG.src =
                            galerie.cdn_url !== undefined
                                ? process.env.REACT_APP_MODE === 'LOCAL'
                                    ? galerie.cdn_url.replace(
                                          process.env.REACT_APP_CDN_URL,
                                          process.env.REACT_APP_CDN_URL_LOCAL
                                      )
                                    : galerie.cdn_url
                                : ''
                    }
                    eleplaceVideo.style.display = 'none'
                }

                let date = document.getElementById(
                    'ga-img-date'
                ) as HTMLSpanElement
                date.innerHTML = galerie.update_at

                let name = document.getElementById(
                    'ga-img-name'
                ) as HTMLSpanElement
                name.innerHTML = galerie.name

                let cdn_url = document.getElementById(
                    'ga-img-url'
                ) as HTMLSpanElement
                cdn_url.innerHTML = galerie.cdn_url

                let content_type = document.getElementById(
                    'ga-img-type'
                ) as HTMLSpanElement
                content_type.innerHTML = galerie.content_type

                let content_size = document.getElementById(
                    'ga-img-content_size'
                ) as HTMLSpanElement
                if (
                    galerie.content_type !== 'application/pdf' &&
                    galerie.content_type !== 'video/webm' &&
                    galerie.content_type !== 'video/mp4'
                ) {
                    content_size.innerHTML = String(
                        (galerie.content_size / 1000000).toFixed(2)
                    )
                } else {
                    content_size.innerHTML = String(
                        (galerie.content_size / 1000).toFixed(2)
                    )
                }

                let width = document.getElementById(
                    'ga-img-width'
                ) as HTMLSpanElement
                width.innerHTML = galerie.width

                let height = document.getElementById(
                    'ga-img-height'
                ) as HTMLSpanElement
                height.innerHTML = galerie.height

                let buttonadded = <></>
                if (withSave === undefined) {
                    if (galerie.content_type !== 'application/pdf') {
                        buttonadded = (
                            <>
                                <Button
                                    type={'button'}
                                    variant={'info'}
                                    className={'col-12'}
                                    onClick={() => addedIMG(galerie)}
                                >
                                    <IntlMessage
                                        messageId={'galerie.button.add'}
                                        Store={Store}
                                    />
                                </Button>
                            </>
                        )
                    }
                } else {
                    buttonadded = (
                        <>
                            <Button
                                type={'button'}
                                variant={'success'}
                                className={'col-12'}
                                onClick={() =>
                                    navigator.clipboard.writeText(
                                        galerie.cdn_url
                                    )
                                }
                            >
                                <IntlMessage
                                    messageId={'galerie.button.copy_url'}
                                    Store={Store}
                                />
                            </Button>
                            <br /> <br />
                        </>
                    )
                }

                let newElement = (
                    <>
                        <Form.Group className="mb-3" controlId={'description'}>
                            <Form.Label>
                                <IntlMessage
                                    messageId={'galerie.attachment.description'}
                                    Store={Store}
                                />
                            </Form.Label>
                            <Form.Control
                                type={'text'}
                                defaultValue={galerie.description}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId={'copyright'}>
                            <Form.Label>
                                <IntlMessage
                                    messageId={'galerie.attachment.copyright'}
                                    Store={Store}
                                />
                            </Form.Label>
                            <Form.Control
                                type={'text'}
                                defaultValue={galerie.copyright}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId={'author_name'}>
                            <Form.Label>
                                <IntlMessage
                                    messageId={'galerie.attachment.author_name'}
                                    Store={Store}
                                />
                            </Form.Label>
                            <Form.Control
                                type={'text'}
                                defaultValue={galerie.author_name}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId={'context'}>
                            <Form.Label>
                                <IntlMessage
                                    messageId={'galerie.attachment.context'}
                                    Store={Store}
                                />
                            </Form.Label>
                            <Form.Control
                                type={'text'}
                                defaultValue={galerie.context}
                            />
                        </Form.Group>
                        <Button
                            type={'button'}
                            variant={'success'}
                            className={'col-12'}
                            onClick={() => save(galerie)}
                        >
                            <IntlMessage
                                messageId={'galerie.button.save'}
                                Store={Store}
                            />
                        </Button>
                        <br /> <br />
                        {buttonadded}
                    </>
                )
                let temp = document.createElement('div')
                ReactDOM.render(newElement, temp)
                let ele = document.getElementById(
                    'optionHolder'
                ) as HTMLDivElement
                ele.innerHTML = ''
                if (ele !== null) {
                    ele.appendChild(temp)
                    ele.style.display = 'block'
                }
            })
            .catch((error) => checkLogin(error))
    }
    var myHeaders = new Headers()
    myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'))

    var requestOptions = {
        method: 'GET',
        headers: myHeaders
    }

    fetch(apiRoot + 'gallery/1/', requestOptions)
        .then((response) => response.text())
        .then((result) => {
            let galerie: galeriApiType[] = JSON.parse(result)

            let tags = []
            let ControllTags = []
            for (let x = 0; x < galerie.length; x++) {
                if (withSave !== undefined) {
                    if (
                        galerie[x].content_type === 'video/webm' ||
                        galerie[x].content_type === 'video/mp4'
                    ) {
                        // @ts-ignore
                        img.push(
                            <>
                                <Col
                                    id={'img' + galerie[x].id}
                                    className={'ga-item ' + galerie[x].context}
                                    onClick={(e) => setImage(e, galerie[x])}
                                >
                                    <div className={'ga-item-img'}>
                                        <video
                                            width={146}
                                            height={155}
                                            controls
                                            data-value={galerie[x].cdn_url}
                                            data-command="pick"
                                        >
                                            <source
                                                // @ts-ignore
                                                src={
                                                    process.env
                                                        .REACT_APP_MODE ===
                                                    'LOCAL'
                                                        ? galerie[
                                                              x
                                                          ].cdn_url.replace(
                                                              process.env
                                                                  .REACT_APP_CDN_URL as string,
                                                              process.env
                                                                  .REACT_APP_CDN_URL_LOCAL as string
                                                          )
                                                        : galerie[x].cdn_url
                                                }
                                                type="video/mp4"
                                            />
                                            Your browser does not support the
                                            video tag.
                                        </video>
                                        <div className="ga-name">
                                            {galerie[x].name}
                                        </div>
                                    </div>
                                </Col>
                            </>
                        )
                    } else {
                        img.push(
                            <>
                                <Col
                                    id={'img' + galerie[x].id}
                                    className={'ga-item ' + galerie[x].context}
                                    onClick={(e) => setImage(e, galerie[x])}
                                >
                                    <div className={'ga-item-img'}>
                                        <img
                                            alt={''}
                                            // @ts-ignore
                                            src={
                                                process.env.REACT_APP_MODE ===
                                                'LOCAL'
                                                    ? galerie[x]
                                                          .content_type !==
                                                      'application/pdf'
                                                        ? galerie[
                                                              x
                                                          ].cdn_url.replace(
                                                              process.env
                                                                  .REACT_APP_CDN_URL as string,
                                                              process.env
                                                                  .REACT_APP_CDN_URL_LOCAL as string
                                                          )
                                                        : '/assets/images/img.png'
                                                    : galerie[x]
                                                          .content_type !==
                                                      'application/pdf'
                                                    ? galerie[x].cdn_url
                                                    : '/assets/images/img.png'
                                            }
                                            data-command="pick"
                                            data-value={galerie[x].cdn_url}
                                        />
                                        <div className="ga-name">
                                            {galerie[x].name}
                                        </div>
                                    </div>
                                </Col>
                            </>
                        )
                    }
                    if (
                        galerie[x].context !== null &&
                        galerie[x].context !== undefined &&
                        galerie[x].context !== ''
                    ) {
                        if (galerie[x].context.split(' ').length > 0) {
                            for (
                                let s = 0;
                                s < galerie[x].context.split(' ').length;
                                s++
                            ) {
                                let stop = false
                                for (let c = 0; c < ControllTags.length; c++) {
                                    if (
                                        ControllTags[c] ===
                                        galerie[x].context.split(' ')[s]
                                    ) {
                                        stop = true
                                    }
                                }
                                if (stop === true) {
                                } else {
                                    tags.push(
                                        <>
                                            <Badge
                                                bg="primary"
                                                id={
                                                    'tag' +
                                                    x +
                                                    '' +
                                                    galerie[x].context.split(
                                                        ' '
                                                    )[s]
                                                }
                                                onClick={() =>
                                                    tag(
                                                        galerie[
                                                            x
                                                        ].context.split(' ')[s],
                                                        'tag' +
                                                            x +
                                                            '' +
                                                            galerie[
                                                                x
                                                            ].context.split(
                                                                ' '
                                                            )[s]
                                                    )
                                                }
                                                style={{ marginRight: '10px' }}
                                            >
                                                {
                                                    galerie[x].context.split(
                                                        ' '
                                                    )[s]
                                                }
                                            </Badge>
                                        </>
                                    )
                                    ControllTags.push(
                                        galerie[x].context.split(' ')[s]
                                    )
                                }
                            }
                        } else {
                            let stop = false
                            for (let c = 0; c < ControllTags.length; c++) {
                                if (ControllTags[c] === galerie[x].context) {
                                    stop = true
                                }
                            }
                            if (stop === true) {
                            } else {
                                ControllTags.push(galerie[x].context)
                                tags.push(
                                    <>
                                        <Badge
                                            bg="primary"
                                            id={
                                                'tag' +
                                                x +
                                                '' +
                                                galerie[x].context
                                            }
                                            onClick={() =>
                                                tag(
                                                    galerie[x].context,
                                                    'tag' +
                                                        x +
                                                        '' +
                                                        galerie[x].context
                                                )
                                            }
                                            style={{ marginRight: '10px' }}
                                        >
                                            {galerie[x].context}
                                        </Badge>
                                    </>
                                )
                            }
                        }
                    }
                } else if (
                    video === 'true' &&
                    (galerie[x].content_type === 'video/webm' ||
                        galerie[x].content_type === 'video/mp4')
                ) {
                    img.push(
                        <>
                            <Col
                                id={'img' + galerie[x].id}
                                className={'ga-item ' + galerie[x].context}
                                onClick={(e) => setImage(e, galerie[x])}
                            >
                                <div className={'ga-item-img'}>
                                    <video
                                        width={146}
                                        height={155}
                                        controls
                                        data-value={galerie[x].cdn_url}
                                        data-command="pick"
                                    >
                                        <source
                                            // @ts-ignore
                                            src={
                                                process.env.REACT_APP_MODE ===
                                                'LOCAL'
                                                    ? galerie[
                                                          x
                                                      ].cdn_url.replace(
                                                          process.env
                                                              .REACT_APP_CDN_URL as string,
                                                          process.env
                                                              .REACT_APP_CDN_URL_LOCAL as string
                                                      )
                                                    : galerie[x].cdn_url
                                            }
                                            type="video/mp4"
                                        />
                                        Your browser does not support the video
                                        tag.
                                    </video>
                                    <div className="ga-name">
                                        {galerie[x].name}
                                    </div>
                                </div>
                            </Col>
                        </>
                    )

                    if (
                        galerie[x].context !== null &&
                        galerie[x].context !== undefined &&
                        galerie[x].context !== ''
                    ) {
                        if (galerie[x].context.split(' ').length > 0) {
                            for (
                                let s = 0;
                                s < galerie[x].context.split(' ').length;
                                s++
                            ) {
                                let stop = false
                                for (let c = 0; c < ControllTags.length; c++) {
                                    if (
                                        ControllTags[c] ===
                                        galerie[x].context.split(' ')[s]
                                    ) {
                                        stop = true
                                    }
                                }
                                if (stop === true) {
                                } else {
                                    tags.push(
                                        <>
                                            <Badge
                                                bg="primary"
                                                id={
                                                    'tag' +
                                                    x +
                                                    '' +
                                                    galerie[x].context.split(
                                                        ' '
                                                    )[s]
                                                }
                                                onClick={() =>
                                                    tag(
                                                        galerie[
                                                            x
                                                        ].context.split(' ')[s],
                                                        'tag' +
                                                            x +
                                                            '' +
                                                            galerie[
                                                                x
                                                            ].context.split(
                                                                ' '
                                                            )[s]
                                                    )
                                                }
                                                style={{ marginRight: '10px' }}
                                            >
                                                {
                                                    galerie[x].context.split(
                                                        ' '
                                                    )[s]
                                                }
                                            </Badge>
                                        </>
                                    )
                                    ControllTags.push(
                                        galerie[x].context.split(' ')[s]
                                    )
                                }
                            }
                        } else {
                            let stop = false
                            for (let c = 0; c < ControllTags.length; c++) {
                                if (ControllTags[c] === galerie[x].context) {
                                    stop = true
                                }
                            }
                            if (stop === true) {
                            } else {
                                ControllTags.push(galerie[x].context)
                                tags.push(
                                    <>
                                        <Badge
                                            bg="primary"
                                            id={
                                                'tag' +
                                                x +
                                                '' +
                                                galerie[x].context
                                            }
                                            onClick={() =>
                                                tag(
                                                    galerie[x].context,
                                                    'tag' +
                                                        x +
                                                        '' +
                                                        galerie[x].context
                                                )
                                            }
                                            style={{ marginRight: '10px' }}
                                        >
                                            {galerie[x].context}
                                        </Badge>
                                    </>
                                )
                            }
                        }
                    }
                } else if (
                    video === 'false' &&
                    galerie[x].content_type !== 'application/pdf' &&
                    galerie[x].content_type !== 'video/webm' &&
                    galerie[x].content_type !== 'video/mp4'
                ) {
                    img.push(
                        <>
                            <Col
                                id={'img' + galerie[x].id}
                                className={'ga-item ' + galerie[x].context}
                                onClick={(e) => setImage(e, galerie[x])}
                            >
                                <div className={'ga-item-img'}>
                                    <img
                                        alt={''}
                                        // @ts-ignore
                                        src={
                                            process.env.REACT_APP_MODE ===
                                            'LOCAL'
                                                ? galerie[x].content_type !==
                                                  'application/pdf'
                                                    ? galerie[
                                                          x
                                                      ].cdn_url.replace(
                                                          process.env
                                                              .REACT_APP_CDN_URL as string,
                                                          process.env
                                                              .REACT_APP_CDN_URL_LOCAL as string
                                                      )
                                                    : '/assets/images/img.png'
                                                : galerie[x].content_type !==
                                                  'application/pdf'
                                                ? galerie[x].cdn_url
                                                : '/assets/images/img.png'
                                        }
                                        data-command="pick"
                                        data-value={galerie[x].cdn_url}
                                    />
                                    <div className="ga-name">
                                        {galerie[x].name}
                                    </div>
                                </div>
                            </Col>
                        </>
                    )

                    if (
                        galerie[x].context !== null &&
                        galerie[x].context !== undefined &&
                        galerie[x].context !== ''
                    ) {
                        if (galerie[x].context.split(' ').length > 0) {
                            for (
                                let s = 0;
                                s < galerie[x].context.split(' ').length;
                                s++
                            ) {
                                let stop = false
                                for (let c = 0; c < ControllTags.length; c++) {
                                    if (
                                        ControllTags[c] ===
                                        galerie[x].context.split(' ')[s]
                                    ) {
                                        stop = true
                                    }
                                }
                                if (stop === true) {
                                } else {
                                    tags.push(
                                        <>
                                            <Badge
                                                bg="primary"
                                                id={
                                                    'tag' +
                                                    x +
                                                    '' +
                                                    galerie[x].context.split(
                                                        ' '
                                                    )[s]
                                                }
                                                onClick={() =>
                                                    tag(
                                                        galerie[
                                                            x
                                                        ].context.split(' ')[s],
                                                        'tag' +
                                                            x +
                                                            '' +
                                                            galerie[
                                                                x
                                                            ].context.split(
                                                                ' '
                                                            )[s]
                                                    )
                                                }
                                                style={{ marginRight: '10px' }}
                                            >
                                                {
                                                    galerie[x].context.split(
                                                        ' '
                                                    )[s]
                                                }
                                            </Badge>
                                        </>
                                    )
                                    ControllTags.push(
                                        galerie[x].context.split(' ')[s]
                                    )
                                }
                            }
                        } else {
                            let stop = false
                            for (let c = 0; c < ControllTags.length; c++) {
                                if (ControllTags[c] === galerie[x].context) {
                                    stop = true
                                }
                            }
                            if (stop === true) {
                            } else {
                                ControllTags.push(galerie[x].context)
                                tags.push(
                                    <>
                                        <Badge
                                            bg="primary"
                                            id={
                                                'tag' +
                                                x +
                                                '' +
                                                galerie[x].context
                                            }
                                            onClick={() =>
                                                tag(
                                                    galerie[x].context,
                                                    'tag' +
                                                        x +
                                                        '' +
                                                        galerie[x].context
                                                )
                                            }
                                            style={{ marginRight: '10px' }}
                                        >
                                            {galerie[x].context}
                                        </Badge>
                                    </>
                                )
                            }
                        }
                    }
                }
            }

            let newElements = <>{img}</>
            let temps = document.createElement('div')
            temps.className = 'row'
            ReactDOM.render(newElements, temps)
            let eles = document.getElementById('imgHolder') as HTMLDivElement
            eles.appendChild(temps)

            let newElementstags = <>{tags}</>
            let tempstags = document.createElement('div')
            ReactDOM.render(newElementstags, tempstags)
            let elestags = document.getElementById('tags') as HTMLDivElement
            elestags.appendChild(tempstags)
        })
        .catch((error) => console.log('error', error))
}

export default Gallery
