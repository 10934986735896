import React from 'react'
import Chat from "./Pages/chat";

export const ChatPagesConfig = (value: any) => {

            return [

                {
                    path: '/chat/message',
                    elements: <Chat value={value} />,
                    roles: [
                        'superadmin',
                        'admin',
                        'author',
                        'contentcreator',
                        'operator',
                        'merchant',
                        'merchant_ma',
                        'user',
                        'host',
                        'icymare',
                        'engineer',
                        'locksmith',
                        'consultant',
                        'ticketsystem',
                        'worktime',
                        'teamtakt'
                    ]
                },
            ]

}

export const  ChatMenuConfig = (value: any) => {

            return [
                {
                    header: 'Chat',
                    icon: 'chat',
                    roles: [
                        'superadmin',
                        'admin',
                        'author',
                        'contentcreator',
                        'operator',
                        'merchant',
                        'merchant_ma',
                        'user',
                        'host',
                        'icymare',
                        'engineer',
                        'locksmith',
                        'consultant',
                        'ticketsystem',
                        'worktime',
                        'teamtakt'
                    ],
                    menuitem: [

                        {
                            name: 'dashboard.messages',
                            route: '/chat/message',
                            icon: 'chat',
                            roles: [
                                'superadmin',
                                'admin',
                                'author',
                                'contentcreator',
                                'operator',
                                'merchant',
                                'merchant_ma',
                                'user',
                                'host',
                                'icymare',
                                'engineer',
                                'locksmith',
                                'consultant',
                                'ticketsystem',
                                'worktime',
                                'teamtakt'
                            ]
                        },
                    ]
                }
            ]

}


export const ChatSupport = () => {


            return [

                {
                    title: 'Chat',
                    href: '/chat/new/ticketsystem/overview/1',
                    undertitle: 'Chat',
                    icon: 'chat',
                    text: 'menu.item.labels.description',
                    roles: ['superadmin', 'admin']
                }
            ]


}


export const ChatSidebar: string =
    '/ticket/overview/:page,/Label/ticket/overview'
