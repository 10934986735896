import React, {Component, useEffect} from "react";

const DesktopNotification = (props:{username:string,setFetchData:any,setMessages:any}) => {

    useEffect(() => {
        if (!("Notification" in window)) {
            console.log("Browser does not support desktop notification");
        } else {
            Notification.requestPermission();
        }

    })

    const showNotification = () => {

        var options = {
            body: props.username+' send a Message',
            icon:'http://localhost:3000/icon.png'
        };

        new Notification('TeamTakt - '+props.username ,options)
    }

   showNotification();
   props.setMessages([])
   props.setFetchData(Date.now());
    return (
        <></>
    );
}

/*class DesktopNotification extends Component {
    private body: string;

    constructor(body:string) {
        // @ts-ignore
        super();
        this.body = body;
        this.showNotification = this.showNotification.bind(this);
    }

    componentDidMount() {
        if (!("Notification" in window)) {
            console.log("Browser does not support desktop notification");
        } else {
            Notification.requestPermission();
        }
    }

    showNotification() {

        var options = {
            body: 'Mario Ugurcu send a Message',
            icon:'http://localhost:3000/icon.png'
        };

        new Notification('TeamTakt',options)
    }


    render() {
        this.showNotification()
        return (
            <div>
               </div>
        );
    }
}*/

export default DesktopNotification;
