import React, {useRef} from "react";
import UniButton from "../Button";
import Form from "react-bootstrap/Form";

interface FileUploadButtonProps{
    required?: boolean;
    classNameInput?: string | undefined;
    styleInput?: React.CSSProperties | undefined;
    id?: string | undefined;
    defaultValue?: string ;
    accept: string;
    ButtonLabel: string;
    DelButtonLabel:string,
    placeholderImg:string;
    classNameButton?: string | undefined;
    styleButton?: React.CSSProperties | undefined;
    name: string;
    defaultSRC?:string,
    img:{
        width:string | undefined,
        height:string | undefined
    }

}

const  FileUploadButton = (props:FileUploadButtonProps) => {
    const inputFileRef = useRef( null );
    const ImgRef = useRef( null );
    const InputBase64Ref = useRef( null );

    const convertBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const onFilechange = async ( e:any ) => {
        const [file] = e.target.files
        if (file !== null ) {
            const base64 = await convertBase64(file);
            if(ImgRef.current !== null) {
                (ImgRef.current as HTMLInputElement).src = base64 as string;
            }
            if(InputBase64Ref.current !== null) {
                (InputBase64Ref.current as HTMLInputElement).value = (base64 as string).split('base64,')[1];
            }
        }

    }

    const ClearImg = () => {

        if(ImgRef.current !== null) {
            (ImgRef.current as HTMLInputElement).src = props.placeholderImg;
        }
        if(inputFileRef.current !== null) {
        (inputFileRef.current as HTMLInputElement).value = '';
        }
        if(InputBase64Ref.current !== null) {
            (InputBase64Ref.current as HTMLInputElement).value = '';
        }

    }

    const onBtnClick = () => {
        /*Collecting node-element and performing click*/
        if(inputFileRef.current !== null) {
            (inputFileRef.current as HTMLInputElement).click();
        }

    }

    return (
        <>
            <div className={'text-cetner'}>
                <img src={(props.defaultSRC !== undefined && props.defaultSRC !== null && props.defaultSRC !== '' ? 'data:image/png;base64,'+props.defaultSRC : props.placeholderImg)} ref={ImgRef} className={'border center'} style={{height:props.img.height,width:props.img.width}}/>
            </div>

            <Form.Control ref={InputBase64Ref} name={props.name} defaultValue={props.defaultValue}
                          type={'hidden'}/>
            <Form.Control type={'file'}
                          id={props.id}
                          ref={inputFileRef}
                          style={props.styleInput}
                          className={props.classNameInput}
                          accept={props.accept}
                          required={props.required !== undefined ? props.required : false}
                          onChange={onFilechange}/>
            <UniButton type={'button'} class={props.classNameButton} style={props.styleButton} variant={'primary'}
                       onClick={onBtnClick}>{props.ButtonLabel}</UniButton>

            <UniButton type={'button'} class={props.classNameButton} style={props.styleButton} variant={'danger'}
                       onClick={ClearImg}>{props.DelButtonLabel}</UniButton>
        </>
    )
}

export default FileUploadButton;
