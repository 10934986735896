import { AuthPagesConfig } from '../@WUM/templates/default/Auth/modules'
import { TicketPagesConfig } from '../modules/ticketsystem/modules'
import {MergeTwoObjecttoArray} from '../@WUM/core/Function/index'
import components from './RegisterComponents'
import { DashbaordPagesConfig } from '../modules/CMS/dashboard/modules'
import { useSelector } from 'react-redux'
import {NotificationPagesConfig} from "../@WUM/core/notification";
import {MemberPagesConfig} from "../modules/settings/Member";
import {ActivityRecordingConfig} from "../modules/activityRecording";
import {Software_SupportConfig} from "../modules/support_system";
import {Ticketsystem2Config} from "../modules/ticketsystem2.0";
import {WorkTimeTrackingConfig} from "../modules/worktimetracking";
import {PluginPagesConfig} from "../modules/CMS/plugin/modules";
import {ChatPagesConfig} from "../@WUM/core/chat/modules";

export const RegisterRoute = (value: any) => {
    const Store = useSelector((state: any) => state)

    let TMPRegister = []
    let TMPRegisterIndividual
    TMPRegister = [
        ...AuthPagesConfig(value),
        ...TicketPagesConfig(value),
        ...MemberPagesConfig(value),
        ...NotificationPagesConfig(value),
        ...DashbaordPagesConfig(value),
        ...ActivityRecordingConfig(value),
        ...Software_SupportConfig(value),
        ...Ticketsystem2Config(value),
        ...PluginPagesConfig(value),
        ...WorkTimeTrackingConfig(value),
        ...ChatPagesConfig(value)
    ]
    TMPRegisterIndividual = []

    if (
        process.env.REACT_APP_DB_MODULS !== undefined &&
        Store.dynamikModuls.PagesModuls.length > 0 &&
        !Store.dynamikModuls.PagesModuls[0].hasOwnProperty('msg')
    ) {
        for (let x = 0; x < Store.dynamikModuls.PagesModuls.length; x++) {
            let search = Store.dynamikModuls.PagesModuls[x].namespace

            TMPRegisterIndividual = MergeTwoObjecttoArray(
                TMPRegisterIndividual,
                /* @ts-ignore */
                components[search](value)
            )
        }
    }
    TMPRegisterIndividual = [
        ...TMPRegisterIndividual,
        ...DashbaordPagesConfig(value),
        ...NotificationPagesConfig(value)
    ]

    if (process.env.REACT_APP_DB_MODULS === undefined) {
        return [...TMPRegister]
    } else {
        if (localStorage.getItem('userRole') === 'superadmin') {
            return [...TMPRegister, ...TMPRegisterIndividual]
        } else {
            return [...TMPRegisterIndividual]
        }
    }
}
