export interface config {
    id: number
    tax: any;
    vat: any;
    bic: any;
    iban: any;
    bank: any;
    blz: any;
    accountNumber: any;
    accountHolder: any;
    website:any
    mail:any
    cityL:any
    zip: any;
    street: any;
    owner: any;
    name: any;
    country:any
    phone:any
    favicon:any
    logo:any
}

export function output(Data: any) {
    let back = {
        id: Data.id,
        tax: Data.tax,
        vat: Data.vat,
        bic: Data.bic,
        iban: Data.iban,
        bank:Data.bank,
        blz: Data.blz,
        accountNumber: Data.accountNumber,
        accountHolder: Data.accountHolder,
        website:Data.website,
        mail:Data.mail,
        cityL:Data.cityL,
        zip: Data.zip,
        street: Data.street,
        owner:Data.owner,
        name:Data.name,
        country:Data.country,
        phone:Data.phone,
        favicon:Data.favicon,
        logo:Data.logo
    }

    return back
}
