import { configureStore } from '@reduxjs/toolkit'
import LocaleReducer from '../@WUM/templates/default/localization/store/Reducers/Locale/LocaleReducer'
import UserReducer from '../@WUM/templates/default/Auth/store/reducer/User/UserReducer'
import DynamikModulsReducer from '../@WUM/templates/default/Auth/store/reducer/dynamikModuls/dynamikModulsReducer'
import SingleTasksReducer from "../modules/ticketsystem/modules/new/store/reducer/Singletask/SingleTask";

const store = configureStore({
    reducer: {
        locale: LocaleReducer,
        user: UserReducer,
        dynamikModuls: DynamikModulsReducer,
        SingleTasks: SingleTasksReducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
