import {chatInterface} from "./chat.interface";
import {requests} from "../../../RestFullApi/api";
import {UserInterfaceApiType} from "./user.interface";
import {groupInterface} from "./group.interface";

export const chat  = {
    getPostsAllChat: (id: any): Promise<chatInterface[]> =>
        requests.get(`yoptimize/chat/messages/${id}/`),
    getPostUser: (): Promise<UserInterfaceApiType[]> =>
        requests.get(`User/fetch/`),
    getPostGroup: (): Promise<groupInterface[]> =>
        requests.get(`yoptimize/chat/user/group/`)
}
