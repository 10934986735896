import image from './elements/image'
import string from './elements/text'
import status from './elements/status'
import date from './elements/date'
import shipping_state from './elements/shipping_state'
import payment_state from './elements/payment_state'
import order_state from './elements/order_state'
import dateCut from './elements/dateCut'
import summe from './elements/summe'
import customer from './elements/customer'
import invoicetype from './elements/invoicetype'
import dateInvoice from './elements/dateInvoice'
import invoicepdf from './elements/invoicepdf'
import coupon from './elements/coupon'
import { time } from './elements/time'
import { timeline } from './elements/timeline'
import { km } from './elements/km'
import { timeCut } from './elements/timeCut'
import { dateCutfromdate } from './elements/dateCutfromdate'
import amountToSum from './elements/amountToSum'
import downloadPDF from './elements/downloadPDF'
import buttonTable from './elements/button'
import { usergroupMHP } from './elements/usergroupMHP'
import indi from './elements/indi'
import dateFormateDE from './elements/dateFormateDE'
import euro from './elements/euro'
import fetchLocal from './elements/fetchLocal'
import email from './elements/email'
import phone from './elements/phone'
import injection from './elements/injection'
import stringUtf8Decode from './elements/text_utf8_decode'
import {addedString} from "./elements/addedString";
import {TableBadge} from "./elements/badge";
import summeWithStorno from "./elements/summeWithStorno";
import Twofields from "./elements/Twofields";
import PumpenwolfKdIcon from "./elements/PumpenwolfKdIcon";
import {TableBadgeGrundfos} from "./elements/badgeGrundfos";
import buttonChangeable from "./elements/buttonChangeable"
import doubleButtonChangeable from "./elements/doubleButtonChangeable";

function baseRow(props: {
    data: any
    tableConfiguration: any
    tableBodyType: any
    indexID: any
    Menu: any
    Store: any
    tableBodyPosition: any
}) {
    const row = []

    for (let i = 0; i < props.tableConfiguration.length; i++) {
        let datamap
        let VisitData = false
        if (props.Menu.menutrigger === true) {
            VisitData = makeParam(props, props.Menu.menuParams[0], i)
        } else {
        }
        let VisitDataDel = false
        if (props.Menu.menutriggerDelete === true) {
            VisitDataDel = makeParam(props, props.Menu.menuParams[2], i)
        } else {
        }
        if (props.tableBodyType[i] !== 'individual') {
            datamap = makedata(props, i)
        } else {
        }

        if (props.tableConfiguration[i])
            if (props.tableBodyType[i] === 'img') {
                row.push(
                    image(
                        props.data[props.tableConfiguration[i]],
                        props.data[props.indexID],
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'usergroupMHP') {
                row.push(
                    usergroupMHP(
                        props.data[props.tableConfiguration[i]],
                        props.data[props.indexID],
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'string') {
                row.push(
                    string(
                        datamap,
                        props.data[props.indexID],
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'email') {
                row.push(
                    email(
                        datamap,
                        props.data[props.indexID],
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'phone') {
                row.push(
                    phone(
                        datamap,
                        props.data[props.indexID],
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'injection') {
                row.push(
                    injection(
                        datamap,
                        props.data[props.indexID],
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'invoicepdf') {
                row.push(
                    invoicepdf(
                        datamap,
                        props.data[props.indexID],
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i].includes('status')) {
                let param = props.tableBodyType[i].split('|')
                row.push(
                    status(
                        datamap,
                        props.data[props.indexID],
                        i,
                        param,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'date') {
                row.push(
                    date(
                        datamap,
                        props.data[props.indexID],
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'dateInvoice') {
                row.push(
                    dateInvoice(
                        datamap,
                        props.data[props.indexID],
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'dateCut') {
                row.push(
                    dateCut(
                        datamap,
                        props.data[props.indexID],
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'time') {
                row.push(
                    time(
                        datamap,
                        props.data[props.indexID],
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i].split('|')[0] === 'timeline') {
                row.push(
                    timeline(
                        datamap,
                        props.data,
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyType[i],
                        props.data[props.indexID],
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i].split('|')[0] === 'downloadPDF') {
                row.push(
                    downloadPDF(
                        datamap,
                        props.data,
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyType[i],
                        props.data[props.indexID],
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i].split('|')[0] === 'indi') {
                row.push(
                    indi(
                        datamap,
                        props.data,
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyType[i],
                        props.data[props.indexID],
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i].split('|')[0] === 'Twofields') {
                row.push(
                    Twofields(
                        datamap,
                        props.data,
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyType[i],
                        props.data[props.indexID],
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i].split('|')[0] === 'buttonChangeable') {
                row.push(
                    buttonChangeable(
                        props.data,
                        props.Store,
                        props.tableBodyType[i],
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i].split('|')[0] === 'doubleButtonChangeable') {
                row.push(
                    doubleButtonChangeable(
                        props.data,
                        props.Store,
                        props.tableBodyType[i],
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i].split('|')[0] === 'fetchLocal') {
                row.push(
                    fetchLocal(
                        datamap,
                        props.data,
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyType[i],
                        props.data[props.indexID],
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'km') {
                row.push(
                    km(
                        datamap,
                        props.data[props.indexID],
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'PumpenwolfKdIcon') {
                row.push(
                    PumpenwolfKdIcon(
                        datamap,
                        props.data[props.indexID],
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'orderstate') {
                row.push(
                    order_state(
                        datamap,
                        props.data[props.indexID],
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'shipping_state') {
                row.push(
                    shipping_state(
                        datamap,
                        props.data[props.indexID],
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'payment_state') {
                row.push(
                    payment_state(
                        datamap,
                        props.data[props.indexID],
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'individual') {
                row.push(
                    string(
                        props.tableConfiguration[i],
                        props.data[props.indexID],
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'summe') {
                row.push(
                    summe(
                        datamap,
                        props.data[props.indexID],
                        i,
                        props.data,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'summeWithStorno') {
                row.push(
                    summeWithStorno(
                        datamap,
                        props.data[props.indexID],
                        i,
                        props.data,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'euro') {
                row.push(
                    euro(
                        datamap,
                        props.data[props.indexID],
                        i,
                        props.data,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'amountToSum') {
                row.push(
                    amountToSum(
                        datamap,
                        props.data[props.indexID],
                        i,
                        props.data,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'costumer') {
                row.push(
                    customer(
                        datamap,
                        props.data[props.indexID],
                        i,
                        props.data,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'invoice_type') {
                row.push(
                    invoicetype(
                        datamap,
                        props.data[props.indexID],
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'buttonTable') {
                row.push(
                    buttonTable(
                        props.tableConfiguration[i],
                        props.data[props.indexID],
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'coupon') {
                row.push(
                    coupon(
                        datamap,
                        props.data[props.indexID],
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'timeCut') {
                row.push(
                    timeCut(
                        datamap,
                        props.data[props.indexID],
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'dateCutfromdate') {
                row.push(
                    dateCutfromdate(
                        datamap,
                        props.data[props.indexID],
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'dateFormateDE') {
                row.push(
                    dateFormateDE(
                        datamap,
                        props.data[props.indexID],
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i] === 'stringUtf8Decode') {
                row.push(
                    stringUtf8Decode(
                        datamap,
                        props.data[props.indexID],
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i]
                    )
                )
            } else if (props.tableBodyType[i].split('|')[0] === 'addedString') {
                row.push(
                    addedString(
                        datamap,
                        props.data[props.indexID],
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i],
                        props.tableBodyType[i]
                    )
                )
            }  else if (props.tableBodyType[i].split('|')[0] === 'Badge') {
                row.push(
                    TableBadge(
                        datamap,
                        props.data[props.indexID],
                        i,
                        VisitData,
                        VisitDataDel,
                        props.Store,
                        props.tableBodyPosition[i],
                        props.tableBodyType[i]
                    )
                )
            }  else if (props.tableBodyType[i].split('|')[0] === 'BadgeGrundfos') {
                    row.push(
                        TableBadgeGrundfos(
                            datamap,
                            props.data[props.indexID],
                            i,
                            VisitData,
                            VisitDataDel,
                            props.Store,
                            props.tableBodyPosition[i],
                            props.tableBodyType[i]
            )
        )
    }
    }
    return row
}

export const makeParam = (
    props: { data: any; tableConfiguration: any },
    data: any,
    i: number
) => {
    let datamap

    if (data === undefined) {
        datamap = ''

        return datamap
    }

    let testing = data.split('.')
    if (testing.length === 1) {
        datamap = props.data[data]
    } else if (testing.length === 2) {
        if (
            props.data.hasOwnProperty(testing[0]) === true &&
            props.data[testing[0]] !== null
        ) {
            if (props.data[testing[0]].hasOwnProperty(testing[1]) === true) {
                datamap = props.data[testing[0]][testing[1]]
            } else {
                datamap = ''
            }
        } else {
            datamap = ''
        }
    } else if (testing.length === 3) {
        if (
            props.data.hasOwnProperty(testing[0]) === true &&
            props.data[testing[0]] !== null
        ) {
            if (props.data[testing[0]].hasOwnProperty(testing[1]) === true) {
                if (
                    props.data[testing[0]][testing[1]].hasOwnProperty(
                        testing[2]
                    ) === true
                ) {
                    datamap = props.data[testing[0]][testing[1]][testing[2]]
                } else {
                    datamap = ''
                }
            } else {
                datamap = ''
            }
        } else {
            datamap = ''
        }
    } else if (testing.length === 4) {
        if (
            props.data.hasOwnProperty(testing[0]) === true &&
            props.data[testing[0]] !== null
        ) {
            if (props.data[testing[0]].hasOwnProperty(testing[1]) === true) {
                if (
                    props.data[testing[0]][testing[1]].hasOwnProperty(
                        testing[2]
                    ) === true
                ) {
                    if (
                        props.data[testing[0]][testing[1]][
                            testing[2]
                        ].hasOwnProperty(testing[3]) === true
                    ) {
                        datamap =
                            props.data[testing[0]][testing[1]][testing[2]][
                                testing[3]
                            ]
                    } else {
                        datamap = ''
                    }
                } else {
                    datamap = ''
                }
            } else {
                datamap = ''
            }
        } else {
            datamap = ''
        }
    } else if (testing.length === 5) {
        if (
            props.data.hasOwnProperty(testing[0]) === true &&
            props.data[testing[0]] !== null
        ) {
            if (props.data[testing[0]].hasOwnProperty(testing[1]) === true) {
                if (
                    props.data[testing[0]][testing[1]].hasOwnProperty(
                        testing[2]
                    ) === true
                ) {
                    if (
                        props.data[testing[0]][testing[1]][
                            testing[2]
                        ].hasOwnProperty(testing[3]) === true
                    ) {
                        if (
                            props.data[testing[0]][testing[1]][testing[2]][
                                testing[3]
                            ].hasOwnProperty(testing[4]) === true
                        ) {
                            datamap =
                                props.data[testing[0]][testing[1]][testing[2]][
                                    testing[3]
                                ][testing[4]]
                        } else {
                            datamap = ''
                        }
                    } else {
                        datamap = ''
                    }
                } else {
                    datamap = ''
                }
            } else {
                datamap = ''
            }
        } else {
            datamap = ''
        }
    }

    return datamap
}

function makedata(props: { data: any; tableConfiguration: any }, i: number) {
    let datamap
    let testing = props.tableConfiguration[i].split('.')
    if (testing.length === 1) {
        datamap = props.data[props.tableConfiguration[i]]
    } else if (testing.length === 2) {
        if (
            props.data.hasOwnProperty(testing[0]) === true &&
            props.data[testing[0]] !== null
        ) {
            if (props.data[testing[0]].hasOwnProperty(testing[1]) === true) {
                datamap = props.data[testing[0]][testing[1]]
            } else {
                datamap = ''
            }
        } else {
            datamap = ''
        }
    } else if (testing.length === 3) {
        if (
            props.data.hasOwnProperty(testing[0]) === true &&
            props.data[testing[0]] !== null
        ) {
            if (props.data[testing[0]].hasOwnProperty(testing[1]) === true) {
                if (
                    props.data[testing[0]][testing[1]].hasOwnProperty(
                        testing[2]
                    ) === true
                ) {
                    datamap = props.data[testing[0]][testing[1]][testing[2]]
                } else {
                    datamap = ''
                }
            } else {
                datamap = ''
            }
        } else {
            datamap = ''
        }
    } else if (testing.length === 4) {
        if (
            props.data.hasOwnProperty(testing[0]) === true &&
            props.data[testing[0]] !== null
        ) {
            if (props.data[testing[0]].hasOwnProperty(testing[1]) === true) {
                if (
                    props.data[testing[0]][testing[1]].hasOwnProperty(
                        testing[2]
                    ) === true
                ) {
                    if (
                        props.data[testing[0]][testing[1]][
                            testing[2]
                        ].hasOwnProperty(testing[3]) === true
                    ) {
                        datamap =
                            props.data[testing[0]][testing[1]][testing[2]][
                                testing[3]
                            ]
                    } else {
                        datamap = ''
                    }
                } else {
                    datamap = ''
                }
            } else {
                datamap = ''
            }
        } else {
            datamap = ''
        }
    } else if (testing.length === 5) {
        if (
            props.data.hasOwnProperty(testing[0]) === true &&
            props.data[testing[0]] !== null
        ) {
            if (props.data[testing[0]].hasOwnProperty(testing[1]) === true) {
                if (
                    props.data[testing[0]][testing[1]].hasOwnProperty(
                        testing[2]
                    ) === true
                ) {
                    if (
                        props.data[testing[0]][testing[1]][
                            testing[2]
                        ].hasOwnProperty(testing[3]) === true
                    ) {
                        if (
                            props.data[testing[0]][testing[1]][testing[2]][
                                testing[3]
                            ].hasOwnProperty(testing[4]) === true
                        ) {
                            datamap =
                                props.data[testing[0]][testing[1]][testing[2]][
                                    testing[3]
                                ][testing[4]]
                        } else {
                            datamap = ''
                        }
                    } else {
                        datamap = ''
                    }
                } else {
                    datamap = ''
                }
            } else {
                datamap = ''
            }
        } else {
            datamap = ''
        }
    }

    return datamap
}

export default baseRow
